import { ApiClient } from '../shared/api/api-client'

export interface ExchangeRatesModel {
    [key: string]: number
}

export class ExchangeRatesApi {
    public static get() {
        return ApiClient.get<ExchangeRatesModel>(`api/ExchangeRates/Get`)
    }
}
