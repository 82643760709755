import { ApiClient } from '../shared/api/api-client'
import { ConcreteTenderDocumentModel, Currency, TenderStatusHistoryEnum } from '../shared/models'

export interface TenderConfigurationModel {
    canChangeVat: boolean
    showProductDimensionsInfo: boolean
    isFreeCompany: boolean
    enterFullPriceEnabled: boolean
    nextTenderNumber: string
    newTenderNote: string
    newTenderReceiptNote: string
    newTenderCurrency: Currency
    newTenderClientCurrency: Currency
    defaultVat: number
    documentCurrencyRate: number
    clientCurrencyRate: number
    isAccountingVat: boolean
    editPurchasePrice: boolean
    defaultMailText: string
    canCreateInvoices: boolean
    canCreateTenders: boolean
    defaultTenderLanguageId: string
    showVatAccountingWarning: boolean
    showPriceCalculationWarning: boolean
}

export interface TenderLogModel {
    createdAt: string
    status: TenderStatusHistoryEnum
    userFullName: string | null
    additionalInformation: string | null
    showOnlyStatusChange: boolean
}

export class ConcreteTenderApi {
    public static get(concreteTenderId: number | string) {
        return ApiClient.get<ConcreteTenderDocumentModel>(`api/ConcreteTender/GetTender/${concreteTenderId}`)
    }

    public static delete(tenderId: number | string) {
        return ApiClient.delete(`api/ConcreteTender/DeleteTender/${tenderId}`)
    }

    public static getDocument(tenderId: number | string) {
        return ApiClient.get(`api/ConcreteTender/GetDocument?tenderId=${tenderId}`, { responseType: 'arraybuffer' })
    }

    public static upsert(document: ConcreteTenderDocumentModel) {
        return ApiClient.post<ConcreteTenderDocumentModel>(`api/ConcreteTender/Upsert`, document)
    }

    public static getSimpleTenderConfig() {
        return ApiClient.get<{ nextNumber: string; defaultTenderLanguageId: string }>(`api/ConcreteTender/GetSimpleTenderConfig`)
    }

    public static getConfiguration(tenderId: string | undefined) {
        return ApiClient.get<TenderConfigurationModel>(`api/ConcreteTender/GetConfiguration${tenderId ? `?tenderId=${tenderId}` : ''}`)
    }

    static sendTender(
        tenderId: string | number | undefined,
        toEmail: string,
        toEmailsCc: string,
        emailBody: string,
        includeCopyToSelf: boolean
    ) {
        return ApiClient.post<number>('api/concretetender/SendTenderViaEmail', {
            tenderId,
            toEmail,
            toEmailsCc,
            emailBody,
            includeCopyToSelf,
        })
    }

    public static changeStatus(tenderId: number, newStatus: number) {
        return ApiClient.post(`api/concretetender/ChangeTenderStatus`, {
            tenderId: tenderId,
            status: newStatus,
        })
    }

    public static getLogs(tenderId: number | string) {
        return ApiClient.get<TenderLogModel[]>(`api/concretetender/logs/${tenderId}`)
    }

    public static createFromTemplateTender(templateTenderId: number | string) {
        return ApiClient.post<{ id: number }>(`api/documentconversion/CreateConcreteTenderFromTemplateTender`, { templateTenderId })
    }

    public static getListAggregatedValues(projectionName: string, filters?: any[] | null, customFilter?: string | null) {
        return ApiClient.post<{ currency: Currency; sumOfItemsBasePrice: number; sumOfItemsFullPrice: number }[]>(
            'api/concretetender/GetListAggregatedValues',
            {
                projectionName,
                filters: filters || [],
                customFilter,
            }
        )
    }
}
