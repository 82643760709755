import React, { ReactNode } from 'react'
import { Formik, Form, Field, FormikProps } from 'formik'
import { useGridActions } from './GridContextProvider'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'

export const GridFilterForm = <T extends {}>({
    children,
    titleKey,
    searchButtonLabelKey,
    onSubmit,
    initialValues,
    searchButtonClassName = '',
}: {
    children: ((formikProps: FormikProps<T>) => ReactNode) | ReactNode;
    titleKey: string;
    searchButtonClassName?: string;
    searchButtonLabelKey?: string | null;
    onSubmit: (values: T) => void;
    initialValues: T;
}) => {
    const { t } = useOfferionTranslation()

    const ga = useGridActions();

    const searchButtonClasses = `button button--blue search__button mod-margin-bottom-16 ${searchButtonClassName}`
    return (
        <Formik onSubmit={val => {
            onSubmit(val);

            ga.toggleFiltersModal();
        }} initialValues={initialValues}>
            {formikProps => <Form className="search-form simple-search">
                <h2 className="search__title">{t(titleKey)}</h2>

                <div className="search__content">
                    {children instanceof Function ? children(formikProps) : children}
                    {searchButtonLabelKey && <button type="submit" className={searchButtonClasses}>
                        {t(searchButtonLabelKey)}
                        <span className="icon icon__search mod-margin-left-4"></span>
                    </button>}
                </div>
            </Form>
            }
        </Formik>
    )
}

export const GridFilterInput = ({ name, placeholderKey }: { name: string; placeholderKey: string }) => {
    const { t } = useOfferionTranslation()

    return <Field type="text" name={name} placeholder={t(placeholderKey)} className="search__input search__input--with-options search__item" />
}