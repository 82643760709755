export default function forCharge(enterFullPriceEnabled: boolean, items: { vat: number, discount: number, quantity: number, fullPrice: number, salePrice: number }[]) {
    return items.reduce((prev, item) => {

        let total = 0
        let charge = 0
        if (enterFullPriceEnabled) {
            total = (item.fullPrice ?? 0) / (1 + item.vat / 100)
            charge = item.fullPrice ?? 0
        } else {
            total = item.salePrice * item.quantity * (1 - item.discount / 100)
            charge = item.salePrice * item.quantity * (1 + item.vat / 100) * (1 - item.discount / 100)
        }
        prev += charge

        return prev;

    }, 0)
}