import React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../shared/routing/AuthenticatedRoute'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { AgreedPricesContainer } from './agreed-prices/AgreedPricesListContainer';
import { ClientsListContainer } from './list/ClientsListContainer';

export const ClientsContainer = () => {
    const { t } = useOfferionTranslation();

    return <>

        <BreadcrumbsItem to="/clients">
            {t("Clients.Header.pageTitle")}
        </BreadcrumbsItem>

        <Switch>
            <AuthenticatedRoute exact path="/clients" component={ClientsListContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/clients/:clientId/agreed-prices" component={AgreedPricesContainer}></AuthenticatedRoute>
        </Switch>

    </>
}