import React from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Switch } from 'react-router-dom';
import { AuthenticatedRoute } from '../../shared/routing/AuthenticatedRoute'
import { CompanySettingsContainer } from './company-settings/CompanySettingsContainer';
import { PrintSettingsContainer } from './print-settings/PrintSettingsContainer';
import { UsersListContainer } from './users/UsersListContainer'
import { PicturesContainer } from './pictures/PicturesContainer'
import { SalePlacesListContainer } from './sale-places/SalePlacesListContainer'
import { FiscalizationSettingsContainer } from './fiscalization/FiscalizationSettingsContainer'
import { EInvoiceSettingsContainer } from './e-invoice/EInvoiceSettingsContainer'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export const SettingsContainer = () => {
    const { t } = useOfferionTranslation();

    return <>

        <BreadcrumbsItem to="/settings">
            {t("Settings.Header.pageTitle")}
        </BreadcrumbsItem>

        <Switch>
            <AuthenticatedRoute exact path="/settings" component={CompanySettingsContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/print" component={PrintSettingsContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/users" component={UsersListContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/pictures" component={PicturesContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/sale-places" component={SalePlacesListContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/fiscalization" component={FiscalizationSettingsContainer}></AuthenticatedRoute>
            <AuthenticatedRoute exact path="/settings/e-invoice" component={EInvoiceSettingsContainer}></AuthenticatedRoute>
        </Switch>

    </>
}