import React, { ReactNode, useEffect } from "react";
import { createPortal } from "react-dom";

const modalRoot = document.getElementById("modal-root");

export const Modal = ({ isOpen, children }: { isOpen: boolean, children: ReactNode | Function }) => {

    if (!isOpen)
        return null;

    if (!modalRoot)
        return null;

    return createPortal(
        <div style={{ zIndex: 1200, display: "block" }}>
            {typeof children == "function" ? children() : children}
        </div>,
        modalRoot
    )
}

export default Modal;