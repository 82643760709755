import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, PropsWithChildren, ReactNode, useEffect, useRef, CSSProperties } from 'react'
import { Link } from 'react-router-dom'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'

export const GridMenuLink = ({ to, translationKey, icon }: { to: string; translationKey: string, icon?: IconProp }) => {
    const { t } = useOfferionTranslation()

    return (
        <li>
            <Link to={to}>
                {icon && <span style={{ width: "30px", display: "inline-block" }} ><FontAwesomeIcon icon={icon} /></span>}

                {t(translationKey)}
            </Link>
        </li>
    )
}

export const GridMenuActionLink = ({ onClick, translationKey, icon }: { onClick: () => void; translationKey: string, icon?: IconProp }) => {
    const { t } = useOfferionTranslation()

    return (
        <li>
            <a href="#" onClick={e => { e.preventDefault(); onClick(); }}>
                {icon && <span style={{ width: "30px", display: "inline-block" }} ><FontAwesomeIcon icon={icon} /></span>}

                {t(translationKey)}
            </a>
        </li>
    )
}

export const GridMenuCell = ({ children, style }: { children: ReactNode, style?: CSSProperties }) => {
    const [showSideMenu, setShowSideMenu] = useState(false)
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {

        const handleClick = (e: MouseEvent) => {
            const isClickInside = ref.current && ref.current.contains(e.target as any);

            if (!isClickInside)
                setShowSideMenu(false);
        }

        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        }
    }, [])

    return (
        <div ref={ref} className="table__cell table__cell__menu" onClick={() => showSideMenu && setShowSideMenu(false)}>
            <span className="table__cell table__cell__menu" ref={ref} onClick={() => showSideMenu && setShowSideMenu(false)}>
                <div
                    className={`button-outer overlay__item overlay__menu overlay__item--bottom-left ${showSideMenu ? 'overlay__item--active overlay__item--show-overlay' : ''
                        }`}>
                    <button
                        type="button"
                        onClick={() => setShowSideMenu(!showSideMenu)}
                        className={`button button--gray overlay__button  ${showSideMenu ? 'overlay__button--active' : ''}`}>
                        <span className="icon icon__menu-blue"></span>
                    </button>

                    <div className="overlay">
                        <ul className="overlay__list">{children}</ul>
                    </div>
                </div>
            </span>
        </div>
    )
}
