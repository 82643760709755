import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { DocumentItemModel, DocumentModel, DocumentDetails } from '../../../models'
import { CurrencyCode, CurrencyLabel } from '../../../../shared/ui/currency'
import { CompanyPrintSettingsModel } from '../../../models'
import { TaxFreeCheckbox } from './TaxFreeCheckbox'
import { OfferionErrorMessage } from '../../../../shared/forms/validation/OfferionErrorMessage'
import * as Yup from 'yup'
import { UnitItemModel } from '../../../../services/UnitsApi'
import { useHistory } from 'react-router-dom'
import { ImageWithAuthorization } from '../../../elements/ImageWithAuthorization'
import { Overlay } from '../../../elements/Overlay'
import round from '../../../utils/round'
import calculateUpdatedPrices from '../../../utils/calculateUpdatedPrices'
import { CurrencyField } from '../../../forms/CurrencyField'
import { NumberField } from '../../../forms/NumberField'
import { useOfferionDocument } from '../DocumentDetailsContainer'
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation'

interface DocumentItemFormProps {
    closeForm: () => void
    onSubmit: (client: DocumentItemModel) => Promise<DocumentDetails>
    initialValues?: DocumentItemModel | null
    enterFullPriceEnabled: boolean
    document: DocumentModel<DocumentDetails>
    units: UnitItemModel[]
    isAccountingVat: boolean
    canChangeVat: boolean
    defaultVat: number
    editPurchasePrice: boolean
    printSettings: CompanyPrintSettingsModel
    canSubmit: boolean
    showProductDimensionsInfo: boolean
}

export const DocumentItemForm = (props: DocumentItemFormProps) => {
    const { t } = useOfferionTranslation()
    const history = useHistory()
    const offerionDocument = useOfferionDocument()

    const unitIsInSettings = !!props.units.find(x => x.name == props.initialValues?.unit.name)

    const enterFullPriceEnabled = props.enterFullPriceEnabled

    const [showAdditionalInformationEditFormOverlay, setShowAdditionalInformationEditFormOverlay] = useState(false)
    const [showUnitSelector, setShowUnitSelector] = useState(!unitIsInSettings)

    const initialValues = props.initialValues
        ? {
              ...props.initialValues,
              vat: props.initialValues.vat,
              realPrice: round(props.initialValues.fullPrice / (1 - props.initialValues.discount / 100)),
          }
        : {
              quantity: 1,
              salePrice: 0,
              fullPrice: 0,
              id: 0,
              vat: props.isAccountingVat ? props.defaultVat : 0,
              discount: offerionDocument.globalSettings.globalDiscount * 100,
              purchasePrice: 0,
              unit: {
                  existsInSettings: false,
                  name: '',
              },
              taxFree: false,
              name: '',
              index: props.document.items.length + 1,
              dimension: '',
              additionalNote: '',

              realPrice: 0,
          }

    const onSubmit_inner = (item: DocumentItemModel) => {
        if (enterFullPriceEnabled) {
            item.salePrice = round(round(round(item.fullPrice / item.quantity / (1 - item.discount / 100))) / (1 + item.vat / 100))
        } else {
            item.fullPrice = round(round(round(round(item.salePrice * item.quantity) * (1 + item.vat / 100))) * (1 - item.discount / 100))
        }

        return props.onSubmit(item)
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('Allaround.Validation.required', { fieldName: t('Invoice.Table.Header.name') })),
        salePrice: Yup.number().required(t('Allaround.Validation.required', { fieldName: t('Invoice.Edit.basicPrice') })),
        purchasePrice: Yup.number().required(t('Allaround.Validation.required', { fieldName: t('Invoice.Edit.purchasePrice') })),
        quantity: Yup.number()
            .nullable()
            .required(t('Allaround.Validation.required', { fieldName: t('Invoice.Edit.quantity') }))
            .notOneOf([0]),
        discount: Yup.number()
            .required(t('Allaround.Validation.required', { fieldName: t('Invoice.Edit.discount') }))
            .test('LessThan0', 'Allaround.Validation.discountNegative', val => {
                if (val == null) return true

                return val >= 0
            })
            .test('MoreOrEqualThan100', 'Allaround.Validation.discountMoreThan100', val => {
                if (val == null) return true

                return val < 100
            }),
        realPrice: Yup.number().min(Yup.ref('fullPrice'), t('Allaround.Validation.invalidPriceWithoutDiscount')),
        fullPrice: Yup.number().required(t('Allaround.Validation.required', { fieldName: t('Invoice.Edit.totalPrice') })),
    })

    return (
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmit_inner}>
            {formikProps => {
                const unitIsInSettings = !!props.units.find(x => x.name == formikProps.values.unit.name)

                const decimalVat = formikProps.values.vat / 100
                const updatedPrices = calculateUpdatedPrices(enterFullPriceEnabled, formikProps.values)

                return (
                        <Overlay style={{ textAlign: 'left' }}>
                            <Form noValidate={true}>
                                <div>
                                    <h1 className="overlay__title">
                                        {props.initialValues
                                            ? props.initialValues.name || props.initialValues.dimension
                                            : t('Invoice.Table.buttonNew')}
                                    </h1>

                                    <button type="button" className="button overlay__close" onClick={props.closeForm}>
                                        <span className="icono-cross"></span>
                                    </button>

                                    <div className="info__container">
                                        <div className="info__row">
                                            <label className="info__row-name">{t('Invoice.Table.Header.name')}</label>
                                            <div className="info__row-content">
                                                <Field autoFocus as="textarea" placeholder={t('Invoice.Edit.itemNamePlaceholder')} name="name" />
                                                <OfferionErrorMessage name="name" />
                                            </div>
                                        </div>
                                        <div className="info__row">
                                            <div className="info__row__inner-half info__row__inner-special-case">
                                                <label>
                                                    {' '}
                                                    {enterFullPriceEnabled ? t('Invoice.Edit.totalPrice') : t('Invoice.Edit.basicPrice')}
                                                </label>
                                            </div>
                                            <div className="info__row__inner-half">
                                                {enterFullPriceEnabled ? (
                                                    <Field
                                                        component={CurrencyField}
                                                        name="fullPrice"
                                                        placeholder={t('Invoice.Edit.totalPricePlaceholder')}
                                                        onChange={(newValue: number | '') => {
                                                            const fullPrice = newValue == '' ? 0 : newValue

                                                            formikProps.setValues({
                                                                ...formikProps.values,
                                                                realPrice: round(fullPrice / (1 - formikProps.values.discount / 100)),
                                                                fullPrice: newValue,
                                                            } as any)
                                                        }}
                                                    />
                                                ) : (
                                                    <Field
                                                        component={CurrencyField}
                                                        name="salePrice"
                                                        placeholder={t('Invoice.Edit.basicPricePlaceholder')}
                                                    />
                                                )}
                                                <span className="text-color mod-margin-left-4">
                                                    <CurrencyCode currency={props.document.details.documentCurrency}></CurrencyCode>
                                                </span>
                                                <div>
                                                    {enterFullPriceEnabled ? (
                                                        <OfferionErrorMessage name="fullPrice" />
                                                    ) : (
                                                        <OfferionErrorMessage name="salePrice" />
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="info__row">
                                            <div className="info__row__inner-half info__row__inner-special-case">
                                                <label className="">{t('Invoice.Edit.quantity')}</label>
                                            </div>

                                            <div className="info__row__inner-half">
                                                <Field component={NumberField} name="quantity" />
                                                <div>
                                                    <OfferionErrorMessage name="quantity" />
                                                </div>
                                            </div>
                                        </div>
                                        {enterFullPriceEnabled && (
                                            <div className="info__row">
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__label">{t('Invoice.Edit.pricePerUnit')}</label>
                                                </div>
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__amount left">
                                                        <CurrencyLabel
                                                            amount={
                                                                formikProps.values.quantity != 0
                                                                    ? formikProps.values.fullPrice / formikProps.values.quantity
                                                                    : 0
                                                            }
                                                            currency={props.document.details.documentCurrency}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                        {enterFullPriceEnabled && props.isAccountingVat && (
                                            <div
                                                className="info__row"
                                                style={{ position: 'relative', bottom: '10px', borderTop: 0, color: '#505050' }}>
                                                <div className="info__row__inner-half">
                                                    <span className="subscript" style={{ textAlign: 'left', marginLeft: 0 }}>
                                                        Jedinična cijena bez PDV-a
                                                    </span>
                                                </div>
                                                <div className="info__row__inner-half">
                                                    <span className="subscript" style={{ textAlign: 'left' }}>
                                                        <CurrencyLabel
                                                            amount={
                                                                formikProps.values.quantity != 0
                                                                    ? formikProps.values.fullPrice /
                                                                      formikProps.values.quantity /
                                                                      (1 + decimalVat)
                                                                    : 0
                                                            }
                                                            currency={props.document.details.documentCurrency}
                                                        />
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                        {showAdditionalInformationEditFormOverlay && (
                                            <div className="info__additional">
                                                <div className="info__row">
                                                    <label className="info__row-name">{t('Invoice.Edit.measurementUnit')}</label>
                                                    <div className="info__row-content">
                                                        {!showUnitSelector ? (
                                                            <div
                                                                className="info__editable"
                                                                onClick={() => setShowUnitSelector(true)}
                                                                style={{ float: 'left', width: '49%', marginRight: '5px' }}>
                                                                {formikProps.values.unit.name}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div
                                                                    className="info__select-container select-container"
                                                                    style={{
                                                                        float: 'left',
                                                                        position: 'relative',
                                                                        width: '49%',
                                                                        marginRight: '5px',
                                                                    }}>
                                                                    <Field
                                                                        as="select"
                                                                        name="unit.name"
                                                                        style={{
                                                                            position: 'relative',
                                                                            padding: 0,
                                                                            width: '100%',
                                                                            marginRight: 0,
                                                                        }}>
                                                                        {props.units.map(u => (
                                                                            <option value={u.name}>{u.name}</option>
                                                                        ))}

                                                                        <option
                                                                            value={
                                                                                unitIsInSettings ? 'Drugo' : formikProps.values.unit.name
                                                                            }>
                                                                            {t('Allaround.DropDown.Other')}
                                                                        </option>
                                                                    </Field>
                                                                </div>
                                                                {!unitIsInSettings && (
                                                                    <Field
                                                                        onBlur={(e: any) => {
                                                                            formikProps.setFieldValue('unit.name', e.target.value)
                                                                        }}
                                                                        type="text"
                                                                        style={{ width: '49%', float: 'left' }}
                                                                        maxlength="10"
                                                                        className="info__additional__text-input"
                                                                        name="unit.name"
                                                                        placeholder={t('Invoice.Edit.measurementUnit')}
                                                                    />
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>

                                                {props.showProductDimensionsInfo && (
                                                    <div className="info__row">
                                                        <div className="info__row__inner-third">
                                                            <label>{t('Invoice.Edit.width')}</label>
                                                            <Field
                                                                component={NumberField}
                                                                name="width"
                                                                placeholder={formikProps.values.width}
                                                            />
                                                        </div>
                                                        <div className="info__row__inner-third">
                                                            <label>{t('Invoice.Edit.height')}</label>
                                                            <Field
                                                                component={NumberField}
                                                                name="height"
                                                                placeholder={formikProps.values.height}
                                                            />
                                                        </div>
                                                        <div className="info__row__inner-third">
                                                            <label>{t('Invoice.Edit.depth')}</label>
                                                            <Field
                                                                component={NumberField}
                                                                name="depth"
                                                                placeholder={formikProps.values.depth}
                                                            />
                                                        </div>
                                                    </div>
                                                )}

                                                <div className="info__row">
                                                    <label className="info__row-name">{t('Invoice.Edit.description')}</label>

                                                    <div className="info__row-content">
                                                        <Field
                                                            as="textarea"
                                                            rows={2}
                                                            placeholder={t('Invoice.Edit.descriptionPlaceholder')}
                                                            name="additionalNote"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="info__row">
                                                    <div className="info__row__inner-half">
                                                        <label>{t('Invoice.Edit.discount')}</label>
                                                        <Field
                                                            component={NumberField}
                                                            name="discount"
                                                            onChange={(newValue: number | '') => {
                                                                const discount = newValue == '' ? 0 : newValue

                                                                formikProps.setValues({
                                                                    ...formikProps.values,
                                                                    realPrice: round(formikProps.values.fullPrice / (1 - discount / 100)),
                                                                    discount: newValue,
                                                                } as any)
                                                            }}
                                                            placeholder={formikProps.values.discount}
                                                        />
                                                        <span className="text-color mod-margin-left-4">%</span>
                                                        <div>
                                                            <OfferionErrorMessage name="discount" />
                                                        </div>
                                                    </div>
                                                    {enterFullPriceEnabled ? (
                                                        <div className="info__row__inner-half">
                                                            <label htmlFor="priceWithDiscount">
                                                                {t('Invoice.Edit.priceWithoutDiscount')}
                                                            </label>
                                                            <span className="text-color">
                                                                <Field
                                                                    component={CurrencyField}
                                                                    name="realPrice"
                                                                    onChange={(newValue: number | '') => {
                                                                        const val = newValue != '' ? newValue : 0

                                                                        const discount =
                                                                            val != 0
                                                                                ? round((1 - formikProps.values.fullPrice / val) * 100)
                                                                                : 0

                                                                        formikProps.setValues({
                                                                            ...formikProps.values,
                                                                            realPrice: val,
                                                                            discount: discount,
                                                                        })
                                                                    }}
                                                                />
                                                            </span>
                                                            <span className="text-color mod-margin-left-4">
                                                                <CurrencyCode
                                                                    currency={props.document.details.documentCurrency}></CurrencyCode>
                                                            </span>
                                                            <div>
                                                                <OfferionErrorMessage name="realPrice" />
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className="info__row__inner-half">
                                                            <label htmlFor="priceWithDiscount">{t('Invoice.Edit.discountPrice')}</label>

                                                            <span className="text-color">
                                                                <CurrencyLabel
                                                                    amount={updatedPrices.discountedPrice}
                                                                    currency={props.document.details.documentCurrency}
                                                                />
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                {!formikProps.values.taxFree && props.isAccountingVat && (
                                                    <div className="info__row">
                                                        <div className="info__row__inner-half">
                                                            <label htmlFor="tax">{t('Invoice.Edit.vat')}</label>
                                                            {!props.isAccountingVat || !props.canChangeVat ? (
                                                                <span className="text-color">{formikProps.values.vat}</span>
                                                            ) : (
                                                                <Field
                                                                    component={NumberField}
                                                                    placeholder={formikProps.values.vat}
                                                                    name="vat"
                                                                />
                                                            )}
                                                            <span className="text-color mod-margin-left-4">%</span>
                                                        </div>
                                                        {enterFullPriceEnabled ? (
                                                            <div className="info__row__inner-half">
                                                                <label htmlFor="priceWithTax">Cijena bez PDV-a</label>
                                                                <span className="text-color">
                                                                    <CurrencyLabel
                                                                        amount={updatedPrices.discountedPriceWithoutVat}
                                                                        currency={props.document.details.documentCurrency}
                                                                    />
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="info__row__inner-half">
                                                                <label htmlFor="priceWithTax">{t('Invoice.Edit.priceWithVAT')}</label>
                                                                <span className="text-color">
                                                                    <CurrencyLabel
                                                                        amount={updatedPrices.discountedPriceWithVat}
                                                                        currency={props.document.details.documentCurrency}
                                                                    />
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}

                                                {props.isAccountingVat && (
                                                    <div className="info__row">
                                                        <Field component={TaxFreeCheckbox} name="taxFree" defaultVat={props.defaultVat} />
                                                    </div>
                                                )}

                                                {props.editPurchasePrice && (
                                                    <>
                                                        <div className="info__row">
                                                            <div className="info__row__inner-half">
                                                                <label htmlFor="price">{t('Invoice.Edit.purchasePrice')}</label>
                                                                <Field id="price" component={CurrencyField} name="purchasePrice" />
                                                                <span className="text-color mod-margin-left-4">
                                                                    <CurrencyCode
                                                                        currency={props.document.details.documentCurrency}></CurrencyCode>
                                                                </span>

                                                                <div>
                                                                    <OfferionErrorMessage name="purchasePrice" />
                                                                </div>
                                                            </div>
                                                            <div className="info__row__inner-half">
                                                                <label htmlFor="price">{t('Invoice.Edit.priceDifference')}</label>
                                                                <span className="text-color">
                                                                    {round(updatedPrices.differencePercentage)} %
                                                                </span>
                                                            </div>
                                                        </div>

                                                        <div className="info__row">
                                                            <div className="info__row__inner-half">
                                                                <label className="info__row__label">{t('Invoice.Edit.income')}</label>
                                                            </div>
                                                            {enterFullPriceEnabled ? (
                                                                <div className="info__row__inner-half">
                                                                    <label className="info__row__amount left">
                                                                        <CurrencyLabel
                                                                            amount={
                                                                                updatedPrices.discountedPriceWithoutVat -
                                                                                formikProps.values.purchasePrice *
                                                                                    formikProps.values.quantity
                                                                            }
                                                                            currency={props.document.details.documentCurrency}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            ) : (
                                                                <div className="info__row__inner-half">
                                                                    <label className="info__row__amount left">
                                                                        <CurrencyLabel
                                                                            amount={
                                                                                (updatedPrices.discountedPrice -
                                                                                    formikProps.values.purchasePrice) *
                                                                                formikProps.values.quantity
                                                                            }
                                                                            currency={props.document.details.documentCurrency}
                                                                        />
                                                                    </label>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                        <div
                                            className="info__row clickable"
                                            onClick={() =>
                                                setShowAdditionalInformationEditFormOverlay(!showAdditionalInformationEditFormOverlay)
                                            }>
                                            <h1 className="table__cell__highlighted-text">
                                                <span className="icon icon--clickable icon__info"></span>
                                                {t('Invoice.Edit.additionalInformation')}
                                            </h1>

                                            <button type="button" className="button button--gray right">
                                                <span className="button button--gray right">
                                                    <span
                                                        className={`icon ${
                                                            showAdditionalInformationEditFormOverlay ? 'icon__up' : 'icon__down-blue'
                                                        }`}></span>
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="table__price-container">
                                        {props.isAccountingVat && (
                                            <div className="table__price-row">
                                                <span className="table__price-label">{t('Invoice.Edit.totalWithoutVAT')}</span>
                                                <span className="table__price-amount">
                                                    {!enterFullPriceEnabled && (
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={
                                                                    formikProps.values.salePrice *
                                                                    formikProps.values.quantity *
                                                                    (1 - formikProps.values.discount / 100)
                                                                }
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    )}
                                                    {enterFullPriceEnabled && (
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={formikProps.values.fullPrice / (1 + formikProps.values.vat / 100)}
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                        )}
                                        {!formikProps.values.taxFree && props.isAccountingVat && (
                                            <div className="table__price-row">
                                                <span className="table__price-label">
                                                    {t('Invoice.Edit.vat')} {formikProps.values.vat}%
                                                </span>

                                                {!enterFullPriceEnabled && (
                                                    <span className="table__price-amount">
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={
                                                                    formikProps.values.salePrice *
                                                                        formikProps.values.quantity *
                                                                        (1 + formikProps.values.vat / 100) *
                                                                        (1 - formikProps.values.discount / 100) -
                                                                    formikProps.values.salePrice *
                                                                        formikProps.values.quantity *
                                                                        (1 - formikProps.values.discount / 100)
                                                                }
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    </span>
                                                )}

                                                {enterFullPriceEnabled && (
                                                    <span className="table__price-amount">
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={
                                                                    formikProps.values.fullPrice -
                                                                    formikProps.values.fullPrice / (1 + formikProps.values.vat / 100)
                                                                }
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        <div className="table__price-row">
                                            <span className="table__price-label table__price-label--big">
                                                {t('Invoice.Edit.totalWithVAT')}
                                            </span>

                                            {!enterFullPriceEnabled && (
                                                <span className="table__price-amount table__price-amount--big">
                                                    {!formikProps.values.taxFree && (
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={
                                                                    formikProps.values.salePrice *
                                                                    formikProps.values.quantity *
                                                                    (1 + formikProps.values.vat / 100) *
                                                                    (1 - formikProps.values.discount / 100)
                                                                }
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    )}
                                                    {formikProps.values.taxFree && (
                                                        <span style={{ width: '78px' }}>
                                                            <CurrencyLabel
                                                                className="price-amount"
                                                                amount={
                                                                    formikProps.values.salePrice *
                                                                    formikProps.values.quantity *
                                                                    (1 - formikProps.values.discount / 100)
                                                                }
                                                                currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                        </span>
                                                    )}
                                                </span>
                                            )}

                                            {enterFullPriceEnabled && (
                                                <span className="table__price-amount">
                                                    <span style={{ width: '78px' }}>
                                                        <CurrencyLabel
                                                            className="price-amount"
                                                            amount={formikProps.values.fullPrice}
                                                            currency={props.document.details.documentCurrency}></CurrencyLabel>
                                                    </span>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: '20px', marginRight: '20px', marginTop: '10px' }}>
                                        <label className="info__row-name">{t('Allaround.Picture.title')}</label>
                                        {formikProps.values.imagePath ? (
                                            <>
                                                <label
                                                    className="button button--gray button--padded right"
                                                    onClick={() => {
                                                        formikProps.setValues({
                                                            ...formikProps.values,
                                                            predefinedImageId: null,
                                                            customImageResourceId: null,
                                                            imagePath: null,
                                                        })
                                                    }}>
                                                    <span className="icon icon--small icon__upload-delete"></span>
                                                    <span className="button-text">{t('Allaround.Picture.removePicture')}</span>
                                                </label>

                                                <ImageWithAuthorization className="mod-margin-top-8" src={formikProps.values.imagePath} />
                                            </>
                                        ) : (
                                            <>
                                                <label
                                                    className="button button--gray button--padded right"
                                                    onClick={async () => {
                                                        const errors = await formikProps.validateForm()

                                                        if (Object.keys(errors).length) return

                                                        formikProps.submitForm().then((res: any) => {
                                                            const item = res.items.find((x: any) => x.index == formikProps.values.index)

                                                            history.push('/image-selection', {
                                                                documentItemId: item.id,
                                                            })
                                                        })
                                                    }}>
                                                    <span className="icon icon--small icon__upload"></span>
                                                    <span className="button-text">{t('Allaround.Picture.addPicture')}</span>
                                                </label>
                                            </>
                                        )}
                                    </div>

                                    <div className="button-group bottom-controls">
                                        {props.canSubmit && (
                                            <button className="button button--gray button--padded" type="submit">
                                                <span className="icon icon__check-green"></span>
                                                <span className="button-text button-text--always-show">{t('Invoice.Edit.buttonSave')}</span>
                                            </button>
                                        )}
                                        <button type="button" className="button button--gray button--padded" onClick={props.closeForm}>
                                            <span className="icon icon--small icon__cross-red"></span>
                                            <span className="button-text button-text--always-show">{t('Invoice.Edit.buttonCancel')}</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Overlay>
                )
            }}
        </Formik>
    )
}
