import React, { useState, useContext } from 'react'
import { GridActions, SortDirection } from './Types'

const GridActionsUpdateContext = React.createContext((actions: GridActions) => {})

const GridActionsContext = React.createContext<GridActions>({
    refresh: () => {},
    toggleFiltersModal: () => {},
    totalRows: 0,
    config: {
        pageNumber: 0,
        pageSize: 0,
        sort: {
            direction: SortDirection.Asc,
            key: '',
        },
    },
    setPage: (page: number) => {},
})

export const useGridActions = () => {
    return useContext(GridActionsContext)
}

export const useGridActionsUpdater = () => {
    return useContext(GridActionsUpdateContext)
}

export const GridContextProvider = (props: React.PropsWithChildren<any>) => {
    const [gridActions, setGridActions] = useState<GridActions>({
        refresh: () => {},
        toggleFiltersModal: () => {},
        totalRows: 0,
        config: {
            pageNumber: 0,
            pageSize: 0,
            sort: {
                direction: SortDirection.Asc,
                key: '',
            },
        },
        setPage: (page: number) => {},
    })

    return (
        <GridActionsUpdateContext.Provider
            value={(actions: GridActions) => {
                setGridActions(actions)
            }}>
            <GridActionsContext.Provider value={gridActions}>{props.children}</GridActionsContext.Provider>
        </GridActionsUpdateContext.Provider>
    )
}
