import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { Formik, Form, Field } from 'formik'
import { Currency } from '../models'
import { CurrencyLabel } from '../ui/currency'
import { numberAsText } from '../utils/formatters'
import round from '../utils/round'

interface RequiredProps {
    currency: Currency | null
    required: true
    children?: ReactNode
    className?: string
    currentValue: number
    onSave: ({ newValue }: { newValue: number }) => void
    inline?: boolean
}
interface NotRequiredProps {
    currency: Currency | null
    required: false
    children?: ReactNode
    className?: string
    currentValue: number
    onSave: ({ newValue }: { newValue: number | null }) => void
    inline?: boolean
}

export const InlineCurrencyEdit = ({ currentValue, currency, className, inline, ...rest }: RequiredProps | NotRequiredProps) => {
    const [isEdit, setIsEdit] = useState(false)
    const fieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (isEdit && fieldRef.current) {
            fieldRef.current.focus()
        }
    }, [isEdit])

    const currentValueAsText = currentValue == 0 ? '' : numberAsText(currentValue)

    const spanStyle = inline
        ? { display: isEdit ? 'none' : 'inline', width: '100%', cursor: 'pointer' }
        : { display: isEdit ? 'none' : 'block', width: '100%', cursor: 'pointer' }

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{ newValue: currentValueAsText }}
                onSubmit={(values, helpers) => {
                    const correctedValue = values.newValue ? round(Number.parseFloat(values.newValue.replace(',', '.'))) : null

                    if (isEdit && correctedValue != currentValue) {
                        if (correctedValue != null) rest.onSave({ newValue: correctedValue })

                        if (correctedValue == null && !rest.required) rest.onSave({ newValue: correctedValue })
                    } else {
                        helpers.resetForm()
                    }

                    setIsEdit(false)
                }}>
                {props => {
                    return (
                        <Form style={{ display: isEdit ? 'block' : 'none' }}>
                            <Field
                                autoFocus
                                inputMode="decimal"
                                onKeyDown={(e: any) => {
                                    const key = e.key

                                    if (key === 'Enter' || key === 'Tab') {
                                        props.submitForm()
                                        return
                                    }

                                    const isValidKey =
                                        key === 'Enter' ||
                                        key === 'Tab' ||
                                        key === 'ArrowLeft' ||
                                        key === 'ArrowRight' ||
                                        key === '-' ||
                                        key === 'Backspace' ||
                                        key === 'Delete' ||
                                        key === ',' ||
                                        key === '.' ||
                                        /\d/.test(key)

                                    if (!isValidKey) {
                                        e.preventDefault()
                                    }
                                }}
                                innerRef={fieldRef}
                                onBlur={props.submitForm}
                                type="text"
                                name="newValue"></Field>
                        </Form>
                    )
                }}
            </Formik>

            <CurrencyLabel
                className={className}
                currency={currency}
                amount={currentValue}
                style={spanStyle}
                onClick={() => setIsEdit(true)}
            />
        </>
    )
}
