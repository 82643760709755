import React, { useEffect, useRef } from 'react'
import { ApiClient } from '../api/api-client';

export const ImageWithAuthorization = ({ src, reloadDepdendency, ...rest }: { src: string, reloadDepdendency?: any } & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => {

    const ref = useRef<HTMLImageElement>(null);

    useEffect(() => {

        ApiClient.get(src, { responseType: 'arraybuffer' }).then((res: any) => {

            const blob = new Blob([res], {
                type: 'image/jpeg',
            });

            var objectURL = URL.createObjectURL(blob);

            if (ref.current)
                ref.current.src = objectURL;

        });
    }, [src, reloadDepdendency])

    return <img alt={"Loading..."} ref={ref} {...rest} style={{ objectFit: "contain" }} />;
}