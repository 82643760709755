import React, { useState, useEffect, ReactText, useMemo } from 'react'
import { Grid } from '../../../shared/grid/Grid'
import { GridMenuCell, GridMenuActionLink } from '../../../shared/grid/GridMenu'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GridActions, TableParams } from '../../../shared/grid/Types'
import { ClientDiscountsApi, ClientDiscountRow, } from '../../../services/ClientDiscountsApi'
import { useParams, useLocation, useHistory } from 'react-router-dom'
import { InlineCurrencyEdit } from '../../../shared/forms/InlineCurrencyEdit'
import { Currency, ClientModel } from '../../../shared/models'
import { InlineNumberEdit } from '../../../shared/forms/InlineNumberEdit'
import { ClientApi } from '../../../services/ClientApi'
import { AgreedPricesSidebar } from './AgreedPricesSidebar'
import { AddItemFromPricelistButton } from './AddItemFromPricelistButton';
import { EditItemOverlay } from './EditItemOverlay'
import { toastSuccess } from '../../../shared/toastr'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { GridContextProvider } from '../../../shared/grid/GridContextProvider'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'

export const AgreedPricesContainer = () => {
    const { t } = useOfferionTranslation()
    const params = useParams<{ clientId: string }>();
    const location = useLocation();
    const history = useHistory();
    const [client, setClient] = useState<ClientModel | null>(null);
    const [currency, setCurrency] = useState<Currency | null>(null);
    const offerionModal = useOfferionModal();

    useEffect(() => {
        ClientApi.get(params.clientId).then(setClient);
    }, [params.clientId])


    useEffect(() => {
        CompanySettingsApi.getDefaultCurrency().then(setCurrency);
    }, [])

    const currentPath = new URLSearchParams(location.search).get("gpath");
    const pathArray = currentPath ? currentPath.split(",") : []
    const currentGroup = pathArray.length ? pathArray[pathArray.length - 1] : null;

    const tableSchema = {
        columns: [
            {
                cssClass: 'table__header__item__icon',
                labelKey: '',
            },
            {
                cssClass: 'table__header__item__name',
                labelKey: 'Clients.AgreedPrices.Table.Header.title',
            },
            {
                cssClass: 'table__header__item__discount',
                labelKey: 'Clients.AgreedPrices.Table.Header.discount',
            },
            {
                cssClass: 'table__header__item__price',
                labelKey: 'Clients.AgreedPrices.Select.price'
            },
            {
                cssClass: 'table__header__item__menu'
            },
        ],
    }

    const dataFunctionFactory = useMemo(() => {
        return (config: TableParams) => {
            return ClientDiscountsApi.getClientDiscounts(params.clientId, config.pageNumber, config.pageSize, currentGroup)
        }
    }, [currentGroup])

    const RowComponent = ({ row, gridActions }: { row: ClientDiscountRow, gridActions: GridActions }) => {

        const history = useHistory();
        const location = useLocation();
        const [currentPath] = useState(new URLSearchParams(location.search).get("gpath"));
        const [selectedItem, setSelectedItem] = useState<ClientDiscountRow | null>(null)

        const goToSubgroup = (groupId: number) => {

            let pathArray: ReactText[] = [];

            if (currentPath)
                pathArray = currentPath.split(",");

            pathArray.push(groupId);

            history.push(`${location.pathname}?gpath=${pathArray.join(",")}`)
        }

        const deleteAgreedPrice = () => {
            const messageKey = row.groupId
                ? "Clients.AgreedPrices.Delete.Group.questionYesNo"
                : "Clients.AgreedPrices.Delete.Product.questionYesNo";

            offerionModal.open('Clients.AgreedPrices.Side.Delete.pageTitle', messageKey, async () => {
                await ClientDiscountsApi.deleteDiscount(row.discountId);

                if (row.groupId)
                    toastSuccess('Clients.AgreedPrices.Delete.Group.successMessage', { groupName: row.name });
                else
                    toastSuccess('Clients.AgreedPrices.Delete.Product.successMessage', { productName: row.name });

                gridActions.refresh();
            }, { productName: row.name, groupName: row.name })
        }

        const updateAgreedPrice = (newRow: ClientDiscountRow) => {

            if (newRow.productId)
                ClientDiscountsApi.updateProductDiscount(newRow)
                    .then(() => toastSuccess('Clients.AgreedPrices.Edit.Product.Message.successSave', { productName: newRow.name }))
                    .then(gridActions.refresh)
                    .then(() => setSelectedItem(null));
            else
                ClientDiscountsApi.updateGroupDiscount(newRow)
                    .then(() => toastSuccess('Clients.AgreedPrices.Edit.Group.Message.successSave', { groupName: newRow.name }))
                    .then(gridActions.refresh)
                    .then(() => setSelectedItem(null));

        }

        return <>

            <span className="table__cell table__cell__icon mobile-hide">
                {row.productId ? <span className="icon icon__item"></span> : null}
                {row.groupId ? <span className="icon icon__group pointer" onClick={() => row.groupId && goToSubgroup(row.groupId)}></span> : null}
            </span>

            {selectedItem && currency && <EditItemOverlay currency={currency} selectedItem={selectedItem} onSubmit={updateAgreedPrice} closeOverlay={() => setSelectedItem(null)} />}

            {row.groupId ? <span className="table__cell table__cell__icon mobile-only">
                <span className="icon icon__group"></span>
            </span> : null}

            {row.productId ? <span className="table__cell table__cell__icon mobile-only">
                <span className="icon icon__item"></span>
            </span> : null}

            {row.groupId ? <span className='table__cell table__cell__title mobile-only pointer' onClick={() => row.groupId && goToSubgroup(row.groupId)}>
                {row.name}
            </span> : null}

            {row.productId ? <span className='table__cell table__cell__title mobile-only'>
                {row.name}
            </span> : null}

            <span className="table__cell table__cell__title">
                <span className='mobile-hide'>
                    {row.productId ? <span>
                        {row.name}
                    </span> : null}
                </span>
                {row.groupId ? <span onClick={() => row.groupId && goToSubgroup(row.groupId)}
                    className='mobile-hide pointer'>
                    {row.name}
                </span> : null}

                {/* <span ng-if="price.parentGroupName">
                    [{{ price.parentGroupName }}]
                            </span> */}
                {/* <span className="icon icon--small icon__picture"></span> */}
            </span>

            <span className="table__cell table__item-inline table__cell--prefixed table__item-inline-title--prefixed">
                {row.discountPercent != null
                    ? <InlineNumberEdit onSave={({ newValue }) => updateAgreedPrice({ ...row, discountPercent: newValue })} required={true} currentValue={row.discountPercent}>%</InlineNumberEdit>
                    : "-"
                }
            </span>

            {row.groupId ? <span className="table__cell">-</span> : null}

            {row.productId ? <span className="table__cell table__item-inline table__cell--prefixed table__item-inline-title--prefixed">

                {row.salePrice != null
                    ? <InlineCurrencyEdit currency={currency} required={true} currentValue={row.salePrice} onSave={({ newValue }) => updateAgreedPrice({ ...row, salePrice: newValue })} />
                    : "-"
                }
            </span> : null}

            <GridMenuCell>
                <GridMenuActionLink onClick={() => setSelectedItem(row)} translationKey="Clients.AgreedPrices.Table.ItemMenu.edit"></GridMenuActionLink>
                <GridMenuActionLink onClick={deleteAgreedPrice} translationKey="Clients.AgreedPrices.Table.ItemMenu.delete"></GridMenuActionLink>

            </GridMenuCell>
        </>
    }

    return (
        <GridContextProvider>
            <BreadcrumbsItem to={`/clients/${params.clientId}/agreed-prices`}>
                {t("Clients.AgreedPrices.Header.pageTitle")}
            </BreadcrumbsItem>

            <main className="main">
                <div className="main__header">
                    <div className="main-title">{t('Clients.AgreedPrices.Header.pageTitle')}</div>

                    <div className="main__header-buttons">
                        <AddItemFromPricelistButton clientId={params.clientId} />
                    </div>
                </div>

                <Grid
                    emptyMessageKey="Clients.AgreedPrices.Table.emptyMessage"
                    emptySearchMessageKey="Clients.AgreedPrices.Table.emptySearchMessage"
                    translationKey={["Clients.AgreedPrices.Table.Header.title", client ? client.name : ""]}
                    rowsPromiseFactory={dataFunctionFactory}
                    rowComponent={RowComponent}
                    sidebarComponent={AgreedPricesSidebar}
                    firstRowComponent={currentGroup ? () => {
                        return <>
                            <span className="overlay__item overlay__item--right-middle"></span>
                            <span onClick={history.goBack} className="table__cell table__cell__icon">
                                <span className="icon icon__back"></span>
                            </span>
                            <span className="table__cell table__cell__title">-</span>
                        </>
                    } : null}
                    tableSchema={tableSchema}></Grid>
            </main>
        </GridContextProvider>
    )
}
