import React, { useState, useEffect, useRef } from 'react'
import { ClientForm2 } from '../../../../../components/clients/list/ClientForm'
import { ClientModel } from '../../../../models'
import { InlineInputEdit } from '../../../../forms/InlineInputEdit'
import { FieldProps } from 'formik'
import { GridMenuActionLink } from '../../../../grid/GridMenu'
import { ClientGridRow, ListingApi } from '../../../../../services/ListingApi'
import './ClientInput.css'
import changeItemField from '../../../../utils/changeItemField'
import { useOfferionTranslation } from '../../../../store/hooks/useOfferionTranslation'
import { ButtonWithOverlay } from '../../../../elements/ButtonWithOverlay'
import AutocompleteInput from '../../../../elements/AutocompleteInput'

type ClientSearchInputProps = {
    onClientSelected: (client: ClientGridRow) => void
    onPlusButtonClicked: (searchValue: string) => void
    clientFormVisible: boolean
    initialClientValue: ClientModel | null
    form: any
    field: any
    setClientFormVisible: (visible: boolean) => void
}

const ClientSearchInput = ({
    onClientSelected,
    onPlusButtonClicked,
    clientFormVisible,
    initialClientValue,
    form,
    field,
    setClientFormVisible,
}: ClientSearchInputProps) => {
    const { t } = useOfferionTranslation()
    const currentSearchValue = useRef<string>('')
    const wrapperRef = useRef<HTMLDivElement | null>(null)

    function loadOptions(value: string) {
        return ListingApi.getList<ClientGridRow>('ClientsGridModel', 1, 10, [
            {
                property: 'Name',
                operator: 'Contains',
                value: value,
            },
        ]).then(result => {
            return result.data.map(i => ({ value: i.id, label: i.name, ...i }))
        })
    }

    const containerClasses = ` overlay__item overlay__item--bottom-left ${clientFormVisible ? 'overlay__item--active overlay__item--show-overlay' : ''
        }`

    return (
        <div ref={wrapperRef} className={containerClasses} style={{ display: 'flex', gap: '1rem' }}>
            <div>
                <AutocompleteInput
                    loadOptions={loadOptions}
                    placeholder={t('Invoice.Table.Client.noClientPlaceholder')}
                    createNewLabel={t("Invoice.Table.Client.createClient")}
                    onChange={onClientSelected}
                    onCreateOption={item => {
                        onClientSelected({
                            address: '',
                            cellPhone: '',
                            city: '',
                            clientRemark: '',
                            country: '',
                            discountPercent: 0,
                            email: '',
                            fax: '',
                            id: 0,
                            name: item,
                            oib: '',
                            paymentDelayInDays: 0,
                            phone: '',
                            printRemark: '',
                        })
                    }}
                />
            </div>

            <div className="nowrap">
                <ButtonWithOverlay
                    icon={<span className="icon icon__plus"></span>}
                    showOverlay={clientFormVisible}
                    placement='bottom-left'
                    onClick={() => {
                        onPlusButtonClicked(currentSearchValue.current)
                        currentSearchValue.current = ''
                    }}
                >
                    <ClientForm2
                        closeForm={() => setClientFormVisible(false)}
                        onSubmit={c => {
                            form.setFieldValue(field.name, c)
                            form.submitForm()
                            setClientFormVisible(false)
                        }}
                        initialValues={initialClientValue}
                    />
                </ButtonWithOverlay>

                <span className="button-text" style={{marginLeft: "0.5rem"}}>{t("Invoice.Table.Client.buttonNew")}</span>
            </div>
        </div>
    )
}

export const ClientInput = (
    props: FieldProps<ClientModel | null> & { enterFullPriceEnabled: boolean; shouldPreserveIdentity: boolean }
) => {
    const { t } = useOfferionTranslation()

    const [clientFormVisible, setClientFormVisible] = useState(false)
    const [initialValue, setInitialValue] = useState(props.field.value)

    const [showClientMenu, setShowClientMenu] = useState(false)

    useEffect(() => {
        setInitialValue(props.field.value)
    }, [props.field.value])

    const wrapperClasses = clientFormVisible ? `overlay__item--active overlay__item--show-overlay overlay__item--bottom-left` : ''
    return (
        <div className={wrapperClasses}>
            {!props.field.value ? (
                <div className="info__row">
                    <label className="info__row-name">{t('Invoice.Table.Client.title')}</label>
                    <div className="info__row-content">
                        <ClientSearchInput
                            clientFormVisible={clientFormVisible}
                            initialClientValue={initialValue}
                            form={props.form}
                            field={props.field}
                            setClientFormVisible={setClientFormVisible}
                            onPlusButtonClicked={searchValue => {
                                setInitialValue({
                                    address: '',
                                    cellPhone: '',
                                    city: '',
                                    clientRemark: '',
                                    country: '',
                                    discountPercent: 0,
                                    email: '',
                                    fax: '',
                                    id: 0,
                                    name: searchValue,
                                    oib: '',
                                    paymentDelayInDays: 0,
                                    phone: '',
                                    printRemark: '',
                                    postalCode: '',
                                })

                                setClientFormVisible(!clientFormVisible)
                            }}
                            onClientSelected={selectedClient => {
                                if (selectedClient) {
                                    const newFormValue = {
                                        ...props.form.values,

                                        details: {
                                            ...props.form.values.details,
                                            PaymentDateOffset: selectedClient.paymentDelayInDays,
                                        },
                                        [props.field.name]: {
                                            ...selectedClient,
                                            id: props.shouldPreserveIdentity ? selectedClient.id : 0,
                                            hideOnListing: !!selectedClient.id,
                                            sourceClientId: !!selectedClient.id && !props.shouldPreserveIdentity ? selectedClient.id : null,
                                        },
                                        items: props.form.values.items.map((item: any) => {
                                            return changeItemField(
                                                item,
                                                'Discount',
                                                selectedClient.discountPercent * 100,
                                                props.enterFullPriceEnabled
                                            )
                                        }),
                                    }

                                    props.form.setValues(newFormValue)

                                    props.form.submitForm()
                                } else {
                                    props.form.setFieldValue(props.field.name, null)
                                }
                            }}
                        />
                    </div>
                </div>
            ) : (
                <div className="info__row" ng-if="areClientDetailsAvailable">
                    <label className="info__row-name">{t('Invoice.Table.Client.title')}</label>
                    <div className="info__row-content info__row-content--extended">
                        <ul className="client-details">
                            <div className="button-outer overlay__item overlay__menu">
                                {clientFormVisible ? (
                                    <ButtonWithOverlay
                                        placement="bottom-left"
                                        icon={<span className="icon icon__menu-blue"></span>}
                                        showOverlay
                                        setOverlay={setClientFormVisible}>
                                        <ClientForm2
                                            closeForm={() => setClientFormVisible(false)}
                                            onSubmit={c => {
                                                props.form.setFieldValue(props.field.name, c)

                                                props.form.submitForm()

                                                setClientFormVisible(false)
                                            }}
                                            initialValues={initialValue}
                                        />
                                    </ButtonWithOverlay>
                                ) : (
                                    <ButtonWithOverlay
                                        placement="bottom-left"
                                        icon={<span className="icon icon__menu-blue"></span>}
                                        showOverlay={showClientMenu}
                                        setOverlay={setShowClientMenu}>
                                        <div className="overlay">
                                            <ul className="overlay__list">
                                                <GridMenuActionLink
                                                    onClick={() => {
                                                        setClientFormVisible(true)
                                                        setShowClientMenu(false)
                                                    }}
                                                    translationKey="Invoice.Table.Client.Menu.edit"></GridMenuActionLink>
                                                <GridMenuActionLink
                                                    onClick={() => {
                                                        props.form.setFieldValue(props.field.name, null)
                                                        props.form.submitForm()
                                                        setShowClientMenu(false)
                                                    }}
                                                    translationKey="Invoice.Table.Client.Menu.remove"></GridMenuActionLink>
                                            </ul>
                                        </div>
                                    </ButtonWithOverlay>
                                )}
                            </div>
                            {props.field.value.name && (
                                <li className="client-details__item client-details__name text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.name}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.name`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}

                            {props.field.value.address && (
                                <li className="client-details__item client-details__street text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.address}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.address`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}

                            {props.field.value.email && (
                                <li className="client-details__item client-details__email text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.email}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.email`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.city && (
                                <li className="client-details__item client-details__address text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.city}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.city`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.phone && (
                                <li className="client-details__item client-details__phone">
                                    <span className="client-details__label">{t('Clients.Edit.phone')}:</span>
                                    <InlineInputEdit
                                        currentValue={props.field.value.phone}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.phone`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.postalCode && (
                                <li className="client-details__item client-details__address text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.postalCode}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.postalCode`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.cellPhone && (
                                <li className="client-details__item client-details__mobile">
                                    <span className="client-details__label">{t('Clients.Edit.cellphone')}:</span>
                                    <InlineInputEdit
                                        currentValue={props.field.value.cellPhone}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.cellPhone`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.country && (
                                <li className="client-details__item client-details__address text-truncate">
                                    <InlineInputEdit
                                        currentValue={props.field.value.country}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.country`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.fax && (
                                <li className="client-details__item client-details__fax">
                                    <span className="client-details__label">{t('Clients.Edit.fax')}:</span>
                                    <InlineInputEdit
                                        currentValue={props.field.value.fax}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.fax`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.oib && (
                                <li className="client-details__item client-details__pin">
                                    <span className="client-details__label">{t('Clients.Edit.companyNumber')}:</span>
                                    <InlineInputEdit
                                        currentValue={props.field.value.oib}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.oib`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                            {props.field.value.printRemark && (
                                <li className="client-details__item client-details__name text-truncate">
                                    <span className="client-details__label">{t('Clients.Edit.printRemark')}:</span>
                                    <InlineInputEdit
                                        multiline
                                        currentValue={props.field.value.printRemark}
                                        onSave={({ newValue }) => {
                                            props.form.setFieldValue(`${props.field.name}.printRemark`, newValue)
                                            props.form.submitForm()
                                        }}></InlineInputEdit>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    )
}
