import React, { useEffect, useState } from 'react'

import { DocumentListContainer, DocumentGridModel } from '../../shared/documents/DocumentListContainer'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GridMenuCell, GridMenuActionLink, GridMenuLink } from '../../shared/grid/GridMenu';
import { useHistory } from 'react-router-dom';
import { TemplateTenderApi } from '../../services/TemplateTenderApi';
import { toastSuccess, toastWarning } from '../../shared/toastr';
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter';
import { useOfferionModal } from '../../shared/modal/ConfirmationModal';
import { GridActions } from '../../shared/grid/Types';
import { AggregatedResultsButton } from '../../shared/documents/details/elements/AggregatedResultsButton';
import { faEdit, faFileMedical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { CompanySettingsApi } from '../../services/CompanySettingsApi';
import { InvoiceApi } from '../../services/InvoiceApi';
import { ConcreteTenderApi } from '../../services/ConcreteTenderApi';
import { SortDirection } from '../../services/ListingApi';

export const TemplateTenderListContainer = () => {
    const { t } = useOfferionTranslation();
    const history = useHistory();
    const offerionModal = useOfferionModal();
    const [canCreateTenders, setCanCreateTenders] = useState(false);
    const [canCreateInvoices, setCanCreateInvoices] = useState(false);
    const [settingsLoaded, setSettingsLoaded] = useState(false);

    useEffect(() => {
        CompanySettingsApi.getTemplateTenderListSettings().then((result) => {
            setCanCreateTenders(result.canCreateTenders);
            setCanCreateInvoices(result.canCreateInvoices);
            setSettingsLoaded(true);
        })
    }, [])

    const deleteTender = (tenderId: number, templateName: string, gridActions: GridActions) => {
        offerionModal.open('Invoice.DeleteTemplate.pageTitle', 'Invoice.DeleteTemplate.questionYesNo', async () => {
            await TemplateTenderApi.delete(tenderId);

            gridActions.refresh();

            toastSuccess('Invoice.DeleteTemplate.Message.successDelete');
        }, { templateName })
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Templates.Table.Header.templateName',
        },
    ]

    const createInvoice = (templateTenderId: number) => {

        if (!canCreateInvoices) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            });

            toastWarning(message, true)

            return;
        }

        InvoiceApi.createFromTender(templateTenderId).then(({ id }) => {
            history.push(`/invoice/${id}`);
        })
    }

    const createOffer = (templateTenderId: number) => {

        if (!canCreateTenders) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
            });

            toastWarning(message, true)

            return;
        }

        ConcreteTenderApi.createFromTemplateTender(templateTenderId).then(({ id }) => {
            history.push(`/offer/${id}`);
        })
    }

    return (
        <>
            <BreadcrumbsItem to="/templates">
                {t("Templates.Header.pageTitle")}
            </BreadcrumbsItem>

            <DocumentListContainer
                settingsLoaded={settingsLoaded}
                columns={columns}
                hideOfficialDate={true}
                emptyMessageKey="Templates.Table.emptyMessage"
                emptySearchMessageKey="Templates.Table.emptySearchMessage"
                pageTitleKey="Templates.Header.pageTitle"
                gridTitleKey="Templates.Table.Header.title"
                buttonsComponent={<><div className="button-container">
                    <button className="button button--white" onClick={() => history.push("/template-offer/new")}>
                        <span className="icon icon__plus"></span>
                    </button>
                    <span className="button-text table__button-text mod-margin-left-4">{t('Invoice.Header.pageTitleNewTemplate')}</span>

                </div>
                    <AggregatedResultsButton aggregatedResultsPromiseFactory={config => TemplateTenderApi.getListAggregatedValues("TemplateTendersGridModel", config.filter, config.customFilter)} />
                </>
                }
                filtersComponent={({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
                    return <GridFilterForm
                        titleKey="Invoices.Side.searchTitle"
                        searchButtonLabelKey="Invoices.Side.buttonSearch"
                        onSubmit={(values) => {
                            const filters = [];

                            if (values.clientName)
                                filters.push({ property: "Client.Name", operator: "Contains", value: values.clientName })

                            if (values.name)
                                filters.push({ property: "TemplateName", operator: "Contains", value: values.name })

                            updateFilter(filters);
                        }}
                        initialValues={{ clientName: "", name: "" }}>

                        <GridFilterInput name="name" placeholderKey="Templates.Side.searchPlaceholder"></GridFilterInput>
                        <GridFilterInput name="clientName" placeholderKey="Invoices.Side.clientPlaceholder"></GridFilterInput>
                    </GridFilterForm>
                }}
                menuCellComponent={({ row, gridActions }) => {
                    return <GridMenuCell>
                        <GridMenuLink icon={faEdit} to={`/template-offer/${row.id}`} translationKey="Templates.Table.ItemMenu.edit"></GridMenuLink>
                        <GridMenuActionLink icon={faFileMedical} onClick={() => createOffer(row.id)} translationKey="Templates.Table.ItemMenu.createOffer"></GridMenuActionLink>
                        <GridMenuActionLink icon={faFileMedical} onClick={() => createInvoice(row.id)} translationKey="Templates.Table.ItemMenu.createInvoice"></GridMenuActionLink>
                        <GridMenuActionLink icon={faTrashAlt} onClick={() => deleteTender(row.id, row.templateName, gridActions)} translationKey="Templates.Table.ItemMenu.delete"></GridMenuActionLink>
                    </GridMenuCell>
                }}
                firstColumnSelector={(row: { templateName: string } & DocumentGridModel) => <span onClick={() => history.push(`/template-offer/${row.id}`)}
                    className="table__cell table__cell__title mod-cursor-pointer">
                    {row.templateName}
                </span>}
                projectionName="TemplateTendersGridModel"
                orderByProperty="TemplateName"
                sortDirection={SortDirection.Asc}
            />
        </>
    )
}
