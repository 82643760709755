import { ApiClient } from '../shared/api/api-client'
import { GridData } from '../shared/grid/Types';
import { ReactText } from 'react';

export interface ClientDiscountRow {
    name: string;
    discountId: number;
    groupId?: number;
    productId?: number;
    discountPercent: number;
    salePrice: number;
    clientName: string;
}

export interface ClientGlobalDiscountModel {
    discountPercent: number;
    paymentDelayInDays: number;
}

export interface PriceListItem {
    groupId?: number;
    productId?: number;
    parentGroupName: string;
    parentGroupNameChain: null;
    name: string;
    price?: number;
    numberOfElements?: number;
    imageUrl: string;
    unit: string | null;
    hasImage: boolean;
}

export class ClientDiscountsApi {

    public static getClientDiscounts(clientId: number | string, page: number, takeCount: number, groupId?: ReactText | null) {
        return ApiClient.post<GridData<ClientDiscountRow>>(`api/clientdiscount/getClientDiscounts`,
            {
                clientId,
                page,
                takeCount,
                groupId
            });
    }

    public static deleteAllDiscountForClient(clientId: number | string) {
        return ApiClient.delete(`api/clientdiscount/DeleteAllDiscountsForClient/${clientId}`);
    }

    public static deleteDiscount(discountId: number) {
        return ApiClient.delete(`api/clientdiscount/DeleteDiscount/${discountId}`);
    }

    public static updateGroupDiscount({ discountId, discountPercent }: { discountId: number, discountPercent: number }) {
        return ApiClient.post(`api/clientdiscount/UpdateGroupDiscount`, {
            discountId,
            discountPercent
        });
    }

    public static updateProductDiscount({ discountId, salePrice, discountPercent }: { discountId: number, salePrice: number, discountPercent: number }) {
        return ApiClient.post(`api/clientdiscount/UpdateProductDiscount`, {
            discountId,
            salePrice,
            discountPercent
        });
    }

    public static getClientGlobalDiscounts(clientId: number | string) {
        return ApiClient.get<ClientGlobalDiscountModel>(`api/clientdiscount/GetClientGlobalDiscounts?clientId=${clientId}`);
    }

    public static updateClientGlobalDiscounts(discountsConfiguration: ClientGlobalDiscountModel & { clientId: string | number }) {
        return ApiClient.post(`api/clientdiscount/UpdateClientGlobalDiscounts`, discountsConfiguration);
    }

    public static createGroupDiscount(discount: { clientId: number | string, discountPercent: number, groupId: number }) {
        return ApiClient.post(`api/clientdiscount/createGroupDiscount`, discount);
    }

    public static createProductDiscount(discount: { clientId: number | string, discountPercent: number, salePrice: number, productId: number }) {
        return ApiClient.post(`api/clientdiscount/createProductDiscount`, discount);
    }

    public static getPriceList(page: number, takeCount: number, clientId: ReactText, groupId: null | number, searchTerm: null | string) {
        return ApiClient.post<GridData<PriceListItem>>('api/clientdiscount/GetPriceList', {
            page, takeCount, groupId, searchTerm, clientId
        })
    }

    public static getProductDiscountForClient(clientId: number, productId: number) {
        return ApiClient.get<{ salePrice: number | null, discountPercent: number | null } | null>(`api/clientdiscount/GetProductDiscountForClient/${clientId}/${productId}`);
    }
}