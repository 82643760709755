import React from 'react'
import { Formik, Form, Field } from 'formik';
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit'
import { InvoiceApi } from '../../services/InvoiceApi';
import { toastWarning } from '../../shared/toastr';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { openDocument } from '../../shared/utils/openDocument';

export const ReportsModal = ({ close }: { close: () => void }) => {
    const { t } = useOfferionTranslation();

    const onSubmit = (val: { reportType: string, fromDate: Date, toDate: Date }) => {
        InvoiceApi.getReport(val.reportType, val.fromDate, val.toDate).then((data: ArrayBuffer) => {

            if (data.byteLength == 0) {
                toastWarning('Reports.noInvoicesMessage');

                return;
            }

            let title: string = "";

            switch (val.reportType) {
                case "0":
                    title = t('Reports.Type.salesReport');
                    break;

                case "1":
                    title = t('Reports.Type.revenuePerDay')
                    break;

                case "2":
                    title = t('Reports.Type.invoicesBook')
                    break;

                case "3":
                    title = t('Reports.Type.productReport')
                    break;

                case "4":
                    title = t('Reports.Type.salesBook')
                    break;
            }

            openDocument( `${title}.pdf`, data);

            // close()
        });
    }

    return <div className="popup__overlay popup__overlay--show send-document-by-email">
        <div className="popup" >
            <h1 className="overlay__title"> {t('Reports.Header.pageTitle')}</h1>
            <div className="popup__close icono-cross" onClick={close} style={{ cursor: "pointer" }}>{t('Allaround.Popup.close')}</div>
            <Formik
                initialValues={{ fromDate: new Date(), toDate: new Date(), reportType: "0" }}
                onSubmit={val => onSubmit(val)}>
                {
                    formikProps => <Form noValidate={true}>
                        <div className="address-placeholder">

                            <div className="info__row">

                                <label className="info__row-name" htmlFor="">{t('Reports.Type.title')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <Field as="select" className="offerion-select" name="reportType">
                                        <option value="0">{t('Reports.Type.salesReport')}</option>
                                        <option value="1">{t('Reports.Type.revenuePerDay')}</option>
                                        <option value="2">{t('Reports.Type.invoicesBook')}</option>
                                        <option value="3">{t('Reports.Type.productReport')}</option>
                                        <option value="4">{t('Reports.Type.salesBook')}</option>
                                    </Field>
                                </div>
                            </div>

                            <div className="info__row">

                                <label className="info__row-name" htmlFor="">{t('Reports.from')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <InlineDateEdit
                                        className="offerion-date"
                                        currentValue={formikProps.values.fromDate}
                                        onSave={({ newValue }) => formikProps.setFieldValue("fromDate", newValue)} />
                                </div>
                            </div>

                            <div className="info__row">

                                <label className="info__row-name" htmlFor="">{t('Reports.until')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <InlineDateEdit
                                        className="offerion-date"
                                        currentValue={formikProps.values.toDate}
                                        onSave={({ newValue }) => formikProps.setFieldValue("toDate", newValue)} />
                                </div>
                            </div>
                        </div>
                        <div className="popup__buttons mod-margin-top-8">
                            <button className="button button--gray popup__button" type="submit">
                                <span className="icon icon__check-green"></span>
                                {t('Allaround.Button.download')}
                            </button>
                            <button className="button button--gray popup__button" type="button" onClick={close}>
                                <span className="icon icon--small icon__cross-red"></span>
                                {t('Allaround.Button.cancel')}
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
        </div >
    </div >;
}