import React, { useEffect, useState } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'

import './styles/main.scss'
import './styles/custom.css'
import './App.css'

import { AuthenticatedRoute } from './shared/routing/AuthenticatedRoute'
import { Header } from './components/ui/Header'
import { Footer } from './components/ui/Footer'

import { LoginContainer } from './components/login/LoginContainer'
import { DashboardContainer } from './components/dashboard/DashboardContainer'
import { ClientsContainer } from './components/clients/ClientsContainer'
import { useRootStoreSelector } from './shared/store/hooks/useRootStoreSelector'
import { SpinnerLoader } from './components/ui/SpinnerLoader'
import { ConcreteTenderListContainer } from './components/concrete-tender/ConcreteTenderListContainer'
import { TemplateTenderListContainer } from './components/template-tender/TemplateTenderListContainer'
import { InvoiceListContainer } from './components/invoice/InvoiceListContainer'
import { SignoutContainer } from './components/signout/SignoutContainer'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { InvoiceDetailsContainer } from './components/invoice/InvoiceDetailsContainer'
import { ConcreteTenderDetailsContainer } from './components/concrete-tender/ConcreteTenderDetailsContainer'
import { TemplateTenderDetailsContainer } from './components/template-tender/TemplateTenderDetailsContainer'
import { SettingsContainer } from './components/settings/SettingsContainer'
import { PriceListContainer } from './components/price-list/PriceListContainer'
import { ImageSelectionContainer } from './shared/documents/DocumentImageSelectionContainer'
import { ToastContainer } from 'react-toastify'
import { RegistrationContainer } from './components/registration/RegistrationContainer'
import { ResetPasswordContainer } from './components/reset-password/ResetPasswordContainer'
import { ImpersonationContainer } from './components/impersonation/ImpersonationContainer'
import { ForgotPasswordContainer } from './components/reset-password/ForgotPasswordContainer'

import 'react-toastify/dist/ReactToastify.minimal.css'
import i18next from 'i18next'

const UsetifulContainer = () => {
    const scriptId = 'usetifulScript'
    const user = useRootStoreSelector(state => state.user)

    useEffect(() => {
        const isProd = process.env.REACT_APP_QA == 'false'

        if (!isProd) return

        if (user.authenticated) {
            const companyType = user.CompanyType

            let segment: string = ''
            switch (companyType) {
                case '0':
                    segment = 'Basic'
                    break
                case '1':
                    segment = 'Lawyer'
                    break
                case '2':
                    segment = 'Window Maker'
                    break
                case '3':
                    segment = 'Renter'
                    break
                case '4':
                    segment = 'Other'
                    break
            }

            ;(window as any).usetifulTags = { userId: user.UserId, segment: segment, firstName: user.FirstName }

            const script = document.createElement('script')
            script.async = true
            script.src = 'https://www.usetiful.com/dist/usetiful.js'
            script.id = scriptId
            script.dataset.token = 'c33b8f968023e5e0c1b645ce3e40aa47'

            document.head.appendChild(script)
        } else {
            const el = document.getElementById(scriptId)

            if (el) el.remove()
            
            ;(window as any).usetifulTags = undefined
        }
    }, [user.authenticated, user.UserId])

    return null
}

function App() {
    const user = useRootStoreSelector(state => state.user)

    useEffect(() => {
        i18next.changeLanguage(user.UiLanguage)
    }, [user.UiLanguage])

    return (
        <>
            <ToastContainer />
            <UsetifulContainer />

            <Switch>
                <Route path="/impersonation" component={ImpersonationContainer}></Route>
                <Route path="">
                    <BreadcrumbsItem to="/">Izračunko</BreadcrumbsItem>

                    <SpinnerLoader />

                    <div className="push-footer" id="maindiv">
                        <Header />

                        <div ui-view="content">
                            <Switch>
                                <Route path="/login" component={LoginContainer}></Route>
                                <Route path="/register" component={RegistrationContainer}></Route>
                                <Route path="/reset-password" component={ResetPasswordContainer}></Route>
                                <Route path="/forgot-password" component={ForgotPasswordContainer}></Route>

                                <AuthenticatedRoute exact path="/dashboard" component={DashboardContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/price-list" component={PriceListContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/offers" component={ConcreteTenderListContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/templates" component={TemplateTenderListContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute path="/clients" component={ClientsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute exact path="/invoices" component={InvoiceListContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute
                                    path="/settings"
                                    requiredRole="Administrator"
                                    component={SettingsContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/signout" component={SignoutContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/template-offer/new"
                                    component={TemplateTenderDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/template-offer/new-from-offer"
                                    component={TemplateTenderDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/template-offer/new-from-invoice"
                                    component={TemplateTenderDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/template-offer/:tenderId"
                                    component={TemplateTenderDetailsContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/offer/new" component={ConcreteTenderDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/offer/new-from-invoice"
                                    component={ConcreteTenderDetailsContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute
                                    exact
                                    path="/offer/:tenderId"
                                    component={ConcreteTenderDetailsContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/invoice/new" component={InvoiceDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/invoice/new-from-offer"
                                    component={InvoiceDetailsContainer}></AuthenticatedRoute>
                                <AuthenticatedRoute
                                    exact
                                    path="/invoice/:invoiceId"
                                    component={InvoiceDetailsContainer}></AuthenticatedRoute>

                                <AuthenticatedRoute exact path="/image-selection" component={ImageSelectionContainer}></AuthenticatedRoute>

                                <Route
                                    path="/"
                                    render={() => {
                                        return user.authenticated ? <Redirect to="/dashboard" /> : <Redirect to="/login" />
                                    }}
                                />
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </Route>
            </Switch>
        </>
    )
}

export default App
