import React, { useState } from 'react'

import { ClientGridRow, ListingApi, SortDirection } from '../../../services/ListingApi'
import { Grid } from '../../../shared/grid/Grid'
import { GridFilterForm, GridFilterInput } from '../../../shared/grid/GridFilter'
import { GridMenuCell, GridMenuLink, GridMenuActionLink } from '../../../shared/grid/GridMenu'
import { AddNewClientButton } from './AddNewClientButton'
import { InlineInputEdit } from '../../../shared/forms/InlineInputEdit';
import { ClientApi } from '../../../services/ClientApi';
import { GridActions } from '../../../shared/grid/Types'
import { ClientForm } from './ClientForm'
import { ClientModel } from '../../../shared/models'
import { toastSuccess } from '../../../shared/toastr'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { GridContextProvider, useGridActions } from '../../../shared/grid/GridContextProvider'
import { MobileSidebarOpener } from '../../../shared/elements/MobileSidebarOpener'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { faEdit, faPercent, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Filter = ({ updateFilter }: { updateFilter: (filter: any[], customFilter?: string) => void }) => {
    return (
        <GridFilterForm
            initialValues={{ clientName: '' }}
            onSubmit={v =>
                updateFilter([
                    {
                        property: 'Name',
                        operator: 'Contains',
                        value: v.clientName,
                    },
                ])
            }
            titleKey="Clients.Side.searchTitle"
            searchButtonLabelKey="Clients.Side.searchButton">
            <GridFilterInput name="clientName" placeholderKey="Clients.Side.searchPlaceholder"></GridFilterInput>
        </GridFilterForm>
    )
}

const RowComponent = ({ row, gridActions }: { row: ClientGridRow, gridActions: GridActions }) => {

    const [client, setClient] = useState<ClientModel | null>(null);
    const offerionModal = useOfferionModal();

    const editClient = () => {
        ClientApi.get(row.id).then(setClient);
    }

    const deleteClient = () => {
        offerionModal.open('Clients.Delete.pageTitle', 'Clients.Delete.questionYesNo', () => {
            return ClientApi.delete(row.id)
                .then(() => toastSuccess('Clients.Delete.Message.successDelete', { clientName: row.name }))
                .then(() => setClient(null))
                .then(gridActions.refresh)
        }, { clientName: row.name })
    }

    const upsertClient = (client: ClientModel) => {
        ClientApi.upsertClient(client)
            .then(() => toastSuccess('Clients.Edit.Message.successUpdate'))
            .then(() => setClient(null))
            .then(gridActions.refresh)
    }

    return <>
        <div
            style={{ width: '300px' }}
            className="table__cell table__cell__title table__cell__title--single-line overlay__item overlay__item--right-middle">
            <InlineInputEdit placeholderKey='Clients.Edit.namePlaceholder' currentValue={row.name} onSave={({ newValue }) => ClientApi.patchField(row.id, "Name", newValue).then(gridActions.refresh)}></InlineInputEdit>
            {client && <ClientForm initialValues={client} closeForm={() => setClient(null)} onSubmit={upsertClient}></ClientForm>}
        </div>
        <div style={{ width: '200px' }} className="table__cell">
            <InlineInputEdit placeholderKey='Clients.Edit.addressPlaceholder' currentValue={row.city} onSave={({ newValue }) => ClientApi.patchField(row.id, "AddressPlace", newValue).then(gridActions.refresh)}></InlineInputEdit>
        </div>
        <div style={{ width: '250px' }} className="table__cell table__cell__email">
            <InlineInputEdit placeholderKey="Clients.Edit.emailPlaceholder" currentValue={row.email} onSave={({ newValue }) => ClientApi.patchField(row.id, "Email", newValue).then(gridActions.refresh)}></InlineInputEdit>
        </div>

        <GridMenuCell>
            <GridMenuActionLink icon={faEdit} onClick={editClient} translationKey="Clients.Table.ItemMenu.edit"></GridMenuActionLink>
            <GridMenuActionLink icon={faTrashAlt} onClick={deleteClient} translationKey="Clients.Table.ItemMenu.delete"></GridMenuActionLink>
            <GridMenuLink
                icon={faPercent}
                to={`clients/${row.id}/agreed-prices`}
                translationKey="Clients.Table.ItemMenu.agreedPrices"></GridMenuLink>
        </GridMenuCell>
    </>
}

export const ClientsListContainer = () => {
    const { t } = useOfferionTranslation()

    const tableSchema = {
        columns: [
            {
                cssClass: 'table__header__item__name',
                key: 'Name',
                labelKey: 'Clients.Table.Header.name',
            },
            {
                cssClass: 'table__header__item__location',
                key: 'City',
                labelKey: 'Clients.Table.Header.city',
            },
            {
                key: 'Email',
                labelKey: 'Clients.Table.Header.mail',
            },
            {
                cssClass: 'table__header__item__menu'
            },
        ],
    }

    const PageWithGrid = ({ children }: { children: React.ReactNode }) => {

        return <main className="main client-list">
            <div className="main__header">
                <div className="main-title">{t('Clients.Header.pageTitle')}</div>

                <div className="main__header-buttons">
                    <AddNewClientButton />

                    <MobileSidebarOpener iconClass="icon__search" containerClass="mod-margin-left-8" />

                </div>
            </div>

            {children}
        </main>
    }

    return (
        <GridContextProvider>
            <PageWithGrid>
                <Grid
                    emptyMessageKey="Clients.Table.emptyMessage"
                    emptySearchMessageKey="Clients.Table.emptySearchMessage"
                    pagingLabelKey="Clients.Table.Header.numberOfClients"
                    translationKey="Clients.Table.Header.title"
                    rowsPromiseFactory={config =>
                        ListingApi.getList<ClientGridRow>('ClientsGridModel', config.pageNumber, config.pageSize, config.filter, undefined, "Name", SortDirection.Asc)
                    }
                    rowComponent={RowComponent}
                    sidebarComponent={Filter}
                    tableSchema={tableSchema}></Grid>
            </PageWithGrid>
        </GridContextProvider>
    )
}
