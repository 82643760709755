export const openDocument = (name: string, data: any, type?: string) => {

    const blob = new Blob([data], { type: type || 'application/pdf' })
    const url = window.URL.createObjectURL(blob);

    const anchor = window.document.createElement("a");
    anchor.download = name;
    anchor.href = url;
    anchor.click();
}

export default openDocument;