import React, { useState, useEffect, useRef, ReactNode } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { numberAsText } from '../utils/formatters'
import round from '../utils/round'

interface RequiredProps {
    required: true
    validationSchema?: Yup.ObjectSchema
    children?: ReactNode
    className?: string
    currentValue: number
    onSave: ({ newValue }: { newValue: number }) => void
}
interface NotRequiredProps {
    required: false
    validationSchema?: Yup.ObjectSchema
    children?: ReactNode
    className?: string
    currentValue: number | null
    onSave: ({ newValue }: { newValue: number | null }) => void
}

export const InlineNumberEdit = ({ currentValue, validationSchema, className, ...rest }: RequiredProps | NotRequiredProps) => {
    const [isEdit, setIsEdit] = useState(false)
    const fieldRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (isEdit && fieldRef.current) {
            fieldRef.current.focus()
        }
    }, [isEdit])

    const currentValueAsString = numberAsText(currentValue)

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{ newValue: currentValue == 0 ? '' : currentValueAsString }}
                onSubmit={async (values, helpers) => {
                    const correctedValue = values.newValue ? round(Number.parseFloat(values.newValue.replace(',', '.'))) : null

                    const isValid = validationSchema ? await validationSchema.isValid({ newValue: correctedValue }) : true

                    if (isValid) {
                        if (correctedValue != currentValue) {
                            if (correctedValue != null) rest.onSave({ newValue: correctedValue })
                            else if (correctedValue == null && !rest.required) rest.onSave({ newValue: correctedValue })
                            else helpers.resetForm()
                        } else {
                            helpers.resetForm()
                        }
                    } else {
                        helpers.resetForm()
                    }

                    setIsEdit(false)

                    return Promise.resolve()
                }}>
                {props => {
                    return (
                        <Form style={{ display: isEdit ? 'block' : 'none' }}>
                            <Field
                                autoFocus
                                inputMode="decimal"
                                onKeyDown={(e: any) => {
                                    const key = e.key

                                    if (key === 'Enter' || key === 'Tab') {
                                        props.submitForm()
                                        return
                                    }

                                    const isValidKey =
                                        key === 'ArrowLeft' ||
                                        key === 'ArrowRight' ||
                                        key === '-' ||
                                        key === 'Backspace' ||
                                        key === 'Delete' ||
                                        key === ',' ||
                                        key === '.' ||
                                        /\d/.test(key)

                                    if (!isValidKey) {
                                        e.preventDefault()
                                    }
                                }}
                                innerRef={fieldRef}
                                onBlur={props.submitForm}
                                type="text"
                                name="newValue"></Field>
                        </Form>
                    )
                }}
            </Formik>

            {isEdit ? null : (
                <span className={className} style={{ width: '100%' }}>
                    <span style={{ cursor: 'pointer' }} onClick={() => setIsEdit(true)}>
                        {currentValueAsString}
                        {rest.children}
                    </span>
                </span>
            )}
        </>
    )
}
