import React, { ReactNode } from 'react'
import { Breadcrumbs as DynamicBreadcrumbs } from 'react-breadcrumbs-dynamic'
import { Link } from 'react-router-dom'

export const Breadcrumbs = () => {

    return <DynamicBreadcrumbs
        item={({ to, children }: any) => {
            return <li className="breadcrumbs__item">
                 <Link className="breadcrumbs__link mod-margin-left-4" to={to}>
                    {children}
                </Link>
            </li>
        }}
        container={({ children }: { children: ReactNode[] }) => {
            return <div className="breadcrumbs__container">
                <div className="breadcrumbs">
                    <ul className="breadcrumbs__list">{children}
                    </ul>
                </div>
            </div>
        }}
        finalProps={{ active: true }}
        duplicateProps={{ to: 'href' }}
    />
}
