import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import storeConfig from '../store/Store'
import { Spinner } from '../../services/Spinner'
import { toastError } from '../toastr';

const UNAUTHORIZED_STATUS_CODE = 401

let activeRequestsCounter = 0

export interface IApiClient {
    get: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
    post: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean } & AxiosRequestConfig) => Promise<TReturn>
    put: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
    delete: <TReturn>(url: string, data?: {}, headers?: {}, config?: { ignoreLoader?: boolean }) => Promise<TReturn>
}

const instance = axios.create({
    baseURL: process.env.REACT_APP_SERVER_LOCATION
})

instance.interceptors.request.use(
    function (config) {

        if (!(config as any).ignoreLoader) {
            activeRequestsCounter++

            Spinner.show()
        }

        const access_token = storeConfig.store.getState().user.access_token
        const language = storeConfig.store.getState().user.UiLanguage

        config.headers['Accept-Language'] = language;

        if (access_token) {
            config.headers['Authorization'] = `Bearer ${access_token}`
        } else {
            config.headers['Authorization'] = ''
        }

        return config
    },
    function (error) {
        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0)
            Spinner.hide()

        toastError('Allaround.Message.unknownError');

        return Promise.reject(error)
    }
)

instance.interceptors.response.use(
    function (response) {

        if (response && response.config && (response.config as any).ignoreLoader)
            return response;

        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0) Spinner.hide()

        return response
    },
    function (error) {
        if (error && error.response && error.response.status == UNAUTHORIZED_STATUS_CODE) {
            window.location.href = "/signout";

            return;
        }

        activeRequestsCounter = Math.max(0, activeRequestsCounter - 1);

        if (activeRequestsCounter == 0) Spinner.hide()

        const isArrayBuffer = error && error.response && error.response.status == 400 && error.response.data && error.response.data.byteLength;
        const isValidationError = error && error.response && error.response.status == 400 && error.response.data && error.response.data.modelState;

        if (isValidationError) {
            Object.values<string[]>(error.response.data.modelState).forEach((r: string[]) => toastError(r[0], false))
        }
        else if (isArrayBuffer) {
            const encoder = new TextDecoder("utf-8");
            const result = JSON.parse(encoder.decode(error.response.data));

            if (result.modelState)
                Object.values<string[]>(result.modelState).forEach((r: string[]) => toastError(r[0], false))
            else if (result.message)
                toastError(result.message)
        }
        else {
            toastError('Allaround.Message.unknownError');
        }

        return Promise.reject(error)
    }
)

function callApi<T>(method: any, url: string, data: any, headers = {}, customData = {}): AxiosPromise<T> {
    const config = { ...customData, headers };

    return (instance as any)[method](url, data, config).then((r: any) => r.data)
}

const get = callApi.bind(null, 'get')
const post = callApi.bind(null, 'post')
const put = callApi.bind(null, 'put')
const del = callApi.bind(null, 'delete')

export const ApiClient = { get, post, put, delete: del } as IApiClient
