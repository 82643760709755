import React, { ReactNode, useEffect } from 'react'
import { createPortal } from 'react-dom'
import { useIsMobile } from '../store/hooks/useIsMobile'
import FocusTrap from 'focus-trap-react'

type OverlayProps = {
    children: ReactNode
    style?: React.CSSProperties
    setRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    setArrowRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    styles?: any
    attributes?: any
    forceGlobal?: boolean
}

export const Overlay = ({ children, style, setRef, setArrowRef, styles = {}, attributes = {}, forceGlobal = false }: OverlayProps) => {
    const isMobile = useIsMobile()
    const showGlobalOverlay = isMobile || (window as any).forceMobile || forceGlobal

    useEffect(() => {
        if (showGlobalOverlay) document.body.classList.add('no-scroll')

        return () => {
            if (showGlobalOverlay) document.body.classList.remove('no-scroll')
        }
    }, [])

    const popperStyle = styles?.popper || {}
    const popperAttributes = attributes?.popper || {}
    const arrowStyles = styles?.arrow || {}

    return showGlobalOverlay ? (
        createPortal(
            <div className="overlay__background overlay__background--visible" style={{ zIndex: 9999 }}>
                <FocusTrap>
                    <div className="overlay overlay--global overlay--wide">{children}</div>
                </FocusTrap>
            </div>,
            document.body
        )
    ) : (
        <div ref={setRef} style={{ ...style, ...popperStyle }} {...popperAttributes} className="overlay overlay--wide">
            <Arrow setRef={setArrowRef} style={arrowStyles} />
            {children}
        </div>
    )
}

function Arrow({ setRef, style }: { setRef?: any; style?: any }) {
    if (!setRef) return null
    return <div className="arrow" ref={setRef} style={style} data-placement="left" />
}
