import React from 'react';
import dayjs from 'dayjs';
import c from 'currency.js';
import round from './round';
import i18next from 'i18next'
import { ReceiptStatus, ReceiptStatusHistory, TenderStatus, TenderStatusHistoryEnum } from '../models';

export const date = (date: Date | null | string) => {
    return date ? dayjs(date).format("DD.MM.YYYY") : "";
}

export const dateWithTime = (date: Date | null | string) => {
    return date ? dayjs(date).format("DD.MM.YYYY HH:mm") : "";
}

export const numberAsText = (amount: number | string | null, ignorePrecision?: boolean) => {
    if (amount == null)
        return '';

    amount = typeof amount == "string"
        ? Number.parseFloat(amount)
        : amount;

    const hasDecimalPlaces = amount % 1 != 0

    if (hasDecimalPlaces) {
        const doesEndWithZero = (amount * 100) % 10 == 0;

        return doesEndWithZero
            ? c(amount, { separator: '', decimal: ",", precision: ignorePrecision ? 6 : 1, symbol: '' }).format()
            : c(amount, { separator: '', decimal: ",", precision: ignorePrecision ? 6 : 2, symbol: '' }).format();

    }

    return round(amount).toString();
}

export const currencyAsText = (amount: number | string | null) => {
    return c(amount || "", { separator: '.', decimal: ",", precision: 2, symbol: '' }).format()
}

export const getKeyForReceiptStatus = (status: ReceiptStatusHistory) => {
    let key = '';

    switch (status) {
        case ReceiptStatusHistory.Draft:
            key = 'Allaround.Status.draft';
            break;
        case ReceiptStatusHistory.Sent:
        case ReceiptStatusHistory.SentEInvoice:
            key = 'Allaround.Status.invoiceSent';
            break;
        case ReceiptStatusHistory.Due:
            key = 'Allaround.Status.invoicePastDue';
            break;
        case ReceiptStatusHistory.Paid:
            key = 'Allaround.Status.invoicePaid';
            break;
        case ReceiptStatusHistory.Cancelled:
            key = 'Allaround.Status.invoiceCancelled';
            break;
        default:
            throw new Error('Not supported');
    }

    return key;
}

export const getKeyForTenderStatus = (status: TenderStatusHistoryEnum) => {
    let key = '';

    switch (status) {
        case TenderStatusHistoryEnum.Draft:
            key = 'Allaround.Status.draft';
            break;
        case TenderStatusHistoryEnum.Approved:
            key = 'Allaround.Status.offerAccepted';
            break;
        case TenderStatusHistoryEnum.Declined:
            key = 'Allaround.Status.offerDeclined';
            break;
        case TenderStatusHistoryEnum.Sent:
            key = 'Allaround.Status.offerSent';
            break;
    }

    return key;
}