import React, { useState } from 'react'
import { ClientForm } from './ClientForm'
import { ClientModel } from '../../../shared/models'
import { ClientApi } from '../../../services/ClientApi'
import { useGridActions } from '../../../shared/grid/GridContextProvider'
import { toastSuccess } from '../../../shared/toastr'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'

export const AddNewClientButton = () => {
    const { t } = useOfferionTranslation()
    const grid = useGridActions();

    const [hideOverlay, setHideOverlay] = useState(true)

    const onSubmit = async (client: ClientModel) => {
        await ClientApi.upsertClient(client);

        toastSuccess("Clients.Edit.Message.successCreate");

        setHideOverlay(true);

        grid.refresh();
    }

    return (
        <>
            {hideOverlay ? (
                <div className="table__button-container mr0">
                    <button className="button button--white table__button overlay__button" onClick={() => setHideOverlay(false)}>
                        <span className="icon icon__plus"></span>
                    </button>
                    <span className="button-text table__button-text mod-margin-left-4">{t('Clients.Header.newClient')}</span>
                </div>
            ) : (
                <div className="button-container-with-text table__button-container mr0">
                    <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active overlay__item--show-overlay mr0">
                        <button className="button button--gray button--plus overlay__button overlay__button--active">
                            <span className="icon icon__plus"></span>
                        </button>

                        <ClientForm onSubmit={onSubmit} closeForm={() => setHideOverlay(true)} />
                    </div>
                    <span className="button-text table__button-text mod-margin-left-4">{t('Clients.Header.newClient')}</span>
                </div>
            )}
        </>
    )
}
