import i18next from 'i18next';
import React, { ReactNode } from 'react';
import { cssTransition, toast, ToastContentProps } from 'react-toastify';

const offerionToast = (node: (props: ToastContentProps) => React.ReactNode, options?: { autoClose?: number | false }) => {
    toast(node,
        {
            bodyClassName: "notification-body",
            className: "notification-container",
            hideProgressBar: true,
            autoClose: options?.autoClose,
            draggable: false,
            closeButton: false,
            transition: cssTransition({
                enter: 'zoomIn',
                exit: 'zoomOut',
                duration: [0, 500]
            })
        });
}

const Header = ({ type }: { type: string }) => {
    return <h3 className={`notification__title notification__title--${type}`}>{i18next.t(`Popup.${type}Title`)}</h3>
}

export const toastSuccess = (messageKey: string, options?: {}) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="success" />
        <p className="notification__message" dangerouslySetInnerHTML={{ __html: i18next.t(messageKey, options) }} ></p>
    </div>)
}

export const toastInfo = (messageKey: string) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="info" />
        <p className="notification__message">{i18next.t(messageKey)}</p>
    </div>)
}

export const toastWarning = (messageKey: string, skipTranslate?: boolean, autoClose?: number | false) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="warning" />
        <p className="notification__message" dangerouslySetInnerHTML={{ __html: skipTranslate ? messageKey : i18next.t(messageKey) }} ></p>
    </div>, {
        autoClose
    })
}

export const toastError = (messageKey: string, autoClose?: number | false) => {
    offerionToast(props => <div className="notification">
        <a className="notification__close icono-cross pointer" onClick={props.closeToast}></a>
        <Header type="error" />
        <p className="notification__message" dangerouslySetInnerHTML={{ __html: i18next.t(messageKey) }} ></p>
    </div>, {
        autoClose
    })
}