import React, { useState } from 'react'
import { useRootStoreSelector } from '../../shared/store/hooks/useRootStoreSelector'
import { Breadcrumbs } from './Breadcrumbs'
import { Link, useLocation } from 'react-router-dom'
import Modal from '../../shared/modal'
import { ReportsModal } from '../invoice/ReportsModal'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import Logo from '../../assets/images/logo_2x.png'

export const Header = () => {
    const [menuVisible, setMenuVisible] = useState(false)
    const { t } = useOfferionTranslation()
    const user = useRootStoreSelector(state => state.user)
    const [isReportsModalVisible, setIsReportsModalVisible] = useState(false);
    const path = useLocation();

    const links = [
        {
            icon: "icon__document-star-white",
            to: "/offers",
            translationKey: "Header.offers"
        },
        {
            icon: "icon__document-check",
            to: "/invoices",
            translationKey: "Header.invoices"
        },
        {
            icon: "icon__document-catalogue",
            to: "/price-list",
            translationKey: "Header.priceList"
        }, {
            icon: "icon__documents",
            to: "/templates",
            translationKey: "Header.templates"
        },

        {
            icon: "icon__person-white",
            to: "/clients",
            translationKey: "Header.clients"
        },
    ].map(link => {

        return <li className={`nav__list-item ${path.pathname.startsWith(link.to) ? 'nav__list-item--active' : ''}`}>
            <Link to={link.to} onClick={() => setMenuVisible(!menuVisible)}>
                <span className={`icon ${link.icon}`}></span>
                <span className="mod-margin-left-4">{t(link.translationKey)}</span>
            </Link>
        </li>
    });

    return (
        <header className="header">
            <div className="header__navigation">
                <Link to="/dashboard">
                    <img className="header__logo" onClick={() => setMenuVisible(false)} src={Logo} alt="Izračunko logo" />
                </Link>
                {user.authenticated ? (
                    <>
                        <Link className="header__menu-icon js-menu icon-menu" to="#" onClick={() => setMenuVisible(!menuVisible)}></Link>

                        <nav className={`nav ${menuVisible ? 'nav--show' : ''}`}>
                            <ul className="nav__list">

                                {links}

                                <li className={`nav__list-item`}>
                                    <a href="#" onClick={() => setIsReportsModalVisible(true)}>
                                        <span className="icon icon__document-rise-white"></span>
                                        <span className="mod-margin-left-4">{t("Invoices.Header.reports")}</span>
                                    </a>
                                </li>

                                <Modal isOpen={isReportsModalVisible}>
                                    <ReportsModal close={() => setIsReportsModalVisible(false)}></ReportsModal>
                                </Modal>

                                {user.Role && user.Role.includes("Administrator") && <li className="nav__list-item nav__list-item--settings">
                                    <Link to="/settings" className="header__settings" onClick={() => setMenuVisible(!menuVisible)}>
                                        <span className="icon icon__settings-white"></span>
                                        <span className="header__settings__label">{t('Header.settings')}</span>
                                    </Link>
                                </li>}

                                <li className="nav__list-item nav__list-item--logout mobile-only">
                                    <Link to="/signout">
                                        <span className="icon icon__logout"></span>
                                        <span className="mod-margin-left-4">{t('Header.signOut')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                        <Link to="/signout" className="header__sign-out icon-sign_out">
                            <span className="icon-sign-out"></span>
                            <span className="mod-margin-left-4">{t('Header.signOut')}</span>
                        </Link>
                    </>
                ) : null}
            </div>

            {user.authenticated && <Breadcrumbs />}
        </header>
    )
}