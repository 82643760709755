import { Field, Form, Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';
import { InvoiceApi, EInvoiceModel, EInvoiceRequestModel } from '../../services/InvoiceApi';
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { Currency } from '../../shared/models';
import * as Yup from 'yup'
import { OfferionTooltip } from '../../shared/tooltip';
import { CurrencyLabel } from '../../shared/ui/currency';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export enum EInvoiceModalType {
    SendEinvoice,
    DownloadEInvoice
}

interface Props {
    type: EInvoiceModalType;
    close: () => void;
    details: {
        forCharge: number,
        clientEmail: string,
        documentNumber: string,
        clientName: string,
        invoiceId: number,
        currency: Currency,
        creator: string
    }
    onSubmit: (data: EInvoiceRequestModel) => Promise<void>;
    documentFooterRenderer?: () => ReactNode;
}

export const EInvoiceModal = ({ type, details, close, onSubmit, documentFooterRenderer }: Props) => {

    const { t } = useOfferionTranslation();

    const [eInvoiceData, setEinvoiceData] = useState<EInvoiceModel | null>(null)

    useEffect(() => {
        InvoiceApi.getEInvoiceData(details.invoiceId).then(result => {
            setEinvoiceData(result);
        })
    }, [])

    if (!eInvoiceData)
        return null;

    const schema = Yup.object().shape({
        toEmail: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("Invoice.Send.email") }))
            .nullable(),
        orderReference: Yup.string()
            .when('processIdentificator', (processIdentificator: string, schema: Yup.StringSchema) => {

                const shouldBeRequired = processIdentificator == "P1"
                    || processIdentificator == "P3"
                    || processIdentificator == "P6"
                    || processIdentificator == "P7"
                    || processIdentificator == "P8";

                if (shouldBeRequired)
                    return schema.required(t("Allaround.Validation.required", { fieldName: t("Invoice.SendEInvoice.purchaseOrderNum") }));

                return schema.notRequired()

            })
            .nullable(),
        taxExemptionReason: Yup.string()
            .test('Check exemption reason required', t("Allaround.Validation.required", { fieldName: t("Invoice.SendEInvoice.globalExemptionReason") }), (val: any) => {
                if (!eInvoiceData.reasonContainers.length)
                    return true;

                return val && val.length;
            })
            .nullable(),
        reasonContainers: Yup.array()
            .when('isTaxExemptionReasonGlobal', {
                is: false,
                then: Yup.array().of(
                    Yup.object().shape({
                        reason: Yup.string().required(t("Allaround.Validation.required", { fieldName: t("Invoice.SendEInvoice.itemExemptionReason") })).nullable(),
                    })
                )
            })
    });

    const initialValues = {
        ...eInvoiceData,
        downloadAfterSend: true,
        type: type,
        invoiceId: details.invoiceId,
        toEmail: details.clientEmail,
        processIdentificator: eInvoiceData.processIdentificator || "P1",
        deliveryDate: eInvoiceData.deliveryDate || new Date(),
        shouldFiscalize: true,
        isTaxExemptionReasonGlobal : true
    }

    return <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: "default" }}>
        <div className="popup" >
            <h1 className="overlay__title"> {t(type == EInvoiceModalType.DownloadEInvoice ? 'Invoice.SendEInvoice.pageTitleDownload' : 'Invoice.SendEInvoice.pageTitleSend')}
                {type == EInvoiceModalType.DownloadEInvoice && <OfferionTooltip placement="right" titleKey='Invoice.SendEInvoice.pageTitleDownload.infoTitle' bodyKey="Invoice.SendEInvoice.pageTitleDownload.infoText" />}
            </h1>
            <div className="popup__close icono-cross" onClick={close} style={{ cursor: "pointer" }}>{t('Allaround.Popup.close')}</div>
            <div className="invoice-info-placeholder mod-margin-bottom-8">
                {t('Invoice.Send.invoiceAttached')}
                <span className="invoice-info">
                    <ul>
                        <li>{details.documentNumber}</li>
                        <li>{details.clientName}</li>
                        <li><CurrencyLabel amount={details.forCharge} currency={details.currency} /></li>
                        <li>{details.creator}</li>
                    </ul>
                </span>
            </div>
            <Formik validationSchema={schema}
                initialValues={initialValues}
                onSubmit={(values, ctx) => {
                    onSubmit(values).catch(() => {
                        if (values.shouldFiscalize)
                            ctx.setFieldValue("shouldFiscalize", false);
                    })
                }}>
                {
                    formikProps => <Form noValidate={true}>
                        <div className="address-placeholder">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">{t('Invoice.Send.email')}</label>
                                <div className="info__row-content mod-margin-8">
                                    <Field type="email" className="address-input" name="toEmail" placeholder={t('Invoice.Send.emailPlaceholder')} />
                                    <OfferionErrorMessage name="toEmail" />
                                </div>
                            </div>
                            <div className="info__row">

                                <label className="info__row-name" htmlFor="">{t('Invoice.SendEInvoice.deliveryDate')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <InlineDateEdit showTimeInput
                                        className="offerion-date"
                                        currentValue={formikProps.values.deliveryDate}
                                        onSave={({ newValue }) => formikProps.setFieldValue("deliveryDate", newValue)} />
                                    <OfferionErrorMessage name="deliveryDate" />
                                </div>
                            </div>

                            <div className="info__row">

                                <label className="info__row-name" htmlFor="">{t('Invoice.SendEInvoice.invoiceProcess')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <Field as="select" className="offerion-select" name="processIdentificator">
                                        <option value="P1">{t('Invoice.SendEInvoice.InvoiceProcess.p1')}</option>
                                        <option value="P2">{t('Invoice.SendEInvoice.InvoiceProcess.p2')}</option>
                                        <option value="P3">{t('Invoice.SendEInvoice.InvoiceProcess.p3')}</option>
                                        <option value="P4">{t('Invoice.SendEInvoice.InvoiceProcess.p4')}</option>
                                        <option value="P5">{t('Invoice.SendEInvoice.InvoiceProcess.p5')}</option>
                                        <option value="P6">{t('Invoice.SendEInvoice.InvoiceProcess.p6')}</option>
                                        <option value="P7">{t('Invoice.SendEInvoice.InvoiceProcess.p7')}</option>
                                        <option value="P8">{t('Invoice.SendEInvoice.InvoiceProcess.p8')}</option>
                                        <option value="P9">{t('Invoice.SendEInvoice.InvoiceProcess.p9')}</option>
                                        <option value="P10">{t('Invoice.SendEInvoice.InvoiceProcess.p10')}</option>
                                        <option value="P11">{t('Invoice.SendEInvoice.InvoiceProcess.p11')}</option>
                                        <option value="P12">{t('Invoice.SendEInvoice.InvoiceProcess.p12')}</option>
                                    </Field>
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">{t('Invoice.SendEInvoice.purchaseOrderNum')}</label>
                                <div className="info__row-content mod-margin-bottom-8">
                                    <Field type="text" name="orderReference" />
                                    <OfferionErrorMessage name="orderReference" />
                                </div>
                            </div>

                            {
                                formikProps.values.reasonContainers.length ? <>
                                    <div className="info__row">
                                        <label className="info__row-name" htmlFor="">{t('Invoice.SendEInvoice.globalExemptionReason')}</label>
                                        <div className="info__row-content mod-margin-8">
                                            <Field type="text" className="address-input" name="taxExemptionReason" />
                                            <OfferionErrorMessage name="taxExemptionReason" />
                                        </div>
                                    </div>

                                    {formikProps.values.reasonContainers.length > 1 ?
                                        <>
                                            <div className="info__row">

                                                <div style={{ float: "left" }}>
                                                    <Field id="show-in-print1" type="checkbox" className="checkbox" name="isTaxExemptionReasonGlobal" />
                                                    <label htmlFor="show-in-print1"
                                                        className="button button--gray button--padded checkbox-button button--print">
                                                        <span className="checkbox-button__icon"></span>
                                                    </label>
                                                </div>
                                                <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                                                    {t("Invoice.SendEInvoice.isTaxExemptionReasonGlobal")}
                                                </label>
                                            </div>
                                            {!formikProps.values.isTaxExemptionReasonGlobal && formikProps.values.reasonContainers.map((item, index) => {
                                                return <div className="info__row">
                                                    <label className="info__row-name" htmlFor="">{t('Invoice.SendEInvoice.exemptionReasonFor', { itemName: item.name })}</label>
                                                    <div className="info__row-content mod-margin-8">
                                                        <Field as="textarea" className="address-input" name={`reasonContainers.${index}.reason`} />
                                                        <OfferionErrorMessage name={`reasonContainers.${index}.reason`} />
                                                    </div>
                                                </div>
                                            })}
                                        </>
                                        : null}
                                </>
                                    : null}
                            {type == EInvoiceModalType.SendEinvoice && <div className="info__row">

                                <div style={{ float: "left" }}>
                                    <Field id="show-in-print2" type="checkbox" className="checkbox" name="downloadAfterSend" />
                                    <label htmlFor="show-in-print2"
                                        className="button button--gray button--padded checkbox-button button--print">
                                        <span className="checkbox-button__icon"></span>
                                    </label>
                                </div>
                                <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                                    {t("Invoice.SendEInvoice.downloadAfterSendYesNo")}
                                </label>
                            </div>}
                            {documentFooterRenderer && documentFooterRenderer()}
                        </div>
                        <div className="popup__buttons mod-margin-top-8">
                            <button className="button button--gray popup__button" type="submit">
                                <span className="icon icon__check-green"></span>
                                {t(type == EInvoiceModalType.SendEinvoice ? 'Allaround.Button.send' : 'Allaround.Button.download')}
                            </button>
                            <button className="button button--gray popup__button" type="button" onClick={close}>
                                <span className="icon icon--small icon__cross-red"></span>
                                {t('Allaround.Button.cancel')}
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
        </div >
    </div >

}
