import React from "react"
import { ReceiptStatus, ReceiptStatusHistory, StatusIconMapping, TenderStatus, TenderStatusHistoryEnum } from "../../shared/models"

type StatusColor = "blue" | "red" | "yellow" | "green"

type StatusIndicatorProps = {
  status: number,
  mapping: StatusIconMapping,
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void,
  small?: boolean
}

export default function StatusIndicator({ status, mapping, onClick, small }: StatusIndicatorProps) {
  const color: StatusColor = getStatusColor(mapping, status)
  let classes = `status-indicator status-indicator--${color}`
  if (small) {
    classes += " status-indicator--small"
  }

  return <span onClick={onClick} className={classes} />
}

function getStatusColor(mapping: StatusIconMapping, status: number) {
  // @ts-ignore
  const statusColor: StatusColor = mappings[mapping][status]
  return statusColor || "yellow"
}

const mappings = {
  "ReceiptStatus": {
    [ReceiptStatus.Draft]: "blue",
    [ReceiptStatus.Sent]: "yellow",
    [ReceiptStatus.Due]: "red",
    [ReceiptStatus.Paid]: "green",
    [ReceiptStatus.Cancelled]: "red",
  },
  "ReceiptStatusHistory": {
    [ReceiptStatusHistory.Draft]: "blue",
    [ReceiptStatusHistory.Sent]: "yellow",
    [ReceiptStatusHistory.Due]: "red",
    [ReceiptStatusHistory.Paid]: "green",
    [ReceiptStatusHistory.Cancelled]: "red",
    [ReceiptStatusHistory.SentEInvoice]: "yellow",
    [ReceiptStatusHistory.Fiscalized]: "green",
  },
  "TenderStatus": {
    [TenderStatus.Draft]: "blue",
    [TenderStatus.Sent]: "yellow",
    [TenderStatus.Approved]: "green",
    [TenderStatus.Declined]: "red",
  },
  "TenderStatusHistoryEnum": {
    [TenderStatusHistoryEnum.Draft]: "blue",
    [TenderStatusHistoryEnum.Sent]: "yellow",
    [TenderStatusHistoryEnum.Approved]: "yellow",
    [TenderStatusHistoryEnum.InvoiceCreated]: "green",
    [TenderStatusHistoryEnum.Declined]: "red",
  }
}
