import React, { useState, useEffect, ComponentType, useMemo } from 'react'
import { TableParams, GridData, SortModel, TableSchema, GridActions } from './Types'
import { useGridActionsUpdater } from './GridContextProvider'
import Modal from '../modal'
import Skeleton from 'react-loading-skeleton'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'
import { useIsMobile } from '../store/hooks/useIsMobile'

interface Props<T> {
    emptyGridMessage?: string | JSX.Element
    translationKey: string | string[]
    tableSchema: TableSchema<T>
    rowsPromiseFactory: (params: TableParams) => Promise<GridData<T>>
    defaultPageSize?: number
    rowComponent: ComponentType<{ row: T; gridActions: GridActions }>
    emptyRowComponent?: ComponentType<{ gridActions: GridActions }>
    firstRowComponent?: ComponentType<{ gridActions: GridActions }> | null
    sidebarComponent?: ComponentType<{
        currentCustomFilter?: string | null
        updateFilter: (filter: any[], customFilter?: string) => void
    }> | null
    pagingLabelKey?: string
    hidePaging?: boolean
    emptyMessageKey: string
    emptySearchMessageKey: string
    alwaysShowFirstRow?: boolean
    sidebarDependencyArray?: any[]
    customRowClass?: (row: T) => string
    defaultCustomFilter?: string | null
    sidebarComponentMobileTitleKey?: string
    showMultiLineRowContent?: boolean
}

const TablePaging = (props: { currentPage: number; total: number; pageSize: number; onPageSelected: (page: number) => void }) => {
    let pageSelectorsToBuild: number[]
    const dots = -1
    const maxNumberOfPagingElements = 5
    const numberOfPages = props.total > props.pageSize ? Math.ceil(props.total / props.pageSize) : 1

    if (numberOfPages <= 1) return null

    if (numberOfPages <= maxNumberOfPagingElements) pageSelectorsToBuild = Array.apply(0, new Array(numberOfPages)).map((_, i) => i + 1)
    else if (props.currentPage <= 3) pageSelectorsToBuild = [1, 2, 3, dots, numberOfPages]
    else if (props.currentPage > numberOfPages - 3) pageSelectorsToBuild = [1, dots, numberOfPages - 2, numberOfPages - 1, numberOfPages]
    else pageSelectorsToBuild = [1, dots, props.currentPage, dots, numberOfPages]

    pageSelectorsToBuild = pageSelectorsToBuild.filter(page => page <= numberOfPages)

    const lastPage = pageSelectorsToBuild[pageSelectorsToBuild.length - 1]

    const builtPageSelectors = pageSelectorsToBuild.map(page => {
        return (
            <button
                key={page}
                onClick={() => page !== dots && props.onPageSelected(page)}
                className={`button button--gray pagination__button ng-binding ng-scope ${
                    props.currentPage === page ? 'pagination__button--active' : ''
                }`}>
                {page === dots ? '...' : page}
            </button>
        )
    })

    return (
        <ul className="pagination">
            <button
                className={`button button--gray pagination__button ng-scope ${props.currentPage === 1 ? 'disabled' : ''}`}
                disabled={props.currentPage === 1}
                onClick={() => props.onPageSelected(1)}>
                «
            </button>
            <button
                className={`button button--gray pagination__button ng-scope ${props.currentPage === 1 ? 'disabled' : ''}`}
                disabled={props.currentPage === 1}
                onClick={() => props.onPageSelected(props.currentPage - 1)}>
                ‹
            </button>
            {builtPageSelectors}
            <button
                className={`button button--gray pagination__button ng-scope ${props.currentPage === lastPage ? 'disabled' : ''}`}
                disabled={props.currentPage === lastPage}
                onClick={() => props.onPageSelected(props.currentPage + 1)}>
                ›
            </button>
            <button
                className={`button button--gray pagination__button ng-scope ${props.currentPage === lastPage ? 'disabled' : ''}`}
                disabled={props.currentPage === lastPage}
                onClick={() => props.onPageSelected(lastPage)}>
                {' '}
                »
            </button>
        </ul>
    )
}

export const Grid = <T extends {}>(tableProps: Props<T>) => {
    const isMobile = useIsMobile()
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(tableProps.defaultPageSize ? tableProps.defaultPageSize : 10)
    const [sort, setSort] = useState<SortModel>(null)
    const [filterObject, setFilter] = useState<{ customFilter?: string | null; filter: any[] }>({
        customFilter: tableProps.defaultCustomFilter,
        filter: [],
    })
    const [rows, setRows] = useState<T[]>([])
    const [total, setTotal] = useState<number>(0)
    const gridActionsUpdater = useGridActionsUpdater()
    const [isDataLoaded, setIsDataLoaded] = useState(false)
    const [isFiltersModalVisible, setIsFiltersModalVisible] = useState(false)
    const { t } = useOfferionTranslation()

    const loadData = () => {
        setRows([])
        setIsDataLoaded(false)

        tableProps
            .rowsPromiseFactory({
                filter: filterObject.filter,
                pageNumber,
                pageSize,
                sort,
                customFilter: filterObject.customFilter,
            })
            .then(data => {
                setRows(data.data)
                setTotal(data.count)
                setIsDataLoaded(true)
            })
    }

    const getGridActions = () => {
        return {
            refresh: loadData,
            toggleFiltersModal: () => {
                setIsFiltersModalVisible(val => !val)
            },
            totalRows: total,
            config: {
                filter: filterObject.filter,
                pageNumber,
                pageSize,
                sort,
                customFilter: filterObject.customFilter,
            },
            setPage: (page: number) => {
                setPageNumber(page)
            },
        }
    }

    useEffect(() => {
        const newGridActions = getGridActions()

        gridActionsUpdater(newGridActions)
    }, [pageNumber, pageSize, sort, filterObject, tableProps.rowsPromiseFactory])

    useEffect(loadData, [pageNumber, pageSize, sort, filterObject, tableProps.rowsPromiseFactory])

    const columnNames = tableProps.tableSchema.columns
        .filter(x => !x.hidden)
        .map((columnSchema, index) => {
            return (
                <li key={index} className={`${columnSchema.cssClass || ''}`}>
                    {t(columnSchema.labelKey || '')}
                </li>
            )
        })

    const gridActions = getGridActions()

    const tableBody = rows.map((row, index) => {
        return (
            <li key={index} className={`table__row ${tableProps.customRowClass ? tableProps.customRowClass(row) : ''}`}>
                {React.createElement(tableProps.rowComponent, { row, gridActions })}
            </li>
        )
    })

    const updateFilter = (newFilter: any[], customFilter?: string) => {
        setFilter({
            customFilter: customFilter,
            filter: newFilter,
        })
        setPageNumber(1)
    }

    const translationKeys = typeof tableProps.translationKey == 'string' ? [tableProps.translationKey] : tableProps.translationKey

    const heading = () => {
        return (
            <React.Fragment>
                <h1 className="table__title table__title--inline">{translationKeys.map(t)}</h1>
                {tableProps.hidePaging ? null : (
                    <div className="table__controls">
                        {tableProps.pagingLabelKey && (
                            <label className="control-label control-label--left">{t(tableProps.pagingLabelKey)}:</label>
                        )}

                        <div className="select-container">
                            <select
                                value={pageSize}
                                onChange={evt => {
                                    setPageSize(Number.parseInt(evt.target.value))
                                    setPageNumber(1)
                                }}
                                className="select">
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                            </select>
                        </div>
                        <label className="control-label control-label--right">{t('Clients.Table.Header.perPage')}</label>
                    </div>
                )}
            </React.Fragment>
        )
    }

    const sidebarComponent = useMemo(() => {
        return tableProps.sidebarComponent
            ? React.createElement(tableProps.sidebarComponent, { currentCustomFilter: filterObject.customFilter, updateFilter })
            : null
    }, tableProps.sidebarDependencyArray || [])

    const hasFilter = filterObject.filter.filter(x => x).length > 0 || !!filterObject.customFilter
    const tableClasses = tableProps.showMultiLineRowContent ? 'table table--multi-line-cell-content' : 'table'

    return (
        <>
            {sidebarComponent}

            <div className="table__container curled-paper">
                {heading()}
                <div className="row">
                    <div className="col-md-12">
                        <div className="table__outer">
                            {rows.length || tableProps.alwaysShowFirstRow ? (
                                <ul className={tableClasses}>
                                    <ul className="table__header">{columnNames}</ul>
                                    {tableProps.firstRowComponent ? (
                                        <li className={`table__row`}>
                                            {React.createElement(tableProps.firstRowComponent, { gridActions })}
                                        </li>
                                    ) : null}
                                    {tableBody}
                                </ul>
                            ) : isDataLoaded ? (
                                <div className="empty-list">
                                    <img className="empty-list__image" src="/assets/images/warning.png" alt="No content" />
                                    <p
                                        className="empty-list__message"
                                        style={{ whiteSpace: 'pre-wrap' }}
                                        dangerouslySetInnerHTML={{
                                            __html: t(hasFilter ? tableProps.emptySearchMessageKey : tableProps.emptyMessageKey),
                                        }}></p>
                                </div>
                            ) : (
                                <Skeleton count={10} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {tableProps.hidePaging || !isDataLoaded ? null : (
                <TablePaging
                    currentPage={pageNumber}
                    pageSize={pageSize}
                    total={total}
                    onPageSelected={pageNumber => {
                        setPageNumber(pageNumber)
                    }}
                />
            )}
            {isMobile && (
                <Modal isOpen={isFiltersModalVisible}>
                    <div className="popup__overlay popup__overlay--show">
                        <div className="popup">
                            <h1 className="overlay__title">
                                {' '}
                                {t(tableProps.sidebarComponentMobileTitleKey || 'Invoices.Side.searchTitle')}
                            </h1>
                            <div
                                className="popup__close icono-cross"
                                onClick={gridActions.toggleFiltersModal}
                                style={{ cursor: 'pointer' }}>
                                {t('Allaround.Popup.close')}
                            </div>
                            <div className="sidebar-inside-modal" style={{ padding: '0px 20px' }}>
                                {sidebarComponent}
                            </div>
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
