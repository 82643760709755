import React from 'react'
import { FieldProps } from 'formik'
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation'

export const TaxFreeCheckbox = (props: FieldProps<boolean> & { defaultVat: number }) => {
    const { t } = useOfferionTranslation()

    function changeValue() {
        const newValue = !props.field.value

        props.form.setFieldValue(props.field.name, newValue)
        props.form.setFieldValue('vat', newValue ? 0 : props.defaultVat)
    }

    return (
        <>
            <label className="info__row__inner-half" style={{ width: '90%' }} htmlFor="itemTaxFree">
                {t('Invoice.Edit.itemTaxFree')}
            </label>
            <div className="info__row__inner-half" style={{ width: '10%' }}>
                <input
                    className="checkbox"
                    type="checkbox"
                    id="itemTaxFree"
                    name="taxFree"
                    checked={props.field.value}
                />
                <button type="button" onClick={changeValue} className="button button--gray checkbox-button right">
                    <span className="checkbox-button__icon"></span>
                </button>
            </div>
        </>
    )
}
