export interface DocumentItemModel {
    name: string;
    additionalNote?: string;
    index: number;
    quantity: number;
    salePrice: number;
    fullPrice: number;
    id: number;
    discount: number;
    unit: {
        name: string,
        existsInSettings: boolean
    }
    vat: number;
    dimension: string;
    purchasePrice: number;
    taxFree: boolean;
    imagePath?: string | null;
    width?: number;
    height?: number;
    depth?: number,
    predefinedImageId?: number | null;
    customImageResourceId?: number | null;
}

export interface DocumentModel<TDocumentDetails extends DocumentDetails> {
    client?: ClientModel | null;
    items: DocumentItemModel[]
    details: TDocumentDetails
}

export interface DocumentDetails {
    id: number;
    documentCurrency: Currency
    clientCurrency: Currency;
    documentCurrencyRate : number;
    clientCurrencyRate: number;
    priceCalculationMode: PriceCalculationMode;
    cultureName: string | null;
    versionInformation: VersionInformation
    shouldUpdateVersionInformation?: boolean | null;
}

export enum PaymentMethod {
    Cash,
    CreditCard,
    Account,
    Cheque,
    Other
}

export enum PriceCalculationMode {
    BasePrice,
    FullPrice
}

export interface InvoiceDetails extends DocumentDetails {
    note: string;
    paymentDateOffset?: number
    number: string;
    officialDate: Date | string
    isCancelled?: boolean;
    cancelledFromReceiptId?: number;
    paymentMethod: PaymentMethod
    status: ReceiptStatus;
    concreteTenderId?: number;
    paymentDate?: Date;
}

export interface ConcreteTenderDetails extends DocumentDetails {
    paymentNoteForTender: string;
    paymentNoteForReceipt: string;
    number: string;
    officialDate: Date | string
    status: TenderStatus;
}

export interface TemplateTenderDetails extends DocumentDetails {
    paymentNoteForTender: string;
    paymentNoteForReceipt: string;
    templateName: string;
}

export interface InvoiceDocumentModel extends DocumentModel<InvoiceDetails> {
    creator?: {
        id: number,
        firstName: string,
        lastName: string
    };
    fiscalization: {
        isFiscalized: boolean;
        isInsideFiscalizationInterval: boolean;
    };
}

export interface ConcreteTenderDocumentModel extends DocumentModel<ConcreteTenderDetails> { }

export interface TemplateTenderDocumentModel extends DocumentModel<TemplateTenderDetails> { }

export interface PopularProductModel {
    productId: number;
    parentGroupName: string;
    name: string;
    salePrice: number;
    fullPrice: number;
    purchasePrice: number;
    width?: number;
    height?: number;
    depth?: number;
    customImageResourceId?: number;
    predefinedImageId?: number;
}

export enum ReceiptStatus {
    Draft,
    Sent,
    Due,
    Paid,
    Cancelled
}


export enum ReceiptStatusHistory {
    Draft,
    Sent,
    Due,
    Paid,
    Cancelled,

    SentEInvoice,
    Fiscalized
}

export enum TenderStatus {
    Draft = 0,
    Sent = 1,
    Approved = 2,
    Declined = 3
}

export enum TenderStatusHistoryEnum {
    Draft = 0,
    Sent = 1,
    Approved = 2,
    Declined = 3,
    InvoiceCreated = 4
}

// For mapping statuses with the UI, we'll use this as a helper
export type StatusIconMapping = "ReceiptStatus" | "ReceiptStatusHistory" | "TenderStatus" | "TenderStatusHistoryEnum"

export enum Currency {
    EUR = 0,
    HRK = 1,
    BAM = 2,
    GBP = 3,
    USD = 4,
    CHF = 5,
    RSD = 6,
}

export enum VersionInformation {
    CannotBeChanged = 0,
    NotLatest = 1,
    Latest = 2
}

export interface ClientModel {
    id: number;
    name: string
    address: string
    city: string
    oib: string
    email: string
    country: string
    phone: string
    cellPhone: string
    fax: string
    clientRemark: string
    printRemark: string
    postalCode: string;
    discountPercent: number;
    hideOnListing?: boolean;
    paymentDelayInDays: number;
    sourceClientId?: number | null;
}

export enum CompanyType {
    Basic = "0",
    Lawyer = "1",
    WindowMaker = "2"
}

export interface AuthToken {
    ".issued": string;
    CompanyType: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Role: string;
    UserId: string;
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;

    CompanyStartYear: string;
    NumberOfInvoicesInPackage: string;
    NumberOfTendersInPackage: string;
    NumberOfUsersInPackage: string;
    NumberOfInvoicesCreated: string;
    NumberOfTendersCreated: string;
    NumberOfUsersCreated: string;
    NumberOfDaysTillPackageExpiry: string;
    ShowPackageAboutToExpireWarning: string;
    ShowPackageExpiredWarning: string;
    ShowInvoiceLimitAboutToBeReached: string;
    ShowTenderLimitAboutToBeReached: string;
    PackageExpirationDate: string;
    ShowTenderLimitReached: string;
    ShowInvoiceLimitReached: string;
    AnyInvoiceNotFiscalized: string;

    UiLanguage: string;
}


export interface UserState {
    CompanyType: string;
    CompanyStartYear: string;
    Email: string;
    FirstName: string;
    LastName: string;
    Role: string;
    UserId: string;
    UiLanguage: string;

    access_token: string;
    authenticated: boolean;
}

export interface StoreState {
    user: UserState;
}

export enum PrintType {
    A4,
    POS
}

export interface UserModel {
    id: number;
    companySalePlaceId: number;
    firstName: string;
    lastName: string;
    title: string;
    oib: string;
    code: string;
    email: string;
    canCreateReceipts: boolean;
    canChangeSalePlace: boolean;
    isAdministrator: boolean;
}

export interface CompanyPrintSettingsModel {
    type: PrintType;
    tenderTitle: string;
    invoiceTitle: string;
    invoiceDate: string;
    paymentDueDate: string;
    personResponsibleTitle: string;
    invoiceValidationText: string;
    columnRowNumber: string;
    columnName: string;
    columnUnit: string;
    columnAmount: string;
    columnItemPrice: string;
    columnRebate: string;
    columnFinalItemPrice: string;
    columnTotalPrice: string;
    columnTotalPriceIncludingTax: string;
    columnTax: string;
    oib: string;
    telephone: string;
    mobile: string;
    fax: string;
    telephoneAndFax: string;
    email: string;
    website: string;
    accountNumber: string;
    receiptUserCodeTitle: string;
    personResponsible: string;
    paymentMethod: string;
    taxFree: string;
    alwaysShowPaymentDueDate: string;
    cancellationText: string;
    hasFooter: boolean;
    hasHeader: boolean;
    hasSignature: boolean;
    isFreeCompany : boolean;
}

export interface CompanySettingsModel {
    name: string;
    oib: string;
    telephone: string;
    mobile: string;
    fax: string;
    contactEmail: string;
    website: string;
    bankAccount: string;
    street: string;
    defaultUiLanguage: string;
    streetNumber: string;
    city: string;
    postalCode: string;
    country: string;
    defaultMailReceiptText: string;
    defaultMailTenderText: string;
    showCompanyName: boolean;
    showCompanyOib: boolean;
    showCompanyAddress: boolean;
    showCompanyTelephone: boolean;
    showCompanyMobile: boolean;
    showCompanyFax: boolean;
    showCompanyEmail: boolean;
    showCompanyWebSite: boolean;
    showCompanyBankAccount: boolean;
    hasMultipleValutes: boolean;
    defaultTenderCurrency: Currency;
    defaultReceiptCurrency: Currency;
    editPurchasePrice: boolean;
    canChangeVat: boolean;
    defaultCurrency: Currency;
    defaultVat: number;
    defaultTenderNote: string;
    defaultReceiptPaymentTerms: string;
    lastEnteredMargin: number;
    lastEnteredRebate: number;
    isAccountingVat: string;
    hideGroups: boolean;
    hideSimilarProducts: boolean;
    defaultTenderLanguageId: string;
    showProductDimensionsInfo: boolean;
    enterFullPriceEnabled: boolean;
    units: { name: string }[]

    isFiscalizationModuleActive: boolean;
    isFiscalizationActive: boolean;
}

export interface ProductModel {
    id: number;
    name: string;
    salePrice?: number | null;
    fullPrice?: number;
    purchasePrice?: number;
    unit: string;
    code: string;
    vatRate?: number | null;
    taxFree: boolean;
    groupId?: number;
    imagePath: string;

    //form model
    priceDifference?: number;
}

export interface GroupModel {
    id: number;
    imagePath: string;
    groupId?: number | null;
    name: string;
}

export interface SalePlaceModel {
    id: number;
    externalId: string;
    externalSubId: string;
    addressStreet: string;
    addressNumber: string;
    addressZipCode: string;
    addressArea: string;
    addressCity: string;
    addressDescription: string;
    displayName: string;
}

export enum UpdateEInvoiceConfigurationResult {
    Success,
    WrongLoginInformation,
    CompanyMissingOib,
    CompanyMissingCountry
}