import React, { useEffect, useState } from 'react'

import { DocumentListContainer, DocumentGridModel } from '../../shared/documents/DocumentListContainer'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GridMenuActionLink, GridMenuCell, GridMenuLink } from '../../shared/grid/GridMenu';
import { useHistory } from 'react-router-dom';
import { ConcreteTenderApi } from '../../services/ConcreteTenderApi';
import { toastSuccess, toastWarning } from '../../shared/toastr';
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter';
import { DocumentDateFilters } from '../../shared/documents/DocumentDateFilters';
import Modal from '../../shared/modal';
import { CurrencyLabel } from '../../shared/ui/currency';
import { SendViaEmailModal } from '../../shared/documents/SendViaEmailModal';
import { CompanySettingsApi } from '../../services/CompanySettingsApi';
import { InvoiceApi } from '../../services/InvoiceApi';
import { useOfferionModal } from '../../shared/modal/ConfirmationModal';
import { GridActions } from '../../shared/grid/Types';
import { ChangeStatusModal } from '../../shared/documents/ChangeStatusModal';
import { TenderStatus } from '../../shared/models';
import { Field } from 'formik';
import { AggregatedResultsButton } from '../../shared/documents/details/elements/AggregatedResultsButton';
import { faEdit, faEnvelope, faFileMedical, faHistory, faMedkit, faPrint, faStream, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { openDocument } from '../../shared/utils/openDocument';
import { TenderLogModal } from './ConcreteTenderDetailsContainer';
import StatusIndicator from '../ui/StatusIndicator';

interface ConcreteTenderListConfig {
    pageTitleKey: string;
    gridTitleKey: string;
    buttons: React.ComponentType<{ canCreateTenders: boolean }>;
    hideFilters?: boolean;
    hidePaging?: boolean;
    hideHeader?: boolean;
    defaultPageSize?: number;
}

const EmailActionLink = ({ row, defaultMailText }: { row: { tenderNumber: string } & DocumentGridModel, defaultMailText: string }) => {
    const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false)

    const emailOffer = (info: { toEmail: string, toEmailsCc: string, emailBody: string, includeCopyToSelf: boolean }) => {
        return ConcreteTenderApi.sendTender(row.id, info.toEmail, info.toEmailsCc, info.emailBody, info.includeCopyToSelf).then(() => setSendEmailModalVisible(false));
    }

    return <>
        <GridMenuActionLink icon={faEnvelope} onClick={() => setSendEmailModalVisible(true)} translationKey="Invoice.Header.Menu.sendMail"></GridMenuActionLink>

        <Modal isOpen={sendEmailModalVisible}>
            <SendViaEmailModal
                pageTitleKey="Invoice.Send.pageTitleOffer"
                subTitleKey="Invoice.Send.offerAttached"
                defaultMailText={defaultMailText}
                clientEmail={row.clientEmail || ""}
                documentDetails={() => <span className="invoice-info">
                    <ul>
                        <li>{row.tenderNumber}</li>
                        <li>{row.clientName}</li>
                        <li><CurrencyLabel amount={row.sumOfItemsFullPrice} currency={row.currency} /></li>
                    </ul>
                </span>}
                close={() => setSendEmailModalVisible(false)} onSubmit={emailOffer} />
        </Modal>
    </>
}

const TenderStatusAction = ({ row }: { row: DocumentGridModel }) => {
    const [isLogModalVisible, setIsLogModalVisible] = useState(false);

    return <>
        <GridMenuActionLink icon={faHistory} onClick={() => setIsLogModalVisible(true)} translationKey="Invoice.Header.Menu.log"></GridMenuActionLink>
        <Modal isOpen={isLogModalVisible}>
            <TenderLogModal tenderId={row.id} close={() => setIsLogModalVisible(false)} />
        </Modal>
    </>
}
const ChangeStatusAction = ({ row, onSubmit, documentStatusModalVisible, setDocumentStatusModalVisible }: { documentStatusModalVisible: number|null, setDocumentStatusModalVisible: (v: number|null) => void, row: DocumentGridModel, onSubmit: (newStatus: number) => void }) => {
    return <>
        <GridMenuActionLink icon={faStream} onClick={() => setDocumentStatusModalVisible(row.id)} translationKey="Invoice.Side.changeStatus"></GridMenuActionLink>
        <Modal isOpen={documentStatusModalVisible === row.id}>
            <ChangeStatusModal
                mapping="TenderStatus"
                currentStatus={row.status}
                onSubmit={onSubmit}
                pageTitleKey="Invoice.ChangeStatus.pageTitle"
                close={() => setDocumentStatusModalVisible(null)}
                options={[
                    { value: 0, translationKey: "Allaround.Status.draft" },
                    { value: 1, translationKey: "Allaround.Status.offerSent" },
                    { value: 2, translationKey: "Allaround.Status.offerAccepted" },
                    { value: 3, translationKey: "Allaround.Status.offerDeclined" },
                ]} />
        </Modal>
    </>
}

export const ConcreteTenderList = (props: ConcreteTenderListConfig) => {

    const [defaultMailText, setDefaultMailText] = useState("");
    const [canCreateTenders, setCanCreateTenders] = useState(false);
    const [canCreateInvoices, setCanCreateInvoices] = useState(false);
    const history = useHistory();
    const { t } = useOfferionTranslation();
    const offerionModal = useOfferionModal();
    const [settingsLoaded, setSettingsLoaded] = useState(false)
    // can be null or document id
    const [documentStatusModalVisible, setDocumentStatusModalVisible] = useState<number|null>(null)

    useEffect(() => {
        CompanySettingsApi.getTenderListSettings().then(result => {
            setDefaultMailText(result.defaultMailText)
            setCanCreateTenders(result.canCreateTenders);
            setCanCreateInvoices(result.canCreateInvoices);
            setSettingsLoaded(true)
        });
    }, [])


    const deleteTender = (tenderId: number, offerNumber: string, gridActions: GridActions) => {

        offerionModal.open('Invoice.DeleteOffer.pageTitle', 'Invoice.DeleteOffer.questionYesNo', async () => {
            await ConcreteTenderApi.delete(tenderId)

            gridActions.refresh();

            setCanCreateTenders(true)

            toastSuccess('Invoice.DeleteOffer.Message.successDelete')
        }, { offerNumber })
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Offers.Table.Header.tenderNr',
        },
    ]

    const changeStatus = async (row: DocumentGridModel, newStatus: number, gridActions: GridActions) => {
        await ConcreteTenderApi.changeStatus(row.id, newStatus);
        setDocumentStatusModalVisible(null);
        gridActions.refresh();
    }

    const getDocument = (row: { tenderNumber: string } & DocumentGridModel) => {
        ConcreteTenderApi.getDocument(row.id).then((data: any) => {
            openDocument(`${t('Invoice.Print.offer')} ${row.tenderNumber}.pdf`, data);
        });
    }

    const createInvoice = (row: { tenderNumber: string } & DocumentGridModel) => {

        if (!canCreateInvoices) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            });

            toastWarning(message, true)

            return;
        }

        InvoiceApi.createFromTender(row.id).then(({ id }) => {
            history.push(`/invoice/${id}`);
        })
    }

    function documentStatusModalVisibleHandler(e: React.MouseEvent<HTMLButtonElement>, row: DocumentGridModel) {
        // if the click event propagates it'll open the document
        e.stopPropagation();
        setDocumentStatusModalVisible(row.id);
    }

    return <DocumentListContainer
        settingsLoaded={settingsLoaded}
        emptyMessageKey="Offers.Table.emptyMessage"
        emptySearchMessageKey="Offers.Table.emptySearchMessage"
        pageTitleKey={props.gridTitleKey}
        gridTitleKey={props.pageTitleKey}
        defaultPageSize={props.defaultPageSize}
        hidePaging={props.hidePaging}
        filtersComponent={props.hideFilters ? null : ({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
            return <GridFilterForm
                titleKey="Invoices.Side.searchTitle"
                searchButtonLabelKey="Invoices.Side.buttonSearch"
                onSubmit={(values) => {
                    const filters = [];

                    if (values.clientName)
                        filters.push({ property: "Client.Name", operator: "Contains", value: values.clientName })

                    if (values.number)
                        filters.push({ property: "Number", operator: "Contains", value: values.number });

                    if (values.day)
                        filters.push({ property: "OfficialDate.Day", operator: "Equal", value: values.day })

                    if (values.month)
                        filters.push({ property: "OfficialDate.Month", operator: "Equal", value: values.month })

                    if (values.year)
                        filters.push({ property: "OfficialDate.Year", operator: "Equal", value: values.year })

                    if (values.status != "")
                        filters.push({ property: "Status", operator: "Equal", value: values.status })

                    updateFilter(filters);
                }}
                initialValues={{ clientName: "", day: "", month: "", year: "", number: "", status: "" }}>

                <GridFilterInput name="clientName" placeholderKey="Invoices.Side.clientPlaceholder"></GridFilterInput>
                <GridFilterInput name="number" placeholderKey="Offers.Side.offerNumberPlaceholder"></GridFilterInput>
                <DocumentDateFilters />

                <div className="select-container search__item mod-margin-bottom-8">
                    <Field as="select" name="status" className="select">
                        <option value="">{t('Invoices.Side.statusAll')}</option>
                        <option value="0">{t('Allaround.Status.draft')}</option>
                        <option value="1">{t('Allaround.Status.offerSent')}</option>
                        <option value="2">{t('Allaround.Status.offerAccepted')}</option>
                        <option value="3">{t('Allaround.Status.offerDeclined')}</option>
                    </Field>
                </div>

            </GridFilterForm>
        }}
        hideHeader={props.hideHeader}
        buttonsComponent={React.createElement(props.buttons, { canCreateTenders: canCreateTenders })}
        columns={columns}
        firstColumnSelector={(row: { tenderNumber: string } & DocumentGridModel) => {
            return <span
                className="table__cell table__cell__title mod-cursor-pointer vertical-center" onClick={() => history.push(`/offer/${row.id}`)}>
                <span className="table__cell__status" >
                    <StatusIndicator
                        small
                        mapping="TenderStatus"
                        status={row.status}
                        onClick={e => documentStatusModalVisibleHandler(e, row)}
                    />
                </span>
                {row.tenderNumber}
            </span>
        }}
        menuCellComponent={({ row, gridActions }) => {
            return <GridMenuCell>
                <GridMenuLink icon={faEdit} to={`/offer/${row.id}`} translationKey="Offers.Table.ItemMenu.edit"></GridMenuLink>
                <GridMenuActionLink icon={faPrint} onClick={() => getDocument(row)} translationKey="Invoice.Header.Menu.print"></GridMenuActionLink>
                <EmailActionLink row={row} defaultMailText={defaultMailText} />
                <GridMenuActionLink icon={faFileMedical} onClick={() => createInvoice(row)} translationKey="Invoice.Side.createInvoice"></GridMenuActionLink>
                <ChangeStatusAction
                    documentStatusModalVisible={documentStatusModalVisible}
                    setDocumentStatusModalVisible={setDocumentStatusModalVisible}
                    row={row}
                    onSubmit={(newStatus) => changeStatus(row, newStatus, gridActions)}
                />
                <TenderStatusAction row={row} />
                <GridMenuActionLink icon={faTrashAlt} onClick={() => deleteTender(row.id, row.tenderNumber, gridActions)} translationKey="Offers.Table.ItemMenu.delete"></GridMenuActionLink>
            </GridMenuCell >
        }}
        orderByProperty="OfficialDate"
        projectionName="ConcreteTendersGridModel"
    />
}

export const ConcreteTenderListContainer = () => {
    const { t } = useOfferionTranslation();
    const history = useHistory();

    return (
        <>
            <BreadcrumbsItem to="/offers">
                {t("Offers.Header.pageTitle")}
            </BreadcrumbsItem>

            <ConcreteTenderList
                pageTitleKey="Offers.Table.Header.title"
                gridTitleKey="Offers.Header.pageTitle"
                buttons={({ canCreateTenders }) => {
                    return <>
                        <div className="button-container">
                            <button className="button button--white" onClick={() => {

                                if (canCreateTenders) {
                                    history.push("/offer/new")
                                }
                                else {
                                    const message = t('Allaround.Message.packageLimitReached', {
                                        offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
                                    });

                                    toastWarning(message, true)
                                }
                            }}>
                                <span className="icon icon__plus"></span>
                            </button>
                            <span className="button-text table__button-text mod-margin-left-4">{t('Offers.Header.newOffer')}</span>
                        </div>

                        <AggregatedResultsButton aggregatedResultsPromiseFactory={config => ConcreteTenderApi.getListAggregatedValues("ConcreteTendersGridModel", config.filter, config.customFilter)} />
                    </>
                }}
            />
        </>
    )
}
