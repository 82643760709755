import { ApiClient } from '../shared/api/api-client'
import { CompanyPrintSettingsModel } from '../shared/models';

export class CompanyPrintSettingsApi {

    static get() {
        return ApiClient.get<CompanyPrintSettingsModel>(`api/CompanyPrintSettings`);
    }

    static update(settings: CompanyPrintSettingsModel) {
        return ApiClient.post(`api/CompanyPrintSettings`, settings);
    }
}
