import { ApiClient } from '../shared/api/api-client'

export enum ChangeFiscalizationAvailabilityResult {
    NoCertificate,
    CertificateExpired,
    CertificateAndCompanyDoNotMatch,
    CompanyMissingOib,
    UserMissingOib,
    CompanyMissingCity,
    CompanyMissingStreet
}

export class FiscalizationApi {
    public static getSettings() {
        return ApiClient.get<{ isFiscalizationActive: boolean, currentCertificate: string, notAfter: string }>(`api/Fiscalization/GetFiscalizationSettings`);
    }

    public static updateFiscalizationSettings(isActive: boolean) {
        return ApiClient.post<ChangeFiscalizationAvailabilityResult[]>(`api/Fiscalization/UpdateFiscalizationSettings`, { isFiscalizationActive: isActive });
    }

    public static uploadCertificate(file: File, password: string) {
        const formData = new FormData();

        formData.append("file", file);
        formData.append("password", password)

        return ApiClient.post<{ certificateName: string, notAfter: string }>('api/Fiscalization/UploadCertificate', formData);
    }
}
