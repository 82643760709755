import { UserState } from '../../models'

export const UpdateAuthenticatedUser = (user: UserState) => {
    return {
        type: 'USER_AUTHENTICATED',
        user: user,
    }
}

export const UserSignOut = () => {
    return {
        type: 'USER_LOGGED_OUT',
    }
}

export const UpdateLanguage = (language: string) => {
    return {
        type: 'UPDATE_LANGUAGE',
        language: language
    }
}