import React, { useState } from 'react'
import { Field } from 'formik'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';
import { useRootStoreSelector } from '../store/hooks/useRootStoreSelector';

export const DocumentDateFilters = () => {
    const { t } = useOfferionTranslation();
    const [isOverlayActive, setIsOverlayActive] = useState(false)
    const companyStartYear = useRootStoreSelector(x => Number.parseInt(x.user.CompanyStartYear));
    const years = [companyStartYear];

    for (let i = 1; i <= new Date().getFullYear() - companyStartYear; i++) {
        years.push(companyStartYear + i)
    }

    return <div className={`search__options overlay__item overlay__item--bottom-left ${isOverlayActive ? 'overlay__item--active overlay__item--show-overlay' : ''}`}>
        <button onClick={() => setIsOverlayActive(val => !val)} type="button" className={`button button--gray overlay__button search__options-trigger ${isOverlayActive ? 'overlay__button--active' : ''}`}>
            <span className="icon icon__menu-blue"></span>
        </button>

        <div className="search__options__inner overlay">
            <div className="select-container search__item mod-margin-top-8">
                <Field as="select" name="day" className="select">

                    <option value="">{t('Invoices.Side.day')}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">14</option>
                    <option value="15">15</option>
                    <option value="16">16</option>
                    <option value="17">17</option>
                    <option value="18">18</option>
                    <option value="19">19</option>
                    <option value="20">20</option>
                    <option value="21">21</option>
                    <option value="22">22</option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                </Field>

            </div>

            <div className="select-container search__item mod-margin-top-8">
                <Field as="select" name="month" className="select">
                    <option value="">{t('Invoices.Side.month')}</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                </Field>
            </div>

            <div className="select-container search__item mod-margin-top-8 mod-margin-bottom-8">
                <Field as="select" name="year" className="select">
                    <option value="">{t('Invoices.Side.year')}</option>
                    {years.map(y => <option value={y}>{y}</option>)}
                </Field>
            </div>
        </div>
    </div>

}