import React from "react";
import { Route, Redirect, RouteProps } from "react-router";
import { useRootStoreSelector } from "../store/hooks/useRootStoreSelector";

type Props = RouteProps & { requiredRole?: string }

export const AuthenticatedRoute = ({ component, requiredRole, ...rest }: Props) => {
  const user = useRootStoreSelector(state => state.user);
  const ComponentToRender: any = component;

  if (requiredRole && !user.Role.includes(requiredRole))
    return <Redirect to="/" />

  return (
    <Route
      {...rest}
      render={props =>
        user.authenticated ? (
          <ComponentToRender {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};
