import React from "react";
import { Formik, Form, Field } from "formik";
import { Link, useHistory, Redirect } from "react-router-dom";
import { LoginApi } from "./LoginApi";
import * as Yup from "yup";
import { OfferionErrorMessage } from "../../shared/forms/validation/OfferionErrorMessage";
import { useDispatch } from "react-redux";
import { UpdateAuthenticatedUser } from "../../shared/store/actions/userActions";
import { useRootStoreSelector } from "../../shared/store/hooks/useRootStoreSelector";
import { toastError, toastWarning } from "../../shared/toastr";
import { Spinner } from "../../services/Spinner";
import { useOfferionTranslation } from "../../shared/store/hooks/useOfferionTranslation";
import { isTrialPeriod } from "../../shared/utils/isTrialPeriod";
import { enableUserbackReplay, setUserbackUser } from "../../shared/utils/userback";

export const LoginContainer = () => {
  const { t } = useOfferionTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useRootStoreSelector(state => state.user);

  if (user.authenticated) return <Redirect to="dashboard" />;

  const onSubmit = async (values: { email: string; password: string }) => {

    Spinner.show()

    try {
      const result = await LoginApi.login(values.email, values.password);

      setUserbackUser(result);
      if (isTrialPeriod(result)) {
        enableUserbackReplay();
      }

      if (result.ShowInvoiceLimitAboutToBeReached == "True" && result.ShowTenderLimitAboutToBeReached == "True") {
        const invoiceCount = Number.parseInt(result.NumberOfInvoicesInPackage) - Number.parseInt(result.NumberOfInvoicesCreated);
        const offerCount = Number.parseInt(result.NumberOfTendersInPackage) - Number.parseInt(result.NumberOfTendersCreated);

        const message = t('Allaround.Message.packageThresholdReachedTwofold', {
          numOffersLeft: offerCount,
          offerSingular: offerCount == 1 ? t('Allaround.Message.offerAkuzativ') : t('Allaround.Message.offerPlural2'),
          numInvoicesLeft: invoiceCount,
          invoiceSingular: invoiceCount == 1 ? t('Allaround.Message.invoiceAkuzativ') : t('Allaround.Message.invoicePlural'),

          numOffers: result.NumberOfTendersCreated,
          numOffersInPackage: result.NumberOfTendersInPackage,
          offerPlural: Number.parseInt(result.NumberOfTendersCreated) == 1 ? t('Allaround.Message.offerAkuzativ') : t('Allaround.Message.offerPlural2'),

          numInvoces: result.NumberOfInvoicesCreated,
          numInvoicesInPackage: result.NumberOfInvoicesInPackage,
          invoicePlural: Number.parseInt(result.NumberOfInvoicesCreated) == 1 ? t('Allaround.Message.invoiceAkuzativ') : t('Allaround.Message.invoicePlural')
        });

        toastWarning(message, true, false)

      }
      else if (result.ShowInvoiceLimitAboutToBeReached == "True") {
        const count = Number.parseInt(result.NumberOfInvoicesInPackage) - Number.parseInt(result.NumberOfInvoicesCreated);
        const message = t('Allaround.Message.packageThresholdReached', {
          numOffersOrInvoicesLeft: count,
          offerOrInvoiceSingular: count == 1 ? t('Allaround.Message.invoiceAkuzativ') : t('Allaround.Message.invoicePlural'),
          numOffersOrInvoices: result.NumberOfInvoicesCreated,
          numOffersOrInvoicesInPackage: result.NumberOfInvoicesInPackage
        });

        toastWarning(message, true, false)
      }
      else if (result.ShowTenderLimitAboutToBeReached == "True") {
        const count = Number.parseInt(result.NumberOfTendersInPackage) - Number.parseInt(result.NumberOfTendersCreated);
        const message = t('Allaround.Message.packageThresholdReached', {
          numOffersOrInvoicesLeft: count,
          offerOrInvoiceSingular: count == 1 ? t('Allaround.Message.offerAkuzativ') : t('Allaround.Message.offerPlural1'),
          numOffersOrInvoices: result.NumberOfTendersCreated,
          numOffersOrInvoicesInPackage: result.NumberOfTendersInPackage
        });

        toastWarning(message, true, false)
      }

      if (result.ShowInvoiceLimitReached == "True" && result.ShowTenderLimitReached == "True") {
        const message = t('Allaround.Message.packageLimitReached', {
          offersOrInvoicesGenitiv: t('Allaround.Message.offersAndInvoicesGenitiv'),
        });

        toastWarning(message, true, false)
      }
      else if (result.ShowInvoiceLimitReached == "True") {
        const message = t('Allaround.Message.packageLimitReached', {
          offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
        });

        toastWarning(message, true, false)
      }
      else if (result.ShowTenderLimitReached == "True") {
        const message = t('Allaround.Message.packageLimitReached', {
          offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
        });

        toastWarning(message, true, false)
      }

      if (result.ShowPackageAboutToExpireWarning == "True") {
        const count = Number.parseInt(result.NumberOfDaysTillPackageExpiry)

        const message = t('Allaround.Message.subscriptionExpiresSoon', {
          numDays: count,
          days: count == 1 ? t('Allaround.Message.day') : t('Allaround.Message.dayPlural')
        });

        toastWarning(message, true, false)
      }
      else if (result.ShowPackageExpiredWarning == "True") {
        const message = t('Allaround.Message.subscriptionExpired', {
          packageExpirationDate: result.PackageExpirationDate
        });

        toastWarning(message, true, false)
      }

      if (result.AnyInvoiceNotFiscalized == "True") {
        const message = t('Allaround.Message.invoicesNotFiscalized', {
          link: "/invoices?custom-filter=OnlyNotFiscalized"
        });

        toastWarning(message, true, false)
      }

      dispatch(UpdateAuthenticatedUser({ ...result, authenticated: true }));

      history.push("/dashboard");
    }
    catch (error) {
      if (error && error.response && error.response.data && error.response.data.error == 'invalid_grant')
        toastError("Login.popupErrorWrongUser");
      else
        toastError('Allaround.Message.unknownError');
    }
    finally {
      Spinner.hide()
    }

  };

  const schema = Yup.object().shape({
    email: Yup.string()
      .required(t("Allaround.Validation.required", { fieldName: t("Login.email") })),
    password: Yup.string()
      .required(t("Allaround.Validation.required", { fieldName: t("Login.password") }))
      .min(6, t("Allaround.Validation.minLength", { fieldName: t("Login.password"), minLength: 6 })),
  });

  return (
    <main className="login__container">
      <h1 className="login__title">{t("Login.pageTitle")}</h1>
      <Formik
        validationSchema={schema}
        initialValues={{ email: "", password: "" }}
        onSubmit={onSubmit}
      >
        {formikProps => {
          return (
            <Form noValidate={true} autoComplete="false">
              <div className="login__form">
                <div className="login__form-group">
                  <Field
                    // ng-className="(loginForm.userEmail.$invalid && loginForm.userEmail.$touched)? 'login__input error' : 'login__input'"

                    id="email"
                    type="email"
                    placeholder={t("Login.email")}
                    name="email"
                  // ng-className="{'error-message': loginForm.userEmail.$invalid && !loginForm.userEmail.$pristine}"
                  />
                  <OfferionErrorMessage name="email" />
                </div>

                <div className="login__form-group">
                  <Field
                    // ng-className="(loginForm.userPassword.$invalid && loginForm.userPassword.$touched)? 'login__input error' : 'login__input'"

                    id="password"
                    type="password"
                    placeholder={t("Login.password")}
                    name="password"
                  />
                  <OfferionErrorMessage name="password" />
                </div>

                <button type="submit" className="login__button button--blue button--padded">
                  {t("Login.loginButton")}
                </button>
                <Link to={"/forgot-password"} className="login__link">
                  {t("Login.forgotPassword")}
                </Link>
              </div>
            </Form>
          );
        }}
      </Formik>
    </main>
  );
};
