import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AccountApi } from '../../services/AccountApi';
import { toastSuccess } from '../../shared/toastr';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export const ResetPasswordContainer = () => {

    const { t } = useOfferionTranslation();
    const location = useLocation();
    const history = useHistory();

    const schema = Yup.object().shape({
        password: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.accountdataPassword') }))
            .min(6, t("Allaround.Validation.minLength", { fieldName: t("Registration.accountdataPassword"), minLength: 6 })),
        password2: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.accountdataPassword') }))
            .min(6, t("Allaround.Validation.minLength", { fieldName: t("Registration.accountdataPassword"), minLength: 6 }))
            .oneOf([Yup.ref('password')], t('Allaround.Validation.passwordsDontMatch'))

    });

    const onSubmit = async ({ password }: { password: string }) => {

        const queryParams = new URLSearchParams(location.search);

        const token = queryParams.get("token");

        const userId = queryParams.get("userId");

        if (token == null || userId == null) {
            console.error("Missing query params");
        
            return;
        }

        await AccountApi.resetPassword(token, userId, password);

        toastSuccess('ResetPassword.showSuccessMessage');

        history.push("/");
    }

    return <main className="login__container">
        <h1 className="login__title">{t('ResetPassword.title')}</h1>
        <Formik initialValues={{ password: "", password2: "" }} onSubmit={onSubmit} validationSchema={schema}>
            <Form>
                <div className="login__form">
                    <div>
                        <div className="info__row-content">
                            <Field className="login__input" id="password" type="password" placeholder={t('ResetPassword.passwordPlaceholder')} name="password" />
                            <OfferionErrorMessage name="password" />
                        </div>
                    </div>

                    <div className="mod-margin-top-8">
                        <div className="info__row-content">
                            <Field className="login__input" id="password2" type="password" placeholder={t("ResetPassword.passwordRepeatPlaceholder")} name="password2" is-equal="vm.data.password" />
                            <OfferionErrorMessage name="password2" />
                        </div>
                    </div>

                    <br />
                    <button type="submit" className="login__button button--blue button--padded">{t('ResetPassword.buttonText')}</button>
                </div>
            </Form>
        </Formik>
    </main>
}