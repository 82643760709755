import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { UpdateAuthenticatedUser } from '../../shared/store/actions/userActions'
import axios from 'axios'
import { UserState } from '../../shared/models'

interface Response {
    companyType: string
    userId: string
    role: string
    firstName: string
    lastName: string
    email: string
}

export const ImpersonationContainer = () => {
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const api = axios.create({
        baseURL: process.env.REACT_APP_SERVER_LOCATION,
    })

    useEffect(() => {
        const encryptedToken = new URLSearchParams(location.search).get('encryptedToken')
        api.post<{ accessToken: string }>(
            '/api/impersonation/GenerateAccessToken',
            {
                encryptedToken: decodeURIComponent(encryptedToken!),
            },
            {
                headers: {
                    'Accept-Language': 'hr-0',
                },
            }
        ).then(tokenResult => {
            const access_token = tokenResult.data.accessToken

            api.get<Response>('/api/users/GetCurrentUserDetails', {
                headers: {
                    Authorization: `Bearer ${access_token}`,
                    'Accept-Language': 'hr-0',
                },
            }).then(result => {
                const user = result.data

                const newUser: UserState = {
                    access_token: access_token,
                    CompanyType: user.companyType,
                    CompanyStartYear: '2020',
                    Email: user.email,
                    FirstName: user.firstName,
                    LastName: user.lastName,
                    Role: user.role,
                    UserId: user.userId,
                    authenticated: true,
                    UiLanguage: 'hr',
                }

                dispatch(UpdateAuthenticatedUser(newUser))

                history.push('/')
            })
        })
    }, [])

    return null
}
