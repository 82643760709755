import React from 'react'
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation'
import { Currency, VersionInformation } from '../../../../shared/models'
import { CurrencyName, CurrencyOptions } from '../../../../shared/ui/currency'
import { Formik, Form, Field, validateYupSchema } from 'formik'
import { ExchangeRatesModel } from '../../../../services/ExchangeRatesApi'
import { Overlay } from '../../../elements/Overlay'
import { OfferionTooltip } from '../../../tooltip'
import { NumberField } from '../../../forms/NumberField'
import { toastSuccess } from '../../../toastr'

interface Props {
    closeForm: () => void
    onSubmit: (values: {
        newDocumentCurrencyRate: number
        newClientCurrencyRate: number
        documentCurrency: Currency
        itemsConversionRate: number
        clientCurrency: Currency
        cultureName: string | null
        shouldUpdateVersionInformation: boolean
    }) => Promise<void>
    currentDocumentCurrencyRate: number
    currentClientCurrencyRate: number
    documentCurrencyTitleKey: string
    documentCurrency: Currency
    clientCurrency: Currency
    exchangeRates: ExchangeRatesModel
    cultureName: string | null
    titleKey: string
    versionInformation: VersionInformation
    setRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    setArrowRef?: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
    styles: any
    attributes: any
}

export const CustomRateForm = (props: Props) => {
    let updateVersionInformationLabel: string
    const { t } = useOfferionTranslation()

    switch (props.versionInformation) {
        case VersionInformation.CannotBeChanged:
            updateVersionInformationLabel = t('Invoice.Settings.updateDocumentNotActiveDisallowed')
            break

        case VersionInformation.NotLatest:
            updateVersionInformationLabel = t('Invoice.Settings.updateDocumentActive')
            break

        case VersionInformation.Latest:
            updateVersionInformationLabel = t('Invoice.Settings.updateDocumentNotActive')
            break
    }

    const isFormDebug = (window as any).DEBUG_FORM

    return (
        <Formik
            initialValues={{
                documentCurrency: props.documentCurrency,
                clientCurrency: props.clientCurrency,
                documentCurrencyRate: props.currentDocumentCurrencyRate,
                clientCurrencyRate: props.currentClientCurrencyRate,
                cultureName: props.cultureName,
                customConversionRate: props.currentClientCurrencyRate * props.currentDocumentCurrencyRate / props.exchangeRates[props.documentCurrency],
                shouldUpdateVersionInformation: false,
            }}
            onSubmit={({
                clientCurrencyRate,
                documentCurrencyRate,
                clientCurrency,
                documentCurrency,
                cultureName,
                customConversionRate,
                shouldUpdateVersionInformation,
            }) => {
                props
                    .onSubmit({
                        newDocumentCurrencyRate: documentCurrencyRate,
                        newClientCurrencyRate: clientCurrencyRate,
                        itemsConversionRate: props.documentCurrency == documentCurrency ? 1 : documentCurrencyRate / customConversionRate,
                        documentCurrency,
                        clientCurrency,
                        cultureName,
                        shouldUpdateVersionInformation,
                    })
                    .then(() => {
                        if (shouldUpdateVersionInformation) {
                            toastSuccess('Invoice.Settings.Message.successUpdateWithData', { pageTitle: t(props.titleKey) })
                        } else {
                            toastSuccess('Invoice.Settings.Message.successUpdate', { pageTitle: t(props.titleKey) })
                        }
                    })
            }}>
            {formikProps => {
                const shouldLock =
                    (formikProps.values.clientCurrency == Currency.EUR && formikProps.values.documentCurrency == Currency.HRK) ||
                    (formikProps.values.clientCurrency == Currency.HRK && formikProps.values.documentCurrency == Currency.EUR)

                return (
                    <Overlay setRef={props.setRef} setArrowRef={props.setArrowRef} styles={props.styles} attributes={props.attributes}>
                        <Form>
                            <h1 className="overlay__title">{t(props.titleKey)}</h1>
                            <button type="button" className="button overlay__close" onClick={props.closeForm}>
                                <span className="icono-cross"></span>
                            </button>

                            <div className="info__container">
                                <div className="info__row">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label className="overlay__title form-label">{t('Invoice.Settings.currencyTitle')}</label>
                                    </div>
                                </div>

                                <div style={{ padding: '0px 20px' }}>
                                    <div className="info__row">
                                        <label className="overlay__title form-label mod-font-color-regular">
                                            {t(props.documentCurrencyTitleKey)}
                                        </label>

                                        <div className="input-select">
                                            {shouldLock && !isFormDebug ? null : (
                                                <Field
                                                    autoFocus
                                                    component={NumberField}
                                                    ignorePrecision={true}
                                                    name="documentCurrencyRate"
                                                    className="form-control mr-3"
                                                />
                                            )}
                                            <div className="info__select-container select-container">
                                                <Field
                                                    autoFocus={shouldLock && !isFormDebug}
                                                    as="select"
                                                    name="documentCurrency"
                                                    onChange={(e: any) => {
                                                        const otherValue = formikProps.values.clientCurrency
                                                        const thisSelectValue = Number.parseInt(e.target.value)

                                                        formikProps.setFieldValue('documentCurrency', thisSelectValue)

                                                        formikProps.setFieldValue('documentCurrencyRate', props.exchangeRates[otherValue])
                                                        formikProps.setFieldValue(
                                                            'clientCurrencyRate',
                                                            props.exchangeRates[thisSelectValue]
                                                        )

                                                        formikProps.setFieldValue(
                                                            'customConversionRate',

                                                            (props.exchangeRates[otherValue] *
                                                                props.exchangeRates[thisSelectValue] /
                                                                props.exchangeRates[props.documentCurrency]
                                                        ))
                                                    }}>
                                                    <CurrencyOptions />
                                                </Field>
                                            </div>
                                        </div>

                                        {((props.documentCurrency != formikProps.values.documentCurrency && !shouldLock) ||
                                            isFormDebug) && (
                                            <>
                                                <label className="overlay__title form-label mod-font-color-regular">
                                                    {t('Invoice.Settings.recalculateItems')}
                                                </label>

                                                <div className="input-select">
                                                    <Field
                                                        component={NumberField}
                                                        disabled={shouldLock}
                                                        ignorePrecision={true}
                                                        name="customConversionRate"
                                                        className="form-control mr-3"
                                                    />

                                                    <div
                                                        className="info__row-content"
                                                        style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                                        <CurrencyName currency={props.documentCurrency} />
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>

                                    <div className="info__row">
                                        <label className="overlay__title form-label mod-font-color-regular">
                                            {t('Invoice.Settings.clientCurrency')}
                                        </label>
                                        <div className="input-select">
                                            {(props.documentCurrency == formikProps.values.clientCurrency ||
                                                formikProps.values.clientCurrency == formikProps.values.documentCurrency ||
                                                shouldLock) &&
                                            !isFormDebug ? null : (
                                                <Field
                                                    component={NumberField}
                                                    ignorePrecision={true}
                                                    name="clientCurrencyRate"
                                                    className="form-control mr-3"
                                                />
                                            )}
                                            <div className="info__select-container select-container">
                                                <Field
                                                    as="select"
                                                    name="clientCurrency"
                                                    onChange={(e: any) => {
                                                        const otherValue = formikProps.values.documentCurrency
                                                        const thisSelectValue = Number.parseInt(e.target.value)

                                                        formikProps.setFieldValue('clientCurrency', thisSelectValue)
                                                        formikProps.setFieldValue(
                                                            'documentCurrencyRate',
                                                            props.exchangeRates[thisSelectValue]
                                                        )
                                                        formikProps.setFieldValue('clientCurrencyRate', props.exchangeRates[otherValue])
                                                        

                                                        formikProps.setFieldValue(
                                                            'customConversionRate',

                                                            (props.exchangeRates[otherValue] *
                                                                props.exchangeRates[thisSelectValue] /
                                                                props.exchangeRates[props.documentCurrency]
                                                        ))
                                                    }}>
                                                    <CurrencyOptions />
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    {shouldLock ? <div className="info__row">1 EUR = 7,53450 kn (fiksni tečaj)</div> : null}
                                </div>

                                <div className="info__row">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label className="overlay__title form-label">{t('Invoice.Settings.languageTitle')}</label>
                                    </div>
                                </div>

                                <div style={{ padding: '0px 20px' }}>
                                    <div className="info__row">
                                        <label className="info__row-name" style={{ width: '40%' }}>
                                            {t('Invoice.Settings.documentLanguage')}
                                        </label>
                                        <div className="info__row-content">
                                            <div className="info__select-container select-container">
                                                <Field as="select" name="cultureName">
                                                    <option value="">{t('Allaround.Language.InvoiceOrOffer.printSettings')}</option>
                                                    <option value="cs-cz">{t('Allaround.Language.InvoiceOrOffer.cs-cz')}</option>
                                                    <option value="de-de">{t('Allaround.Language.InvoiceOrOffer.de-de')}</option>
                                                    <option value="en-gb">{t('Allaround.Language.InvoiceOrOffer.en-gb')}</option>
                                                    <option value="es-es">{t('Allaround.Language.InvoiceOrOffer.es-es')}</option>
                                                    <option value="fr-fr">{t('Allaround.Language.InvoiceOrOffer.fr-fr')}</option>
                                                    <option value="hr-hr">{t('Allaround.Language.InvoiceOrOffer.hr-hr')}</option>
                                                    <option value="hu-hu">{t('Allaround.Language.InvoiceOrOffer.hu-hu')}</option>
                                                    <option value="it-it">{t('Allaround.Language.InvoiceOrOffer.it-it')}</option>
                                                    <option value="pl-pl">{t('Allaround.Language.InvoiceOrOffer.pl-pl')}</option>
                                                    <option value="sr-Latn">{t('Allaround.Language.InvoiceOrOffer.sr-Latn')}</option>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="info__row">
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <label className="overlay__title form-label">
                                            {t('Invoice.Settings.updatingDocument')}
                                            <OfferionTooltip
                                                placement="right"
                                                titleKey="Invoice.Settings.UpdatingDocument.infoTitle"
                                                bodyKey="Invoice.Settings.UpdatingDocument.infoText"
                                            />
                                        </label>
                                    </div>
                                </div>

                                <div style={{ padding: '0px 20px' }}>
                                    <div className="info__row">
                                        <>
                                            <label
                                                className="info__row__inner-half"
                                                style={{ width: '90%' }}
                                                htmlFor="shouldUpdateVersionInformation">
                                                {updateVersionInformationLabel}
                                            </label>
                                            {props.versionInformation == VersionInformation.NotLatest && (
                                                <div className="info__row__inner-half" style={{ width: '10%' }}>
                                                    <input
                                                        className="checkbox"
                                                        type="checkbox"
                                                        id="shouldUpdateVersionInformation"
                                                        name="shouldUpdateVersionInformation"
                                                        checked={formikProps.values.shouldUpdateVersionInformation}
                                                        onChange={e => {
                                                            formikProps.setFieldValue('shouldUpdateVersionInformation', e.target.checked)
                                                        }}
                                                    />
                                                    <label
                                                        htmlFor="shouldUpdateVersionInformation"
                                                        className="button button--gray checkbox-button right">
                                                        <span className="checkbox-button__icon"></span>
                                                    </label>
                                                </div>
                                            )}
                                        </>
                                    </div>
                                </div>
                            </div>

                            <div className="button-group bottom-controls">
                                <button className="button button--gray button--padded" type="submit">
                                    <span className="icon icon__check-green"></span>
                                    <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                                </button>
                                <button type="button" className="button button--gray button--padded" onClick={props.closeForm}>
                                    <span className="icon icon--small icon__cross-red"></span>
                                    <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                                </button>
                            </div>
                        </Form>
                    </Overlay>
                )
            }}
        </Formik>
    )
}
