import { ApiClient } from '../shared/api/api-client'
import { GridData } from '../shared/grid/Types'

export interface PriceListItem {
    groupId?: number;
    productId?: number;
    parentGroupName: string;
    parentGroupNameChain: string | null;
    name: string;
    price?: number;
    numberOfElements?: number;
    width?: number;
    height?: number;
    depth?: number;
    unit: string | null;
    hasImage: boolean;
}

export class PriceListApi {

    public static getPriceList(page: number, takeCount: number, groupId: null | number, searchTerm: null | string) {
        return ApiClient.post<GridData<PriceListItem>>('api/PriceList/GetPriceList', {
            page, takeCount, groupId, searchTerm
        })
    }

    public static importPriceList(formData: FormData) {
        return ApiClient.post('api/PriceList/ImportPriceList', formData);
    }

    public static exportPriceList() {
        return ApiClient.get('api/PriceList/ExportPriceList');
    }

    public static deletePriceList() {
        return ApiClient.delete('api/PriceList/DeletePriceList');
    }
}
