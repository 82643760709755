import { ApiClient } from '../shared/api/api-client'
import { SalePlaceModel } from '../shared/models'

export class SalePlacesApi {
    public static delete(salePlaceId: number) {
        return ApiClient.delete<boolean>(`api/CompanySalePlaces/Delete/${salePlaceId}`);
    }

    public static get(salePlaceId: number) {
        return ApiClient.get<SalePlaceModel>(`api/CompanySalePlaces/Get/${salePlaceId}`);
    }

    public static upsert(salePlace: SalePlaceModel) {
        return ApiClient.post<{ id: number, salePlaceCreated: boolean, canCreateNextSalePlace: boolean }>(`api/CompanySalePlaces/Upsert`, salePlace);
    }

    public static patchField(clientId: number, field: string, value: string) {
        return ApiClient.post('api/CompanySalePlaces/patchfield', {
            id: clientId,
            field,
            value
        })
    }
}