/**
 * Quick actions are a part of side menu.
 *
 * Since they're used under <div className="search__content">...</div>,
 * we'll reuse the relevant classes even if the naming seems a bit off.
 *
 *
 * Example usage:
 *
 * const QuickActionsComponent = ({ document }: { document: ConcreteTenderDocumentModel }) => {
 *      return (
 *          <>
 *              <QuickActionTitle text={t('Invoice.Side.actionsTitle')} />
 *              <QuickActionItem onClick={() => getDocument(document)} icon={faPrint} text={t('Invoice.Side.print')} />
 *              <QuickActionItem onClick={() => setSendEmailModalVisible(true)} icon={faEnvelope} text={t('Invoice.Side.sendMail')} />
 *              <QuickActionItem icon={faFileMedical} onClick={() => createInvoice(document)} text={t('Invoice.Side.createInvoice')} />
 *          </>
 *      )
 *  }
 */
import React from 'react'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function QuickActionTitle({ text, id }: { text: React.ReactNode, id?: string }) {
    return <h2 id={id} className="search__title" style={{ marginTop: 60 }}>{text}</h2>
}

export function QuickActionSubtitle({ text }: { text: React.ReactNode }) {
    return (
        <p className="search-form__note">
            {text}
            <span className="icon icon__menu-blue"></span>
        </p>
    )
}

export function QuickActionItem({ icon, onClick, text, id }: { onClick: () => void; text: string; icon: IconProp, id?: string }) {
    return (
        <div>
            <button id={id} className="search-form__button search-form__button--full-width button button--gray" onClick={onClick}>
                <div style={{ display: 'inline-block', marginTop: '2px' }}>
                    {icon && <FontAwesomeIcon icon={icon} size="lg" style={{ width: '24px' }} />}
                </div>
                <span className="button-text">{text}</span>
            </button>
        </div>
    )
}
