import React, { ReactNode, ComponentType, useMemo } from 'react'
import { CurrencyLabel } from '../ui/currency'
import { Grid } from '../grid/Grid'
import { ListingApi, SortDirection } from '../../services/ListingApi'
import { date } from '../utils/formatters'
import { GridActions, TableParams, TableSchema } from '../grid/Types'
import { PaymentMethod } from '../models'
import { GridContextProvider, useGridActions } from '../grid/GridContextProvider'
import { MobileSidebarOpener } from '../elements/MobileSidebarOpener'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'

export interface DocumentGridModel {
    id: number;
    officialDate: Date
    issuedBy: string
    sumOfItemsFullPrice: number
    currency: number
    clientName: string
    isCancelled: boolean;
    cancelledFromReceiptId: number | null;
    isFiscalized: boolean;
    paymentMethod: PaymentMethod;
    clientEmail: string;
    isClientDataConfigured: boolean;
    status: number;
    paymentDate?: Date;
}

export const DocumentListContainer = <TDcumentGridModel extends DocumentGridModel>({
    settingsLoaded,
    buttonsComponent,
    menuCellComponent,
    projectionName,
    orderByProperty,
    pageTitleKey,
    gridTitleKey,
    firstColumnSelector,
    filtersComponent,
    hidePaging,
    hideHeader,
    defaultPageSize,
    emptyMessageKey,
    emptySearchMessageKey,
    hideOfficialDate,
    columns,
    sortDirection,
    defaultCustomFilter,
    customRowClass,
    showMultiLineRowContent,
}: {
    buttonsComponent: JSX.Element,
    menuCellComponent: ComponentType<{ row: TDcumentGridModel, gridActions: GridActions }>,
    projectionName: string,
    orderByProperty?: string,
    sortDirection?: SortDirection,
    pageTitleKey: string,
    gridTitleKey: string,
    firstColumnSelector: (row: TDcumentGridModel) => ReactNode,
    filtersComponent?: ComponentType<{ currentCustomFilter?: string | null, updateFilter: (filter: any[], customFilter?: string) => void }> | null,
    hidePaging?: boolean,
    hideHeader?: boolean,
    defaultPageSize?: number,
    emptyMessageKey: string,
    emptySearchMessageKey: string,
    hideOfficialDate?: boolean,
    columns: { labelKey?: string, key?: string, cssClass?: string | undefined, hidden?: boolean }[],
    defaultCustomFilter?: string | null,
    customRowClass?: (row: TDcumentGridModel) => string,
    settingsLoaded: boolean,
    showMultiLineRowContent?: boolean,
}) => {
    const { t } = useOfferionTranslation()

    const MenuCellComponent = menuCellComponent

    const RowComponent = ({ row, gridActions }: { row: TDcumentGridModel, gridActions: GridActions }) => {
        return <>
            {firstColumnSelector(row)}

            {hideOfficialDate ? null : <span className="table__cell table__cell__date table__cell--prefixed" data-prefix={t("Invoices.Table.Header.date")}>{date(row.officialDate)}</span>}

            <span className="table__cell table__cell__client table__cell--prefixed" data-prefix={t('Invoices.Table.Header.client')}>{row.clientName}&nbsp;</span>

            <CurrencyLabel
                data-prefix={t('Invoices.Table.Header.amount')}
                style={{ width: '250px' }}
                className="table__cell table__cell__price table__cell--prefixed"
                amount={row.sumOfItemsFullPrice}
                currency={row.currency}
            />

            <MenuCellComponent row={row} gridActions={gridActions} />
        </>
    }

    const tableSchema: TableSchema<TDcumentGridModel> = {
        columns: columns ? [...columns] : []
    };

    tableSchema.columns.push(
        {
            key: 'OfficialDate',
            labelKey: 'Invoices.Table.Header.date',
            hidden: hideOfficialDate
        },
        {
            key: 'ClientName',
            labelKey: 'Invoices.Table.Header.client',
        },
        {
            key: 'SumOfItemsFullPrice',
            labelKey: 'Invoices.Table.Header.amount',
        },
        {
            cssClass: 'table__header__item__menu ',
        }
    )

    const rowsPromiseFactory = useMemo(() => {
        return (config: TableParams) => ListingApi.getList<TDcumentGridModel>(projectionName, config.pageNumber, config.pageSize, config.filter, config.customFilter, orderByProperty, sortDirection)
    }, [projectionName])

    return (
        <GridContextProvider>
            <main className="main">
                {hideHeader ? null : <div className="main__header">
                    <div className="main-title">{t(pageTitleKey)}</div>

                    <div className="main__header-buttons">
                        {buttonsComponent}

                        {filtersComponent && <MobileSidebarOpener iconClass="icon__search" />}

                    </div>
                </div>}

                {settingsLoaded && <Grid
                    defaultCustomFilter={defaultCustomFilter}
                    emptyMessageKey={emptyMessageKey}
                    emptySearchMessageKey={emptySearchMessageKey}
                    translationKey={gridTitleKey}
                    sidebarComponent={filtersComponent}
                    defaultPageSize={defaultPageSize}
                    hidePaging={hidePaging}
                    rowsPromiseFactory={rowsPromiseFactory}
                    rowComponent={RowComponent}
                    customRowClass={customRowClass}
                    tableSchema={tableSchema}
                    showMultiLineRowContent={showMultiLineRowContent}
                    />}

            </main>
        </GridContextProvider>
    )
}
