import { ApiClient } from '../shared/api/api-client'

export class ItemApi {
    public static linkPredefinedImage(itemId: number, predefinedImageId: number) {
        return ApiClient.post(`api/item/linkPredefinedImage/${itemId}/${predefinedImageId}`);
    }

    public static linkCustomImage(itemId: number, customImageResourceId: number) {
        return ApiClient.post(`api/item/linkCustomImage/${itemId}/${customImageResourceId}`);
    }
}
