import { ApiClient } from '../shared/api/api-client'
import { ClientModel } from '../shared/models'

export class ClientApi {
    public static patchField(clientId: number, field: string, value: string) {
        return ApiClient.post('api/client/patchfield', {
            id: clientId,
            field,
            value
        })
    }

    public static upsertClient(client: ClientModel) {
        return ApiClient.post('api/client/upsert', client);
    }

    public static get(clientId: number | string) {
        return ApiClient.get<ClientModel>(`api/client/get/${clientId}`);
    }

    public static delete(clientId: number) {
        return ApiClient.delete(`api/client/delete/${clientId}`);
    }
}
