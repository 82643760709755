import { string } from 'yup'
import { EInvoiceModalType } from '../components/invoice/EInvoiceModal'
import { ApiClient } from '../shared/api/api-client'
import { PopularProductModel, Currency, InvoiceDocumentModel, ReceiptStatus, ReceiptStatusHistory } from '../shared/models'

export interface InvoiceConfigurationModel {
    canChangeVat: boolean
    defaultVat: number
    isAccountingVat: boolean
    isFiscalizationActive: boolean

    showProductDimensionsInfo: boolean
    enterFullPriceEnabled: boolean
    nextReceiptNumber: string

    newTemplateTenderNote: string
    newReceiptNote: string
    newReceiptCurrency: Currency
    newReceiptClientCurrency: Currency
    newReceiptPaymentMethod: number
    documentCurrencyRate: number
    clientCurrencyRate: number

    defaultMailText: string

    editPurchasePrice: boolean
    isCompanyMissingEInvoiceData: boolean
    isEInvoiceConfigured: boolean

    canCreateInvoices: boolean
    canCreateTenders: boolean

    isCroatia: boolean

    showVatAccountingWarning: boolean
    showPriceCalculationWarning: boolean

    isFreeCompany: boolean
}

export interface EInvoiceModel {
    orderReference: string
    deliveryDate: Date | null
    taxExemptionReason: string
    isTaxExemptionReasonGlobal: boolean
    processIdentificator: string
    reasonContainers: { name: string; reason: string; id: number }[]
}

export interface EInvoiceRequestModel {
    downloadAfterSend: boolean
    type: EInvoiceModalType
    invoiceId: number
    toEmail: string
    orderReference: string
    deliveryDate: Date | null
    taxExemptionReason: string
    isTaxExemptionReasonGlobal: boolean
    reasonContainers: { name: string; reason: string; id: number }[]
    shouldFiscalize: boolean
}

export enum RequestEInvoiceResult {
    Success,
    Error,
}

export interface ReceiptLogModel {
    createdAt: string
    status: ReceiptStatusHistory
    userFullName: string | null
    additionalInformation: string | null
    showOnlyStatusChange: boolean
}

export class InvoiceApi {
    static sendInvoice(
        invoiceId: string | number | undefined,
        toEmail: string,
        toEmailsCc: string,
        emailBody: string,
        includeCopyToSelf: boolean,
        shouldFiscalize: boolean
    ) {
        return ApiClient.post<number>('api/receipt/SendInvoiceViaEmail', {
            receiptId: invoiceId,
            toEmail,
            toEmailsCc,
            emailBody,
            includeCopyToSelf,
            shouldFiscalize,
        })
    }
    public static get(invoiceId: number | string) {
        return ApiClient.get<InvoiceDocumentModel>(`api/receipt/GetReceipt/${invoiceId}`)
    }

    public static cancel(invoiceId: number | string) {
        return ApiClient.post<number>('api/receipt/cancel', { receiptId: invoiceId })
    }

    public static upsert(document: InvoiceDocumentModel) {
        return ApiClient.post<InvoiceDocumentModel>(`api/Receipt/Upsert`, document)
    }

    public static delete(invoiceId: number | string) {
        return ApiClient.delete(`api/receipt/deleteReceipt/${invoiceId}`)
    }

    public static getDocument(invoiceId: number | string, shouldFiscalize: boolean) {
        return ApiClient.get(`api/receipt/getDocument?receiptId=${invoiceId}&shouldFiscalize=${shouldFiscalize}`, {
            responseType: 'arraybuffer',
        })
    }

    public static getReport(reportType: string, fromDate: Date, toDate: Date) {
        return ApiClient.post<ArrayBuffer>(
            `api/receipt/getReport`,
            { reportType: Number.parseInt(reportType), fromDate, toDate },
            undefined,
            { responseType: 'arraybuffer' }
        )
    }

    public static getEInvoiceData(invoiceId: number | string) {
        return ApiClient.get<EInvoiceModel>(`api/receipt/getEinvoiceData/${invoiceId}`)
    }

    public static requestEInvoice(model: EInvoiceRequestModel) {
        return ApiClient.post<{ data: string; status: RequestEInvoiceResult }>(`api/receipt/RequestEInvoice`, model)
    }

    public static getPopularProducs() {
        return ApiClient.get<PopularProductModel[]>('api/receipt/GetMostPopularReceiptProductsByCount')
    }

    public static getNextInvoiceNumber() {
        return ApiClient.get<string>('api/receipt/GetNextReceiptNumber')
    }

    public static getConfiguration(invoiceId: string | undefined) {
        return ApiClient.get<InvoiceConfigurationModel>(`api/receipt/GetConfiguration${invoiceId ? `?invoiceId=${invoiceId}` : ''}`)
    }

    public static createFromTender(tenderId: number | string) {
        return ApiClient.post<{ id: number }>(`api/documentconversion/CreateReceiptFromTender`, { tenderId })
    }

    public static changeStatus(invoiceId: number, newStatus: number, paymentDate: Date) {
        return ApiClient.post(`api/receipt/changeReceiptStatus`, {
            receiptId: invoiceId,
            status: newStatus,
            paymentDate: paymentDate,
        })
    }

    public static getLogs(invoiceId: number | string) {
        return ApiClient.get<ReceiptLogModel[]>(`api/receipt/logs/${invoiceId}`)
    }

    public static getListAggregatedValues(projectionName: string, filters?: any[] | null, customFilter?: string | null) {
        return ApiClient.post<{ currency: Currency; sumOfItemsBasePrice: number; sumOfItemsFullPrice: number }[]>(
            'api/receipt/GetListAggregatedValues',
            {
                projectionName,
                filters: filters || [],
                customFilter,
            }
        )
    }
}
