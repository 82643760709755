import React, { useEffect, useMemo, useState } from 'react'

import { CompanySalePlaceRow, ListingApi, SalePlaceRowModel } from '../../../services/ListingApi'
import { SalePlacesApi } from '../../../services/SalePlacesApi'
import { Grid } from '../../../shared/grid/Grid'
import { GridMenuCell, GridMenuActionLink } from '../../../shared/grid/GridMenu'
import { InlineInputEdit } from '../../../shared/forms/InlineInputEdit';
import { GridActions, TableParams } from '../../../shared/grid/Types'
import { SalePlaceModel } from '../../../shared/models'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { AddSalePlaceButton, SalePlaceForm } from './AddSalePlaceButton'
import { toastSuccess, toastWarning } from '../../../shared/toastr'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { GridContextProvider, useGridActions } from '../../../shared/grid/GridContextProvider'
import { OfferionTooltip } from '../../../shared/tooltip'
import { UsersApi } from '../../../services/UsersApi'
import { Field, Formik } from 'formik'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { MobileSidebarOpener } from '../../../shared/elements/MobileSidebarOpener'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export const SalePlacesListContainer = () => {
    const { t } = useOfferionTranslation()

    const [canCreateSalePlaces, setCanCreateSalePlace] = useState(false);
    const [canChangeSalePlace, setCanChangeSalePlace] = useState(false);
    const [companySalePlaceOptions, setCompanySalePlaceOptions] = useState<JSX.Element[] | null>(null)
    const [currentSalePlaceId, setCurrentSalePlaceId] = useState<number | undefined>();

    function loadSidebarInfo() {

        ListingApi.getList<CompanySalePlaceRow>("CompanySalePlacesModel", 1, 100, []).then(result => {
            const options = result.data.map(o => <option key={o.id} value={o.id}>{o.name}</option>);

            setCompanySalePlaceOptions(options);

        });
    }

    useEffect(() => {
        CompanySettingsApi.getPackageSettings().then(result => {
            setCanCreateSalePlace(result.canCreateSalePlaces);
            setCanChangeSalePlace(result.canChangeSalePlaces)
        })

        loadSidebarInfo();

        UsersApi.getCurrentUserSalePlaceInformation().then(result => {
            setCurrentSalePlaceId(result.companySalePlaceId);
        })
    }, [])

    const tableSchema: any = {
        columns: [
            {
                cssClass: 'table__header__item__name',
                labelKey: 'Settings.SalesPlaces.Table.Header.name',
            },
            {
                cssClass: 'table__header__item__location',
                labelKey: 'Settings.SalesPlaces.Table.Header.city',
            }
        ],
    }

    if (canChangeSalePlace)
        tableSchema.columns.push(
            {
                cssClass: 'table__header__item__menu'
            })

    const RowComponent = ({ row, gridActions }: { row: SalePlaceRowModel, gridActions: GridActions }) => {

        const [salePlace, setSalePlace] = useState<SalePlaceModel | null>(null);

        const offerionModal = useOfferionModal();

        const editSalePlace = () => {
            SalePlacesApi.get(row.id).then(setSalePlace);
        }

        const deleteSalePlace = () => {
            offerionModal.open('Settings.SalesPlaces.Delete.pageTitle', 'Settings.SalesPlaces.Delete.questionYesNo', () => SalePlacesApi.delete(row.id)
                .then((canCreateNextSalePlace) => setCanCreateSalePlace(canCreateNextSalePlace))
                .then(() => toastSuccess('Settings.SalesPlaces.Delete.successDelete', { salesPlaceNameAddress: row.name }))
                .then(() => setSalePlace(null))
                .then(gridActions.refresh)
                .then(loadSidebarInfo),
                {
                    salesPlaceNameAddress: row.name
                })
        }

        return <>
            <span
                style={{ width: '300px' }}
                className="table__cell table__cell__title overlay__item overlay__item--right-middle" >
                {canChangeSalePlace
                    ? <InlineInputEdit currentValue={row.name} onSave={({ newValue }) => SalePlacesApi.patchField(row.id, "Name", newValue).then(gridActions.refresh).then(loadSidebarInfo)}></InlineInputEdit>
                    : <span>{row.name}</span>
                }
                {salePlace && <SalePlaceForm onSalePlaceUpserted={(canCreateNextSalePlace) => {
                    setCanCreateSalePlace(canCreateNextSalePlace);

                    loadSidebarInfo();
                }} initialValues={salePlace} closeForm={() => setSalePlace(null)}></SalePlaceForm>}
            </span>
            <span className="table__cell table__cell--prefixed" style={{ width: '200px' }}>{row.address}</span>
            {canChangeSalePlace && <GridMenuCell>
                <GridMenuActionLink icon={faEdit} onClick={editSalePlace} translationKey="Settings.SalesPlaces.Table.ItemMenu.edit"></GridMenuActionLink>
                {row.isPrimarySalePlace ? null : <GridMenuActionLink icon={faTrashAlt} onClick={deleteSalePlace} translationKey="Settings.SalesPlaces.Table.ItemMenu.delete"></GridMenuActionLink>}
            </GridMenuCell>}
        </>
    }

    const Sidebar = () => {
        const { t } = useOfferionTranslation();

        function onSubmit(newSalePlceId: number) {
            UsersApi.changeCurrentUserSalePlace(newSalePlceId).then(() => {
                toastSuccess("Trenutno prodajno mjesto je uspješno promijenjeno")

                setCurrentSalePlaceId(newSalePlceId);
            })
        }

        if (companySalePlaceOptions == null || companySalePlaceOptions.length == 1 || !canChangeSalePlace || currentSalePlaceId == undefined)
            return null;

        return (
            <form className="search-form search-form--show-mobile simple-search">
                <h2 className="search__title search-title--active-sales-place">{t("Settings.SalesPlaces.Side.activeSalesPlace")}
                    <OfferionTooltip placement="bottom" titleKey='Settings.SalesPlaces.Side.activeSalesPlace' bodyKey="Settings.SalesPlaces.Side.activeSalesPlace.infoMessage" />
                </h2>

                <div className="search-content">
                    <div className="search__group">
                        <Formik initialValues={{ companySalePlaceId: currentSalePlaceId }} onSubmit={val => onSubmit(val.companySalePlaceId)}>
                            {
                                formikProps => {
                                    return <div className="select-container search__item">
                                        <Field
                                            as="select"
                                            onChange={(e: any) => {
                                                formikProps.setFieldValue("companySalePlaceId", Number.parseInt(e.target.value));

                                                formikProps.submitForm();
                                            }}
                                            name="companySalePlaceId"
                                            id="companySalePlaceId"
                                            className="select">
                                            {companySalePlaceOptions}
                                        </Field>
                                    </div>
                                }
                            }
                        </Formik>


                    </div>
                </div>
            </form>
        )
    }

    const rowPromiseFactory = useMemo(() => {
        return (config: TableParams) => ListingApi.getList<SalePlaceRowModel>('CompanySalePlacesModel', config.pageNumber, config.pageSize, config.filter)
    }, [])

    return (
        <GridContextProvider>
            <BreadcrumbsItem to="/settings/sale-places">
                {t('Settings.SalesPlaces.Header.pageTitle')}
            </BreadcrumbsItem>

            <main className="main">
                <div className="main__header">
                    <div className="main-title">{t('Settings.SalesPlaces.Header.pageTitle')}</div>

                    <div className="main__header-buttons">
                        {canChangeSalePlace && <AddSalePlaceButton onSalePlaceUpserted={(canCreateNextSalePlace) => {
                            loadSidebarInfo();

                            setCanCreateSalePlace(canCreateNextSalePlace)
                        }} customOnClick={canCreateSalePlaces ? null : () => {
                            toastWarning('Settings.SalesPlaces.Header.NewSalesPlace.warningMessage')
                        }}
                        />}
                        <MobileSidebarOpener iconClass="icon__settings-white" containerClass="mod-margin-left-8" />
                    </div>
                </div>
                <Grid
                    emptyMessageKey="Settings.SalesPlaces.Table.emptyMessage"
                    emptySearchMessageKey="Settings.SalesPlaces.emptySearchMessage"
                    translationKey="Settings.SalesPlaces.Table.Header.title"
                    rowsPromiseFactory={rowPromiseFactory}
                    rowComponent={RowComponent}
                    sidebarComponent={Sidebar}
                    sidebarDependencyArray={[companySalePlaceOptions, canChangeSalePlace, currentSalePlaceId]}
                    sidebarComponentMobileTitleKey="Settings.SalesPlaces.Side.activeSalesPlace"
                    tableSchema={tableSchema}></Grid>
            </main >
        </GridContextProvider>
    )
}
