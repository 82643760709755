import React, { useEffect, useState } from 'react'

import { DocumentListContainer, DocumentGridModel } from '../../shared/documents/DocumentListContainer'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { GridMenuActionLink, GridMenuCell, GridMenuLink } from '../../shared/grid/GridMenu';
import { EInvoiceRequestModel, InvoiceApi, RequestEInvoiceResult } from '../../services/InvoiceApi';
import { useHistory, useLocation } from 'react-router-dom';
import { toastError, toastSuccess, toastWarning } from '../../shared/toastr';
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter';
import { DocumentDateFilters } from '../../shared/documents/DocumentDateFilters';
import Modal from '../../shared/modal';
import { ConfirmFiscalizationModal } from './ConfirmFiscalizationModal';
import { PaymentMethod, ReceiptStatus } from '../../shared/models';
import { CompanySettingsApi } from '../../services/CompanySettingsApi';
import { SendViaEmailModal } from '../../shared/documents/SendViaEmailModal';
import { ChangeStatusModal } from '../../shared/documents/ChangeStatusModal';
import { CurrencyLabel } from '../../shared/ui/currency';
import dayjs from 'dayjs';
import { Field } from 'formik';
import { EInvoiceModal, EInvoiceModalType } from './EInvoiceModal';
import { useOfferionModal } from '../../shared/modal/ConfirmationModal';
import { GridActions } from '../../shared/grid/Types';
import { AggregatedResultsButton } from '../../shared/documents/details/elements/AggregatedResultsButton'
import { faEdit, faEnvelope, faFileContract, faHistory, faPrint, faStream, faTimes, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { useGridActions } from '../../shared/grid/GridContextProvider';
import { openDocument } from '../../shared/utils/openDocument';
import { InvoiceLogModal } from './InvoiceDetailsContainer';
import { useTranslation } from 'react-i18next';
import StatusIndicator from '../ui/StatusIndicator';

interface InvoiceListConfig {
    pageTitleKey: string;
    gridTitleKey: string;
    buttons: React.ComponentType<{ canCreateTenders: boolean, canCreateInvoices: boolean }>;
    hideFilters?: boolean;
    hidePaging?: boolean;
    defaultPageSize?: number;
    defaultCustomFilter?: string | null;
}

function shouldFiscalize(row: DocumentGridModel, isFiscalizationActive: boolean) {
    return isFiscalizationActive && !row.isFiscalized && row.paymentMethod != PaymentMethod.Account;
}

function canFiscalize(row: DocumentGridModel, isFiscalizationActive: boolean) {
    const dayJsOfficialDate = dayjs(row.officialDate).add(2, "day");
    const today = dayjs(new Date());
    const isInsideInterval = dayJsOfficialDate.isAfter(today);

    return shouldFiscalize(row, isFiscalizationActive) && isInsideInterval;
}

const PrintAction = ({ action, row, getDocumentFinal }: { action: () => Promise<boolean>, row: { invoiceNumber: string } & DocumentGridModel, getDocumentFinal: (row: { invoiceNumber: string } & DocumentGridModel, shouldFiscalize: boolean) => Promise<boolean> }) => {
    const [confirmFiscalizationPopupVisible, setConfirmFiscalizationPopupVisible] = useState(false);
    const gridActions = useGridActions()

    return <>
        <GridMenuActionLink icon={faPrint} onClick={() => action().then(result => setConfirmFiscalizationPopupVisible(result))} translationKey="Invoice.Side.print"></GridMenuActionLink>

        <Modal isOpen={confirmFiscalizationPopupVisible}>
            <ConfirmFiscalizationModal
                officialDate={row.officialDate}
                close={() => setConfirmFiscalizationPopupVisible(false)}
                onSubmit={shouldFiscalize => getDocumentFinal(row, shouldFiscalize)
                    .then(result => setConfirmFiscalizationPopupVisible(result))
                    .then(() => {
                        if (shouldFiscalize)
                            gridActions.refresh()
                    })} ></ConfirmFiscalizationModal>
        </Modal>
    </>
}

const EmailAction = ({ defaultMailText, row, isFiscalizationActive, emailInvoice }: { defaultMailText: string, isFiscalizationActive: boolean, row: { invoiceNumber: string } & DocumentGridModel, emailInvoice: (id: number, values: any) => Promise<any> }) => {
    const { t } = useOfferionTranslation();
    const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false);
    const gridActions = useGridActions()

    return <>
        <GridMenuActionLink icon={faEnvelope} onClick={() => setSendEmailModalVisible(true)} translationKey="Invoice.Side.sendMail"></GridMenuActionLink>
        <Modal isOpen={sendEmailModalVisible}>
            <SendViaEmailModal
                pageTitleKey="Invoice.Send.pageTitleInvoice"
                subTitleKey="Invoice.Send.invoiceAttached"
                defaultMailText={defaultMailText}
                clientEmail={row.clientEmail}
                documentDetails={() => <span className="invoice-info">
                    <ul>
                        <li>{row.invoiceNumber}</li>
                        <li>{row.clientName}</li>
                        <li><CurrencyLabel amount={row.sumOfItemsFullPrice} currency={row.currency} /></li>
                        <li>{row.issuedBy}</li>
                    </ul>
                </span>}
                documentFooterRenderer={() => {

                    return canFiscalize(row, isFiscalizationActive) ? <div className="info__row">
                        <div style={{ float: "left" }}>
                            <Field id="show-in-print3" type="checkbox" className="checkbox" name="shouldFiscalize" />
                            <label htmlFor="show-in-print3"
                                className="button button--gray button--padded checkbox-button button--print">
                                <span className="checkbox-button__icon"></span>
                            </label>
                        </div>
                        <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                            {t("Invoice.Send.fiscalizeYesNo")}
                        </label>
                    </div> : null
                }}
                close={() => setSendEmailModalVisible(false)}
                onSubmit={values => emailInvoice(row.id, { ...values, shouldFiscalize: values.shouldFiscalize && canFiscalize(row, isFiscalizationActive) })
                    .then(() => setSendEmailModalVisible(false))
                    .then(() => {
                        if (values.shouldFiscalize)
                            gridActions.refresh()
                    })} />

        </Modal>
    </>
}

const ChangeStatusAction = ({ invoiceStatusModalVisible, setInvoiceStatusModalVisible, row, onSubmit }: { invoiceStatusModalVisible: number|null, setInvoiceStatusModalVisible: (v: number|null) => void, row: { invoiceNumber: string } & DocumentGridModel, onSubmit: (newStatus: number, paymentDate: Date) => void }) => {
    return <>
        <GridMenuActionLink icon={faStream} onClick={() => setInvoiceStatusModalVisible(row.id)} translationKey="Invoice.Side.changeStatus"></GridMenuActionLink>
        <Modal isOpen={invoiceStatusModalVisible === row.id}>
            <ChangeStatusModal
                mapping="ReceiptStatus"
                currentStatus={row.status}
                paymentDate={row.paymentDate}
                onSubmit={onSubmit}
                pageTitleKey="Invoice.ChangeStatus.pageTitle"
                close={() => setInvoiceStatusModalVisible(null)}
                options={[
                    { value: 0, translationKey: "Allaround.Status.draft" },
                    { value: 1, translationKey: "Allaround.Status.invoiceSent" },
                    { value: 3, translationKey: "Allaround.Status.invoicePaid" },
                ]}
                showPaymentDate={status => status == ReceiptStatus.Paid}
            />
        </Modal>
    </>
}

const EInvoiceAction = ({ row, isCompanyMissingEInvoiceData, isEInvoiceConfigured, isFiscalizationActive }: { row: { invoiceNumber: string } & DocumentGridModel, isCompanyMissingEInvoiceData: boolean, isEInvoiceConfigured: boolean, isFiscalizationActive: boolean }) => {
    const { t } = useTranslation();
    const [eInvoiceModalType, setEInvoiceModalType] = useState<EInvoiceModalType | null>(null);

    const showEInvoiceModal = () => {

        if (isCompanyMissingEInvoiceData) {
            toastWarning('Invoice.SendEInvoice.Message.noCompanyData');

            return;
        }

        if (!row.isClientDataConfigured) {
            toastWarning('Invoice.SendEInvoice.Message.noClientData');

            return;
        }

        setEInvoiceModalType(isEInvoiceConfigured ? EInvoiceModalType.SendEinvoice : EInvoiceModalType.DownloadEInvoice)
    }

    const requestEInvoice = (data: EInvoiceRequestModel) => {
        return InvoiceApi.requestEInvoice(data).then(result => {
            if (result.status == RequestEInvoiceResult.Error) {
                toastError(result.data)

                return;
            }

            if (eInvoiceModalType == EInvoiceModalType.DownloadEInvoice || data.downloadAfterSend) {

                openDocument(`eRačun ${row.invoiceNumber}.xml`, result.data, 'application/xml');
            }
            else {
                toastSuccess('Invoice.SendEInvoice.Message.success')
            }

            setEInvoiceModalType(null);
        })
    }


    return <>
        <GridMenuActionLink icon={faFileContract} onClick={showEInvoiceModal} translationKey="Invoice.Header.Menu.sendEInvoice"></GridMenuActionLink>

        {eInvoiceModalType != null && <Modal isOpen={eInvoiceModalType != null}>
            <EInvoiceModal
                details={{
                    forCharge: row.sumOfItemsFullPrice,
                    clientEmail: row.clientEmail,
                    creator: row.issuedBy,
                    clientName: row.clientName,
                    currency: row.currency,
                    documentNumber: row.invoiceNumber,
                    invoiceId: row.id
                }}
                close={() => setEInvoiceModalType(null)}
                onSubmit={res => requestEInvoice({ ...res, shouldFiscalize: canFiscalize(row, isFiscalizationActive) && res.shouldFiscalize })}
                documentFooterRenderer={() => {

                    return canFiscalize(row, isFiscalizationActive) ? <div className="info__row">
                        <div style={{ float: "left" }}>
                            <Field id="show-in-print3" type="checkbox" className="checkbox" name="shouldFiscalize" />
                            <label htmlFor="show-in-print3"
                                className="button button--gray button--padded checkbox-button button--print">
                                <span className="checkbox-button__icon"></span>
                            </label>
                        </div>
                        <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                            {t("Invoice.SendEInvoice.fiscalizeYesNo")}
                        </label>
                    </div> : null
                }}
                type={eInvoiceModalType} ></EInvoiceModal>
        </Modal>}
    </>
}

const InvoiceStatusAction = ({ row }: { row: DocumentGridModel }) => {
    const [isLogModalVisible, setIsLogModalVisible] = useState(false);

    return <>
        <GridMenuActionLink icon={faHistory} onClick={() => setIsLogModalVisible(true)} translationKey="Invoice.Header.Menu.log"></GridMenuActionLink>
        <Modal isOpen={isLogModalVisible}>
            <InvoiceLogModal invoiceId={row.id} close={() => setIsLogModalVisible(false)} />
        </Modal>
    </>
}

export const InvoiceList = (props: InvoiceListConfig) => {

    const { t } = useOfferionTranslation();
    const history = useHistory();
    const [defaultMailText, setDefaultMailText] = useState("")
    const [canCreateInvoices, setCanCreateInvoices] = useState(false);
    const [canCreateTenders, setCanCreateTenders] = useState(false);
    const [isFiscalizationActive, setIsFiscalizationActive] = useState(false);
    const [isCompanyMissingEInvoiceData, setIsCompanyMissingEInvoiceData] = useState(false);
    const [isEInvoiceConfigured, setIsEInvoiceConfigured] = useState(false);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const offerionModal = useOfferionModal();

    // can be null or invoice id
    const [invoiceStatusModalVisible, setInvoiceStatusModalVisible] = useState<number|null>(null);

    useEffect(() => {
        CompanySettingsApi.getReceiptListSettings().then(result => {
            setIsFiscalizationActive(result.isFiscalizationActive);
            setDefaultMailText(result.defaultMailText)
            setIsCompanyMissingEInvoiceData(result.isCompanyMissingEInvoiceData);
            setIsEInvoiceConfigured(result.isEInvoiceConfigured)
            setCanCreateInvoices(result.canCreateInvoices);
            setCanCreateTenders(result.canCreateTenders);
            setSettingsLoaded(true);
        });
    }, [])

    const deleteInvoice = (invoiceId: number, invoiceNumber: string, gridActions: GridActions) => {
        offerionModal.open('Invoice.DeleteInvoice.pageTitle', 'Invoice.DeleteInvoice.questionYesNo', async () => {
            await InvoiceApi.delete(invoiceId);

            setCanCreateInvoices(true);

            gridActions.refresh();

            toastSuccess('Invoice.DeleteInvoice.Message.SuccessDelete')
        }, { invoiceNumber })
    }

    const cancelInvoice = (invoiceId: number, invoiceNumber: string) => {
        offerionModal.open('Invoice.CancelInvoice.pageTitle', 'Invoice.CancelInvoice.questionYesNo', async () => {
            const newInvoiceId = await InvoiceApi.cancel(invoiceId);

            history.push(`/invoice/${newInvoiceId}`)
        }, { invoiceNumber })
    }

    const getDocumentFinal = (row: { invoiceNumber: string } & DocumentGridModel, shouldFiscalize: boolean) => {

        return InvoiceApi.getDocument(row.id, shouldFiscalize).then((data: any) => {

            openDocument(`${t('Invoice.Print.invoice')} ${row.invoiceNumber}.pdf`, data);

            return false;
        });
    }

    const getDocumentStep1 = (row: { invoiceNumber: string } & DocumentGridModel) => {

        const isAccountPaymentMethod = row.paymentMethod == PaymentMethod.Account;

        if (row.isFiscalized || !isFiscalizationActive || isAccountPaymentMethod)
            return getDocumentFinal(row, false);
        else
            return Promise.resolve(true);
    }

    const changeStatus = async (row: DocumentGridModel, newStatus: number, paymentDate: Date, gridActions: GridActions) => {
        await InvoiceApi.changeStatus(row.id, newStatus, paymentDate);
        setInvoiceStatusModalVisible(null);
        gridActions.refresh();
    }


    const emailInvoice = (invoiceId: number, info: { toEmail: string, toEmailsCc: string, emailBody: string, includeCopyToSelf: boolean, shouldFiscalize: boolean }) => {
        return InvoiceApi.sendInvoice(invoiceId, info.toEmail, info.toEmailsCc, info.emailBody, info.includeCopyToSelf, info.shouldFiscalize);
    }

    const columns = [
        {
            key: 'Name',
            labelKey: 'Invoices.Table.Header.invoiceNumber',
            cssClass: "mobile-hide"
        },
    ]

    function invoiceStatusModalVisibleHandler(e: React.MouseEvent<HTMLButtonElement>, row: DocumentGridModel) {
        // if the click event propagates it'll open the invoice
        e.stopPropagation();
        setInvoiceStatusModalVisible(row.id);
    }

    return <DocumentListContainer
        settingsLoaded={settingsLoaded}
        columns={columns}
        emptyMessageKey="Invoices.Table.emptyMessage"
        emptySearchMessageKey="Invoices.Table.emptySearchMessage"
        pageTitleKey={props.pageTitleKey}
        gridTitleKey={props.gridTitleKey}
        defaultCustomFilter={props.defaultCustomFilter}
        showMultiLineRowContent
        filtersComponent={props.hideFilters ? null : ({ currentCustomFilter, updateFilter }: { currentCustomFilter?: string | null, updateFilter: (filter: any[], customFilter?: string) => void }) => {
            return <GridFilterForm
                titleKey="Invoices.Side.searchTitle"
                searchButtonLabelKey="Invoices.Side.buttonSearch"
                onSubmit={(values) => {
                    const filters = [];

                    if (values.clientName)
                        filters.push({ property: "Client.Name", operator: "Contains", value: values.clientName })

                    if (values.number)
                        filters.push({ property: "Number", operator: "Contains", value: values.number });

                    if (values.day)
                        filters.push({ property: "OfficialDate.Day", operator: "Equal", value: values.day })

                    if (values.month)
                        filters.push({ property: "OfficialDate.Month", operator: "Equal", value: values.month })

                    if (values.year)
                        filters.push({ property: "OfficialDate.Year", operator: "Equal", value: values.year })

                    if (values.status != "")
                        filters.push({ property: "Status", operator: "Equal", value: values.status })

                    updateFilter(filters, values.customFilter);

                }}
                initialValues={{ clientName: "", day: "", month: "", year: "", number: "", status: "", customFilter: currentCustomFilter || "" }}>

                <GridFilterInput name="clientName" placeholderKey="Invoices.Side.clientPlaceholder"></GridFilterInput>
                <GridFilterInput name="number" placeholderKey="Invoices.Side.invoiceNumberPlaceholder"></GridFilterInput>
                <DocumentDateFilters />

                <div className="select-container search__item mod-margin-bottom-8">
                    <Field as="select" name="status" className="select">
                        <option value="">{t('Invoices.Side.statusAll')}</option>
                        <option value="0">{t('Allaround.Status.draft')}</option>
                        <option value="1">{t('Allaround.Status.invoiceSent')}</option>
                        <option value="3">{t('Allaround.Status.invoicePaid')}</option>
                        <option value="4">{t('Allaround.Status.invoiceCancelled')}</option>
                    </Field>
                </div>

                <div className="select-container search__item mod-margin-bottom-8">
                    <Field as="select" name="customFilter" className="select">
                        <option value="">{t(isFiscalizationActive ? 'Invoices.Side.paymentAndFiscalization' : 'Invoices.Side.paymentOnly')}</option>
                        {isFiscalizationActive && <option value="OnlyNotFiscalized">{t('Invoices.Side.fiscalizationNotDone')}</option>}
                        <option value="PastOrDue">{t('Allaround.Status.invoicePastDue')}</option>
                    </Field>
                </div>

            </GridFilterForm>
        }}
        hidePaging={props.hidePaging}
        customRowClass={row => shouldFiscalize(row, isFiscalizationActive) ? "row-color--orange" : ""}
        buttonsComponent={React.createElement(props.buttons, { canCreateTenders, canCreateInvoices })}
        menuCellComponent={({ row, gridActions }) => {

            return <GridMenuCell>
                <GridMenuLink icon={faEdit} to={`invoice/${row.id}`} translationKey="Invoices.Table.ItemMenu.edit"></GridMenuLink>
                <PrintAction action={() => getDocumentStep1(row)} row={row} getDocumentFinal={getDocumentFinal} />
                <EmailAction row={row} defaultMailText={defaultMailText} isFiscalizationActive={isFiscalizationActive} emailInvoice={emailInvoice} />
                {!row.cancelledFromReceiptId && !row.isCancelled && <GridMenuActionLink icon={faTimes} onClick={() => cancelInvoice(row.id, row.invoiceNumber)} translationKey="Invoice.Side.cancelInvoice"></GridMenuActionLink>}
                <EInvoiceAction row={row} isFiscalizationActive={isFiscalizationActive} isCompanyMissingEInvoiceData={isCompanyMissingEInvoiceData} isEInvoiceConfigured={isEInvoiceConfigured} />
                {row.status != ReceiptStatus.Cancelled && <ChangeStatusAction invoiceStatusModalVisible={invoiceStatusModalVisible} setInvoiceStatusModalVisible={setInvoiceStatusModalVisible} row={row} onSubmit={(newStatus, paymentDate) => changeStatus(row, newStatus, paymentDate, gridActions)} />}
                <InvoiceStatusAction row={row} />
                {!row.isCancelled && !row.isFiscalized && <GridMenuActionLink icon={faTrashAlt} onClick={() => deleteInvoice(row.id, row.invoiceNumber, gridActions)} translationKey="Invoices.Table.ItemMenu.delete"></GridMenuActionLink>}
            </GridMenuCell>
        }}
        defaultPageSize={props.defaultPageSize}
        orderByProperty="OfficialDate"
        projectionName="ReceiptsGridModel"
        firstColumnSelector={(row: { invoiceNumber: string } & DocumentGridModel) => {
            return <><span
                className="table__cell table__cell__title mod-cursor-pointer" data-by={row.issuedBy} onClick={() => history.push(`invoice/${row.id}`)}>
                <span className="table__cell__status">
                    <StatusIndicator
                    small
                    mapping="ReceiptStatus"
                    status={row.status} onClick={e => invoiceStatusModalVisibleHandler(e, row)}/>
                </span>
                {row.invoiceNumber}
            </span>
                <span className="table__cell table__cell__author table__cell--prefixed mobile-only" data-prefix={t("Invoices.Table.Mobile.created")}>{row.issuedBy}</span>

            </>
        }
        }
    />
}

export const InvoiceListContainer = () => {
    const { t } = useOfferionTranslation();
    const history = useHistory();
    const location = useLocation();

    return (
        <>
            <BreadcrumbsItem to="/invoices">
                {t("Invoices.Header.pageTitle")}
            </BreadcrumbsItem>

            <InvoiceList
                defaultCustomFilter={new URLSearchParams(location.search).get("custom-filter")}
                pageTitleKey={"Invoices.Header.pageTitle"}
                gridTitleKey="Invoices.Table.Header.title"
                buttons={({ canCreateInvoices }) => {
                    return <>
                        <div className="button-container">
                            <button className="button button--white table__button" onClick={() => {

                                if (canCreateInvoices) {
                                    history.push("/invoice/new")
                                }
                                else {
                                    const message = t('Allaround.Message.packageLimitReached', {
                                        offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
                                    });

                                    toastWarning(message, true)
                                }
                            }}>
                                <span className="icon icon__plus"></span>
                            </button>
                            <span className="button-text table__button-text mod-margin-left-4">{t('Invoices.Header.newInvoice')}</span>
                        </div>

                        <AggregatedResultsButton aggregatedResultsPromiseFactory={config => InvoiceApi.getListAggregatedValues("ReceiptsGridModel", config.filter, config.customFilter)} />
                    </>
                }} />

        </>
    )
}