import React, { useState, useEffect } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { Formik, Form, Field, FieldAttributes } from 'formik'
import { CompanyPrintSettingsApi } from '../../../services/CompanyPrintSettingsApi'
import { CompanyPrintSettingsModel } from '../../../shared/models'
import { useHistory } from 'react-router-dom'
import { toastSuccess, toastWarning } from '../../../shared/toastr'
import { FileUploadModal } from '../../../shared/elements/FileUploadModal'
import Modal from '../../../shared/modal'
import { ImageWithAuthorization } from '../../../shared/elements/ImageWithAuthorization'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { ResourceApi } from '../../../services/ResourceApi'
import { GridMenuActionLink, GridMenuCell } from '../../../shared/grid/GridMenu'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { OfferionTooltip } from '../../../shared/tooltip'
import { CollapsibleSection } from '../../ui/CollapsibleSection'
import { Checkbox } from '../../ui/Checkbox'

enum VisibleModalType {
    None,
    Header,
    Signature,
    Footer,
}

const TextRow = ({
    labelKey,
    placeholderKey,
    tooltipTitleKey,
    tooltipBodyKey,
    ...rest
}: { labelKey: string; placeholderKey: string; tooltipTitleKey?: string; tooltipBodyKey?: string } & FieldAttributes<any>) => {
    const { t } = useOfferionTranslation()

    return (
        <div className="info__row">
            <label className="info__row-name">{t(labelKey)}</label>
            <div className="info__row-content info__row-content--with-button">
                <div className="">
                    <Field type="text" placeholder={t(placeholderKey)} {...rest} />
                    {tooltipTitleKey && tooltipBodyKey && <OfferionTooltip titleKey={tooltipTitleKey} bodyKey={tooltipBodyKey} />}
                </div>
            </div>
        </div>
    )
}

export const PrintSettingsContainer = () => {
    const { t } = useOfferionTranslation()
    const history = useHistory()

    const [settings, setSettings] = useState<CompanyPrintSettingsModel | null>(null)
    const [visibleModal, setVisibleModal] = useState(VisibleModalType.None)
    const [resourcesKey, setResourcesKey] = useState(0)
    const modal = useOfferionModal()

    useEffect(() => {
        CompanyPrintSettingsApi.get().then(setSettings)
    }, [])

    if (!settings) return null

    const onSubmit = (newSettings: CompanyPrintSettingsModel) => {
        CompanyPrintSettingsApi.update(newSettings).then(() => {
            toastSuccess('Settings.PrintSettings.Message.successUpdate')

            toastWarning('Settings.Save.Message.successUpdateNote')
        })
    }

    const setVisibleModalFunc = (modalType: VisibleModalType) => {
        if (settings.isFreeCompany && modalType == VisibleModalType.Footer) {
            toastWarning('Settings.PrintSettings.Table.Pictures.footerFreeNote')
        } else {
            setVisibleModal(modalType)
        }
    }

    const deleteImage = (modalType: VisibleModalType) => {
        if (settings.isFreeCompany && modalType == VisibleModalType.Footer) {
            toastWarning('Settings.PrintSettings.Table.Pictures.footerFreeNote')

            return
        }

        const cleanupUpload = () => {
            setResourcesKey(resourcesKey + 1)

            setVisibleModal(VisibleModalType.None)
        }

        switch (modalType) {
            case VisibleModalType.Header:
                modal.open(
                    'Settings.PrintSettings.Table.Pictures.headerDeleteTitle',
                    'Settings.PrintSettings.Table.Pictures.headerDelete',
                    async () => {
                        CompanySettingsApi.linkDocumentHeaderResource({ resourceId: null })
                            .then(cleanupUpload)
                            .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewHeader.headerRemoved'))
                            .then(() => setSettings({ ...settings, hasHeader: false }))
                    }
                )

                break

            case VisibleModalType.Signature:
                modal.open(
                    'Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemove',
                    'Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoveQuestion',
                    async () => {
                        CompanySettingsApi.LinkDocumentSignatureResource({ resourceId: null })
                            .then(cleanupUpload)
                            .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewSignature.signatureRemoved'))
                            .then(() => setSettings({ ...settings, hasSignature: false }))
                    }
                )

                break

            case VisibleModalType.Footer:
                modal.open(
                    'Settings.PrintSettings.Table.Pictures.footerDeleteTitle',
                    'Settings.PrintSettings.Table.Pictures.footerDelete',
                    async () => {
                        CompanySettingsApi.linkDocumentFooterResource({ resourceId: null })
                            .then(cleanupUpload)
                            .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewFotter.footerRemoved'))
                            .then(() => setSettings({ ...settings, hasFooter: false }))
                    }
                )

                break
        }
    }

    const uploadResource = (file: File, modalType: VisibleModalType) => {
        ResourceApi.uploadResource(file).then(result => {
            const cleanupUpload = () => {
                setResourcesKey(resourcesKey + 1)

                setVisibleModal(VisibleModalType.None)
            }

            switch (modalType) {
                case VisibleModalType.Header:
                    CompanySettingsApi.linkDocumentHeaderResource(result)
                        .then(cleanupUpload)
                        .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewHeader.headerAdded'))
                        .then(() => setSettings({ ...settings, hasHeader: true }))
                    break

                case VisibleModalType.Signature:
                    CompanySettingsApi.LinkDocumentSignatureResource(result)
                        .then(cleanupUpload)
                        .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewSignature.signatureAdded'))
                        .then(() => setSettings({ ...settings, hasSignature: true }))
                    break

                case VisibleModalType.Footer:
                    CompanySettingsApi.linkDocumentFooterResource(result)
                        .then(cleanupUpload)
                        .then(() => toastSuccess('Settings.PrintSettings.Table.Pictures.NewFooter.footerAdded'))
                        .then(() => setSettings({ ...settings, hasFooter: true }))
                    break
            }
        })
    }

    return (
        <>
            <BreadcrumbsItem to="/settings/print">{t('Settings.PrintSettings.Header.pageTitle')}</BreadcrumbsItem>

            <main className="main">
                <Formik initialValues={settings} onSubmit={onSubmit}>
                    <Form noValidate={true}>
                        <div className="main__header">
                            <div className="main-title">{t('Settings.PrintSettings.Header.pageTitle')}</div>

                            <div className="main__header-buttons main__header-buttons--show-all">
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="submit">
                                        <span className="icon icon__save"></span>
                                        <span className="button-text">{t('Allaround.Button.save')}</span>
                                    </button>
                                </div>
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                        <span className="icon icon__delete"></span>
                                        <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="invoices__container curled-paper settings pt3">
                            <CollapsibleSection title={<span>{t('Settings.PrintSettings.Table.Invoice.title')}</span>}>
                                <div className="info__row">
                                    <label className="info__row-name">{t('Settings.PrintSettings.Table.Invoice.printingMethod')}</label>
                                    <div className="info__row-content">
                                        <div className="info__row-content select-container">
                                            <Field as="select" name="printType">
                                                <option value="0">A4</option>
                                                <option value="1">POS</option>
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                                <div className="info__row info__row--split">
                                    <span>
                                        <label htmlFor="alwaysShowPaymentDueDate">
                                            {t('Settings.PrintSettings.Table.Invoice.showPaymentDate')}
                                        </label>
                                    </span>
                                    <Checkbox name="alwaysShowPaymentDueDate" />
                                </div>

                                <div className="info__row info__row--split">
                                    <span>
                                        <label htmlFor="showConversionRateInDocuments">
                                            {t('Settings.PrintSettings.Table.Invoice.printConversionRate')}
                                        </label>
                                    </span>
                                    <Checkbox name="showConversionRateInDocuments" />
                                </div>

                                <TextRow
                                    name="tenderTitle"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithOfferNumber"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.textWithOfferNumberDefaultText"
                                />

                                <TextRow
                                    name="invoiceTitle"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceNumber"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceNumberDefaultText"
                                />

                                <TextRow
                                    name="invoiceDate"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceDate"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.textWithInvoiceDateDefaultText"
                                />

                                <TextRow
                                    name="paymentDueDate"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithPaymentDate"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.textWithPaymentDateDefaultText"
                                />

                                <TextRow
                                    name="personResponsibleTitle"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithPersonInCharge"
                                    placeholderKey=""
                                />

                                <TextRow
                                    style={{ whiteSpace: ' normal!important' }}
                                    name="personResponsible"
                                    labelKey="Settings.PrintSettings.Table.Invoice.namePersonInCharge"
                                    placeholderKey=""
                                />

                                <TextRow
                                    name="receiptUserCodeTitle"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textWithOperator"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.textWithOperatorDefaultText"
                                />

                                <TextRow
                                    name="paymentMethod"
                                    labelKey="Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPayment"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.PaymentTypes.textWithWayOfPaymentDefaultText"
                                />

                                <TextRow
                                    as="textarea"
                                    rows={6}
                                    name="invoiceValidationText"
                                    labelKey="Settings.PrintSettings.Table.Invoice.textInInvoiceFooter"
                                    placeholderKey=""
                                />

                                <TextRow
                                    name="cancellationText"
                                    labelKey="Settings.PrintSettings.Table.Invoice.stornoInvoiceNote"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.stornoInvoiceNoteDefaultText"
                                />

                                <TextRow
                                    name="invoiceNotFiscalized"
                                    labelKey="Settings.PrintSettings.Table.Invoice.Unfiscalized.infoTitle"
                                    placeholderKey="Settings.PrintSettings.Table.Invoice.Unfiscalized.infoText"
                                />
                            </CollapsibleSection>

                            <CollapsibleSection title={<span>{t('Settings.PrintSettings.Table.Columns.title')}</span>}>
                                <TextRow
                                    name="columnRowNumber"
                                    labelKey="Settings.PrintSettings.Table.Columns.itemPosition"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.itemPositionDefaultText"
                                />

                                <TextRow
                                    name="columnName"
                                    labelKey="Settings.PrintSettings.Table.Columns.item"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.itemDefaultText"
                                />

                                <TextRow
                                    name="columnAmount"
                                    labelKey="Settings.PrintSettings.Table.Columns.itemQuantity"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.itemQuantityDefaultText"
                                />

                                <TextRow
                                    name="columnUnit"
                                    labelKey="Settings.PrintSettings.Table.Columns.measurementUnit"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.measurementUnitDefaultText"
                                />

                                <TextRow
                                    name="columnItemPrice"
                                    labelKey="Settings.PrintSettings.Table.Columns.price"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.priceDefaultText"
                                />

                                <TextRow
                                    name="columnRebate"
                                    labelKey="Settings.PrintSettings.Table.Columns.discount"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.discountDefaultText"
                                />

                                <TextRow
                                    name="columnFinalItemPrice"
                                    labelKey="Settings.PrintSettings.Table.Columns.totalPrice"
                                    placeholderKey="Settings.PrintSettings.Table.Columns.totalPriceDefaultText"
                                />
                            </CollapsibleSection>

                            <CollapsibleSection title={<span>{t('Settings.PrintSettings.Table.Totals.title')}</span>}>
                                <TextRow
                                    name="columnTotalPrice"
                                    labelKey="Settings.PrintSettings.TableTotalName.totalPrice"
                                    placeholderKey="Settings.PrintSettings.TableTotalName.totalPriceDefaultText"
                                />
                                <TextRow
                                    name="columnTax"
                                    labelKey="Settings.PrintSettings.TableTotalName.tax"
                                    placeholderKey="Settings.PrintSettings.TableTotalName.taxDefaultText"
                                />
                                <TextRow
                                    name="calculatedOn"
                                    labelKey="Settings.PrintSettings.TableTotalName.calculatedOn"
                                    placeholderKey="Settings.PrintSettings.TableTotalName.calculatedOnDefaultText"
                                    tooltipTitleKey="Settings.PrintSettings.TableTotalName.calculatedOnInfoTitle"
                                    tooltipBodyKey="Settings.PrintSettings.TableTotalName.calculatedOnInfo"
                                />
                                <TextRow
                                    name="taxFree"
                                    labelKey="Settings.PrintSettings.TableTotalName.nonTaxable"
                                    placeholderKey="Settings.PrintSettings.TableTotalName.nonTaxableDefaultText"
                                />
                                <TextRow
                                    name="columnTotalPriceIncludingTax"
                                    labelKey="Settings.PrintSettings.TableTotalName.totalPriceWithVAT"
                                    placeholderKey="Settings.PrintSettings.TableTotalName.totalPriceWithVATDefaultText"
                                />
                            </CollapsibleSection>

                            <CollapsibleSection title={<span>{t('Settings.PrintSettings.Table.Contacts.title')}</span>}>
                                <TextRow
                                    name="oib"
                                    labelKey="Settings.PrintSettings.TextContact.VAT"
                                    placeholderKey="Settings.PrintSettings.TextContact.VATDefaultText"
                                />
                                <TextRow
                                    name="telephone"
                                    labelKey="Settings.PrintSettings.TextContact.phoneDefaultText"
                                    placeholderKey="Settings.PrintSettings.TextContact.phone"
                                />
                                <TextRow
                                    name="fax"
                                    labelKey="Settings.PrintSettings.TextContact.faxDefaultText"
                                    placeholderKey="Settings.PrintSettings.TextContact.fax"
                                />
                                <TextRow
                                    name="telephoneAndFax"
                                    labelKey="Settings.PrintSettings.TextContact.telFax"
                                    placeholderKey="Settings.PrintSettings.TextContact.telFaxDefaultText"
                                />
                                <TextRow
                                    name="mobile"
                                    labelKey="Settings.PrintSettings.TextContact.mobile"
                                    placeholderKey="Settings.PrintSettings.TextContact.mobileDefaultText"
                                />
                                <TextRow
                                    name="email"
                                    labelKey="Settings.PrintSettings.TextContact.mail"
                                    placeholderKey="Settings.PrintSettings.TextContact.web"
                                />
                                <TextRow
                                    name="accountNumber"
                                    labelKey="Settings.PrintSettings.TextContact.IBAN"
                                    placeholderKey="Settings.PrintSettings.TextContact.IBANDefaultText"
                                />
                            </CollapsibleSection>

                            <CollapsibleSection title={<span>{t('Settings.PrintSettings.Table.Pictures.title')}</span>}>
                                <div className="info__container">
                                    <div className="info__row">
                                        <div>
                                            <label className="notice__title">{t('Settings.PrintSettings.Table.Pictures.header')}</label>

                                            <div className="print-settings-hamburger">
                                                {settings.hasHeader ? (
                                                    <GridMenuCell>
                                                        <GridMenuActionLink
                                                            onClick={() => setVisibleModalFunc(VisibleModalType.Header)}
                                                            translationKey="Allaround.Button.modify"></GridMenuActionLink>
                                                        <GridMenuActionLink
                                                            onClick={() => deleteImage(VisibleModalType.Header)}
                                                            translationKey="Allaround.Button.delete"></GridMenuActionLink>
                                                    </GridMenuCell>
                                                ) : (
                                                    <GridMenuCell>
                                                        <GridMenuActionLink
                                                            onClick={() => setVisibleModalFunc(VisibleModalType.Header)}
                                                            translationKey="Settings.MyPictures.Table.UploadPicture.addPictureButton"></GridMenuActionLink>
                                                    </GridMenuCell>
                                                )}
                                            </div>
                                        </div>
                                        <div
                                            className="info__container image__container"
                                            style={{ marginBottom: '0px', paddingBottom: '0px', width: '95%' }}>
                                            <ImageWithAuthorization
                                                key={resourcesKey}
                                                onClick={() => setVisibleModalFunc(VisibleModalType.Header)}
                                                className="placeholder__content"
                                                src="api/company/getHeaderResource"
                                            />
                                        </div>
                                    </div>
                                    <div className="info__row">
                                        <label className="notice__title">{t('Settings.PrintSettings.Table.Pictures.footer')}</label>
                                        <div className="print-settings-hamburger">
                                            {settings.hasFooter ? (
                                                <GridMenuCell>
                                                    <GridMenuActionLink
                                                        onClick={() => setVisibleModalFunc(VisibleModalType.Footer)}
                                                        translationKey="Allaround.Button.modify"></GridMenuActionLink>
                                                    <GridMenuActionLink
                                                        onClick={() => deleteImage(VisibleModalType.Footer)}
                                                        translationKey="Allaround.Button.delete"></GridMenuActionLink>
                                                </GridMenuCell>
                                            ) : (
                                                <GridMenuCell>
                                                    <GridMenuActionLink
                                                        onClick={() => setVisibleModalFunc(VisibleModalType.Footer)}
                                                        translationKey="Settings.MyPictures.Table.UploadPicture.addPictureButton"></GridMenuActionLink>
                                                </GridMenuCell>
                                            )}
                                        </div>
                                        <div
                                            className="info__container image__container"
                                            style={{ marginBottom: '0px', paddingBottom: '0px', width: '95%' }}>
                                            <ImageWithAuthorization
                                                key={resourcesKey}
                                                className="placeholder__content"
                                                onClick={() => setVisibleModalFunc(VisibleModalType.Footer)}
                                                src="api/company/getFooterResource"
                                            />
                                        </div>
                                    </div>
                                    <div className="info__row">
                                        <label className="notice__title">{t('Settings.PrintSettings.Table.Pictures.signature')}</label>
                                        <div className="print-settings-hamburger">
                                            {settings.hasSignature ? (
                                                <GridMenuCell>
                                                    <GridMenuActionLink
                                                        onClick={() => setVisibleModalFunc(VisibleModalType.Signature)}
                                                        translationKey="Allaround.Button.modify"></GridMenuActionLink>
                                                    <GridMenuActionLink
                                                        onClick={() => deleteImage(VisibleModalType.Signature)}
                                                        translationKey="Allaround.Button.delete"></GridMenuActionLink>
                                                </GridMenuCell>
                                            ) : (
                                                <GridMenuCell>
                                                    <GridMenuActionLink
                                                        onClick={() => setVisibleModalFunc(VisibleModalType.Signature)}
                                                        translationKey="Settings.MyPictures.Table.UploadPicture.addPictureButton"></GridMenuActionLink>
                                                </GridMenuCell>
                                            )}
                                        </div>
                                        <div
                                            className="info__container image__container"
                                            style={{ marginBottom: '0px', paddingBottom: '0px', width: '95%' }}>
                                            <ImageWithAuthorization
                                                key={resourcesKey}
                                                onClick={() => setVisibleModalFunc(VisibleModalType.Signature)}
                                                className="placeholder__content"
                                                src="api/company/getSignatureResource"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </CollapsibleSection>
                        </div>

                        <div className="main__footer">
                            <div className="main__footer-buttons main__footer-buttons--mobile-only">
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="submit">
                                        <span className="icon icon__save"></span>
                                        <span className="button-text">{t('Allaround.Button.save')}</span>
                                    </button>
                                </div>
                                <div className="button-outer">
                                    <button className="button button--white button--padded" type="button" onClick={history.goBack}>
                                        <span className="icon icon__delete"></span>
                                        <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </main>

            <Modal isOpen={visibleModal == VisibleModalType.Header}>
                <FileUploadModal
                    titleKey={
                        settings.hasHeader
                            ? 'Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleEdit'
                            : 'Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleNew'
                    }
                    footerKey={'Settings.PrintSettings.Table.Pictures.NewHeader.infoNote'}
                    onSubmit={file => uploadResource(file, VisibleModalType.Header)}
                    close={() => setVisibleModal(VisibleModalType.None)}
                />
            </Modal>

            <Modal isOpen={visibleModal == VisibleModalType.Signature}>
                <FileUploadModal
                    titleKey={
                        settings.hasSignature
                            ? 'Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleEdit'
                            : 'Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleNew'
                    }
                    footerKey={'Settings.PrintSettings.Table.Pictures.NewHeader.infoNote'}
                    onSubmit={file => uploadResource(file, VisibleModalType.Signature)}
                    close={() => setVisibleModal(VisibleModalType.None)}
                />
            </Modal>

            <Modal isOpen={visibleModal == VisibleModalType.Footer}>
                <FileUploadModal
                    titleKey={
                        settings.hasSignature
                            ? 'Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleEdit'
                            : 'Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleNew'
                    }
                    footerKey={'Settings.PrintSettings.Table.Pictures.NewHeader.infoNote'}
                    onSubmit={file => uploadResource(file, VisibleModalType.Footer)}
                    close={() => setVisibleModal(VisibleModalType.None)}
                />
            </Modal>
        </>
    )
}
