import { useTranslation } from 'react-i18next'
import { CompanyType } from '../../models';
import { useRootStoreSelector } from './useRootStoreSelector'

export const useOfferionTranslation = () => {

    const companyType = useRootStoreSelector(r => r.user.CompanyType)
    const { t } = useTranslation();

    return {
        t: (key: string, options?: {}) => {
            return t(key, { ...options, context: companyType == CompanyType.Lawyer ? "lawyer" : "" })
        }
    }
}

export default useOfferionTranslation;