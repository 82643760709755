import React, { useEffect, useRef, useState } from 'react'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { InvoiceList } from '../invoice/InvoiceListContainer';
import { useHistory } from 'react-router-dom';
import { ConcreteTenderList } from '../concrete-tender/ConcreteTenderListContainer';
import { toastWarning } from '../../shared/toastr';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { DropdownMenu, DropdownMenuActionLink } from '../../shared/elements/DropdownMenu';

export const DashboardContainer = () => {
    const { t } = useOfferionTranslation();

    const dashboardLabel = t("Dashboard.pageTitle");

    return <div className="dashboard-container">

        <BreadcrumbsItem to="/dashboard">
            {dashboardLabel}
        </BreadcrumbsItem>

        <InvoiceList
            gridTitleKey="Dashboard.lastFiveInvoices"
            pageTitleKey={dashboardLabel}
            hideFilters={true}
            hidePaging={true}
            defaultPageSize={5}
            buttons={({ canCreateTenders, canCreateInvoices }) => {
                return <Actions canCreateTenders={canCreateTenders} canCreateInvoices={canCreateInvoices} />
            }} />

        <ConcreteTenderList
            gridTitleKey=""
            pageTitleKey="Dashboard.lastFiveOffers"
            hideFilters={true}
            hidePaging={true}
            hideHeader={true}
            defaultPageSize={5}
            buttons={() => null}
        />
    </div>
}

type ActionProps = {
    canCreateTenders: boolean,
    canCreateInvoices: boolean,
}

function Actions({ canCreateTenders, canCreateInvoices }: ActionProps) {
    const { t } = useOfferionTranslation();
    const history = useHistory();


    function handleCreateTender() {
        if (canCreateTenders) {
            history.push("/offer/new")
        }
        else {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
            });

            toastWarning(message, true)
        }
    }

    function handleCreateInvoice() {

        if (canCreateInvoices) {
            history.push("/invoice/new")
        }
        else {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            });

            toastWarning(message, true)
        }
    }

    return (
        <>
            {/* Mobile screen - dropdown */}
            <div className="table__button-container small-only">
                <DropdownMenu icon="plus">
                    <DropdownMenuActionLink onClick={handleCreateTender} translationKey="Offers.Header.newOffer" />
                    <DropdownMenuActionLink onClick={handleCreateInvoice} translationKey="Invoices.Header.newInvoice" />
                </DropdownMenu>
            </div>

            {/* Larger screen - regular buttons */}
            <div className="table__button-container small-hide">
                <button className="button button--white table__button" onClick={handleCreateTender}>
                    <span className="icon icon__plus"></span>
                </button>
                <span className="button-text table__button-text mod-margin-left-4">{t('Offers.Header.newOffer')}</span>
            </div>

            <div className="table__button-container small-hide">
                <button className="button button--white table__button" onClick={handleCreateInvoice}>
                    <span className="icon icon__plus"></span>
                </button>
                <span className="button-text table__button-text mod-margin-left-4">{t('Invoices.Header.newInvoice')}</span>
            </div>
        </>
    )
}