import React, { ReactNode, useEffect, useState } from 'react'

import { UserGridRow, ListingApi } from '../../../services/ListingApi'
import { Grid } from '../../../shared/grid/Grid'
import { GridFilterForm, GridFilterInput } from '../../../shared/grid/GridFilter'
import { GridMenuCell, GridMenuActionLink } from '../../../shared/grid/GridMenu'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { InlineCheckboxEdit } from '../../../shared/forms/InlineCheckboxEdit';
import { InlineInputEdit } from '../../../shared/forms/InlineInputEdit';
import { GridActions } from '../../../shared/grid/Types'
import { UsersApi } from '../../../services/UsersApi'
import * as Yup from 'yup'
import { UserModel } from '../../../shared/models'
import { UserForm } from './UserForm'
import { AddNewUserButton } from './AddNewUserButton'
import { toastSuccess, toastWarning } from '../../../shared/toastr'
import { useRootStoreSelector } from '../../../shared/store/hooks/useRootStoreSelector'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { GridContextProvider, useGridActions } from '../../../shared/grid/GridContextProvider'
import { MobileSidebarOpener } from '../../../shared/elements/MobileSidebarOpener'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

const Filter = ({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
    return (
        <GridFilterForm
            initialValues={{ partOfname: '' }}
            onSubmit={v =>
                updateFilter([
                    {
                        property: 'FirstName',
                        operator: 'Contains',
                        value: v.partOfname,
                    },
                    {
                        property: 'LastName',
                        operator: 'Contains',
                        value: v.partOfname,
                    },
                ])
            }
            titleKey="Settings.Users.Side.searchTitle"
            searchButtonLabelKey="Clients.Side.searchButton">
            <GridFilterInput name="partOfname" placeholderKey="Settings.Users.Side.searchPlaceholder"></GridFilterInput>
        </GridFilterForm>
    )
}

export const UsersListContainer = () => {
    const { t } = useOfferionTranslation()
    const [canCreateUser, setCanCreateUser] = useState(false)

    useEffect(() => {
        CompanySettingsApi.getPackageSettings().then(result => {
            setCanCreateUser(result.canCreateUsers);
        })
    }, []);

    const tableSchema = {
        columns: [
            {
                cssClass: 'table__header__item__name',
                key: 'Name',
                labelKey: 'Settings.Users.Table.Header.user',
            },
            {
                cssClass: 'table__header__item__location',
                key: 'City',
                labelKey: 'Settings.Users.Table.Header.mail',
            },
            {
                key: 'Email',
                labelKey: 'Settings.Users.Table.Header.admin',
            },
            {
                cssClass: 'table__header__item__menu'
            },
        ],
    }

    const RowComponent = ({ row, gridActions }: { row: UserGridRow, gridActions: GridActions }) => {

        const currentUserId = useRootStoreSelector(x => x.user.UserId)
        const { t } = useOfferionTranslation();

        const [initialEmail] = useState(row.email);
        const [user, setUser] = useState<UserModel | null>(null);

        const offerionModal = useOfferionModal();

        const editUser = () => {
            UsersApi.get(row.id).then(setUser);
        }

        const deleteUser = () => {

            offerionModal.open('Settings.Users.Delete.pageTitle', 'Settings.Users.Delete.questionYesNo', async () => {

                const canCreateNextUser = await UsersApi.delete(row.id);

                setCanCreateUser(canCreateNextUser)

                gridActions.refresh();

                toastSuccess('Settings.Users.Delete.successDelete', { userName: row.firstName + ' ' + row.lastName })
            }, { userName: row.firstName + ' ' + row.lastName })
        }

        const upsertUser = async (user: UserModel & { newPassword: string, sendEmail: boolean }) => {
            const result = await UsersApi.upsertUser(user);

            setCanCreateUser(result.canCreateNextUser);

            gridActions.refresh();

            setUser(null);
        }

        const emailValidationSchema = Yup.object<{ newValue: string }>()
            .shape({
                newValue: Yup.string()
                    .email()
                    .required(t("Allaround.Validation.required", { fieldName: t("Settings.Users.Table.Header.mail") }))
                    .test('Check duplicate email', 'Duplicirani email', function (val) {
                        if (!val)
                            return false;

                        if (val == initialEmail)
                            return true;

                        if (typeof val == "string")
                            return UsersApi.isEmailUnique(val);

                        return false;
                    })
            });

        return <>
            <span
                className="table__cell table__cell__title overlay__item overlay__item--right-middle"
                style={{ width: "300px" }}>
                {row.firstName + ' ' + row.lastName}
                {user && <UserForm closeOverlay={() => setUser(null)} user={user} onSubmit={upsertUser} />}
            </span>
            <span style={{ width: '200px' }} className="table__cell table__cell--prefixed mobile-hide">
                <InlineInputEdit validationSchema={emailValidationSchema} currentValue={row.email} onSave={({ newValue }) => UsersApi.changeUserEmail(row.id, newValue).then(gridActions.refresh)}></InlineInputEdit>
            </span>
            <span className="table__cell centered mobile-hide">
                <InlineCheckboxEdit id={`isAdministrator-${row.id}`} currentValue={row.isAdministrator} onSave={({ newValue }) => UsersApi.changeUserAdminStatus(row.id, newValue)}></InlineCheckboxEdit>
            </span>

            <GridMenuCell>
                <GridMenuActionLink icon={faEdit}  onClick={editUser} translationKey="Settings.Users.Table.ItemMenu.edit"></GridMenuActionLink>
                {row.id.toString() != currentUserId && <GridMenuActionLink icon={faTrashAlt} onClick={deleteUser} translationKey="Settings.Users.Table.ItemMenu.delete"></GridMenuActionLink>}
            </GridMenuCell>

        </>
    }

    const PageWithGrid = ({ children }: { children: ReactNode }) => {
        return <main className="main">
            <div className="main__header">
                <div className="main-title">{t("Settings.Users.Header.pageTitle")}</div>

                <div className="main__header-buttons">
                    <AddNewUserButton customOnClick={canCreateUser ? null : () => {
                        toastWarning('Settings.Users.Header.NewUser.warningMessage')
                    }} onUserUpserted={(canCreateNextUser) => setCanCreateUser(canCreateNextUser)} />

                    <MobileSidebarOpener iconClass="icon__search" containerClass="mod-margin-left-8" />

                </div>
            </div>

            {children}
        </main>
    }

    return (
        <GridContextProvider>
            <BreadcrumbsItem to="/settings/users">
                {t("Settings.Users.Header.pageTitle")}
            </BreadcrumbsItem>

            <PageWithGrid>
                <Grid
                    emptyMessageKey="Users.Table.emptyMessage"
                    emptySearchMessageKey="Settings.Users.emptySearchMessage"
                    translationKey="Settings.Users.Table.Header.title"
                    rowsPromiseFactory={config =>
                        ListingApi.getList<UserGridRow>('UsersModel', config.pageNumber, config.pageSize, config.filter)
                    }
                    rowComponent={RowComponent}
                    sidebarComponent={Filter}
                    tableSchema={tableSchema}></Grid>
            </PageWithGrid>
        </GridContextProvider>
    )
}
