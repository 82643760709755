import { ApiClient } from '../shared/api/api-client'
import { TemplateTenderDocumentModel, Currency } from '../shared/models'

export interface TemplateTenderConfigurationModel {
    canChangeVat: boolean
    showProductDimensionsInfo: boolean
    enterFullPriceEnabled: boolean

    newTemplateTenderNote: string
    newTemplateReceiptNote: string
    newTemplateTenderCurrency: Currency
    newTemplateTenderClientCurrency: Currency
    documentCurrencyRate: number
    clientCurrencyRate: number
    defaultVat: number
    isAccountingVat: boolean
    editPurchasePrice: boolean

    canCreateInvoices: boolean
    canCreateTenders: boolean

    showVatAccountingWarning: boolean
    showPriceCalculationWarning: boolean

    isFreeCompany: boolean
}

export class TemplateTenderApi {
    public static get(tenderId: number | string) {
        return ApiClient.get<TemplateTenderDocumentModel>(`api/TemplateTender/GetTender/${tenderId}`)
    }

    public static upsert(document: TemplateTenderDocumentModel) {
        return ApiClient.post<TemplateTenderDocumentModel>(`api/TemplateTender/Upsert`, document)
    }

    public static delete(tenderId: number | string) {
        return ApiClient.delete(`api/TemplateTender/DeleteTender/${tenderId}`)
    }

    public static getConfiguration(tenderId: string | undefined) {
        return ApiClient.get<TemplateTenderConfigurationModel>(
            `api/TemplateTender/GetConfiguration${tenderId ? `?tenderId=${tenderId}` : ''}`
        )
    }

    public static getListAggregatedValues(projectionName: string, filters?: any[] | null, customFilter?: string | null) {
        return ApiClient.post<{ currency: Currency; sumOfItemsBasePrice: number; sumOfItemsFullPrice: number }[]>(
            'api/TemplateTender/GetListAggregatedValues',
            {
                projectionName,
                filters: filters || [],
                customFilter,
            }
        )
    }
}
