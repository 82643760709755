import React, { useEffect, useRef, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useOfferionTranslation } from '../../store/hooks/useOfferionTranslation'
import { ImageWithAuthorization } from '../../../shared/elements/ImageWithAuthorization'
import './DocumentDetailsContainer.css'
import { Formik, Field, FieldArray, FormikProps, Form, useFormikContext } from 'formik'
import { InvoiceApi } from '../../../services/InvoiceApi'
import {
    DocumentModel,
    DocumentItemModel,
    PopularProductModel,
    Currency,
    CompanyPrintSettingsModel,
    DocumentDetails,
    PriceCalculationMode,
} from '../../models'
import { ExchangeRatesApi, ExchangeRatesModel } from '../../../services/ExchangeRatesApi'
import { UnitItemModel, UnitsApi } from '../../../services/UnitsApi'
import { ResourceApi } from '../../../services/ResourceApi'
import { CompanyPrintSettingsApi } from '../../../services/CompanyPrintSettingsApi'
import { DocumentItemRow } from './elements/DocumentItemRow'
import { useRootStoreSelector } from '../../../shared/store/hooks/useRootStoreSelector'
import { CurrencyOptions, CurrencyLabel } from '../../../shared/ui/currency'
import { DocumentItemForm } from './elements/DocumentItemForm'
import { CustomRateForm } from './elements/CustomRateForm'
import { PriceListModalForm } from './elements/PriceListModalForm'
import * as Yup from 'yup'
import { PriceListApi } from '../../../services/PriceListApi'
import Modal from '../../modal'
import { FileUploadModal } from '../../elements/FileUploadModal'
import { CompanySettingsApi } from '../../../services/CompanySettingsApi'
import round from '../../utils/round'
import { toastSuccess, toastWarning } from '../../toastr'
import { NumberField } from '../../forms/NumberField'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ClientDiscountsApi } from '../../../services/ClientDiscountsApi'
import { OfferionTooltip } from '../../tooltip'
import { ButtonWithOverlay } from '../../elements/ButtonWithOverlay'
import { usePopper } from 'react-popper'

interface DocumentContextModel {
    document: DocumentModel<DocumentDetails>
    printSettings: CompanyPrintSettingsModel
    globalSettings: {
        enterFullPriceEnabled: boolean
        globalDiscount: number
    }
}

const DocumentContext = React.createContext<DocumentContextModel>({} as any)

export const useOfferionDocument = () => {
    return useContext(DocumentContext)
}

const calculatePriceGroups = (enterFullPriceEnabled: boolean, items: DocumentItemModel[]) => {
    const priceGroups: {
        totalItemsPrice: number
        totalForCharge: number
        vatGroups: { [key: string]: number }
    } = {
        totalItemsPrice: 0,
        totalForCharge: 0,
        vatGroups: {},
    }

    return items.reduce((prev, item) => {
        let total = 0
        let charge = 0
        if (enterFullPriceEnabled) {
            total = round((item.fullPrice ?? 0) / (1 + item.vat / 100))
            charge = item.fullPrice ?? 0
        } else {
            total = round(round(item.salePrice * item.quantity) * (1 - item.discount / 100))
            charge = round(round(item.salePrice * item.quantity * (1 + item.vat / 100)) * (1 - item.discount / 100))
        }
        prev.totalItemsPrice += total
        prev.totalForCharge += charge

        if (item.taxFree) return prev

        var difference = charge - total
        if (prev.vatGroups[item.vat]) {
            prev.vatGroups[item.vat] += difference
        } else {
            prev.vatGroups[item.vat] = difference
        }

        return prev
    }, priceGroups)
}

interface Props<TDocumentModel extends DocumentModel<TDocumentDetails>, TDocumentDetails extends DocumentDetails> {
    documentPromiseFactory: (isReload: boolean) => Promise<TDocumentModel>
    pageTitle: string
    currencyOverlayKey: string
    validationSchema?: Yup.ObjectSchema
    defaultVat: number
    canChangeVat: boolean
    editPurchasePrice: boolean
    showProductDimensionsInfo: boolean
    isAccountingVat: boolean
    isFreeCompany: boolean
    canSubmit: (document: TDocumentModel) => boolean
    onSubmit: (values: TDocumentModel) => Promise<TDocumentModel>
    actionButtonsComponent: React.ComponentType<{
        document: TDocumentModel
        actions: { reloadDocument: () => Promise<void> }
        openSettingsModal: () => void
    }> | null
    quickActionsMenuComponent?: React.ComponentType<{
        document: TDocumentModel
    }>
    headerComponent: React.ComponentType<{ document: TDocumentModel; formik: FormikProps<TDocumentModel>; rateComponent: JSX.Element }>
    footerComponent: React.ComponentType<{ document: TDocumentModel; formik: FormikProps<TDocumentModel> }>
    beforeTableComponent?: React.ComponentType<{
        document: TDocumentModel
        formik: FormikProps<TDocumentModel>
        printSettings: CompanyPrintSettingsModel
        settingsComponent: JSX.Element
    }>
    successCreateKey: string
    customRateFormTitleKey: string

    successUpdateKey: string

    footerSrc: string
    headerSrc: string
    signatureSrc: string
    borderColorClass: (doc: TDocumentModel) => string
}

enum VisibleModalType {
    None,
    Header,
    Signature,
    Footer,
}

export const ActionMenuItem = ({
    onClick,
    labelKey,
    allowActionWithoutAnyItems,
    allowActionIfNotSaved,
    icon,
    id,
}: {
    onClick: () => void
    icon?: IconProp
    labelKey: string
    allowActionWithoutAnyItems?: boolean
    allowActionIfNotSaved?: boolean
    id?: string
}) => {
    const { t } = useOfferionTranslation()
    const { document } = useOfferionDocument()

    return (
        <li
            id={id}
            onClick={e => {
                if (allowActionWithoutAnyItems && allowActionIfNotSaved) {
                    onClick()

                    return
                }

                if (document.details.id == 0 && !allowActionIfNotSaved)
                    toastWarning('Invoice.Header.Menu.Message.requiresSaveForNewDocuments')
                else if (document.items.length || allowActionWithoutAnyItems) onClick()
                else toastWarning('Invoice.Header.Menu.Message.warningNoItems')
            }}>
            <a href="#">
                {icon && (
                    <span style={{ width: '30px', display: 'inline-block' }}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}

                {t(labelKey)}
            </a>
        </li>
    )
}

const ActionsMenu = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false)
    const ref = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            const isButtonClick = ref.current && ref.current.contains(e.target as any)

            if (!isButtonClick) setIsMenuVisible(false)
        }

        document.addEventListener('click', handleClick)

        return () => {
            document.removeEventListener('click', handleClick)
        }
    }, [])

    return (
        <button
            ref={ref}
            onClick={() => setIsMenuVisible(!isMenuVisible)}
            type="button"
            className={`button button--gray overlay__button ${isMenuVisible ? 'overlay__button--active' : ''}`}>
            <span className="icon icon__menu-blue"></span>
        </button>
    )
}

export const DocumentDetailsContainer = <TDocumentModel extends DocumentModel<TDocumentDetails>, TDocumentDetails extends DocumentDetails>(
    props: Props<TDocumentModel, TDocumentDetails>
) => {
    const history = useHistory()
    const { t } = useOfferionTranslation()

    const onCancel = () => {
        history.goBack()
    }

    const currentUserFullName = useRootStoreSelector(selector => `${selector.user.FirstName} ${selector.user.LastName}`)

    const [document, setDocument] = useState<TDocumentModel | undefined>()
    const [printSettings, setPrintSettings] = useState<CompanyPrintSettingsModel | undefined>()
    const [exchangeRates, setExchangeRates] = useState<ExchangeRatesModel | undefined>()
    const [units, setUnits] = useState<UnitItemModel[]>([])
    const [addNewItemOverlayVisible, setAddNewItemOverlayVisible] = useState(false)
    const [isCustomRateOverlayVisible, setIsCustomRateOverlayVisible] = useState(false)
    const [isAddItemFromPriceListVisible, setIsAddItemFromPriceListVisible] = useState(false)
    const [popularProducts, setPopularProducts] = useState<PopularProductModel[]>([])
    const [visibleModal, setVisibleModal] = useState(VisibleModalType.None)
    const [resourcesKey, setResourcesKey] = useState(0)
    const [currency, setCurrency] = useState<Currency | null>(null)
    const [canSubmit, setCanSubmit] = useState(false)
    const [enterFullPriceEnabled, setEnterFullPriceEnabled] = useState(false)

    const [referenceElement, setReferenceElement] = useState<HTMLSpanElement | null>(null)
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null)
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            { name: 'arrow', options: { element: arrowElement } },
            { name: 'offset', options: { offset: [0, 10] } },
        ],
        placement: 'left',
    })

    useEffect(() => {
        ExchangeRatesApi.get().then(setExchangeRates)

        UnitsApi.get().then(setUnits)

        CompanyPrintSettingsApi.get().then(setPrintSettings)

        InvoiceApi.getPopularProducs().then(setPopularProducts)

        CompanySettingsApi.getDefaultCurrency().then(setCurrency)
    }, [])

    const loadDocument = (isReload: boolean) =>
        props.documentPromiseFactory(isReload).then(result => {
            setEnterFullPriceEnabled(result.details.priceCalculationMode == PriceCalculationMode.FullPrice)

            setCanSubmit(props.canSubmit(result))

            setDocument(result)
        })

    useEffect(() => {
        loadDocument(false)
    }, [props.documentPromiseFactory])

    if (!document || !exchangeRates || !printSettings || currency == null) return null

    const setVisibleModalFunc = (modalType: VisibleModalType) => {
        if (props.isFreeCompany && modalType == VisibleModalType.Footer) {
            toastWarning('Settings.PrintSettings.Table.Pictures.footerFreeNote')
        } else {
            setVisibleModal(modalType)
        }
    }

    const uploadResource = (file: File, formikProps: FormikProps<TDocumentModel>, modalType: VisibleModalType) => {
        ResourceApi.uploadResource(file).then(result => {
            const cleanupUpload = () => {
                formikProps.setFieldValue('details.shouldUpdateVersionInformation', true)

                formikProps.submitForm().then(() => {
                    setResourcesKey(resourcesKey + 1)

                    setVisibleModal(VisibleModalType.None)
                })
            }

            switch (modalType) {
                case VisibleModalType.Header:
                    return CompanySettingsApi.linkDocumentHeaderResource(result).then(cleanupUpload)

                case VisibleModalType.Signature:
                    return CompanySettingsApi.LinkDocumentSignatureResource(result).then(cleanupUpload)

                case VisibleModalType.Footer:
                    return CompanySettingsApi.linkDocumentFooterResource(result).then(cleanupUpload)
            }
        })
    }

    const SelectedProductComponent = ({ selectedProduct }: { selectedProduct: any }) => {
        const formik = useFormikContext<DocumentModel<TDocumentDetails>>()

        const [priceToRender, setPriceToRender] = useState(selectedProduct.price)
        const [hasAgreedPrice, setHasAgreedPrice] = useState(false)

        useEffect(() => {
            if (!document.client || !document.client.sourceClientId || !selectedProduct.productId) return

            ClientDiscountsApi.getProductDiscountForClient(document.client.sourceClientId, selectedProduct.productId).then(result => {
                if (result == null) return

                if (result.salePrice) setPriceToRender(result.salePrice)

                if (result.discountPercent && selectedProduct.price)
                    setPriceToRender(round(selectedProduct.price * (1 - result.discountPercent)))

                setHasAgreedPrice(true)
            })
        }, [])

        const onAddProduct = (quantity: number) => {
            const conversionRate = exchangeRates[currency] / exchangeRates[formik.values.details.documentCurrency]

            const price = round((priceToRender || 0) * conversionRate)

            const salePrice = enterFullPriceEnabled
                ? round(round(price / (1 - (document.client?.discountPercent || 0))) / (1 + props.defaultVat / 100))
                : price - round(price * (document.client?.discountPercent || 0))

            const newItem = {
                index: formik.values.items.length + 1,
                productId: selectedProduct.productId,
                name: selectedProduct.parentGroupNameChain
                    ? `${selectedProduct.parentGroupNameChain} -> ${selectedProduct.name}`
                    : selectedProduct.name,
                additionalNote: null,
                vat: props.isAccountingVat ? props.defaultVat : 0,
                quantity: quantity,
                fullPrice:
                    enterFullPriceEnabled || !props.isAccountingVat
                        ? price * quantity
                        : round(price * quantity * (1 + props.defaultVat / 100)),
                salePrice: salePrice,
                discount: document.client ? document.client.discountPercent * 100 : 0,
                unit: {
                    name: selectedProduct.unit,
                },
                width: selectedProduct.width,
                height: selectedProduct.height,
                depth: selectedProduct.depth,
                customImageResourceId: selectedProduct.customImageResourceId,
                predefinedImageId: selectedProduct.predefinedImageId,
                purchasePrice: selectedProduct.purchasePrice || 0,
            }

            const items = [...formik.values.items, newItem]

            formik.setFieldValue('items', items)

            formik.submitForm()

            setIsAddItemFromPriceListVisible(false)
        }

        return (
            <Formik enableReinitialize={true} onSubmit={({ quantity }) => onAddProduct(quantity)} initialValues={{ quantity: 1 }}>
                {formikProps => {
                    return (
                        <Form>
                            <div className="info__container">
                                <div className="info__row">
                                    <label className="info__row-name">{t('Invoice.Edit.quantity')}</label>

                                    <div className="info__row-content">
                                        <Field component={NumberField} placeholder="Unesite količinu" name="quantity" />
                                    </div>
                                </div>

                                <div className="info__row" style={{ display: 'flex', alignItems: 'center' }}>
                                    <label className="info__row-name">{t('Invoice.Edit.totalWithoutVAT')}</label>

                                    <div className="info__row-content mod-margin-left-4" style={{ flexGrow: 1 }}>
                                        <span className="table__price-amount table__price-amount--big">
                                            <CurrencyLabel
                                                amount={(priceToRender || 0) * formikProps.values.quantity}
                                                currency={currency}></CurrencyLabel>
                                            &nbsp;
                                            {hasAgreedPrice && (
                                                <OfferionTooltip
                                                    placement="right"
                                                    titleKey="Invoice.AddProduct.Message.AgreedPrice.infoTitle"
                                                    bodyKey="Invoice.AddProduct.Message.AgreedPrice.infoText"
                                                />
                                            )}
                                        </span>
                                    </div>
                                </div>

                                <div className="button-group bottom-controls">
                                    <button className="button button--gray button--padded" type="submit">
                                        <span className="icon icon__check-green"></span>
                                        <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="button button--gray button--padded"
                                        onClick={() => setIsAddItemFromPriceListVisible(false)}>
                                        <span className="icon icon--small icon__cross-red"></span>
                                        <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        )
    }

    let isSubmitting = false

    return (
        <>
            <main className="main document-details">
                <Formik
                    enableReinitialize={true}
                    validationSchema={props.validationSchema}
                    initialValues={document}
                    onSubmit={(doc, helper) => {
                        if (canSubmit && !isSubmitting) {
                            helper.setSubmitting(true)

                            isSubmitting = true

                            return props.onSubmit(doc).then(newOrUpdatedDocument => {
                                setDocument(newOrUpdatedDocument)

                                setCanSubmit(props.canSubmit(newOrUpdatedDocument))

                                helper.setSubmitting(false)

                                isSubmitting = false

                                return newOrUpdatedDocument
                            })
                        }

                        return Promise.resolve(doc)
                    }}>
                    {formikProps => {
                        const priceGroups = calculatePriceGroups(enterFullPriceEnabled, formikProps.values.items)

                        return (
                            <DocumentContext.Provider
                                value={{
                                    document: formikProps.values,
                                    printSettings: printSettings,
                                    globalSettings: {
                                        enterFullPriceEnabled,
                                        globalDiscount: document.client ? document.client.discountPercent : 0,
                                    },
                                }}>
                                <div className="main__header">
                                    <div className="main-title">{props.pageTitle}</div>
                                    <div className="main__header-buttons main__header-buttons--show-all">
                                        {canSubmit && (
                                            <div className="button-outer">
                                                <button
                                                    className="button button--gray button--padded"
                                                    onClick={async () => {
                                                        if (formikProps.isSubmitting) return

                                                        const res = await formikProps.validateForm()
                                                        const isValid = Object.keys(res).length == 0

                                                        if (!isValid) return

                                                        //this resolves issue with client saving
                                                        //this allows document.click to be executed before the form is saved
                                                        setTimeout(() => {
                                                            formikProps.submitForm().then(() => {
                                                                if (document.details.id) toastSuccess(props.successUpdateKey)
                                                                else toastSuccess(props.successCreateKey)
                                                            })
                                                        })
                                                    }}>
                                                    <span className="icon icon__save"></span>
                                                    <span className="button-text">{t('Allaround.Button.save')}</span>
                                                </button>
                                            </div>
                                        )}
                                        <div className="button-outer">
                                            <button className="button button--gray button--padded" onClick={onCancel}>
                                                <span className="icon icon__delete"></span>
                                                <span className="button-text">{t('Allaround.Button.cancel')}</span>
                                            </button>
                                        </div>
                                        {props.actionButtonsComponent ? (
                                            <div className="button-outer overlay__item overlay__item--bottom-left less-than-wide-only">
                                                <ActionsMenu />

                                                <div className="overlay">
                                                    <ul className="overlay__list">
                                                        {React.createElement(props.actionButtonsComponent, {
                                                            document: formikProps.values,
                                                            actions: { reloadDocument: () => loadDocument(true) },
                                                            openSettingsModal: () => setIsCustomRateOverlayVisible(true),
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                {popularProducts.length || props.quickActionsMenuComponent ? (
                                    <div className="side-content">
                                        <div className="search__content">
                                            {props.quickActionsMenuComponent
                                                ? React.createElement(props.quickActionsMenuComponent, {
                                                      document: formikProps.values,
                                                  })
                                                : null}

                                            {popularProducts.length ? (
                                                <FieldArray name="items">
                                                    {helper => (
                                                        <>
                                                            <h2
                                                                id="Invoice.Side.productsTitle"
                                                                className="search__title"
                                                                style={{ marginTop: 45 }}>
                                                                {t('Invoice.Side.productsTitle')}
                                                            </h2>

                                                            <p className="search-form__note">
                                                                {t('Invoice.Side.productsDescription')}
                                                                <span className="icon icon__plus"></span>
                                                            </p>

                                                            {popularProducts.map(product => {
                                                                return (
                                                                    <div>
                                                                        <button
                                                                            className="search-form__button search-form__button--full-width button button--gray"
                                                                            data-tooltip={product.name}
                                                                            data-tooltip-sidebar-custom
                                                                            onClick={async () => {
                                                                                if (!canSubmit) return

                                                                                const promise = document.client?.sourceClientId
                                                                                    ? ClientDiscountsApi.getProductDiscountForClient(
                                                                                          document.client.sourceClientId,
                                                                                          product.productId
                                                                                      ).then(result => {
                                                                                          if (result == null) return product.salePrice

                                                                                          if (result.salePrice) return result.salePrice

                                                                                          if (result.discountPercent)
                                                                                              return round(
                                                                                                  product.salePrice *
                                                                                                      (1 - result.discountPercent)
                                                                                              )

                                                                                          return product.salePrice
                                                                                      })
                                                                                    : Promise.resolve(product.salePrice)

                                                                                const productSalePrice = await promise

                                                                                const conversionRate =
                                                                                    exchangeRates[currency] /
                                                                                    exchangeRates[
                                                                                        formikProps.values.details.documentCurrency
                                                                                    ]

                                                                                const price = round(conversionRate * productSalePrice)

                                                                                const salePrice = enterFullPriceEnabled
                                                                                    ? round(
                                                                                          round(
                                                                                              price /
                                                                                                  (1 -
                                                                                                      (document.client?.discountPercent ||
                                                                                                          0))
                                                                                          ) /
                                                                                              (1 + props.defaultVat / 100)
                                                                                      )
                                                                                    : price -
                                                                                      round(price * (document.client?.discountPercent || 0))

                                                                                const newItem = {
                                                                                    quantity: 1,
                                                                                    fullPrice:
                                                                                        enterFullPriceEnabled || !props.isAccountingVat
                                                                                            ? price
                                                                                            : round(price * (1 + props.defaultVat / 100)),
                                                                                    salePrice: salePrice,
                                                                                    discount: document.client
                                                                                        ? document.client.discountPercent * 100
                                                                                        : 0,
                                                                                    unit: { name: 'kom' },
                                                                                    vat: props.isAccountingVat ? props.defaultVat : 0,
                                                                                    name: product.parentGroupName
                                                                                        ? `${product.parentGroupName} -> ${product.name}`
                                                                                        : product.name,
                                                                                    index: formikProps.values.items.length + 1,
                                                                                    purchasePrice: product.purchasePrice || 0,
                                                                                    width: product.width,
                                                                                    height: product.height,
                                                                                    depth: product.depth,
                                                                                    customImageResourceId: product.customImageResourceId,
                                                                                    predefinedImageId: product.predefinedImageId,
                                                                                }

                                                                                const items = [...formikProps.values.items, newItem]

                                                                                formikProps.setFieldValue('items', items)

                                                                                formikProps.submitForm()
                                                                            }}>
                                                                            <span className="button-text button-text--left">
                                                                                {product.name}
                                                                            </span>
                                                                            <span className="icon icon__plus"></span>
                                                                        </button>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    )}
                                                </FieldArray>
                                            ) : null}
                                        </div>
                                    </div>
                                ) : null}

                                <div className="table__container table__container--alternate-border curled-paper">
                                    <div className="placeholder placeholder--header">
                                        <div className="placeholder__content">
                                            <div className="placeholder__content__text">{t('Invoice.Header.pageHeaderChange')}</div>
                                            <ImageWithAuthorization
                                                reloadDepdendency={document.details.versionInformation}
                                                key={resourcesKey}
                                                onClick={() => setVisibleModalFunc(VisibleModalType.Header)}
                                                className="placeholder__content"
                                                src={props.headerSrc}
                                            />
                                        </div>
                                    </div>
                                    <div className={`table__outer border-left-${props.borderColorClass(formikProps.values)}`}>
                                        <div className="info__container">
                                            {React.createElement(props.headerComponent, {
                                                document: formikProps.values,
                                                formik: formikProps,
                                                rateComponent: (
                                                    <>
                                                        <div className="info__row info__row--small">
                                                            <label className="info__row-name">{t('Invoice.Table.Currency.title')}</label>
                                                            <div className="info__row-content">
                                                                <Field
                                                                    as="select"
                                                                    className="offerion-select"
                                                                    name="details.clientCurrency"
                                                                    onChange={(e: any) => {
                                                                        const newClientCurrency = Number.parseInt(e.target.value)

                                                                        formikProps.setValues({
                                                                            ...formikProps.values,
                                                                            details: {
                                                                                ...formikProps.values.details,
                                                                                documentCurrencyRate: exchangeRates[newClientCurrency],
                                                                                clientCurrencyRate:
                                                                                    exchangeRates[
                                                                                        formikProps.values.details.documentCurrency
                                                                                    ],
                                                                                clientCurrency: newClientCurrency,
                                                                            },
                                                                        })

                                                                        formikProps.submitForm()
                                                                    }}>
                                                                    <CurrencyOptions />
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </>
                                                ),
                                            })}
                                            {isCustomRateOverlayVisible && (
                                                <CustomRateForm
                                                    styles={styles}
                                                    attributes={attributes}
                                                    setRef={setPopperElement}
                                                    setArrowRef={setArrowElement}
                                                    versionInformation={formikProps.values.details.versionInformation}
                                                    cultureName={formikProps.values.details.cultureName}
                                                    exchangeRates={exchangeRates}
                                                    titleKey={props.customRateFormTitleKey}
                                                    closeForm={() => setIsCustomRateOverlayVisible(false)}
                                                    documentCurrencyTitleKey={props.currencyOverlayKey}
                                                    currentDocumentCurrencyRate={formikProps.values.details.documentCurrencyRate}
                                                    currentClientCurrencyRate={formikProps.values.details.clientCurrencyRate}
                                                    documentCurrency={formikProps.values.details.documentCurrency}
                                                    clientCurrency={formikProps.values.details.clientCurrency}
                                                    onSubmit={({
                                                        newClientCurrencyRate,
                                                        newDocumentCurrencyRate,
                                                        itemsConversionRate,
                                                        documentCurrency,
                                                        clientCurrency,
                                                        cultureName,
                                                        shouldUpdateVersionInformation,
                                                    }) => {
                                                        const items = formikProps.values.items.map(item => {
                                                            if (enterFullPriceEnabled) {
                                                                item.fullPrice = round(item.fullPrice * itemsConversionRate)
                                                                item.salePrice = round(
                                                                    round(
                                                                        round(item.fullPrice / item.quantity / (1 - item.discount / 100))
                                                                    ) /
                                                                        (1 + item.vat / 100)
                                                                )
                                                            } else {
                                                                item.salePrice = round(item.salePrice * itemsConversionRate)
                                                                item.fullPrice = round(
                                                                    round(
                                                                        round(round(item.salePrice * item.quantity) * (1 + item.vat / 100))
                                                                    ) *
                                                                        (1 - item.discount / 100)
                                                                )
                                                            }

                                                            return item
                                                        })

                                                        formikProps.setFieldValue('details.documentCurrencyRate', newDocumentCurrencyRate)
                                                        formikProps.setFieldValue('details.clientCurrencyRate', newClientCurrencyRate)
                                                        formikProps.setFieldValue('details.cultureName', cultureName)
                                                        formikProps.setFieldValue('details.documentCurrency', documentCurrency)
                                                        formikProps.setFieldValue('details.clientCurrency', clientCurrency)
                                                        formikProps.setFieldValue(
                                                            'details.shouldUpdateVersionInformation',
                                                            shouldUpdateVersionInformation
                                                        )
                                                        formikProps.setFieldValue('items', items)

                                                        setIsCustomRateOverlayVisible(false)

                                                        return formikProps.submitForm()
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>

                                    {props.beforeTableComponent &&
                                        React.createElement(props.beforeTableComponent, {
                                            document: formikProps.values,
                                            formik: formikProps,
                                            printSettings: printSettings,
                                            settingsComponent: (
                                                <span
                                                    ref={setReferenceElement}
                                                    onClick={() => setIsCustomRateOverlayVisible(!isCustomRateOverlayVisible)}>
                                                    <span className="icon icon__settings"></span>
                                                </span>
                                            ),
                                        })}

                                    <div className="table">
                                        <ul className="table__header">
                                            <li className="table__header__item__order">{t('Invoice.Table.Header.rowNumber')}</li>
                                            <li className="table__header__item__name">{t('Invoice.Table.Header.name')}</li>
                                            <li className="table__header__item__quantity">{t('Invoice.Table.Header.amount')}</li>
                                            <li className="table__header__item__price">{t('Invoice.Table.Header.price')}</li>
                                            <li className="table__header__item__discount">{t('Invoice.Table.Header.discount')}</li>
                                            <li className="table__header__item__total">{t('Invoice.Table.Header.total')}</li>
                                            <li className="table__header__item__menu"></li>
                                        </ul>
                                        <FieldArray name="items">
                                            {helper => {
                                                return formikProps.values.items
                                                    .sort((a, b) => a.index - b.index)
                                                    .map((item, index) => {
                                                        return (
                                                            <Field
                                                                name={`items.${index}`}
                                                                enterFullPriceEnabled={enterFullPriceEnabled}
                                                                defaultVat={props.defaultVat}
                                                                canChangeVat={props.canChangeVat}
                                                                editPurchasePrice={props.editPurchasePrice}
                                                                isAccountingVat={props.isAccountingVat}
                                                                component={DocumentItemRow}
                                                                showProductDimensionsInfo={props.showProductDimensionsInfo}
                                                                units={units}
                                                                canSubmit={canSubmit}
                                                                printSettings={printSettings}
                                                                removeRow={() => {
                                                                    const newItems = formikProps.values.items
                                                                        .filter((x, i) => i != index)
                                                                        .map((item, index) => {
                                                                            return { ...item, index: index + 1 }
                                                                        })

                                                                    formikProps.setFieldValue('items', newItems)

                                                                    formikProps.submitForm()
                                                                }}
                                                            />
                                                        )
                                                    })
                                            }}
                                        </FieldArray>
                                    </div>

                                    {canSubmit && (
                                        <div className="table__bottom-controls  table__controls--mobile-show">
                                            <div className="table__section">
                                                <ButtonWithOverlay
                                                    showOverlay={addNewItemOverlayVisible}
                                                    setOverlay={setAddNewItemOverlayVisible}
                                                    icon={<span className="icon icon__plus"></span>}
                                                    text={<span className="button-text">{t('Invoice.Table.buttonNew')}</span>}
                                                    globalOverlayOnMobile>
                                                    <DocumentItemForm
                                                        canSubmit={canSubmit}
                                                        units={units}
                                                        closeForm={() => setAddNewItemOverlayVisible(false)}
                                                        document={formikProps.values}
                                                        enterFullPriceEnabled={enterFullPriceEnabled}
                                                        defaultVat={props.defaultVat}
                                                        canChangeVat={props.canChangeVat}
                                                        editPurchasePrice={props.editPurchasePrice}
                                                        isAccountingVat={props.isAccountingVat}
                                                        showProductDimensionsInfo={props.showProductDimensionsInfo}
                                                        onSubmit={async newItem => {
                                                            const items = [...formikProps.values.items, newItem]

                                                            formikProps.setFieldValue('items', items)

                                                            setAddNewItemOverlayVisible(false)

                                                            const res: any = await formikProps.submitForm()

                                                            return res
                                                        }}
                                                        printSettings={printSettings}
                                                        initialValues={null}
                                                    />
                                                </ButtonWithOverlay>
                                            </div>
                                            <div className="table__section table__section--no-bottom-border">
                                                <ButtonWithOverlay
                                                    showOverlay={isAddItemFromPriceListVisible}
                                                    setOverlay={setIsAddItemFromPriceListVisible}
                                                    icon={<span className="icon icon__plus"></span>}
                                                    text={<span className="button-text">{t('Invoice.Table.buttonNewFromPricelist')}</span>}
                                                    globalOverlayOnMobile>
                                                    <FieldArray name="items">
                                                        {helper => {
                                                            return (
                                                                <PriceListModalForm
                                                                    priceListPromise={(groupId, searchString) => {
                                                                        return PriceListApi.getPriceList(
                                                                            1,
                                                                            100,
                                                                            groupId,
                                                                            searchString
                                                                        ).then(result => {
                                                                            return result.data
                                                                        })
                                                                    }}
                                                                    currency={currency}
                                                                    onCloseButtonClicked={() => setIsAddItemFromPriceListVisible(false)}
                                                                    selectedProductComponent={SelectedProductComponent}
                                                                />
                                                            )
                                                        }}
                                                    </FieldArray>
                                                </ButtonWithOverlay>
                                            </div>
                                        </div>
                                    )}

                                    <div className="table__price-container">
                                        {props.isAccountingVat && (
                                            <div className="table__price-row">
                                                <span className="table__price-label">{printSettings.columnTotalPrice}</span>
                                                <span className="table__price-amount">
                                                    <span className="price-amount" style={{ width: '120px' }}>
                                                        <CurrencyLabel
                                                            amount={priceGroups.totalItemsPrice}
                                                            currency={formikProps.values.details.documentCurrency}
                                                        />
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                        {props.isAccountingVat &&
                                            Object.entries(priceGroups.vatGroups).map(([vat, value]) => {
                                                return (
                                                    <div className="table__price-row">
                                                        <span className="table__price-label">
                                                            {printSettings.columnTax} {vat}%
                                                        </span>
                                                        <span className="table__price-amount">
                                                            <span className="price-amount" style={{ width: '120px' }}>
                                                                <CurrencyLabel
                                                                    amount={value}
                                                                    currency={formikProps.values.details.documentCurrency}
                                                                />
                                                            </span>
                                                        </span>
                                                    </div>
                                                )
                                            })}

                                        <div className="table__price-row">
                                            <span className="table__price-label table__price-label--big">
                                                {printSettings.columnTotalPriceIncludingTax}
                                            </span>
                                            <span className="table__price-amount table__price-amount--big">
                                                <span className="price-amount" style={{ width: '125px' }}>
                                                    <CurrencyLabel
                                                        amount={priceGroups.totalForCharge}
                                                        currency={formikProps.values.details.documentCurrency}
                                                    />
                                                    <br />
                                                    {(formikProps.values.details.clientCurrencyRate !== 1 ||
                                                        formikProps.values.details.documentCurrencyRate !== 1) &&
                                                        formikProps.values.details.clientCurrency !==
                                                            formikProps.values.details.documentCurrency && (
                                                            <span className="subscript">
                                                                (
                                                                <CurrencyLabel
                                                                    amount={
                                                                        (priceGroups.totalForCharge *
                                                                            formikProps.values.details.clientCurrencyRate) /
                                                                        formikProps.values.details.documentCurrencyRate
                                                                    }
                                                                    currency={formikProps.values.details.clientCurrency}
                                                                />
                                                                )
                                                            </span>
                                                        )}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    {React.createElement(props.footerComponent, { document: formikProps.values, formik: formikProps })}

                                    <div className="signature__container">
                                        <div className="signature__invoice-code" id="barcode"></div>

                                        <div className="signature__person">
                                            <span className="signature__line">{printSettings.personResponsibleTitle}</span>
                                            <span className="signature__line">
                                                {printSettings.personResponsible ? printSettings.personResponsible : currentUserFullName}
                                            </span>
                                            <div className="signature">
                                                <ImageWithAuthorization
                                                    reloadDepdendency={document.details.versionInformation}
                                                    key={resourcesKey}
                                                    onClick={() => setVisibleModalFunc(VisibleModalType.Signature)}
                                                    className="placeholder__content"
                                                    src={props.signatureSrc}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="placeholder placeholder--footer">
                                        <div className="placeholder__content">
                                            <div className="placeholder__content__text">{t('Invoice.Header.pageFooterChange')}</div>
                                            <ImageWithAuthorization
                                                reloadDepdendency={document.details.versionInformation}
                                                key={resourcesKey}
                                                className="placeholder__content"
                                                onClick={() => setVisibleModalFunc(VisibleModalType.Footer)}
                                                src={props.footerSrc}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <Modal isOpen={visibleModal == VisibleModalType.Header}>
                                    <FileUploadModal
                                        titleKey={'Settings.PrintSettings.Table.Pictures.NewHeader.pageTitleEdit'}
                                        footerKey={'Settings.PrintSettings.Table.Pictures.NewHeader.infoNote'}
                                        onSubmit={file => uploadResource(file, formikProps, VisibleModalType.Header)}
                                        extraFooterContentKey={'Settings.PrintSettings.Table.Pictures.New.extraInfoInInvoice'}
                                        close={() => setVisibleModal(VisibleModalType.None)}
                                    />
                                </Modal>

                                <Modal isOpen={visibleModal == VisibleModalType.Signature}>
                                    <FileUploadModal
                                        titleKey={'Settings.PrintSettings.Table.Pictures.NewSignature.pageTitleEdit'}
                                        footerKey={'Settings.PrintSettings.Table.Pictures.NewSignature.signatureInfo'}
                                        onSubmit={file => uploadResource(file, formikProps, VisibleModalType.Signature)}
                                        extraFooterContentKey={'Settings.PrintSettings.Table.Pictures.New.extraInfoInInvoice'}
                                        close={() => setVisibleModal(VisibleModalType.None)}
                                    />
                                </Modal>

                                <Modal isOpen={visibleModal == VisibleModalType.Footer}>
                                    <FileUploadModal
                                        titleKey={'Settings.PrintSettings.Table.Pictures.NewFooter.pageTitleEdit'}
                                        footerKey={'Settings.PrintSettings.Table.Pictures.NewHeader.infoNote'}
                                        onSubmit={file => uploadResource(file, formikProps, VisibleModalType.Footer)}
                                        extraFooterContentKey={'Settings.PrintSettings.Table.Pictures.New.extraInfoInInvoice'}
                                        close={() => setVisibleModal(VisibleModalType.None)}
                                    />
                                </Modal>
                            </DocumentContext.Provider>
                        )
                    }}
                </Formik>
            </main>
        </>
    )
}
