import React from 'react'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import { useRootStoreSelector } from '../../shared/store/hooks/useRootStoreSelector'

export const DebugInformation = () => {
    const debugInfo = useRootStoreSelector(r => ({
        authenticated: r.user.authenticated,
        email: r.user.Email
    }))

    if (!debugInfo.authenticated)
        return null;

    return <span>({debugInfo.email})</span>
}

export const Footer = () => {
    const date = new Date();
    const { t } = useOfferionTranslation()

    return <footer className="footer">
        <div className="footer__content">
            <span className="footer__copyright">{t('Footer.copyright', { currentYear: date.getFullYear() })} <DebugInformation /></span>
            <div className="footer__nav">
                <ul>
                    <li dangerouslySetInnerHTML={{ __html: t('Footer.privacyPolicy') }}>
                    </li>
                    <li dangerouslySetInnerHTML={{ __html: t('Footer.help') }}>
                    </li>
                    <li dangerouslySetInnerHTML={{ __html: t('Footer.termsOfService') }}>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
}

