import { DocumentItemModel } from "../models";

export function calculateUpdatedPrices(enterFullPriceEnabled: boolean, item: DocumentItemModel) {

    if (enterFullPriceEnabled) {
        const withoutVat = item.fullPrice / item.quantity / (1 + item.vat / 100);

        return {
            discountedPriceWithoutVat: item.fullPrice / (1 + item.vat / 100),
            differencePercentage: item.purchasePrice
                ? ((withoutVat / (item.purchasePrice)) - 1) * 100
                : 0,
            discountedPriceWithVat: 0,
            discountedPrice: 0
        }
    }
    else {

        const discountedPriceWithVat = item.salePrice * (1 + item.vat / 100) * (1 - item.discount / 100);
        const discountedPrice = item.salePrice * (1 - item.discount / 100);

        return {
            discountedPrice: discountedPrice,
            discountedPriceWithVat: discountedPriceWithVat,
            differencePercentage: item.purchasePrice != 0
                ? (discountedPrice - item.purchasePrice) / item.purchasePrice * 100
                : 0,
            discountedPriceWithoutVat: 0,
        }
    }
}

export default calculateUpdatedPrices;
