import React from 'react'
import { useIsMobile } from '../store/hooks/useIsMobile'
import FocusTrap from 'focus-trap-react'

type Props = {
    icon?: React.ReactNode
    text?: React.ReactNode
    children: React.ReactNode | React.ReactNodeArray
    showOverlay: boolean
    setOverlay?: (showOverlay: boolean) => void
    onClick?: () => void
    placement?: 'bottom-right' | 'bottom-left'
    // if the mobile overlay is a global one, we don't want certain styles to apply,
    // so if the overlay in children is <Overlay />, set this flag to true
    globalOverlayOnMobile?: boolean
}

export function ButtonWithOverlay({
    icon = null,
    text = null,
    children,
    showOverlay,
    placement = 'bottom-right',
    setOverlay,
    onClick,
    globalOverlayOnMobile = false,
}: Props) {
    const isMobile = useIsMobile()
    // Sometimes we want to show the overlay but not show the active button styles for buttons that trigger the overlay
    // that's usually the case for global overlays. That's what the check below does.
    // this should only be true for Overlay which are NOT global (rendered inside of a Portal)
    // this is a bit convoluted
    const showActiveButtonClasses =
        showOverlay &&
        // if the overlay is never global we can always show active button styles
        (globalOverlayOnMobile === false ||
            // if the overlay is global on mobile and it's not a mobile screen currently we can show the active styles
            (globalOverlayOnMobile === true && isMobile === false))

    const containerClasses = `button-container  overlay__item overlay__item--${placement} ${
        showOverlay ? 'overlay__item--show-overlay' : ''
    } ${showActiveButtonClasses ? 'overlay__item--active' : ''}`
    const buttonClasses = `button button--gray button--plus overlay__button ${showActiveButtonClasses ? 'overlay__button--active' : ''}`

    return (
        <div className={containerClasses}>
            <button
                className={buttonClasses}
                onClick={() => {
                    setOverlay && setOverlay(!showOverlay)
                    onClick && onClick()
                }}
                type="button">
                {icon}
            </button>

            {text}

            {showOverlay && <div>{children}</div>}
        </div>
    )
}
