import { UserState } from '../../models'

export const defaultState = {
    CompanyType: '',
    Email: '',
    FirstName: '',
    LastName: '',
    Role: '',
    UserId: '',
    access_token: '',
    UiLanguage: '',
    authenticated: false,
}

interface UserAuthenticatedAction {
    type: 'USER_AUTHENTICATED'
    user: UserState
}

interface UserLoggedOutAction {
    type: 'USER_LOGGED_OUT'
}

interface UpdateLanguageAction {
    type: 'UPDATE_LANGUAGE',
    language: string
}

type KnownAction = UserAuthenticatedAction | UserLoggedOutAction | UpdateLanguageAction

export function UserReducer(state = defaultState, action: KnownAction) {

    switch (action.type) {
        case 'USER_AUTHENTICATED':
            return {
                ...action.user,
            }

        case 'USER_LOGGED_OUT':
            return defaultState;

        case 'UPDATE_LANGUAGE':
            return { ...state, UiLanguage: action.language };

        default:
            return state
    }
}
