import React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import useOfferionTranslation from '../store/hooks/useOfferionTranslation'

type Props = {
    loadOptions?: (val: string) => Promise<any>
    placeholder?: string
    createNewLabel?: string
    onChange?: (newValue: any) => void
    onCreateOption?: (inputValue: string) => void
}

export default function AutocompleteInput({ loadOptions, placeholder = 'Select...', onChange, onCreateOption, createNewLabel }: Props) {
    const { t } = useOfferionTranslation();

    return (
        <AsyncCreatableSelect
            onChange={onChange}
            onCreateOption={onCreateOption}
            placeholder={placeholder}
            cacheOptions
            defaultOptions
            autoFocus
            loadingMessage={() => t('Allaround.Message.loading')}
            loadOptions={loadOptions}
            className="react-select-container"
            classNamePrefix="react-select"
            formatCreateLabel={createNewLabel ? inputValue => <div className='react-select__create-new-record'>{createNewLabel} "{inputValue}"</div> : undefined}
        />
    )
}
