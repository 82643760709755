import React, { useEffect, useState } from 'react'
import { PriceListModalForm } from '../../../shared/documents/details/elements/PriceListModalForm'
import { Currency } from '../../../shared/models';
import { Formik, Field, Form } from 'formik';
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage';
import { CurrencyCode } from '../../../shared/ui/currency';
import { PriceListItem } from '../../../services/PriceListApi';
import { ClientDiscountsApi } from '../../../services/ClientDiscountsApi';
import { useGridActions } from '../../../shared/grid/GridContextProvider';
import * as Yup from 'yup';
import { toastSuccess, toastWarning } from '../../../shared/toastr';
import { CompanySettingsApi } from '../../../services/CompanySettingsApi';
import { CurrencyField } from '../../../shared/forms/CurrencyField';
import { NumberField } from '../../../shared/forms/NumberField';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';

export const AddItemFromPricelistButton = ({ clientId }: { clientId: number | string }) => {
    const { t } = useOfferionTranslation();
    const gridActions = useGridActions();
    const [isOverlayHidden, setIsOverlayHidden] = useState(true);
    const [currency, setCurrency] = useState<Currency | null>(null);

    useEffect(() => {
        CompanySettingsApi.getDefaultCurrency().then(setCurrency);
    }, [])

    if (currency == null)
        return null;

    const onSubmit = ({ salePrice, discountPercent, selectedItem }: { salePrice: string, discountPercent: string, selectedItem: PriceListItem }) => {

        if (selectedItem.productId) {
            ClientDiscountsApi.createProductDiscount({
                clientId: clientId,
                productId: selectedItem.productId,
                salePrice: Number.parseFloat(salePrice),
                discountPercent: Number.parseFloat(discountPercent)
            })
                .then(() => toastSuccess('Clients.AgreedPrices.AddProductOrGroup.Message.successAdd'))
                .then(gridActions.refresh)
                .then(() => setIsOverlayHidden(true))
        }
        else if (selectedItem.groupId) {
            ClientDiscountsApi.createGroupDiscount({
                clientId: clientId,
                groupId: selectedItem.groupId,
                discountPercent: Number.parseFloat(discountPercent)
            })
                .then(() => toastSuccess('Clients.AgreedPrices.AddProductOrGroup.Message.successAdd'))
                .then(gridActions.refresh)
                .then(() => setIsOverlayHidden(true))
        }
    }

    const onAddButtonClick = () => {
        ClientDiscountsApi.getPriceList(1, 1, clientId, null, null).then(result => {

            if (result.count > 0)
                setIsOverlayHidden(false)
            else
                toastWarning('Clients.AgreedPrices.Header.noProductsMessage');

        })
    }

    return isOverlayHidden ? <div className="button-outer">
        <button className="button button--white button--padded" onClick={onAddButtonClick}>
            <span className="icon icon__item"></span>
            <span className="icon icon__plus"></span>
        </button>
        <span className="button-text table__button-text mod-margin-left-4">{t('Clients.AgreedPrices.Header.selectFromPricelist')}</span>
    </div>
        : <div className="button-outer small-hide overlay__item overlay__item--bottom-right overlay__item--active overlay__item--show-overlay">
            <button className="button button--gray button--plus overlay__button overlay__button--active">
                <span className="icon icon__item"></span>
                <span className="icon icon__plus"></span>
            </button>

            <span className="button-text table__button-text mod-margin-left-4">{t('Clients.AgreedPrices.Header.selectFromPricelist')}</span>

            <PriceListModalForm
                allowGroupSelection={true}
                currency={currency}
                priceListPromise={(groupId, searchString) => {
                    return ClientDiscountsApi.getPriceList(1, 100, clientId, groupId, searchString).then(result => {

                        return result.data
                    });
                }}
                onCloseButtonClicked={() => setIsOverlayHidden(true)}
                selectedProductComponent={({ selectedProduct }) => {

                    const schema = Yup.object().shape({
                        discountPercent: Yup.number()
                            .nullable()
                            .moreThan(0, t('Allaround.Validation.discountNegative'))
                            .lessThan(100, t('Allaround.Validation.discountMoreThan100'))
                            .when([], (schema: Yup.NumberSchema) => {

                                if (selectedProduct.groupId)
                                    return schema.required(t("Allaround.Validation.required", { fieldName: t("Clients.AgreedPrices.AddProductOrGroup.discount") }));

                                return schema.test("T2", t('Clients.AgreedPrices.AddProductOrGroup.priceValidation', { discount: t("Clients.AgreedPrices.AddProductOrGroup.discount"), agreedSellPrice: t("Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice") }), function (discountPecent) {
                                    const salePrice = this.parent.salePrice;

                                    return (discountPecent == undefined && salePrice != undefined) || (discountPecent != undefined && salePrice == undefined);
                                })

                            }),
                        salePrice: Yup.number()
                            .nullable()
                            .moreThan(0, t('Allaround.Validation.positiveNumber', { fieldName: t("Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice") }))
                            .when([], (schema: Yup.NumberSchema) => {

                                if (selectedProduct.groupId)
                                    return schema;

                                return schema.test("T2", t('Clients.AgreedPrices.AddProductOrGroup.priceValidation', { discount: t("Clients.AgreedPrices.AddProductOrGroup.discount"), agreedSellPrice: t("Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice") }), function (salePrice) {
                                    const discountPecent = this.parent.discountPercent;

                                    return (discountPecent == undefined && salePrice != undefined) || (discountPecent != undefined && salePrice == undefined);
                                })
                            })
                    });

                    return <Formik validationSchema={schema} initialValues={{ discountPercent: "", salePrice: "" }} onSubmit={(values) => onSubmit({ ...values, selectedItem: selectedProduct })}>
                        <Form noValidate={true}>
                            <div className="info__container">
                                <div className="info__row">
                                    <label className="info__row-name" htmlFor="overlayDiscountInput">{t('Clients.AgreedPrices.AddProductOrGroup.discount')}</label>

                                    <div className="info__row__inner-half">
                                        <Field name="discountPercent"
                                            component={NumberField}
                                            placeholder={t('Clients.AgreedPrices.AddProductOrGroup.discountPlaceholder')} />
                                        <span className="text-color mod-margin-left-4" >%</span>
                                    </div>
                                    <div className="info__row__inner-half">
                                        <OfferionErrorMessage name="discountPercent" />
                                    </div>
                                </div>

                                {selectedProduct.productId ? <div className="info__row">
                                    <label className="info__row-name">{t('Clients.AgreedPrices.AddProductOrGroup.agreedSellPrice')}</label>

                                    <div className="info__row__inner-half">
                                        <Field name="salePrice"
                                            component={CurrencyField}
                                            placeholder={t('Clients.AgreedPrices.AddProductOrGroup.agreedSellPricePlaceholder')} />
                                        <span className="text-color mod-margin-left-4"><CurrencyCode currency={currency} /></span>
                                    </div>
                                    <div className="info__row__inner-half">
                                        <OfferionErrorMessage name="salePrice" />
                                    </div>
                                </div> : null}
                            </div>

                            <div className="info__container">
                                <div className="info__row">
                                    <div className="button-group">
                                        <button className="button button--gray button--padded" type="submit">
                                            <span className="icon icon__check-green"></span>
                                            <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                                        </button>
                                        <button className="button button--gray button--padded" onClick={() => setIsOverlayHidden(true)}>
                                            <span className="icon icon--small icon__cross-red"></span>
                                            <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </Formik>
                }} />

        </div>
}