import axios from 'axios';
import { ApiClient } from '../shared/api/api-client'

export interface RegistrationData {
    companyType: string;
    companyName: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
}

export class AccountApi {
    public static startResetPasswordProcess(email: string) {
        return ApiClient.post('api/account/StartResetPasswordProcess', {
            email
        })
    }

    public static resetPassword(token: string, userId: string | number, password: string) {
        return ApiClient.post('api/account/ResetPassword', {
            token, userId, password
        })
    }

    public static register(data: RegistrationData) {

        const instance = axios.create({
            baseURL: process.env.REACT_APP_SERVER_LOCATION
        })

        return instance.post<RegistrationData>(
            "api/account/Register",
            data
        ).then(r => r.data);
    }
}
