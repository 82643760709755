import React, { useEffect, useState, useCallback, useRef, ReactText } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import {
    EInvoiceRequestModel,
    InvoiceApi,
    InvoiceConfigurationModel,
    ReceiptLogModel,
    RequestEInvoiceResult,
} from '../../services/InvoiceApi'
import { ActionMenuItem, DocumentDetailsContainer } from '../../shared/documents/details/DocumentDetailsContainer'
import {
    InvoiceDocumentModel,
    CompanyPrintSettingsModel,
    ConcreteTenderDocumentModel,
    TenderStatus,
    TemplateTenderDocumentModel,
    PaymentMethod,
    PriceCalculationMode,
    DocumentModel,
    InvoiceDetails,
    ReceiptStatus,
    ReceiptStatusHistory,
    VersionInformation,
} from '../../shared/models'
import { InlineInputEdit } from '../../shared/forms/InlineInputEdit'
import { FormikProps, Field, useFormik, useFormikContext } from 'formik'
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit'
import { ClientInput } from '../../shared/documents/details/elements/client-input/ClientInput'
import { ChangePricesAction } from '../../shared/documents/details/elements/ChangePricesAction'
import Modal from '../../shared/modal'
import * as Yup from 'yup'
import { SendViaEmailModal } from '../../shared/documents/SendViaEmailModal'
import { toastError, toastSuccess, toastWarning } from '../../shared/toastr'
import { ConcreteTenderApi } from '../../services/ConcreteTenderApi'
import { OfferionTooltip } from '../../shared/tooltip'
import { CurrencyLabel } from '../../shared/ui/currency'
import { EInvoiceModal, EInvoiceModalType } from './EInvoiceModal'
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage'
import { ConfirmFiscalizationModal } from './ConfirmFiscalizationModal'
import dayjs from 'dayjs'
import forCharge from '../../shared/utils/forCharge'
import { date, getKeyForReceiptStatus } from '../../shared/utils/formatters'
import {
    faCog,
    faEnvelope,
    faFileContract,
    faFileMedical,
    faHistory,
    faPrint,
    faStream,
    faTimes,
    faTrashAlt,
} from '@fortawesome/free-solid-svg-icons'
import { ChangeStatusModal } from '../../shared/documents/ChangeStatusModal'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import { openDocument } from '../../shared/utils/openDocument'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal'
import { QuickActionItem, QuickActionSubtitle, QuickActionTitle } from '../../shared/documents/QuickActions'

const InvoiceLogItem = ({ item, isFirst }: { item: ReceiptLogModel; isFirst: boolean }) => {
    const { t } = useOfferionTranslation()
    if (item.status == ReceiptStatusHistory.Draft && isFirst) {
        if (item.additionalInformation)
            return (
                <>
                    {t('Invoice.Log.invoiceCreated', { offerNumber: item.additionalInformation })} ({item.userFullName})
                </>
            )
        else
            return (
                <>
                    {t('Invoice.Log.documentCreated')} ({item.userFullName})
                </>
            )
    }

    if (item.status == ReceiptStatusHistory.Fiscalized)
        return (
            <>
                {t('Invoice.Log.fiscalized')} ({item.userFullName})
            </>
        )

    if (item.showOnlyStatusChange)
        return (
            <>
                {t('Invoice.Log.StatusChanged', { status: t(getKeyForReceiptStatus(item.status)) })} (
                {item.userFullName || t('Invoice.Log.System')})
            </>
        )

    if (item.status == ReceiptStatusHistory.Sent)
        return (
            <>
                {t('Invoice.Log.documentSent', { mailAddress: item.additionalInformation })} ({item.userFullName || t('Invoice.Log.System')}
                )
            </>
        )

    if (item.status == ReceiptStatusHistory.SentEInvoice)
        return (
            <>
                {t('Invoice.Log.documentSentAsEInvoice', { mailAddress: item.additionalInformation })} (
                {item.userFullName || t('Invoice.Log.System')})
            </>
        )

    return (
        <>
            {t('Invoice.Log.StatusChanged', { status: t(getKeyForReceiptStatus(item.status)) })} (
            {item.userFullName || t('Invoice.Log.System')})
        </>
    )
}

export const InvoiceLogModal = ({ close, invoiceId }: { close: () => void; invoiceId: ReactText | undefined }) => {
    const { t } = useOfferionTranslation()
    const [logs, setLogs] = useState<ReceiptLogModel[] | undefined>()

    useEffect(() => {
        if (!invoiceId) return

        InvoiceApi.getLogs(invoiceId).then(result => {
            setLogs(result)
        })
    }, [])

    if (logs == undefined) return null

    return (
        <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: 'default' }}>
            <div className="popup" style={{ maxWidth: '550px' }}>
                <h1 className="overlay__title">{t('Invoice.Log.pageTitle')}</h1>
                <div className="popup__close icono-cross" style={{ cursor: 'pointer' }} onClick={close}>
                    {t('Allaround.Popup.close')}
                </div>
                <ul style={{ padding: 0 }}>
                    {logs.map((l, i) => {
                        return (
                            <li>
                                {date(l.createdAt)} <InvoiceLogItem item={l} isFirst={i == 0} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export const InvoiceDetailsContainer = () => {
    const params = useParams<{ invoiceId: string | undefined }>()
    const history = useHistory()
    const location = useLocation()
    const { t } = useOfferionTranslation()

    const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false)
    const [eInvoiceModalType, setEInvoiceModalType] = useState<EInvoiceModalType | null>(null)
    const [configuration, setConfiguration] = useState<InvoiceConfigurationModel | undefined>()
    const [isLogModalVisible, setIsLogModalVisible] = useState(false)
    const [changeStatusModalVisible, setChangeStatusModalVisible] = useState(false)
    const [confirmFiscalizationPopupVisible, setConfirmFiscalizationPopupVisible] = useState(false)
    const offerionModal = useOfferionModal()

    useEffect(() => {
        InvoiceApi.getConfiguration(params.invoiceId).then(config => {
            if (!config.canCreateInvoices && !params.invoiceId) {
                const message = t('Allaround.Message.packageLimitReached', {
                    offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
                })

                toastWarning(message, true)

                history.replace('/offers')
            }

            if (config.showVatAccountingWarning) {
                if (config.isAccountingVat) {
                    toastWarning('Invoice.Header.VATIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.VATIsNowOn.warningMessage')
                }
            }

            if (config.showPriceCalculationWarning) {
                if (config.enterFullPriceEnabled) {
                    toastWarning('Invoice.Header.TotalPriceIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.TotalPriceIsNowOn.warningMessage')
                }
            }

            setConfiguration(config)
        })
    }, [params.invoiceId])

    const cancelInvoice = () => {
        offerionModal.open('Invoice.CancelInvoice.pageTitle', 'Invoice.CancelInvoice.questionYesNo', async () => {
            if (!params.invoiceId) return

            const newInvoiceId = await InvoiceApi.cancel(params.invoiceId)

            history.push(`/invoice/${newInvoiceId}`)
        })
    }

    const deleteInvoice = (document: InvoiceDocumentModel) => {
        offerionModal.open(
            'Invoice.DeleteInvoice.pageTitle',
            'Invoice.DeleteInvoice.questionYesNo',
            async () => {
                if (!params.invoiceId) return

                await InvoiceApi.delete(params.invoiceId)

                toastSuccess('Invoice.DeleteInvoice.Message.SuccessDelete')

                history.push(`/invoices`)
            },
            { invoiceNumber: document.details.number }
        )
    }

    const getDocumentFinal = (document: InvoiceDocumentModel, shouldFiscalize: boolean) => {
        if (!params.invoiceId) return Promise.resolve(() => {})

        return InvoiceApi.getDocument(params.invoiceId, shouldFiscalize).then((data: any) => {
            setConfirmFiscalizationPopupVisible(false)

            return () => openDocument(`${t('Invoice.Print.invoice')} ${document.details.number}.pdf`, data)
        })
    }

    const getDocumentStep1 = (document: InvoiceDocumentModel) => {
        const isFiscalizationActive = configuration && configuration.isFiscalizationActive
        const isAccountPaymentMethod = document.details.paymentMethod == PaymentMethod.Account

        if (document.fiscalization.isFiscalized || !isFiscalizationActive || isAccountPaymentMethod)
            getDocumentFinal(document, false).then(documentFactory => documentFactory())
        else setConfirmFiscalizationPopupVisible(true)
    }

    const emailInvoice = (info: {
        toEmail: string
        toEmailsCc: string
        emailBody: string
        includeCopyToSelf: boolean
        shouldFiscalize: boolean
    }) => {
        return InvoiceApi.sendInvoice(
            params.invoiceId,
            info.toEmail,
            info.toEmailsCc,
            info.emailBody,
            info.includeCopyToSelf,
            info.shouldFiscalize
        ).then(() => setSendEmailModalVisible(false))
    }

    const showEInvoiceModal = (doc: InvoiceDocumentModel) => {
        if (!configuration) return

        if (configuration.isCompanyMissingEInvoiceData) {
            toastWarning('Invoice.SendEInvoice.Message.noCompanyData')

            return
        }

        const isClientDataConfigured =
            doc.client &&
            doc.client.oib &&
            doc.client.email &&
            doc.client.country &&
            doc.client.city &&
            doc.client.address &&
            doc.client.postalCode

        if (!isClientDataConfigured) {
            toastWarning('Invoice.SendEInvoice.Message.noClientData')

            return
        }

        setEInvoiceModalType(configuration.isEInvoiceConfigured ? EInvoiceModalType.SendEinvoice : EInvoiceModalType.DownloadEInvoice)
    }

    const documentPromiseFactory = useCallback(
        (isReload: boolean) => {
            if (location.state) {
                return new Promise<InvoiceDocumentModel>(resolve => {
                    resolve(location.state as InvoiceDocumentModel)
                })
            }

            if (params.invoiceId)
                return InvoiceApi.get(params.invoiceId).then(invoice => {
                    if (isReload) return invoice

                    if (invoice.details.isCancelled) toastWarning('Invoice.Header.CancelledInvoice.warningMessage')
                    else if (invoice.fiscalization.isFiscalized) toastWarning('Invoice.Header.FiscalizedInvoice.warningMessage')

                    return invoice
                })
            else
                return InvoiceApi.getConfiguration(params.invoiceId).then(configuration => {
                    return {
                        client: null,
                        details: {
                            cultureName: null,
                            paymentMethod: configuration.newReceiptPaymentMethod,
                            status: ReceiptStatus.Draft,
                            id: 0,
                            number: configuration.nextReceiptNumber,
                            documentCurrency: configuration.newReceiptCurrency,
                            clientCurrency: configuration.newReceiptClientCurrency,
                            priceCalculationMode: configuration.enterFullPriceEnabled
                                ? PriceCalculationMode.FullPrice
                                : PriceCalculationMode.BasePrice,
                            documentCurrencyRate: configuration.documentCurrencyRate,
                            clientCurrencyRate: configuration.clientCurrencyRate,
                            note: configuration.newReceiptNote,
                            officialDate: new Date(),
                            paymentDateOffset: 15,
                            versionInformation: VersionInformation.Latest,
                        },
                        fiscalization: {
                            isFiscalized: false,
                            isInsideFiscalizationInterval: true,
                        },
                        items: [],
                    }
                })
        },
        [params.invoiceId]
    )

    const pageTitle = t(params.invoiceId ? 'Invoice.Header.pageTitleEditInvoice' : 'Invoice.Header.pageTitleNewInvoice')

    const upsertDocument = (document: InvoiceDocumentModel) => {
        return InvoiceApi.upsert(document).then(newDocument => {
            if (document.details.id == 0) {
                history.replace(`/invoice/${newDocument.details.id}`)
            }

            return newDocument
        })
    }

    const createOffer = (document: InvoiceDocumentModel) => {
        if (!configuration || !configuration.canCreateTenders) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
            })

            toastWarning(message, true)
        } else {
            ConcreteTenderApi.getSimpleTenderConfig().then(({ nextNumber }) => {
                const offer: ConcreteTenderDocumentModel = {
                    client: document.client ? { ...document.client, id: 0, hideOnListing: true, sourceClientId: null } : null,
                    items: document.items.map(i => {
                        return { ...i, id: 0 }
                    }),
                    details: {
                        ...document.details,
                        id: 0,
                        paymentNoteForReceipt: document.details.note,
                        number: nextNumber,
                        paymentNoteForTender: '',
                        status: TenderStatus.Draft,
                    },
                }

                ConcreteTenderApi.upsert(offer).then(result => {
                    history.push(`/offer/${result.details.id}`, result)
                })
            })
        }
    }

    const createTemplate = (document: InvoiceDocumentModel) => {
        const template: TemplateTenderDocumentModel = {
            client: document.client ? { ...document.client, id: 0, hideOnListing: true, sourceClientId: null } : null,
            items: document.items.map(i => {
                return { ...i, id: 0 }
            }),
            details: {
                ...document.details,
                id: 0,
                paymentNoteForReceipt: document.details.note,
                paymentNoteForTender: '',
                templateName: '',
            },
        }

        history.push('/template-offer/new-from-invoice', template)
    }

    const requestEInvoice = (document: InvoiceDocumentModel, data: EInvoiceRequestModel) => {
        return InvoiceApi.requestEInvoice(data).then(result => {
            if (result.status == RequestEInvoiceResult.Error) {
                toastError(result.data)

                return
            }

            if (eInvoiceModalType == EInvoiceModalType.DownloadEInvoice || data.downloadAfterSend) {
                openDocument(`eRačun ${document.details.number}.xml`, result.data, 'application/xml')
            } else {
                toastSuccess('Invoice.SendEInvoice.Message.success')
            }

            setEInvoiceModalType(null)
        })
    }

    const schema = Yup.object<InvoiceDocumentModel>().shape({
        details: Yup.object().shape({
            paymentDateOffset: Yup.number()
                .nullable()
                .moreThan(-1, t('Allaround.Validation.paymentDateInFuture', { fieldName: t('Invoice.Table.DateDue.title') })),
        }),
    })

    if (!configuration) return null

    const ActionComponent = ({
        document,
        actions: { reloadDocument },
        openSettingsModal,
    }: {
        document: InvoiceDocumentModel
        actions: { reloadDocument: () => Promise<void> }
        openSettingsModal: () => void
    }) => {
        const formik = useFormikContext<InvoiceDocumentModel>()
        const dayJsOfficialDate = dayjs(document.details.officialDate).add(2, 'day')
        const today = dayjs(new Date())
        const isInsideInterval = dayJsOfficialDate.isAfter(today)
        const canFiscalize =
            configuration.isFiscalizationActive &&
            !document.fiscalization.isFiscalized &&
            document.details.paymentMethod != PaymentMethod.Account &&
            isInsideInterval

        return (
            <>
                <ActionMenuItem
                    id="Invoice.Header.Menu.print"
                    icon={faPrint}
                    onClick={() => getDocumentStep1(document)}
                    labelKey="Invoice.Header.Menu.print"></ActionMenuItem>
                <Modal isOpen={confirmFiscalizationPopupVisible}>
                    <ConfirmFiscalizationModal
                        officialDate={document.details.officialDate}
                        close={() => setConfirmFiscalizationPopupVisible(false)}
                        onSubmit={shouldFiscalize =>
                            getDocumentFinal(document, shouldFiscalize).then(documentFactory => {
                                if (shouldFiscalize) reloadDocument().then(documentFactory)
                                else documentFactory()
                            })
                        }></ConfirmFiscalizationModal>
                </Modal>
                <ActionMenuItem
                    id="Invoice.Header.Menu.sendMail"
                    icon={faEnvelope}
                    onClick={() => setSendEmailModalVisible(true)}
                    labelKey="Invoice.Header.Menu.sendMail"></ActionMenuItem>

                <Modal isOpen={sendEmailModalVisible}>
                    <SendViaEmailModal
                        pageTitleKey="Invoice.Send.pageTitleInvoice"
                        subTitleKey="Invoice.Send.invoiceAttached"
                        defaultMailText={configuration.defaultMailText}
                        clientEmail={document.client ? document.client.email : ''}
                        documentDetails={() => (
                            <span className="invoice-info">
                                <ul>
                                    <li>{document.details.number}</li>
                                    <li>{document.client ? document.client.name : null}</li>
                                    <li>
                                        <CurrencyLabel
                                            amount={forCharge(configuration.enterFullPriceEnabled, document.items)}
                                            currency={document.details.documentCurrency}
                                        />
                                    </li>
                                    <li>{document.creator ? `${document.creator.firstName} ${document.creator.lastName}` : null}</li>
                                </ul>
                            </span>
                        )}
                        documentFooterRenderer={() => {
                            return canFiscalize ? (
                                <div className="info__row">
                                    <div style={{ float: 'left' }}>
                                        <Field id="show-in-print3" type="checkbox" className="checkbox" name="shouldFiscalize" />
                                        <label
                                            htmlFor="show-in-print3"
                                            className="button button--gray button--padded checkbox-button button--print">
                                            <span className="checkbox-button__icon"></span>
                                        </label>
                                    </div>
                                    <label className="info__row-name" htmlFor="" style={{ float: 'left' }}>
                                        {t('Invoice.Send.fiscalizeYesNo')}
                                    </label>
                                </div>
                            ) : null
                        }}
                        close={() => setSendEmailModalVisible(false)}
                        onSubmit={values =>
                            emailInvoice({ ...values, shouldFiscalize: canFiscalize && values.shouldFiscalize }).then(() => {
                                if (values.shouldFiscalize) reloadDocument()
                            })
                        }
                    />
                </Modal>
                <ActionMenuItem
                    id="Invoice.Header.Menu.invoiceSettings"
                    icon={faCog}
                    onClick={openSettingsModal}
                    allowActionIfNotSaved={true}
                    allowActionWithoutAnyItems={true}
                    labelKey="Invoice.Header.Menu.invoiceSettings"></ActionMenuItem>
                {!document.details.cancelledFromReceiptId && !document.details.isCancelled && (
                    <ActionMenuItem icon={faTimes} onClick={cancelInvoice} labelKey="Invoice.Header.Menu.cancelInvoice"></ActionMenuItem>
                )}
                <ActionMenuItem
                    id="Invoice.Header.Menu.sendEInvoice"
                    icon={faFileContract}
                    onClick={() => showEInvoiceModal(document)}
                    labelKey="Invoice.Header.Menu.sendEInvoice"></ActionMenuItem>
                {eInvoiceModalType != null && (
                    <Modal isOpen={eInvoiceModalType != null}>
                        <EInvoiceModal
                            details={{
                                forCharge: forCharge(configuration.enterFullPriceEnabled, document.items),
                                clientEmail: document.client ? document.client.email : '',
                                creator: document.creator ? `${document.creator.firstName} ${document.creator.lastName}` : '',
                                clientName: document.client ? document.client.name : '',
                                currency: document.details.documentCurrency,
                                documentNumber: document.details.number,
                                invoiceId: document.details.id,
                            }}
                            documentFooterRenderer={() => {
                                return canFiscalize ? (
                                    <div className="info__row">
                                        <div style={{ float: 'left' }}>
                                            <Field id="show-in-print3" type="checkbox" className="checkbox" name="shouldFiscalize" />
                                            <label
                                                htmlFor="show-in-print3"
                                                className="button button--gray button--padded checkbox-button button--print">
                                                <span className="checkbox-button__icon"></span>
                                            </label>
                                        </div>
                                        <label className="info__row-name" htmlFor="" style={{ float: 'left' }}>
                                            {t('Invoice.SendEInvoice.fiscalizeYesNo')}
                                        </label>
                                    </div>
                                ) : null
                            }}
                            close={() => setEInvoiceModalType(null)}
                            onSubmit={res => requestEInvoice(document, { ...res, shouldFiscalize: canFiscalize && res.shouldFiscalize })}
                            type={eInvoiceModalType}></EInvoiceModal>
                    </Modal>
                )}
                <ActionMenuItem
                    id="Invoice.Side.changeStatus"
                    icon={faStream}
                    onClick={() => setChangeStatusModalVisible(true)}
                    labelKey="Invoice.Side.changeStatus"></ActionMenuItem>
                <Modal isOpen={changeStatusModalVisible}>
                    <ChangeStatusModal
                        mapping="ReceiptStatus"
                        currentStatus={document.details.status}
                        paymentDate={document.details.paymentDate}
                        onSubmit={async (newStatus, newPaymentDate) => {
                            formik.setValues({
                                ...document,
                                details: {
                                    ...document.details,
                                    status: newStatus,
                                    paymentDate: newPaymentDate,
                                },
                            })

                            await formik.submitForm()

                            setChangeStatusModalVisible(false)
                        }}
                        pageTitleKey="Invoice.ChangeStatus.pageTitle"
                        close={() => setChangeStatusModalVisible(false)}
                        options={[
                            { value: 0, translationKey: 'Allaround.Status.draft' },
                            { value: 1, translationKey: 'Allaround.Status.invoiceSent' },
                            { value: 3, translationKey: 'Allaround.Status.invoicePaid' },
                        ]}
                        showPaymentDate={status => status == ReceiptStatus.Paid}
                    />
                </Modal>

                {!document.details.cancelledFromReceiptId && (
                    <ActionMenuItem
                        id="Invoice.Header.Menu.createOffer"
                        icon={faFileMedical}
                        onClick={() => createOffer(document)}
                        labelKey="Invoice.Header.Menu.createOffer"></ActionMenuItem>
                )}
                {!document.details.cancelledFromReceiptId && (
                    <ActionMenuItem
                        id="Invoice.Header.Menu.makeTemplate"
                        icon={faFileMedical}
                        onClick={() => createTemplate(document)}
                        labelKey="Invoice.Header.Menu.makeTemplate"></ActionMenuItem>
                )}
                <ChangePricesAction enterFullPriceEnabled={configuration.enterFullPriceEnabled}></ChangePricesAction>
                <ActionMenuItem
                    id="Invoice.Header.Menu.log"
                    icon={faHistory}
                    onClick={() => setIsLogModalVisible(true)}
                    allowActionWithoutAnyItems={true}
                    labelKey="Invoice.Header.Menu.log"></ActionMenuItem>
                <Modal isOpen={isLogModalVisible}>
                    <InvoiceLogModal invoiceId={params.invoiceId} close={() => setIsLogModalVisible(false)} />
                </Modal>
                {!document.details.isCancelled && !document.fiscalization.isFiscalized && (
                    <ActionMenuItem
                        id="Invoice.Header.Menu.delete"
                        icon={faTrashAlt}
                        allowActionWithoutAnyItems={true}
                        onClick={() => deleteInvoice(document)}
                        labelKey="Invoice.Header.Menu.delete"></ActionMenuItem>
                )}
            </>
        )
    }

    const QuickActionsComponent = ({ document }: { document: InvoiceDocumentModel }) => {
        return (
            <>
                <QuickActionTitle id="Invoice.Side.actionsTitle" text={t('Invoice.Side.actionsTitle')} />
                <QuickActionSubtitle text={t('Invoice.Side.actionsDescription')} />
                <QuickActionItem onClick={() => getDocumentStep1(document)} icon={faPrint} text={t('Invoice.Side.print')} id="Invoice.Side.print" />
                <QuickActionItem onClick={() => setSendEmailModalVisible(true)} icon={faEnvelope} text={t('Invoice.Side.sendMail')} id="Invoice.Side.sendMail" />
                {!document.details.cancelledFromReceiptId && !document.details.isCancelled && (
                    <QuickActionItem onClick={cancelInvoice} icon={faTimes} text={t('Invoice.Side.cancelInvoice')} id="Invoice.Side.cancelInvoice" />
                )}
            </>
        )
    }

    const FooterComponent = ({ document, formik }: { document: InvoiceDocumentModel; formik: FormikProps<InvoiceDocumentModel> }) => {
        return (
            <div className="notice__container notice__container--with-margin">
                <h2 className="notice__title">{t('Invoice.Table.Footer.remark')}</h2>
                <div className="notice__content remark-placeholder">
                    <InlineInputEdit
                        currentValue={document.details.note}
                        multiline
                        as="textarea"
                        preventSubmitOnEnter={true}
                        className="ng-inline-edit__text"
                        placeholderKey="Invoice.Table.Footer.remarkPlaceholder"
                        onSave={({ newValue }) => {
                            formik.setFieldValue(`details.note`, newValue)
                            formik.submitForm()
                        }}
                    />
                </div>
            </div>
        )
    }

    const HeaderComponent = ({
        document,
        formik,
        rateComponent,
    }: {
        document: InvoiceDocumentModel
        formik: FormikProps<InvoiceDocumentModel>
        rateComponent: JSX.Element
    }) => {
        let dueDate: Date | undefined

        if (document.details.paymentDateOffset != null) {
            dueDate = dayjs(document.details.officialDate).add(document.details.paymentDateOffset, 'day').toDate()
        }

        return (
            <>
                <Field
                    shouldPreserveIdentity={false}
                    component={ClientInput}
                    name="client"
                    enterFullPriceEnabled={configuration.enterFullPriceEnabled}
                />

                <div className="info__row info__row--small">
                    <label className="info__row-name">{t('Invoice.Table.Date.title')}</label>

                    <div className="info__row-content">
                        <InlineDateEdit
                            showTimeInput
                            className="offerion-date"
                            currentValue={document.details.officialDate}
                            onSave={({ newValue }) => {
                                formik.setFieldValue('details.officialDate', newValue)
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
                <div className="info__row info__row--small">
                    <label className="info__row-name">{t('Invoice.Table.DateDue.title')}</label>

                    <div className="info__row-content">
                        <InlineDateEdit
                            placeholderKey="Unesite datum dospijeća"
                            className="offerion-date date-due"
                            currentValue={dueDate}
                            onSave={({ newValue }) => {
                                const dueDate = dayjs(newValue)
                                const paymentDateOffset = dueDate.diff(dayjs(document.details.officialDate), 'day')

                                formik.setFieldValue('details.paymentDateOffset', paymentDateOffset)

                                formik.submitForm()
                            }}
                        />
                        <OfferionErrorMessage name="details.paymentDateOffset" />
                    </div>
                </div>
                <div className="info__row info__row--small">
                    <label className="info__row-name">{t('Invoice.Table.Payment.title')}</label>

                    <div className="info__row-content">
                        <Field
                            as="select"
                            className="offerion-select"
                            name="details.paymentMethod"
                            onChange={(e: any) => {
                                formik.setFieldValue('details.paymentMethod', e.target.value)
                                formik.submitForm()
                            }}>
                            <option value="0">{t('Allaround.PaymentType.cash.title')}</option>
                            <option value="1">{t('Allaround.PaymentType.creditCard.title')}</option>
                            <option value="2">{t('Allaround.PaymentType.bankAccount.title')}</option>
                            <option value="3">{t('Allaround.PaymentType.check.title')}</option>
                            <option value="4">{t('Allaround.PaymentType.other.title')}</option>
                        </Field>
                    </div>
                </div>
                {rateComponent}
            </>
        )
    }

    const borderColorClassFactory = (doc: InvoiceDocumentModel) => {
        switch (doc.details.status) {
            case ReceiptStatus.Draft:
                return 'blue'
            case ReceiptStatus.Sent:
                return 'yellow'
            case ReceiptStatus.Due:
                return 'red'
            case ReceiptStatus.Paid:
                return 'green'
            case ReceiptStatus.Cancelled:
                return 'black'
        }
    }

    const BeforeTableComponent = ({
        document,
        formik,
        printSettings: printSettigs,
        settingsComponent,
    }: {
        document: InvoiceDocumentModel
        formik: FormikProps<InvoiceDocumentModel>
        printSettings: CompanyPrintSettingsModel
        settingsComponent: JSX.Element
    }) => {
        const borderColor = borderColorClassFactory(document)

        return (
            <div className={`table__box border-left-${borderColor}`}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="table__title table__title--inline">
                        <span className="table__title-separated">{printSettigs.invoiceTitle}</span>

                        <span className="table__title-separated">
                            <InlineInputEdit
                                currentValue={document.details.number}
                                placeholderKey="Invoice.Table.InvoiceNumber.invoiceNumberPlaceholder"
                                onSave={({ newValue }) => {
                                    formik.setFieldValue('details.number', newValue)
                                    formik.submitForm()
                                }}
                            />
                        </span>
                        <OfferionTooltip
                            placement="right"
                            titleKey="Invoice.Table.InvoiceNumber.infoTitle"
                            bodyKey="Invoice.Table.InvoiceNumber.infoText"
                        />
                    </h1>
                    <div>{settingsComponent}</div>
                </div>
            </div>
        )
    }

    return (
        <>
            <BreadcrumbsItem to={location.pathname}>{pageTitle}</BreadcrumbsItem>

            <DocumentDetailsContainer
                signatureSrc={
                    params.invoiceId ? `api/company/getInvoiceSignatureResource/${params.invoiceId}` : `api/company/getSignatureResource`
                }
                footerSrc={params.invoiceId ? `api/company/getInvoiceFooterResource/${params.invoiceId}` : `api/company/getFooterResource`}
                headerSrc={params.invoiceId ? `api/company/getInvoiceHeaderResource/${params.invoiceId}` : `api/company/getHeaderResource`}
                documentPromiseFactory={documentPromiseFactory}
                pageTitle={pageTitle}
                isFreeCompany={configuration.isFreeCompany}
                validationSchema={schema}
                editPurchasePrice={configuration.editPurchasePrice}
                canSubmit={document => !document.details.isCancelled && !document.fiscalization.isFiscalized}
                canChangeVat={configuration.canChangeVat}
                defaultVat={configuration.defaultVat}
                showProductDimensionsInfo={configuration.showProductDimensionsInfo}
                isAccountingVat={configuration.isAccountingVat}
                onSubmit={upsertDocument}
                actionButtonsComponent={ActionComponent}
                quickActionsMenuComponent={QuickActionsComponent}
                headerComponent={HeaderComponent}
                beforeTableComponent={BeforeTableComponent}
                footerComponent={FooterComponent}
                borderColorClass={borderColorClassFactory}
                currencyOverlayKey={'Invoice.Settings.invoiceCurrency'}
                customRateFormTitleKey={'Invoice.Settings.pageTitleInvoice'}
                successUpdateKey="Invoice.Save.Message.successUpdate"
                successCreateKey="Invoice.Save.Message.successCreate"
            />
        </>
    )
}
