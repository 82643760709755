import React, { useState, ReactText, useRef, useEffect } from 'react'

import { Grid } from '../../shared/grid/Grid'
import { GridMenuCell, GridMenuActionLink } from '../../shared/grid/GridMenu'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { GridActions } from '../../shared/grid/Types'
import { useLocation, useHistory } from 'react-router-dom'
import { PriceListApi, PriceListItem } from '../../services/PriceListApi'
import { GroupApi } from '../../services/GroupApi'
import { InlineCurrencyEdit } from '../../shared/forms/InlineCurrencyEdit'
import { Currency, ProductModel, GroupModel, CompanyType } from '../../shared/models'
import { InlineInputEdit } from '../../shared/forms/InlineInputEdit'
import { ProductApi } from '../../services/ProductApi'
import { GridFilterForm, GridFilterInput } from '../../shared/grid/GridFilter'
import { AddProductButton, ProductForm } from './AddProductButton'
import { AddGroupButton, GroupForm } from './AddGroupButton'
import { GridContextProvider, useGridActions } from '../../shared/grid/GridContextProvider'
import { CompanySettingsApi } from '../../services/CompanySettingsApi'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal'
import { MobileSidebarOpener } from '../../shared/elements/MobileSidebarOpener'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import { useRootStoreSelector } from '../../shared/store/hooks/useRootStoreSelector'
import { openDocument } from '../../shared/utils/openDocument'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

export const PriceListContainer = () => {
    const { t } = useOfferionTranslation()

    const [currentPath, setCurrentPath] = useState<number[]>([])
    const [currentPathName, setCurrentPathName] = useState<string[]>([])
    const currentGroup = currentPath.length ? currentPath[currentPath.length - 1] : null
    const isAdmin = useRootStoreSelector(r => r.user.Role.includes('Administrator'))
    const isLawyer = useRootStoreSelector(r => r.user.CompanyType == CompanyType.Lawyer)

    const [currency, setCurrency] = useState<Currency | null>(null)

    useEffect(() => {
        CompanySettingsApi.getDefaultCurrency().then(setCurrency)
    }, [])

    const RowComponent = ({ row, gridActions }: { row: PriceListItem; gridActions: GridActions }) => {
        const [selectedProduct, setSelectedProduct] = useState<ProductModel | null>(null)
        const [selectedGroup, setSelectedGroup] = useState<GroupModel | null>(null)
        const { t } = useOfferionTranslation()
        const offerionModal = useOfferionModal()

        const goToSubgroup = (groupId: number, groupName: string) => {
            gridActions.setPage(1)

            setCurrentPathName(c => {
                const newArray = [...c]

                newArray.push(groupName)

                return newArray
            })

            setCurrentPath(c => {
                const newArray = [...c]

                newArray.push(groupId)

                return newArray
            })
        }

        const editPriceListItem = () => {
            if (row.productId) {
                ProductApi.getProduct(row.productId).then(setSelectedProduct)
            } else if (row.groupId) {
                GroupApi.getGroup(row.groupId).then(setSelectedGroup)
            }
        }

        const deletePriceListItem = () => {
            const messageKey = row.productId ? 'PriceList.DeleteProduct.questionYesNo' : 'PriceList.DeleteGroup.questionYesNo'
            const titleKey = row.productId ? 'PriceList.DeleteProduct.pageTitle' : 'PriceList.DeleteGroup.pageTitle'
            const translationObject = row.productId
                ? {
                      productName: row.name,
                  }
                : {
                      groupName: row.name,
                  }

            offerionModal.open(
                titleKey,
                messageKey,
                () => {
                    if (row.productId) {
                        return ProductApi.delete(row.productId).then(gridActions.refresh)
                    } else if (row.groupId) {
                        return GroupApi.delete(row.groupId).then(gridActions.refresh)
                    } else {
                        return Promise.resolve()
                    }
                },
                translationObject
            )
        }

        return (
            <>
                <span className="table__cell table__cell__icon mobile-hide">
                    {row.productId ? (
                        <span className="icon icon__item"></span>
                    ) : (
                        <span
                            className="icon icon__group pointer"
                            onClick={() => row.groupId && goToSubgroup(row.groupId, row.name)}></span>
                    )}
                </span>

                {row.groupId ? (
                    <span className="table__cell table__cell__icon mobile-only">
                        <span className="icon icon__group"></span>
                    </span>
                ) : null}

                {row.productId ? (
                    <span className="table__cell table__cell__icon mobile-only">
                        <span className="icon icon__item"></span>
                        {row.parentGroupName ? <span className="mod-margin-left-4">[{row.parentGroupName}]</span> : null}
                    </span>
                ) : null}

                {row.groupId ? (
                    <span
                        className="table__cell table__cell__title mobile-only"
                        onClick={() => row.groupId && goToSubgroup(row.groupId, row.name)}>
                        {row.name}
                        <span className="table__cell__item-count mod-margin-left-4">({row.numberOfElements})</span>
                        {row.parentGroupName ? <span className="mod-margin-left-4">[{row.parentGroupName}]</span> : null}
                        {row.hasImage && <span className="icon icon--small icon__picture"></span>}
                    </span>
                ) : null}

                {row.productId ? (
                    <span className="table__cell table__cell__title mobile-only">
                        {isAdmin ? (
                            <InlineInputEdit
                                placeholderKey='PriceList.EditGroup.namePlaceholder'
                                currentValue={row.name}
                                onSave={({ newValue }) =>
                                    row.productId && ProductApi.patchStringField(row.productId, 'Name', newValue).then(gridActions.refresh)
                                }
                            />
                        ) : (
                            <span>{row.name} </span>
                        )}
                        {row.hasImage && <span className="icon icon--small icon__picture"></span>}
                    </span>
                ) : null}

                <span
                    className=" table__cell table__cell__title  overlay__item overlay__item--inline mobile-hide"
                    style={{ marginTop: '8px', marginBottom: '0px' }}>
                    <span
                        onClick={() => row.groupId && goToSubgroup(row.groupId, row.name)}
                        className={`${(row.productId && isAdmin) || row.groupId ? 'pointer' : ''}`}>
                        {row.productId && isAdmin ? (
                            <InlineInputEdit
                                placeholderKey='PriceList.EditGroup.namePlaceholder'
                                currentValue={row.name}
                                onSave={({ newValue }) =>
                                    row.productId && ProductApi.patchStringField(row.productId, 'Name', newValue).then(gridActions.refresh)
                                }
                            />
                        ) : (
                            row.name
                        )}
                    </span>
                    {row.groupId ? <span className="table__cell__item-count mod-margin-left-4">({row.numberOfElements})</span> : null}
                    {row.parentGroupName ? <span className="mod-margin-left-4">[{row.parentGroupName}]</span> : null}
                    {row.hasImage && <span className="icon icon--small icon__picture"></span>}

                    {selectedProduct ? (
                        <ProductForm currency={currency} initialValues={selectedProduct} closeForm={() => setSelectedProduct(null)} />
                    ) : null}

                    {selectedGroup ? <GroupForm initialValues={selectedGroup} closeForm={() => setSelectedGroup(null)} /> : null}
                </span>

                {row.groupId ? <span className="table__cell table__cell__price"></span> : null}

                {row.productId ? (
                    <span className="table__cell table__item-inline table__item--mobile-alignment">
                        <InlineCurrencyEdit
                            currency={currency}
                            currentValue={row.price || 0}
                            required={true}
                            onSave={({ newValue }) =>
                                row.productId && ProductApi.patchNumberField(row.productId, 'SalePrice', newValue).then(gridActions.refresh)
                            }
                        />
                    </span>
                ) : null}

                {isAdmin && (
                    <GridMenuCell>
                        <GridMenuActionLink
                            icon={faEdit}
                            onClick={editPriceListItem}
                            translationKey="PriceList.Table.ItemMenu.edit"></GridMenuActionLink>
                        <GridMenuActionLink
                            icon={faTrashAlt}
                            onClick={deletePriceListItem}
                            translationKey="PriceList.Table.ItemMenu.delete"></GridMenuActionLink>
                    </GridMenuCell>
                )}
            </>
        )
    }

    const tableSchema = {
        columns: [
            {
                cssClass: 'table__header__item__icon',
                labelKey: '',
            },
            {
                cssClass: '',
                labelKey: 'PriceList.Table.Header.name',
            },
            {
                cssClass: 'table__header__item__price',
                labelKey: 'PriceList.Table.Header.price',
            },
        ],
    }

    if (isAdmin) {
        tableSchema.columns.push({
            labelKey: '',
            cssClass: 'table__header__item__menu',
        })
    }

    let path = '/price-list'

    const buildSafeName = (name: string) => {
        if (name.length <= 18) return name

        const firstPart = name.slice(0, 9)
        const secondPart = name.slice(name.length - 9)

        return `${firstPart}...${secondPart}`
    }

    const breadcrumbsComponent = currentPathName.map((val, ix) => {
        path = path + `/${val}`
        return (
            <BreadcrumbsItem to={path}>
                <span
                    onClick={e => {
                        e.preventDefault()

                        const newPath = currentPath.slice(0, ix + 1)
                        const newPathName = currentPathName.slice(0, ix + 1)

                        setCurrentPath(newPath)
                        setCurrentPathName(newPathName)
                    }}>
                    {buildSafeName(val)}
                </span>
            </BreadcrumbsItem>
        )
    })

    const Sidebar = ({ updateFilter }: { updateFilter: (filter: any[]) => void }) => {
        const location = useLocation()
        const history = useHistory()
        const ref = useRef<HTMLInputElement>(null)
        const gridActions = useGridActions()
        const offerionModal = useOfferionModal()

        const exportPriceList = () => {
            PriceListApi.exportPriceList().then((data: any) => {
                openDocument(`pricelist-export.csv`, data, 'application/csv')
            })
        }

        const deletePriceList = () => {
            offerionModal.open('Obriši cjenik', 'Da li zaista želite obrisati čitavi cjenik?', () =>
                PriceListApi.deletePriceList().then(gridActions.refresh)
            )
        }

        const importPriceList = (file: File | null) => {
            if (!file) return

            const formData = new FormData()

            formData.append('file', file)

            PriceListApi.importPriceList(formData).then(gridActions.refresh)

            ref.current && (ref.current.value = '')
        }

        return (
            <div className="main__side-content">
                <GridFilterForm
                    initialValues={{ keyword: '' }}
                    onSubmit={v => {
                        updateFilter([v.keyword])

                        history.push(location.pathname)
                    }}
                    titleKey="PriceList.Side.searchTitle"
                    searchButtonClassName="full-width-above-wide"
                    searchButtonLabelKey="PriceList.Side.searchButton">
                    <GridFilterInput name="keyword" placeholderKey="PriceList.Side.searchPlaceholder"></GridFilterInput>
                </GridFilterForm>
                {isAdmin && !isLawyer && (
                    <>
                        <div className="main__side-content-item full-width catalogue__button-container mod-margin-top-8">
                            <button className="button button--white full-width text-left catalogue__button" onClick={exportPriceList}>
                                <span className="icon icon__document-catalogue-import"></span>
                                <span className="catalogue__button-text mod-margin-left-8">{t('PriceList.Side.exportPriceList')}</span>
                            </button>
                        </div>

                        <div className="main__side-content-item full-width catalogue__button-container mod-margin-top-8">
                            <input ref={ref} type="file" hidden onChange={e => e.target.files && importPriceList(e.target.files[0])} />
                            <button
                                className="button button--white full-width text-left catalogue__button"
                                onClick={() => ref.current && ref.current.click()}>
                                <span className="icon icon__document-catalogue-export"></span>
                                <span className="catalogue__button-text mod-margin-left-8">{t('PriceList.Side.importPriceList')}</span>
                            </button>
                        </div>

                        <div className="main__side-content-item full-width catalogue__button-container mod-margin-top-8">
                            <button className="button button--white full-width text-left catalogue__button" onClick={deletePriceList}>
                                <span className="icon icon__invoice-delete"></span>
                                <span className="catalogue__button-text mod-margin-left-8">{t('PriceList.Side.deletePriceList')}</span>
                            </button>
                        </div>
                    </>
                )}
            </div>
        )
    }

    return (
        <GridContextProvider>
            <BreadcrumbsItem to={`/price-list`}>
                <span
                    onClick={e => {
                        e.preventDefault()

                        setCurrentPath([])
                        setCurrentPathName([])
                    }}>
                    {t('PriceList.Header.pageTitle')}
                </span>
            </BreadcrumbsItem>

            {breadcrumbsComponent}

            <main className="main">
                <div className="main__header">
                    <div className="main-title">{t('PriceList.Header.pageTitle')}</div>

                    <div className="main__header-buttons">
                        {isAdmin && (
                            <>
                                <AddProductButton currency={currency} currentGroup={currentGroup} />
                                <AddGroupButton currentGroup={currentGroup} />
                            </>
                        )}
                        <MobileSidebarOpener iconClass="icon__search" />
                    </div>
                </div>

                <Grid
                    emptyMessageKey={currentGroup ? 'PriceList.Table.emptyGroupMessage' : 'PriceList.Table.emptyMessage'}
                    emptySearchMessageKey="PriceList.Table.emptySearchMessage"
                    translationKey={'PriceList.Table.Header.title'}
                    rowsPromiseFactory={config =>
                        PriceListApi.getPriceList(
                            config.pageNumber,
                            config.pageSize,
                            currentGroup,
                            config.filter && config.filter.length ? config.filter[0] : null
                        )
                    }
                    rowComponent={RowComponent}
                    firstRowComponent={
                        currentGroup
                            ? ({ gridActions }) => {
                                  return (
                                      <>
                                          <span className="overlay__item overlay__item--right-middle"></span>
                                          <span
                                              onClick={() => {
                                                  gridActions.setPage(1)

                                                  setCurrentPath(p => {
                                                      const newArray = [...p]

                                                      newArray.pop()

                                                      return newArray
                                                  })

                                                  setCurrentPathName(p => {
                                                      const newArray = [...p]

                                                      newArray.pop()

                                                      return newArray
                                                  })
                                              }}
                                              className="table__cell table__cell__icon mod-cursor-pointer">
                                              <span className="icon icon__back"></span>
                                          </span>
                                          <span className="table__cell table__cell__title">-</span>
                                      </>
                                  )
                              }
                            : null
                    }
                    alwaysShowFirstRow={!!currentPath.length}
                    sidebarComponent={Sidebar}
                    sidebarDependencyArray={[isAdmin]}
                    tableSchema={tableSchema}></Grid>
            </main>
        </GridContextProvider>
    )
}
