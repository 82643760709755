import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { PicturesSection } from '../../components/settings/pictures/PicturesContainer';
import { GroupApi } from '../../services/GroupApi';
import { ItemApi } from '../../services/ItemApi'
import { ProductApi } from '../../services/ProductApi';

interface SelectionConfig {
    documentItemId?: number | null;
    productId?: number | null;
    groupId?: number | null;
    onCustomImageSelect: (imageId: number) => void;
}

export const ImageSelectionContainer = () => {
    const location = useLocation();
    const history = useHistory();

    const config = location.state as SelectionConfig

    const onPredefinedImageSelect = (predefinedImageId: number) => {
        if (config.documentItemId)
            ItemApi.linkPredefinedImage(config.documentItemId, predefinedImageId).then(history.goBack)
        else if (config.productId)
            ProductApi.setImage(config.productId, null, predefinedImageId).then(history.goBack)
        else if (config.groupId)
            GroupApi.setImage(config.groupId, null, predefinedImageId).then(history.goBack)
    }

    const onCustomImageSelect = (customImageId: number) => {
        if (config.documentItemId)
            ItemApi.linkCustomImage(config.documentItemId, customImageId).then(history.goBack)
        else if (config.productId)
            ProductApi.setImage(config.productId, customImageId, null).then(history.goBack)
        else if (config.groupId)
            GroupApi.setImage(config.groupId, customImageId, null).then(history.goBack)
    }

    return <PicturesSection onPredefinedImageAdd={onPredefinedImageSelect} onCustomImageAdd={onCustomImageSelect} />
}