import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { UserSignOut } from "../../shared/store/actions/userActions";

export const SignoutContainer = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UserSignOut())
    }, [])

    return null;
}