export class Spinner {
    public static hide() {
        const spinner = document.getElementById('spinnerLoader')

        spinner && (spinner.style.display = 'none')
    }

    public static show() {
        const spinner = document.getElementById('spinnerLoader')

        spinner && (spinner.style.display = 'block')
    }
}
