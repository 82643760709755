import React, { useState, useEffect, ReactNode } from 'react'
import { CustomImageModel, PicturesApi, PredefinedFolderModel } from '../../../services/PicturesApi'
import { ImageWithAuthorization } from '../../../shared/elements/ImageWithAuthorization'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { FileUploadModal } from '../../../shared/elements/FileUploadModal'
import { ResourceApi } from '../../../services/ResourceApi'
import { toastSuccess } from '../../../shared/toastr'
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal'
import { useRootStoreSelector } from '../../../shared/store/hooks/useRootStoreSelector'
import { CompanyType } from '../../../shared/models'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'
import { CollapsibleSection } from '../../ui/CollapsibleSection'

interface PicturesSectionProps {
    onPredefinedImageAdd?: (imageId: number) => void
    onCustomImageAdd?: (imageId: number) => void
}

export const PicturesSection = (props: PicturesSectionProps) => {
    const { t } = useOfferionTranslation()
    const offerionModal = useOfferionModal()

    const [predefinedFoldersPath, setPredefinedFolderPath] = useState<number[]>([])
    const [currentFolderId, setCurrentFolderId] = useState<number | null>(null)

    const [selectedPredefinedImage, setSelectedPredefinedImage] = useState<number | null>()
    const [selectedCustomImageResourceId, setSelectedCustomImageResourceId] = useState<number | null>()
    const [uploadCustomImageModalVisible, setUploadCustomImageModalVisible] = useState<boolean>(false)
    const companyType = useRootStoreSelector(x => x.user.CompanyType)

    const [predefinedFolderInfo, setPredefinedFolderInfo] = useState<PredefinedFolderModel | null>(null)
    const [customImages, setCustomImages] = useState<CustomImageModel[] | null>(null)

    useEffect(() => {
        PicturesApi.getContentForPredefinedFolder(currentFolderId).then(setPredefinedFolderInfo)
    }, [currentFolderId])

    const getCustomImages = () => {
        PicturesApi.getCustomImages().then(setCustomImages)
    }

    useEffect(getCustomImages, [])

    const goToPredefinedFolder = (folderId: number) => {
        const path = [...predefinedFoldersPath, folderId]

        setSelectedPredefinedImage(null)
        setPredefinedFolderInfo(null)
        setPredefinedFolderPath(path)
        setCurrentFolderId(folderId)
    }

    const goToPreviousPredefinedFolder = () => {
        predefinedFoldersPath.pop()

        const folderId = predefinedFoldersPath.length ? predefinedFoldersPath[predefinedFoldersPath.length - 1] : null

        setSelectedPredefinedImage(null)
        setPredefinedFolderInfo(null)
        setPredefinedFolderPath([...predefinedFoldersPath])
        setCurrentFolderId(folderId)
    }

    const deleteCustomImage = (imageId: number) => {
        offerionModal.open('Settings.MyPictures.Delete.pageTitle', 'Settings.MyPictures.Delete.questionYesNo', () => {
            return PicturesApi.deleteCustomImage([imageId])
                .then(() => toastSuccess('Settings.MyPictures.Messages.successDelete'))
                .then(getCustomImages)
        })
    }

    const uploadResource = async (file: File) => {
        const result = await ResourceApi.uploadResource(file)

        await PicturesApi.addCustomImage(result.resourceId)

        toastSuccess('Settings.MyPictures.Messages.successUpload')

        if (props.onCustomImageAdd) {
            props.onCustomImageAdd(result.resourceId)
        } else {
            setUploadCustomImageModalVisible(false)

            getCustomImages()
        }
    }

    if (customImages == null || predefinedFolderInfo == null) return null

    const predefinedPicturesDefaultOpen = companyType == CompanyType.WindowMaker
    const customImagesDefaultOpen = !predefinedPicturesDefaultOpen && customImages.length > 0
    const isUploadDefaultOpen = !customImagesDefaultOpen

    return (
        <main className="main import file-upload settings">
            <div className="main__header">
                <div className="main-title">{t('Settings.MyPictures.Header.pageTitle')}</div>
            </div>

            <div className="table__container curled-paper pt3">
                {companyType == CompanyType.WindowMaker && (
                    <CollapsibleSection
                        title={<span>{t('Settings.MyPictures.Table.PredefinedPictures.title')}</span>}
                        isOpenByDefault={predefinedPicturesDefaultOpen}>
                        <div className="info__container">
                            <div className="info__row-content">
                                {currentFolderId ? (
                                    <button
                                        className="button button--padded button--gray button--accept"
                                        onClick={goToPreviousPredefinedFolder}>
                                        <span className="icon icon__back"></span>
                                        <span className="button-text button-text--always-show">
                                            {t('Settings.MyPictures.Table.PredefinedPictures.buttonBack')}
                                        </span>
                                    </button>
                                ) : null}
                            </div>
                        </div>

                        <div className="info__container image__container">
                            {predefinedFolderInfo &&
                                predefinedFolderInfo.folders.map(f => {
                                    return (
                                        <div className="image__outer image__outer--folder pointer">
                                            <div className="image">
                                                <span className="image__centered-text" onClick={() => goToPredefinedFolder(f.id)}>
                                                    {f.name}
                                                </span>
                                            </div>
                                        </div>
                                    )
                                })}

                            {predefinedFolderInfo &&
                                predefinedFolderInfo.images.map(p => {
                                    return (
                                        <div className="image__outer">
                                            <ImageWithAuthorization src={`api/predefinedImages/getPredefinedImageResource/${p.id}`} />

                                            {props.onPredefinedImageAdd && (
                                                <>
                                                    <input
                                                        id={`predefinedImage-${p.id}`}
                                                        type="checkbox"
                                                        className="checkbox"
                                                        checked={selectedPredefinedImage == p.id}
                                                        onChange={e => {
                                                            if (e.target.checked) setSelectedPredefinedImage(p.id)
                                                            else setSelectedPredefinedImage(null)
                                                        }}
                                                        ng-change="vm.addImageToLinkArray(picture.id)"
                                                    />
                                                    <label
                                                        htmlFor={`predefinedImage-${p.id}`}
                                                        className="button button--gray checkbox-button image__check">
                                                        <span className="checkbox-button__icon"></span>
                                                    </label>
                                                </>
                                            )}
                                        </div>
                                    )
                                })}
                        </div>

                        {!!selectedPredefinedImage && props.onPredefinedImageAdd && (
                            <button
                                className="button button--padded button--gray button--accept"
                                onClick={() => props.onPredefinedImageAdd && props.onPredefinedImageAdd(selectedPredefinedImage)}>
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">
                                    {t('Settings.MyPictures.Table.UploadPicture.addPictureButton')}
                                </span>
                            </button>
                        )}
                    </CollapsibleSection>
                )}

                {customImages.length > 0 && (
                    <CollapsibleSection
                        isOpenByDefault={customImagesDefaultOpen}
                        title={<span>{t('Settings.MyPictures.Table.MyPictures.title')}</span>}>
                        <div className="info__container image__container">
                            {customImages.map((image, index) => {
                                const id = `custom-image-${image.customImageId}`

                                return (
                                    <div className="image__outer">
                                        <ImageWithAuthorization src={`api/resources/GetResource/${image.resourceName}`} />

                                        <input
                                            id={id}
                                            type="checkbox"
                                            className="checkbox"
                                            checked={selectedCustomImageResourceId == image.resourceId}
                                            onChange={e => {
                                                if (e.target.checked) setSelectedCustomImageResourceId(image.resourceId)
                                                else setSelectedCustomImageResourceId(null)
                                            }}
                                        />
                                        <label htmlFor={id} className="button button--gray checkbox-button image__check">
                                            <span className="checkbox-button__icon"></span>
                                        </label>
                                    </div>
                                )
                            })}
                        </div>

                        <div className="info__container">
                            <div className="info__row-content">
                                {!!selectedCustomImageResourceId && props.onPredefinedImageAdd && (
                                    <button
                                        className="button button--padded button--gray button--accept"
                                        onClick={() => props.onCustomImageAdd && props.onCustomImageAdd(selectedCustomImageResourceId)}>
                                        <span className="icon icon__check-green"></span>
                                        <span className="button-text button-text--always-show">
                                            {t('Settings.MyPictures.Table.UploadPicture.addPictureButton')}
                                        </span>
                                    </button>
                                )}

                                {!!selectedCustomImageResourceId && (
                                    <button
                                        className="button button--padded button--gray button--accept"
                                        onClick={() => deleteCustomImage(selectedCustomImageResourceId)}>
                                        <span className="icon icon__delete"></span>
                                        <span className="button-text button-text--always-show">
                                            {t('Settings.MyPictures.Table.MyPictures.buttonDelete')}
                                        </span>
                                    </button>
                                )}
                            </div>
                        </div>
                    </CollapsibleSection>
                )}

                <CollapsibleSection
                    isOpenByDefault={isUploadDefaultOpen}
                    title={<span>{t('Settings.MyPictures.Table.UploadPicture.title')}</span>}>
                    <div className="info__container file-upload__container">
                        <div className="upload-form">
                            <div className="left">
                                <label
                                    htmlFor="file-upload"
                                    className="mod-margin-left-8 button button--padded button--gray file-input__button">
                                    <input
                                        id="file-upload"
                                        className="file-input left"
                                        onClick={() => setUploadCustomImageModalVisible(true)}
                                    />
                                    <span className="icon icon--small icon__upload"></span>
                                    <span className="button-text button-text--always-show">
                                        {t('Settings.MyPictures.Table.UploadPicture.addPictureButton')}
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </CollapsibleSection>

                {uploadCustomImageModalVisible && (
                    <FileUploadModal
                        titleKey={'Settings.MyPictures.Table.UploadPicture.title'}
                        footerKey={'Settings.MyPictures.Table.UploadPicture.maxPictureSize'}
                        maxSizeInKb={100}
                        onSubmit={file => uploadResource(file)}
                        close={() => setUploadCustomImageModalVisible(false)}
                    />
                )}
            </div>
        </main>
    )
}

export const PicturesContainer = () => {
    const { t } = useOfferionTranslation()

    return (
        <>
            <BreadcrumbsItem to="/settings/pictures">{t('Settings.MyPictures.Header.pageTitle')}</BreadcrumbsItem>

            <PicturesSection />
        </>
    )
}
