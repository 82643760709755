import React, { useEffect, useState, useCallback } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { ActionMenuItem, DocumentDetailsContainer } from '../../shared/documents/details/DocumentDetailsContainer'
import {
    InvoiceDocumentModel,
    TemplateTenderDocumentModel,
    TenderStatus,
    ConcreteTenderDocumentModel,
    PaymentMethod,
    PriceCalculationMode,
    ReceiptStatus,
    VersionInformation,
} from '../../shared/models'
import { InlineInputEdit } from '../../shared/forms/InlineInputEdit'
import { FormikProps, Field } from 'formik'
import { TemplateTenderApi, TemplateTenderConfigurationModel } from '../../services/TemplateTenderApi'
import { InvoiceApi } from '../../services/InvoiceApi'
import { ClientInput } from '../../shared/documents/details/elements/client-input/ClientInput'
import * as Yup from 'yup'
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage'
import { ConcreteTenderApi } from '../../services/ConcreteTenderApi'
import { ChangePricesAction } from '../../shared/documents/details/elements/ChangePricesAction'
import { toastSuccess, toastWarning } from '../../shared/toastr'
import { faCog, faFileMedical, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal'
import { QuickActionItem, QuickActionSubtitle, QuickActionTitle } from '../../shared/documents/QuickActions'

export const TemplateTenderDetailsContainer = () => {
    const params = useParams<{ tenderId: string | undefined }>()
    const history = useHistory()
    const location = useLocation()
    const { t } = useOfferionTranslation()
    const offerionModal = useOfferionModal()

    const [configuration, setConfiguration] = useState<TemplateTenderConfigurationModel | undefined>()

    useEffect(() => {
        TemplateTenderApi.getConfiguration(params.tenderId).then(config => {
            if (config.showVatAccountingWarning) {
                if (config.isAccountingVat) {
                    toastWarning('Invoice.Header.VATIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.VATIsNowOn.warningMessage')
                }
            }

            if (config.showPriceCalculationWarning) {
                if (config.enterFullPriceEnabled) {
                    toastWarning('Invoice.Header.TotalPriceIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.TotalPriceIsNowOn.warningMessage')
                }
            }

            setConfiguration(config)
        })
    }, [params.tenderId])

    const deleteTemplateTender = (document: TemplateTenderDocumentModel) => {
        offerionModal.open(
            'Invoice.DeleteTemplate.pageTitle',
            'Invoice.DeleteTemplate.questionYesNo',
            async () => {
                await TemplateTenderApi.delete(document.details.id)

                toastSuccess('Invoice.DeleteTemplate.Message.successDelete')

                history.replace('/templates')
            },
            { templateName: document.details.templateName }
        )
    }

    const documentPromiseFactory = useCallback(() => {
        return params.tenderId
            ? TemplateTenderApi.get(params.tenderId)
            : new Promise<TemplateTenderDocumentModel>(resolve => {
                  if (location.state) {
                      resolve(location.state as TemplateTenderDocumentModel)
                  } else
                      TemplateTenderApi.getConfiguration(params.tenderId).then(configuration =>
                          resolve({
                              client: null,
                              details: {
                                  cultureName: null,
                                  id: 0,
                                  documentCurrency: configuration.newTemplateTenderCurrency,
                                  clientCurrency: configuration.newTemplateTenderClientCurrency,
                                  documentCurrencyRate: configuration.documentCurrencyRate,
                                  clientCurrencyRate: configuration.clientCurrencyRate,
                                  priceCalculationMode: configuration.enterFullPriceEnabled
                                      ? PriceCalculationMode.FullPrice
                                      : PriceCalculationMode.BasePrice,
                                  paymentNoteForReceipt: configuration.newTemplateReceiptNote,
                                  paymentNoteForTender: configuration.newTemplateTenderNote,
                                  templateName: '',
                                  versionInformation: VersionInformation.Latest,
                              },
                              items: [],
                          })
                      )
              })
    }, [params.tenderId])

    if (!configuration) return null

    const createInvoice = (document: TemplateTenderDocumentModel) => {
        if (!configuration || !configuration.canCreateInvoices) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            })

            toastWarning(message, true)
        } else {
            InvoiceApi.getNextInvoiceNumber().then(nextNumber => {
                const invoice: InvoiceDocumentModel = {
                    client: document.client
                        ? {
                              ...document.client,
                              hideOnListing: true,
                              sourceClientId: document.client.id,
                              id: 0,
                          }
                        : null,
                    items: document.items.map(i => {
                        return { ...i, id: 0 }
                    }),
                    fiscalization: {
                        isFiscalized: false,
                        isInsideFiscalizationInterval: true,
                    },
                    details: {
                        status: ReceiptStatus.Draft,
                        ...document.details,
                        id: 0,
                        note: document.details.paymentNoteForReceipt,
                        number: nextNumber,
                        officialDate: new Date(),
                        paymentMethod: PaymentMethod.Account,
                        paymentDateOffset: 15,
                    },
                }

                InvoiceApi.upsert(invoice).then(result => {
                    history.push(`/invoice/${result.details.id}`, result)
                })
            })
        }
    }

    const createOffer = (document: TemplateTenderDocumentModel) => {
        if (!configuration || !configuration.canCreateTenders) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
            })

            toastWarning(message, true)
        } else {
            ConcreteTenderApi.getSimpleTenderConfig().then(({ nextNumber, defaultTenderLanguageId }) => {
                const { templateName, ...details } = document.details

                const tender: ConcreteTenderDocumentModel = {
                    client: document.client,
                    items: document.items.map(i => {
                        return { ...i, id: 0 }
                    }),
                    details: {
                        ...details,
                        cultureName: defaultTenderLanguageId,
                        id: 0,
                        status: TenderStatus.Draft,
                        number: nextNumber,
                        officialDate: new Date(),
                    },
                }

                ConcreteTenderApi.upsert(tender).then(result => {
                    history.push(`/offer/${result.details.id}`, result)
                })
            })
        }
    }

    const upsertDocument = (document: TemplateTenderDocumentModel) => {
        return TemplateTenderApi.upsert(document).then(newDoc => {
            if (document.details.id == 0) {
                history.replace(`/template-offer/${newDoc.details.id}`)
            }

            return newDoc
        })
    }

    const pageTitle = t(params.tenderId ? 'Invoice.Header.pageTitleEditTemplate' : 'Invoice.Header.pageTitleNewTemplate')

    const ActionComponent = ({ document, openSettingsModal }: { document: TemplateTenderDocumentModel; openSettingsModal: () => void }) => {
        return (
            <>
                <ActionMenuItem
                    icon={faCog}
                    onClick={openSettingsModal}
                    allowActionIfNotSaved={true}
                    allowActionWithoutAnyItems={true}
                    labelKey="Invoice.Header.Menu.templateSettings"
                    id="Invoice.Header.Menu.templateSettings"></ActionMenuItem>
                <ActionMenuItem
                    icon={faFileMedical}
                    onClick={() => createInvoice(document)}
                    labelKey="Invoice.Header.Menu.createInvoice"
                    id="Invoice.Header.Menu.createInvoice"></ActionMenuItem>
                <ActionMenuItem
                    icon={faFileMedical}
                    onClick={() => createOffer(document)}
                    labelKey="Invoice.Header.Menu.createOffer"
                    id="Invoice.Header.Menu.createOffer"></ActionMenuItem>
                <ChangePricesAction enterFullPriceEnabled={configuration.enterFullPriceEnabled}></ChangePricesAction>
                <ActionMenuItem
                    icon={faTrashAlt}
                    onClick={() => deleteTemplateTender(document)}
                    labelKey="Templates.Table.ItemMenu.delete"
                    id="Templates.Table.ItemMenu.delete"></ActionMenuItem>
            </>
        )
    }

    const QuickActionsComponent = ({ document }: { document: TemplateTenderDocumentModel }) => {
        return (
            <>
                <QuickActionTitle id="Invoice.Side.actionsTitle" text={t('Invoice.Side.actionsTitle')} />
                <QuickActionSubtitle text={t('Invoice.Side.actionsDescription')} />
                <QuickActionItem onClick={() => createInvoice(document)} icon={faFileMedical} text={t('Invoice.Header.Menu.createInvoice')} id="Invoice.Header.Menu.createInvoice" />
                <QuickActionItem onClick={() => createOffer(document)} icon={faFileMedical} text={t('Invoice.Header.Menu.createOffer')} id="Invoice.Header.Menu.createOffer" />
            </>
        )
    }

    const FooterComponent = ({
        document,
        formik,
    }: {
        document: TemplateTenderDocumentModel
        formik: FormikProps<TemplateTenderDocumentModel>
    }) => {
        return (
            <>
                <div className="notice__container notice__container--with-margin">
                    <h2 className="notice__title">{t('Invoice.Table.Footer.remarkOffer')}</h2>
                    <div className="notice__content">
                        <InlineInputEdit
                            currentValue={document.details.paymentNoteForTender}
                            multiline
                            as="textarea"
                            preventSubmitOnEnter={true}
                            className="ng-inline-edit__text"
                            placeholderKey="Invoice.Table.Footer.remarkPlaceholderOffer"
                            onSave={({ newValue }) => {
                                formik.setFieldValue(`details.paymentNoteForTender`, newValue)
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
                <div className="notice__container notice__container--with-margin">
                    <h2 className="notice__title">{t('Invoice.Table.Footer.remarkInvoice')}</h2>
                    <div className="notice__content">
                        <InlineInputEdit
                            currentValue={document.details.paymentNoteForReceipt}
                            multiline
                            as="textarea"
                            preventSubmitOnEnter={true}
                            className="ng-inline-edit__text"
                            placeholderKey="Invoice.Table.Footer.remarkPlaceholder"
                            onSave={({ newValue }) => {
                                formik.setFieldValue(`details.paymentNoteForReceipt`, newValue)
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }

    const HeaderComponent = ({
        document,
        formik,
    }: {
        document: TemplateTenderDocumentModel
        formik: FormikProps<TemplateTenderDocumentModel>
    }) => {
        return (
            <>
                <div className="info__row">
                    <label className="info__row-name">{t('Invoice.Table.TemplateName.title')}</label>

                    <div className="info__row-content">
                        <Field
                            type="text"
                            name="details.templateName"
                            onChange={(e: any) => {
                                formik.setFieldValue('details.templateName', e.target.value)
                            }}
                            onBlur={() => formik.submitForm()}
                            placeholder={t('Invoice.Table.TemplateName.noTitlePlaceholder')}
                        />

                        <OfferionErrorMessage name="details.templateName" />
                    </div>
                </div>

                <Field
                    shouldPreserveIdentity={true}
                    component={ClientInput}
                    name="client"
                    enterFullPriceEnabled={configuration.enterFullPriceEnabled}
                />
            </>
        )
    }

    const BeforeTableComponent = ({ settingsComponent }: { settingsComponent: JSX.Element }) => {
        const { t } = useOfferionTranslation()
        return (
            <div className="table__box border-left-yellow">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="table__title table__title--inline">
                        <span className="table__title-separated">{t('Invoice.Table.TemplateItems.title')}</span>
                    </h1>

                    {settingsComponent}
                </div>
            </div>
        )
    }

    const schema = Yup.object<TemplateTenderDocumentModel>().shape({
        details: Yup.object().shape({
            templateName: Yup.string().required(t('Allaround.Validation.required', { fieldName: t('Clients.Edit.name') })),
        }),
    })

    return (
        <>
            <BreadcrumbsItem to={location.pathname}>{pageTitle}</BreadcrumbsItem>

            <DocumentDetailsContainer
                showProductDimensionsInfo={configuration.showProductDimensionsInfo}
                signatureSrc={
                    params.tenderId ? `api/company/getTenderSignatureResource/${params.tenderId}` : `api/company/getSignatureResource`
                }
                footerSrc={params.tenderId ? `api/company/getTenderFooterResource/${params.tenderId}` : `api/company/getFooterResource`}
                headerSrc={params.tenderId ? `api/company/getTenderHeaderResource/${params.tenderId}` : `api/company/getHeaderResource`}
                documentPromiseFactory={documentPromiseFactory}
                canSubmit={() => true}
                isFreeCompany={configuration.isFreeCompany}
                editPurchasePrice={configuration.editPurchasePrice}
                canChangeVat={configuration.canChangeVat}
                defaultVat={configuration.defaultVat}
                isAccountingVat={configuration.isAccountingVat}
                onSubmit={upsertDocument}
                validationSchema={schema}
                pageTitle={pageTitle}
                beforeTableComponent={BeforeTableComponent}
                currencyOverlayKey="Invoice.Settings.templateCurrency"
                actionButtonsComponent={ActionComponent}
                quickActionsMenuComponent={QuickActionsComponent}
                headerComponent={HeaderComponent}
                footerComponent={FooterComponent}
                borderColorClass={() => 'green'}
                customRateFormTitleKey={'Invoice.Settings.pageTitleTemplate'}
                successCreateKey="Invoice.SaveTemplate.Message.successCreate"
                successUpdateKey="Invoice.SaveTemplate.Message.successUpdate"
            />
        </>
    )
}
