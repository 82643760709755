export interface GridData<T> {
    count: number
    data: T[]
}

export interface TableParams {
    pageNumber: number
    pageSize: number
    sort: {
        key: string
        direction: SortDirection
    } | null
    filter?: any[]
    customFilter?: string | null
}

export interface GridActions {
    refresh: () => void
    toggleFiltersModal: () => void
    totalRows: number
    config: TableParams
    setPage: (page: number) => void
}

export type TableSchema<T> = {
    columns: {
        key?: string
        labelKey?: string
        cssClass?: string
        hidden?: boolean
    }[]
}

export enum SortDirection {
    Desc = -1,
    Asc = 1,
}

export type SortModel = {
    key: string
    direction: SortDirection
} | null | null
