import { faPercent } from '@fortawesome/free-solid-svg-icons';
import { Field, Form, Formik, useFormikContext } from 'formik';
import React, { useState } from 'react'
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation';
import { GridMenuActionLink } from '../../../grid/GridMenu';
import Modal from '../../../modal';
import { DocumentItemModel } from '../../../models';
import { CurrencyLabel } from '../../../ui/currency';
import calculateUpdatedPrices from '../../../utils/calculateUpdatedPrices';
import round from '../../../utils/round';
import { useOfferionDocument } from '../DocumentDetailsContainer';
import { Slider } from "../../../elements/Slider";
import * as Yup from "yup";
import { OfferionErrorMessage } from '../../../forms/validation/OfferionErrorMessage';

export const ChangePricesAction = ({ enterFullPriceEnabled }: { enterFullPriceEnabled: boolean }) => {
    const { t } = useOfferionTranslation();
    const formikContext = useFormikContext();
    const { document } = useOfferionDocument();
    const [isChangePricesModalVisible, setIsChangePricesModalVisible] = useState(false);


    const onSubmit = (percentageIncrease: number, vat: number, vatEnabled: boolean) => {
        const anyChanges = percentageIncrease == 0 && !vatEnabled;

        if (anyChanges) {
            setIsChangePricesModalVisible(false)
            return
        }

        const newItems = document.items.map(item => {
            const newItem = { ...item }

            if (vatEnabled) {
                newItem.vat = vat
            }

            if (enterFullPriceEnabled) {
                const fullPrice = item.fullPrice + round((percentageIncrease / 100) * item.fullPrice)

                newItem.fullPrice = fullPrice
                newItem.salePrice = round(
                    round(round(fullPrice / newItem['quantity']) / (1 - newItem['discount'] / 100) / (1 + newItem['vat'] / 100))
                )
            } else {
                const salePrice = item.salePrice + round((percentageIncrease / 100) * item.salePrice)

                newItem.salePrice = salePrice
                newItem.fullPrice = round(
                    round(round(salePrice * (1 + newItem['vat'] / 100)) * (1 - newItem['discount'] / 100)) * newItem['quantity']
                )
            }

            return newItem
        })

        formikContext.setFieldValue('items', newItems)

        formikContext.submitForm()

        setIsChangePricesModalVisible(false)
    }

    const calculateIncome = (increase: number | string, vat: number | string, vatEnabled: boolean) => {
        const correctIncrease = typeof increase == 'string' ? Number.parseInt(increase) : increase
        const vatVal = typeof vat == "string" ? round(Number.parseFloat(vat)) : round(vat)

        return document.items.reduce((prev, next) => {
            let item: DocumentItemModel;

            if (enterFullPriceEnabled) {
                const fullPrice = correctIncrease ? next.fullPrice + round((correctIncrease / 100) * next.fullPrice) : next.fullPrice
                const vat = vatEnabled ? vatVal : next.vat
                item = { ...next, vat, fullPrice: fullPrice }
            } else {
                const salePrice = correctIncrease ? next.salePrice + round((correctIncrease / 100) * next.salePrice) : next.salePrice
                const vat = vatEnabled ? vatVal : next.vat
                item = { ...next, vat, salePrice: salePrice }
            }

            const updatedPrices = calculateUpdatedPrices(enterFullPriceEnabled, item)

            if (enterFullPriceEnabled) {
                return prev + updatedPrices.discountedPriceWithoutVat - next.purchasePrice * next.quantity
            } else {
                return prev + updatedPrices.discountedPrice  * next.quantity - next.purchasePrice * next.quantity
            }
        }, 0)
    }

    const calculateTotal = (increase: number | string, vat: number | string, vatEnabled: boolean) => {
        const correctIncrease = typeof increase == 'string' ? Number.parseInt(increase) : increase
        const vatVal = typeof vat == "string" ? round(Number.parseFloat(vat)) : round(vat)

        return document.items.reduce((prev, next) => {
            let item: DocumentItemModel;

            if (enterFullPriceEnabled) {
                const vat = vatEnabled ? vatVal : next.vat

                item = {
                    ...next,
                    vat: vat,
                    fullPrice: correctIncrease ? next.fullPrice + round((correctIncrease / 100) * next.fullPrice) : next.fullPrice,
                }
            } else {
                const vat = vatEnabled ? vatVal : next.vat

                item = {
                    ...next,
                    vat: vat,
                    salePrice: correctIncrease ? next.salePrice + round((correctIncrease / 100) * next.salePrice) : next.salePrice,
                }
            }
            if (enterFullPriceEnabled) return prev + item.fullPrice
            else return prev + item.salePrice * next.quantity * (1 - next.discount / 100)
        }, 0)
    }

    const income = calculateIncome(0, 0, false)
    const total = calculateTotal(0, 0, false)

    const hasPurchasePrice = document.items.filter(x => x.purchasePrice).length > 0

    const schema = Yup.object().shape({
        vat: Yup.number()
            .min(0, t('Allaround.Validation.incorrectVAT'))
            .max(30, t('Allaround.Validation.incorrectVAT'))
            .typeError(t('Allaround.Validation.incorrectVAT')),
    })

    return (
        <>
            <GridMenuActionLink
                icon={faPercent}
                onClick={() => setIsChangePricesModalVisible(true)}
                translationKey="Invoice.Header.Menu.changePrices"></GridMenuActionLink>
            <Modal isOpen={isChangePricesModalVisible}>
                <div className="popup__overlay popup__overlay--show send-document-by-email">
                    <div className="popup">
                        <h1 className="overlay__title"> {t('Invoice.ChangeAllPrices.pageTitle')}</h1>
                        <div
                            className="popup__close icono-cross"
                            onClick={() => setIsChangePricesModalVisible(false)}
                            style={{ cursor: 'pointer' }}>
                            {t('Allaround.Popup.close')}
                        </div>
                        <Formik
                            validationSchema={schema}
                            initialValues={{ percentage: '0', vatEnabled: false, vat: document.items.length ? String(document.items[0].vat) : '0.00' }}
                            onSubmit={val => onSubmit(Number.parseInt(val.percentage), Number.parseFloat(val.vat), val.vatEnabled)}>
                            {formikProps => {
                                const {percentage, vat, vatEnabled} = formikProps.values

                                const newIncome = calculateIncome(percentage, vat, vatEnabled)
                                const newTotal = calculateTotal(percentage, vat, vatEnabled)

                                return (
                                    <Form noValidate={true}>
                                        <div className="address-placeholder">
                                            <div className="info__row">
                                                <div className="info__row-content mod-margin-bottom-8">
                                                    <Field name="percentage">
                                                        {({ field, form }: { field: any; form: any }) => (
                                                            <Slider
                                                                className={vatEnabled ? "w" : "a"}
                                                                disabled={vatEnabled}
                                                                onChange={value => form.setFieldValue('percentage', value)}
                                                                onBlur={field.onBlur}
                                                                value={field.value}
                                                                min={-50}
                                                                max={100}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div style={{ float: 'left', width: '100%' }}>
                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                        <span>-50%</span>
                                                        <span>{formikProps.values.percentage} %</span>
                                                        <span>100%</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="info__row">
                                                <label className="info__row-name" style={{ fontWeight: 'bold' }} htmlFor="">
                                                    {t('Invoice.ChangeAllPrices.new')}
                                                </label>

                                                {hasPurchasePrice && (
                                                    <>
                                                        {' '}
                                                        <div className="info__row__inner-half">
                                                            <label className="info__row__label">
                                                                {t('Invoice.ChangeAllPrices.profit')}
                                                            </label>
                                                        </div>
                                                        <div className="info__row__inner-half">
                                                            <label className="info__row__amount left">
                                                                <CurrencyLabel
                                                                    amount={newIncome}
                                                                    currency={document.details.documentCurrency}
                                                                />
                                                            </label>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__label">{t('Invoice.ChangeAllPrices.total')}</label>
                                                </div>
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__amount left">
                                                        <CurrencyLabel amount={newTotal} currency={document.details.documentCurrency} />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="info__row">
                                                <label className="info__row-name" style={{ fontWeight: 'bold' }} htmlFor="">
                                                    {t('Invoice.ChangeAllPrices.current')}
                                                </label>

                                                {hasPurchasePrice && (
                                                    <>
                                                        <div className="info__row__inner-half">
                                                            <label className="info__row__label">
                                                                {t('Invoice.ChangeAllPrices.profit')}
                                                            </label>
                                                        </div>
                                                        <div className="info__row__inner-half">
                                                            <label className="info__row__amount left">
                                                                <CurrencyLabel
                                                                    amount={income}
                                                                    currency={document.details.documentCurrency}
                                                                />
                                                            </label>
                                                        </div>
                                                    </>
                                                )}
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__label">{t('Invoice.ChangeAllPrices.total')}</label>
                                                </div>
                                                <div className="info__row__inner-half">
                                                    <label className="info__row__amount left">
                                                        <CurrencyLabel amount={total} currency={document.details.documentCurrency} />
                                                    </label>
                                                </div>
                                            </div>

                                            <div className="info__row">
                                                <div className="block">
                                                    <label className="info__row__label clickable">
                                                        <Field
                                                            id="toggle-vat-enabled"
                                                            type="checkbox"
                                                            className="checkbox"
                                                            name="vatEnabled"
                                                        />
                                                        <label
                                                            htmlFor="toggle-vat-enabled"
                                                            className="button button--gray button--padded checkbox-button button--print">
                                                            <span className="checkbox-button__icon"></span>
                                                        </label>
                                                        {t('Invoice.ChangeAllPrices.enableVATChange')}
                                                    </label>
                                                </div>

                                                <div>
                                                    <label htmlFor="vat" className={!vatEnabled ? "disabled-label" : ""} style={{ fontWeight: 'bold' }}>
                                                        {t('Invoice.ChangeAllPrices.changeVAT')}
                                                    </label>

                                                    <Field
                                                        disabled={!vatEnabled}
                                                        type="text"
                                                        name="vat"
                                                        placeholder={t('Invoice.ChangeAllPrices.vatInputPlaceholder')}
                                                    />
                                                    <OfferionErrorMessage name="vat" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="popup__buttons mod-margin-top-8">
                                            <button className="button button--gray popup__button" type="submit">
                                                <span className="icon icon__check-green"></span>
                                                {t('Allaround.Button.accept')}
                                            </button>
                                            <button
                                                className="button button--gray popup__button"
                                                type="button"
                                                onClick={() => setIsChangePricesModalVisible(false)}>
                                                <span className="icon icon--small icon__cross-red"></span>
                                                {t('Allaround.Button.cancel')}
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </Modal>
        </>
    )
}
