import React, { useState } from 'react'
import { UserForm } from './UserForm'
import { UserModel } from '../../../shared/models'
import { UsersApi } from '../../../services/UsersApi'
import { useGridActions } from '../../../shared/grid/GridContextProvider'
import { toastWarning } from '../../../shared/toastr'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'

export const AddNewUserButton = ({ customOnClick, onUserUpserted }: { customOnClick: (() => void) | null, onUserUpserted: (canCreateNextUser: boolean) => void }) => {
    const { t } = useOfferionTranslation()
    const grid = useGridActions();

    const [hideOverlay, setHideOverlay] = useState(true)

    const onSubmit = async (user: UserModel & { newPassword: string, sendEmail: boolean }) => {
        const result = await UsersApi.upsertUser(user);

        if (!result.userUpserted) {
            toastWarning('Settings.Users.Header.NewUser.warningMessage');
        }
        else {
            grid.refresh();

            setHideOverlay(true);
        }

        onUserUpserted(result.canCreateNextUser);
    }

    return (
        <>
            {hideOverlay ? (
                <div className="table__button-container">
                    <button className="button button--white table__button" onClick={() => customOnClick ? customOnClick() : setHideOverlay(false)}>
                        <span className="icon icon__plus"></span>
                    </button>
                    <span className="button-text table__button-text mod-margin-left-4">{t('Settings.Users.Header.newUser')}</span>
                </div>
            ) : (
                <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active overlay__item--show-overlay">
                    <button className="button button--gray button--plus overlay__button overlay__button--active">
                        <span className="icon icon__plus"></span>
                    </button>

                    <UserForm onSubmit={onSubmit} closeOverlay={() => setHideOverlay(true)} />

                    <span className="button-text table__button-text mod-margin-left-4">{t('Settings.Users.Header.newUser')}</span>
                </div>
            )}
        </>
    )
}
