import { Field, Form, Formik } from 'formik';
import React, { ReactText } from 'react'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';
import { InlineDateEdit } from '../forms/InlineDateEdit';
import StatusSelect from '../../components/ui/StatusSelect';
import { StatusIconMapping } from '../models';

interface Props {
    pageTitleKey: string;
    currentStatus: number;
    paymentDate?: Date;
    onSubmit: (newStatus: number, paymentDate: Date) => void
    options: { value: ReactText, translationKey: string }[];
    close: () => void;
    showPaymentDate?: (currentStatus: number) => boolean,
    mapping: StatusIconMapping,
}

export const ChangeStatusModal = ({ pageTitleKey, currentStatus, onSubmit, options, close, showPaymentDate, paymentDate, mapping }: Props) => {
    const { t } = useOfferionTranslation();

    return <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: "default" }}>
        <div className="popup">
            <h1 className="overlay__title">{t(pageTitleKey)}</h1>
            <div className="popup__close icono-cross" style={{ cursor: "pointer" }} onClick={close}>{t('Allaround.Popup.close')}</div>
            <Formik
                initialValues={{ currentStatus: currentStatus, paymentDate: paymentDate || new Date() }}
                onSubmit={v => onSubmit(v.currentStatus, v.paymentDate)}>
                {
                    formikProps => <Form noValidate={true}>
                        <div className="info__row">
                            <label className="overlay__title form-label mod-font-color-regular">
                                {t("Invoice.ChangeStatus.status")}
                            </label>

                            <div className="input-select mb1">
                                <Field name="currentStatus" component={StatusSelect} options={options} mapping={mapping} />
                            </div>
                        </div>

                        {showPaymentDate && showPaymentDate(formikProps.values.currentStatus)
                            ?
                            <div className="info__row info__row">
                                <label className="info__row-name">{t('Invoice.Table.Date.title')}</label>

                                <div className="info__row-content">
                                    <InlineDateEdit showTimeInput
                                        className="offerion-date"
                                        currentValue={formikProps.values.paymentDate}
                                        onSave={({ newValue }) => { formikProps.setFieldValue("paymentDate", newValue); }} />
                                </div>
                            </div>
                            : null}

                        <div className="popup__buttons mod-margin-top-16">
                            <button className="button button--gray popup__button" type="submit">
                                <span className="icon icon__check-green"></span>
                                {t('Allaround.Button.save')}
                            </button>
                            <button className="button button--gray popup__button" type="button" onClick={close}>
                                <span className="icon icon--small icon__cross-red"></span>
                                {t('Allaround.Button.cancel')}
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    </div >

}
