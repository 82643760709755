import React, { useMemo } from 'react'
import Select, { components, GetOptionLabel, SingleValue } from 'react-select'
import { StatusIconMapping } from '../../shared/models'
import useOfferionTranslation from '../../shared/store/hooks/useOfferionTranslation'
import StatusIndicator from './StatusIndicator'

type StatusSelectProps = {
    options: StatusOption[],
    mapping: StatusIconMapping
}

type StatusOption = {
    value: number
    translationKey: string
}

type StatusOptionData = {
    value: number,
    label: string,
    icon: React.ReactElement
}

// problems with typings
export default function StatusSelect({ options, field, form, mapping }: StatusSelectProps & any) {
    const { t } = useOfferionTranslation()

    const data: StatusOptionData[] = useMemo(
        () =>
            options.map((o: StatusOption) => ({
                value: o.value,
                label: t(o.translationKey),
                icon: <StatusIndicator mapping={mapping} status={o.value} />,
            })),
        [options, t, mapping]
    )

    const selectedOption = useMemo(() => data.find((o: StatusOptionData) => o.value === field.value), [data, field.value])

    return (
        <Select
            name={field.name}
            value={selectedOption}
            onChange={(option: SingleValue<StatusOptionData>) => form.setFieldValue(field.name, option?.value)}
            onBlur={field.onBlur}
            components={{ DropdownIndicator }}
            className="custom-select-container"
            classNamePrefix="custom-select"
            options={data}
            openMenuOnClick
            formatOptionLabel={(v: StatusOptionData) => <StatusValue value={v} />}
            // @ts-ignore
            getOptionLabel={(v: GetOptionLabel<StatusOptionData>) => <StatusValue value={v} />}
        />
    )
}

function StatusValue({ value }: { value: StatusOptionData }) {
    const { t } = useOfferionTranslation()

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {value.icon}
            <span style={{ marginLeft: 5 }}>{t(value.label)}</span>
        </div>
    )
}

const DropdownIndicator = (props: any) => {
    return (
        <components.DropdownIndicator {...props}>
            <span className="icon icon__down-gray"></span>
        </components.DropdownIndicator>
    )
}
