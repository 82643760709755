import React, { useState } from 'react'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';

interface Props {
    close: () => void;
    onSubmit: (file: File) => void;
    titleKey: string;
    footerKey: string;
    extraFooterContentKey?: string;
    maxSizeInKb?: number;
}

export const FileUploadModal = ({ close, footerKey, titleKey, onSubmit, maxSizeInKb, extraFooterContentKey }: Props) => {

    const { t } = useOfferionTranslation();
    const [file, setFile] = useState<File | null>(null);
    const [warning, setWarning] = useState<string>("");
    const fileSizeInKb = maxSizeInKb || 200;

    const selectFile = (file: File) => {

        setFile(file);

        if (file.size / 1000 > fileSizeInKb)
            setWarning(t('Allaround.Validation.maxFileSize', { maxFileSize: fileSizeInKb }))
        else
            setWarning("");
    }

    return <div className="popup__overlay popup__overlay--show">
        <div className="popup">
            <h1 className="overlay__title">{t(titleKey)}</h1>
            <div className="popup__close icono-cross" onClick={close}>{t('Allaround.Popup.close')}</div>
            {warning && <div className="popup__status warning__message">{warning}</div>}

            <div className="file-upload__group upload-form">
                <div className="file-upload__form-wrapper">
                    <input className="input file-input__selected"
                        readOnly={true}
                        disabled={true}
                        placeholder={t('Allaround.selectFilePlaceHolder')}
                        value={file ? file.name : ""}
                    />

                    <label htmlFor="file-upload1" className="button button--padded button--gray file-input__button mod-margin-top-8">
                        <input id="file-upload1" className="file-input" type="file"
                            accept=".gif,.jpeg,.png"
                            onChange={e => e.target.files && selectFile(e.target.files[0])} />
                        <span className="icon icon__document-import"></span>
                        <span className="button-text button-text--always-show">{t('Allaround.Button.select')}</span>
                    </label>
                </div>
                <p className="mod-font-11" dangerouslySetInnerHTML={{ __html: t(footerKey) }}></p>
                {extraFooterContentKey ? <p className="mod-font-11" dangerouslySetInnerHTML={{ __html: t(extraFooterContentKey) }}></p> : null}
            </div>

            <div className="popup__buttons">
                <button className="button button--gray popup__button" disabled={file == null && !warning} onClick={() => file && onSubmit(file)}>
                    <span className="icon icon__check-green"></span>
                    {t('Allaround.Button.accept')}
                </button>
                <button className="button button--gray popup__button" onClick={close}>
                    <span className="icon icon--small icon__cross-red"></span>
                    {t('Allaround.Button.cancel')}
                </button>
            </div>
        </div>
    </div>
}