import React from 'react'
import { Formik, Field } from 'formik'

export const InlineCheckboxEdit = ({
    currentValue,
    id,
    onSave,
}: {
    id: string
    currentValue?: boolean
    onSave: ({ newValue }: { newValue: boolean }) => void
}) => {
    return (
        <Formik enableReinitialize={true} initialValues={{ newValue: currentValue || false }} onSubmit={onSave}>
            {props => {
                function clickHandler(_event: React.MouseEvent<HTMLButtonElement>) {
                    props.setFieldValue('newValue', !props.values.newValue)
                    props.submitForm()
                }

                return (
                    <>
                        <Field
                            id={id}
                            type="checkbox"
                            className="checkbox"
                            name="newValue"
                            onChange={(e: any) => {
                                props.setFieldValue('newValue', e.target.checked)
                                props.submitForm()
                            }}
                        />
                        <button type="button" onClick={clickHandler} className="button button--gray checkbox-button">
                            <span className="checkbox-button__icon"></span>
                        </button>
                    </>
                )
            }}
        </Formik>
    )
}
