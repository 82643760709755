import round from '../utils/round'

export const changeItemField = (object: any, propertyName: string, newValue: number, enterFullPriceEnabled: boolean) => {

    if (enterFullPriceEnabled) {
        switch (propertyName) {
            case 'fullPrice':
                object[propertyName] = newValue
                object['salePrice'] = round(round((round((newValue / object["quantity"])) / (1 - object['discount'] / 100)) / (1 + object['vat'] / 100)));
                break
            case 'quantity':
                object['salePrice'] = round(round(round((object['fullPrice'] / (1 - object['discount'] / 100)) / (1 + object['vat'] / 100))) / newValue);
                object['quantity'] = newValue

                if (newValue > 0 && object['fullPrice'] < 0)
                    object['fullPrice'] = -object['fullPrice'];
                else if (newValue < 0 && object['fullPrice'] > 0)
                    object['fullPrice'] = -object['fullPrice'];

                break

            default:
                object[propertyName] = newValue
                break
        }
    } else {
        switch (propertyName) {
            case 'salePrice':
                object['salePrice'] = newValue
                object['fullPrice'] = round(round(round(newValue * (1 + object['vat'] / 100)) * (1 - object['discount'] / 100)) * object["quantity"])
                break
            case 'discount':
                object['discount'] = newValue
                object['fullPrice'] = round(round(round(object['salePrice'] * (1 + object['vat'] / 100)) * (1 - object['discount'] / 100)) * object["quantity"])
                break

            case 'quantity':

                object["fullPrice"] = round(round(round((round(object.salePrice * newValue) * (1 + object.vat / 100)))) * (1 - object.discount / 100))
                object['quantity'] = newValue
                break;

            default:
                object[propertyName] = newValue
                break
        }
    }

    return object;
}
export default changeItemField;