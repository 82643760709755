import { ApiClient } from '../shared/api/api-client'
import { CompanySettingsModel, Currency, UpdateEInvoiceConfigurationResult } from '../shared/models';

export class CompanySettingsApi {

    static get() {
        return ApiClient.get<CompanySettingsModel>(`api/CompanySettings`);
    }

    static getDefaultCurrency() {
        return ApiClient.get<Currency>(`api/CompanySettings/GetDefaultCurrency`);
    }

    static getSimpleCompanySettings() {
        return ApiClient.get<{ vat: number, showProductDimensionsInfo: boolean, canEditPurchasePrice: boolean, isAccountingVat: boolean }>(`api/CompanySettings/GetSimpleCompanySettings`);
    }

    static getReceiptListSettings() {
        return ApiClient.get<{ defaultMailText: string, isFiscalizationActive: boolean, canCreateInvoices: boolean, canCreateTenders: boolean, isCompanyMissingEInvoiceData: boolean, isEInvoiceConfigured: boolean }>(`api/CompanySettings/ReceiptListSettings`);
    }

    static getPackageSettings() {
        return ApiClient.get<{ canCreateUsers: boolean, canCreateSalePlaces: boolean, canChangeSalePlaces: boolean }>(`api/CompanySettings/PackageSettings`);
    }

    static getTenderListSettings() {
        return ApiClient.get<{ defaultMailText: string, canCreateTenders: boolean, canCreateInvoices: boolean }>(`api/CompanySettings/TenderListSettings`);
    }

    static getTemplateTenderListSettings() {
        return ApiClient.get<{ canCreateInvoices: boolean, canCreateTenders: boolean }>(`api/CompanySettings/TemplateTenderListSettings`);
    }

    static update(settings: CompanySettingsModel) {
        return ApiClient.post<CompanySettingsModel>(`api/CompanySettings`, settings);
    }

    static linkDocumentHeaderResource(linkRequest: { resourceId: number | null }) {
        return ApiClient.post<CompanySettingsModel>(`api/Company/LinkDocumentHeaderResource`, linkRequest);
    }

    static linkDocumentFooterResource(linkRequest: { resourceId: number | null }) {
        return ApiClient.post<CompanySettingsModel>(`api/Company/LinkDocumentFooterResource`, linkRequest);
    }

    static LinkDocumentSignatureResource(linkRequest: { resourceId: number | null }) {
        return ApiClient.post<CompanySettingsModel>(`api/Company/LinkDocumentSignatureResource`, linkRequest);
    }

    static GetEInvoiceConfiguration() {
        return ApiClient.get<{ eReceiptUserName: string, legalCompanyName: string }>(`api/CompanySettings/GetEInvoiceConfiguration`);
    }

    static SaveEInvoiceConfiguration(model: { eReceiptUserName: string, legalCompanyName: string, eReceiptPassword: string }) {
        return ApiClient.post<UpdateEInvoiceConfigurationResult>(`api/CompanySettings/SaveEInvoiceConfiguration`, model);
    }
}