
import React from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { toastSuccess } from '../../shared/toastr';
import { AccountApi } from '../../services/AccountApi';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export const ForgotPasswordContainer = () => {

    const { t } = useOfferionTranslation();

    const onSubmit = async ({ email }: { email: string }) => {
        await AccountApi.startResetPasswordProcess(email);

        toastSuccess('ForgotPassword.Message.InstructionsSent', { email });
    }

    const schema = Yup.object().shape({
        email: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("Email") }))
            .email(t("Allaround.Validation.mail", { fieldName: t("Email") }))
    });

    return <main className="login__container">
        <h1 className="login__title">{t('ForgotPassword.pageTitle')}</h1>
        <Formik initialValues={{ email: "" }} onSubmit={onSubmit} validationSchema={schema}>
            {formikProps => {
                return <Form noValidate={true}>

                    <div className="login__form">
                        <label className="login__label" htmlFor="email">{t('Email')}</label>
                        <Field className="login__input" id="email" type="text" placeholder={t('Email')} name="email" />
                        <OfferionErrorMessage name="email" />

                        <br />
                        <button type="submit" className="login__button button--blue button--padded">{t('ForgotPassword.resetPasswordButton')}</button>

                    </div>
                </Form>
            }}
        </Formik >
    </main>
}