import * as React from 'react';
import { connect, getIn, isFunction, FormikContextType } from 'formik';

export interface ErrorMessageProps {
  name: string;
  showOnlyOnTouched?: boolean;
  className?: string;
  component?: string | React.ComponentType;
  children?: (errorMessage: string) => React.ReactNode;
  render?: (errorMessage: string) => React.ReactNode;
}

class ErrorMessageImpl extends React.Component<
  ErrorMessageProps & { formik: FormikContextType<any> }
> {
  shouldComponentUpdate(
    props: ErrorMessageProps & { formik: FormikContextType<any> }
  ) {
    if (
      getIn(this.props.formik.errors, this.props.name) !==
      getIn(props.formik.errors, this.props.name) ||
      getIn(this.props.formik.touched, this.props.name) !==
      getIn(props.formik.touched, this.props.name) ||
      Object.keys(this.props).length !== Object.keys(props).length
    ) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    let { formik, render, children, name, } = this.props;

    const error = getIn(formik.errors, name);
    const touched = getIn(formik.touched, name);

    if (this.props.showOnlyOnTouched && !touched)
      return null;

    return !!error
      ? render
        ? isFunction(render)
          ? render(error)
          : null
        : children
          ? isFunction(children)
            ? children(error)
            : null
          : React.createElement("span", { className: "error-message" }, error)
      : null;
  }
}

export const OfferionErrorMessage = connect<
  ErrorMessageProps,
  ErrorMessageProps & { formik: FormikContextType<any> }
>(ErrorMessageImpl);