import { ApiClient } from '../shared/api/api-client'

export class ResourceApi {

    public static uploadResource(file: File) {
        const formData = new FormData();

        formData.append("file", file);

        return ApiClient.post<{ resourceId: number }>('api/Resources/Upload', formData);
    }
}
