import { Field, Form, Formik } from 'formik';
import React, { ReactNode } from 'react'
import { OfferionErrorMessage } from '../forms/validation/OfferionErrorMessage';
import { DocumentDetails } from '../models';
import { useRootStoreSelector } from '../store/hooks/useRootStoreSelector';
import { OfferionTooltip } from '../tooltip';
import * as Yup from 'yup'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';

interface Props<TDocumentDetails extends DocumentDetails> {
    clientEmail: string;
    close: () => void;
    defaultMailText: string;
    pageTitleKey: string;
    subTitleKey: string;
    onSubmit: (info: { toEmail: string, toEmailsCc: string, emailBody: string, includeCopyToSelf: boolean, shouldFiscalize: boolean }) => Promise<void>
    documentDetails: () => ReactNode;
    documentFooterRenderer?: () => ReactNode;
}

export const SendViaEmailModal = <TDocumentDetails extends DocumentDetails>({ clientEmail, close, onSubmit, defaultMailText, pageTitleKey, subTitleKey, documentDetails, documentFooterRenderer }: Props<TDocumentDetails>) => {
    const { t } = useOfferionTranslation();

    const schema = Yup.object().shape({
        toEmail: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("Invoice.Send.email") }))
            .email(t("Allaround.Validation.mail", { fieldName: t("Invoice.Send.email") }))
            .nullable(),
    });

    const currentUserEmail = useRootStoreSelector(e => e.user.Email);

    return <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: "default" }}>
        <div className="popup">
            <h1 className="overlay__title">{t(pageTitleKey)}</h1>
            <div className="popup__close icono-cross" style={{ cursor: "pointer" }} onClick={close}>{t('Allaround.Popup.close')}</div>
            <div className="invoice-info-placeholder mod-margin-bottom-8" >
                {t(subTitleKey)}
                {documentDetails()}
            </div>
            <Formik
                validationSchema={schema}
                initialValues={{ toEmail: clientEmail, toEmailsCc: "", emailBody: defaultMailText, includeCopyToSelf: false, shouldFiscalize: true }}
                onSubmit={(values, ctx) => {
                    onSubmit(values).catch(() => {
                        if (values.shouldFiscalize)
                            ctx.setFieldValue("shouldFiscalize", false);
                    })
                }}>
                {
                    formikProps => <Form noValidate={true}>
                        <div className="address-placeholder">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">{t('Invoice.Send.email')}</label>
                                <div className="info__row-content">
                                    <Field type="email" className="address-input" name="toEmail"
                                        placeholder={t('Invoice.Send.emailPlaceholder')} />
                                    <OfferionErrorMessage name="toEmail" />
                                </div>
                                <label className="info__row-name" htmlFor="">{t('Invoice.Send.additionalRecipients')}</label>

                                <div className="info__row-content">
                                    <div className="other-recepients-placeholder">
                                        <Field as="textarea" className="address-input" name="toEmailsCc"
                                            placeholder={t('Invoice.Send.additionalRecipientsPlaceholder')}
                                            ng-keyup="validateCCEmail()" rows={2} />

                                        <div role="alert">
                                            <OfferionErrorMessage name="toEmailsCc" />
                                        </div>
                                    </div>

                                    <div className="info-button">
                                        <OfferionTooltip placement="right" titleKey='Invoice.Send.additionalRecipientsInfoTitle' bodyKey="Invoice.Send.additionalRecipientsInfo" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="body-placeholder">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="">{t('Invoice.Send.message')}</label>
                                <Field as="textarea" className="email-body" name="emailBody"
                                    placeholder={t('Invoice.Send.messagePlaceholder')} required />
                                <OfferionErrorMessage name="emailBody" />
                            </div>
                        </div>
                        <div className="info__row">
                            <div style={{ float: "left" }}>
                                <Field id="show-in-print1" type="checkbox" className="checkbox" name="includeCopyToSelf" />
                                <label htmlFor="show-in-print1"
                                    className="button button--gray button--padded checkbox-button button--print">
                                    <span className="checkbox-button__icon"></span>
                                </label>
                            </div>
                            <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                                {t("Invoice.Send.sendCopyToYourselfYesNo")} {currentUserEmail}
                            </label>
                        </div>

                        {documentFooterRenderer && documentFooterRenderer()}

                        <div className="popup__buttons">
                            <button className="button button--gray popup__button" type="submit">
                                <span className="icon icon__check-green"></span>
                                {t('Allaround.Button.send')}
                            </button>
                            <button className="button button--gray popup__button" type="button" onClick={close}>
                                <span className="icon icon--small icon__cross-red"></span>
                                {t('Allaround.Button.cancel')}
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    </div >

}