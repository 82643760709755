import React, { useState } from 'react';
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation';
import Modal from './index'

const ConfirmationModalContext = React.createContext({
    open: (titleKey: string, messageKey: string, onConfirm: () => Promise<any>, translationObject?: {}) => { }
})

export const useOfferionModal = () => {
    return React.useContext(ConfirmationModalContext);
}

export const ConfirmationModal = (props: React.PropsWithChildren<any>) => {
    const { t } = useOfferionTranslation();
    const [configuration, setConfiguration] = useState<
        {

            titleKey: string,
            messageKey: string,
            onClick: () => Promise<any>,
            translationObject?: {}
        } | null>(null);

    return <ConfirmationModalContext.Provider value={{
        open: (titleKey: string, messageKey: string, onConfirm: () => Promise<any>, translationObject?: {}) => {
            setConfiguration({
                titleKey,
                messageKey,
                onClick: onConfirm,
                translationObject
            })
        }
    }}>
        <Modal isOpen={configuration != null}>
            {
                () => <div className="popup__overlay popup__overlay--show">
                    <div className="popup">
                        <div className="popup__close icono-cross" onClick={() => setConfiguration(null)}>{t('Allaround.Popup.close')}</div>
                        <div className="popup__icon icon__status-warning--small"></div>
                        <div className="popup__status warning__message">{t(configuration!.titleKey)}</div>
                        <div className="popup__message">{t(configuration!.messageKey, configuration!.translationObject)}</div>

                        <div className="popup__buttons">
                            <button className="button button--gray popup__button" onClick={() => configuration!.onClick().then(() => setConfiguration(null))}>
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.yes')}</span>
                            </button>
                            <button className="button button--gray popup__button" onClick={() => setConfiguration(null)} >
                                <span className="icon icon--small icon__cross-red"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.no')}</span>
                            </button>
                        </div>
                    </div>
                </div>
            }
        </Modal>
        {props.children}


    </ConfirmationModalContext.Provider>

}