import { ApiClient } from '../shared/api/api-client'
import { GroupModel } from '../shared/models'

export class GroupApi {

    public static upsert(group: GroupModel) {
        return ApiClient.post<{ id: number }>(`api/groups/upsert`, group);
    }

    public static delete(groupId: number) {
        return ApiClient.delete(`api/groups/delete/${groupId}`);
    }

    public static getGroup(groupId: number | number) {
        return ApiClient.get<GroupModel>(`api/groups/getgroup/${groupId}`)
    }

    public static setImage(productId: number, customImageId: number | null, predefinedImageId: number | null) {
        return ApiClient.post('api/groups/setimage', {
            id: productId,
            customImageId,
            predefinedImageId
        })
    }
}