import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, ReactNode, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'

export const DropdownMenu = ({ children, icon = 'menu' }: { children: ReactNode; icon: 'menu' | 'plus' }) => {
    const [showSideMenu, setShowSideMenu] = useState(false)
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const closeOnClickOutsideHandler = (e: MouseEvent) => {
            const isClickInside = ref.current && ref.current.contains(e.target as any)

            if (!isClickInside) setShowSideMenu(false)
        }

        document.addEventListener('click', closeOnClickOutsideHandler)

        return () => {
            document.removeEventListener('click', closeOnClickOutsideHandler)
        }
    }, [])

    let iconEl
    switch (icon) {
        case 'menu':
            iconEl = <span className="icon icon__menu-blue"></span>
            break;
        case 'plus':
            iconEl = <span className="icon icon__plus"></span>
            break;
    }

    return (
        <div ref={ref} className="dropdown-menu" onClick={() => showSideMenu && setShowSideMenu(false)}>
            <div
                className={`button-outer overlay__item overlay__menu overlay__item--bottom-left ${
                    showSideMenu ? 'overlay__item--active overlay__item--show-overlay' : ''
                }`}>
                <button
                    type="button"
                    onClick={() => setShowSideMenu(!showSideMenu)}
                    className={`button button--gray overlay__button  ${showSideMenu ? 'overlay__button--active' : ''}`}>
                    {iconEl}
                </button>

                <div className="overlay">
                    <ul className="overlay__list">{children}</ul>
                </div>
            </div>
        </div>
    )
}

export const DropdownMenuLink = ({ to, translationKey, icon }: { to: string; translationKey: string; icon?: IconProp }) => {
    const { t } = useOfferionTranslation()

    return (
        <li>
            <Link to={to}>
                {icon && (
                    <span style={{ width: '30px', display: 'inline-block' }}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}

                {t(translationKey)}
            </Link>
        </li>
    )
}

export const DropdownMenuActionLink = ({
    onClick,
    translationKey,
    icon,
}: {
    onClick: () => void
    translationKey: string
    icon?: IconProp
}) => {
    const { t } = useOfferionTranslation()

    function handleClick(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
        event.preventDefault()
        onClick()
    }

    return (
        <li>
            <a href="#" onClick={handleClick}>
                {icon && (
                    <span style={{ width: '30px', display: 'inline-block' }}>
                        <FontAwesomeIcon icon={icon} />
                    </span>
                )}

                {t(translationKey)}
            </a>
        </li>
    )
}
