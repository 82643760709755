import React from 'react';
import SliderComponent from 'rc-slider';
import 'rc-slider/assets/index.css';

type SliderProps = {
  onChange: (val: any) => void,
  onBlur: (event: any) => void,
  value: any,
  min?: number,
  max?: number,
  disabled?: boolean,
  className?: string,
};

export function Slider({
  onChange,
  onBlur,
  value,
  min = 0,
  max = 1000,
  className,
  disabled,
}: SliderProps) {
  return (
    <SliderComponent
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      min={min}
      max={max}
      dotStyle={dotStyle}
      style={style}
      trackStyle={trackStyle}
      railStyle={railStyle}
      handleStyle={handleStyle}
      activeDotStyle={activeDotStyle}
      className={className}
      disabled={disabled}
    />
  )
}

const blue = "#3372df";

const dotStyle = {
  background: blue
};

const style={borderColor: blue};
const trackStyle={background: blue, height: 6};
const railStyle={ height: 6};
const handleStyle={background: blue, border: "none", height: 16, width: 16};
const activeDotStyle={background: blue};
