import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { OfferionErrorMessage } from '../../../shared/forms/validation/OfferionErrorMessage'
import { ClientModel } from '../../../shared/models'
import * as Yup from 'yup'
import { Overlay } from '../../../shared/elements/Overlay'
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation'

interface AddNewClientOverlayProps {
    closeForm: () => void
    onSubmit: (client: ClientModel) => void
    initialValues?: ClientModel | null
    initialNameValue?: string | null
}

const Row = ({ labelTranslationKey, placeholderTranslationKey, name, ...rest }: any) => {
    const { t } = useOfferionTranslation()

    return (
        <div className="info__row">
            <label className="info__row-name">{t(labelTranslationKey)}</label>
            <div className="info__row-content">
                <div>
                    <Field name={name} placeholder={t(placeholderTranslationKey)} {...rest} />
                    <OfferionErrorMessage name={name} />
                </div>
            </div>
        </div>
    )
}

export const ClientForm = (props: AddNewClientOverlayProps) => {
    const { t } = useOfferionTranslation()
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(false)

    const schema = Yup.object().shape({
        name: Yup.string().required(t('Allaround.Validation.required', { fieldName: t('Clients.Edit.name') })),
        email: Yup.string().email(t('Allaround.Validation.mail', { fieldName: t('Clients.Edit.email') })),
    })

    return (
        <Formik
            validationSchema={schema}
            initialValues={
                props.initialValues || {
                    id: 0,
                    name: props.initialNameValue || '',
                    address: '',
                    city: '',
                    oib: '',
                    email: '',
                    country: '',
                    phone: '',
                    cellPhone: '',
                    fax: '',
                    clientRemark: '',
                    printRemark: '',
                    postalCode: '',
                    discountPercent: 0,
                    paymentDelayInDays: 0,
                }
            }
            onSubmit={props.onSubmit}>
            <Overlay>
                <Form noValidate={true}>
                    <div>
                        <h1 className="overlay__title">
                            {t(props.initialValues && props.initialValues.id ? 'Clients.Edit.pageTitleEdit' : 'Clients.Edit.pageTitleNew')}
                        </h1>

                        <button className="button overlay__close" onClick={props.closeForm} type="button">
                            <span className="icono-cross"></span>
                        </button>

                        <div className="info__container">
                            <Row
                                autoFocus
                                name="name"
                                component="textarea"
                                rows={2}
                                labelTranslationKey="Clients.Edit.name"
                                placeholderTranslationKey="Invoice.Table.Client.noClientPlaceholder"
                            />
                            <Row
                                name="address"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.address"
                                placeholderTranslationKey="Clients.Edit.addressPlaceholder"
                            />
                            <Row
                                name="city"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.city"
                                placeholderTranslationKey="Clients.Edit.cityPlaceholder"
                            />
                            <Row
                                name="postalCode"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.zipCode"
                                placeholderTranslationKey="Clients.Edit.zipCodePlaceholder"
                            />
                            <Row
                                name="oib"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.companyNumber"
                                placeholderTranslationKey="Clients.Edit.companyNumberPlaceholder"
                            />
                            <Row
                                name="email"
                                component="input"
                                type="email"
                                labelTranslationKey="Clients.Edit.email"
                                placeholderTranslationKey="Clients.Edit.emailPlaceholder"
                            />

                            <div className="info__row clickable" onClick={() => setShowAdditionalInformation(!showAdditionalInformation)}>
                                <h1 className="table__cell__highlighted-text">
                                    <span className="icon icon--clickable icon__info"></span>
                                    {t('Clients.Edit.additionalInformation')}
                                </h1>

                                <button type="button" className="button button--gray right">
                                    <span className="button button--gray right">
                                        <span className={`icon ${showAdditionalInformation ? 'icon__up' : 'icon__down-blue'}`}></span>
                                    </span>
                                </button>
                            </div>

                            {showAdditionalInformation && (
                                <>
                                    <Row
                                        name="country"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.country"
                                        placeholderTranslationKey="Clients.Edit.countryPlaceholder"
                                    />
                                    <Row
                                        name="phone"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.phone"
                                        placeholderTranslationKey="Clients.Edit.phone"
                                    />
                                    <Row
                                        name="cellPhone"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.cellphone"
                                        placeholderTranslationKey="Clients.Edit.cellphonePlaceholder"
                                    />
                                    <Row
                                        name="fax"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.fax"
                                        placeholderTranslationKey="Clients.Edit.faxPlaceholder"
                                    />
                                    <Row
                                        name="clientRemark"
                                        component="textarea"
                                        rows={2}
                                        labelTranslationKey="Clients.Edit.clientRemark"
                                        placeholderTranslationKey="Clients.Edit.clientRemarkPlaceholder"
                                    />
                                    <Row
                                        name="printRemark"
                                        component="textarea"
                                        rows={2}
                                        labelTranslationKey="Clients.Edit.printRemark"
                                        placeholderTranslationKey="Clients.Edit.printRemarkPlaceholder"
                                    />
                                </>
                            )}
                        </div>

                        <div className="button-group bottom-controls">
                            <button className="button button--gray button--padded" type="submit">
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                            </button>
                            <button className="button button--gray button--padded" type="button" onClick={props.closeForm}>
                                <span className="icon icon--small icon__cross-red"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                            </button>
                        </div>

                        <br />
                    </div>
                </Form>
            </Overlay>
        </Formik>
    )
}

type AddNewClientOverlayProps2 = AddNewClientOverlayProps & {
    styles?: any
    attributes?: any
    setPopperElement?: any
}

export const ClientForm2 = (props: AddNewClientOverlayProps2) => {
    const { t } = useOfferionTranslation()
    const [showAdditionalInformation, setShowAdditionalInformation] = useState(false)

    const schema = Yup.object().shape({
        name: Yup.string().required(t('Allaround.Validation.required', { fieldName: t('Clients.Edit.name') })),
        email: Yup.string().email(t('Allaround.Validation.mail', { fieldName: t('Clients.Edit.email') })),
    })

    return (
        <Formik
            validationSchema={schema}
            initialValues={
                props.initialValues || {
                    id: 0,
                    name: props.initialNameValue || '',
                    address: '',
                    city: '',
                    oib: '',
                    email: '',
                    country: '',
                    phone: '',
                    cellPhone: '',
                    fax: '',
                    clientRemark: '',
                    printRemark: '',
                    postalCode: '',
                    discountPercent: 0,
                    paymentDelayInDays: 0,
                }
            }
            onSubmit={props.onSubmit}>
            <div className="overlay overlay__fix_new overlay--wide">
                <Form noValidate={true}>
                    <div>
                        <h1 className="overlay__title">
                            {t(props.initialValues && props.initialValues.id ? 'Clients.Edit.pageTitleEdit' : 'Clients.Edit.pageTitleNew')}
                        </h1>

                        <button type="button" className="button overlay__close" onClick={props.closeForm}>
                            <span className="icono-cross"></span>
                        </button>

                        <div className="info__container">
                            <Row
                                autoFocus
                                name="name"
                                component="textarea"
                                rows={2}
                                labelTranslationKey="Clients.Edit.name"
                                placeholderTranslationKey="Clients.Edit.namePlaceholder"
                            />
                            <Row
                                name="address"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.address"
                                placeholderTranslationKey="Clients.Edit.addressPlaceholder"
                            />
                            <Row
                                name="city"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.city"
                                placeholderTranslationKey="Clients.Edit.cityPlaceholder"
                            />
                            <Row
                                name="postalCode"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.zipCode"
                                placeholderTranslationKey="Clients.Edit.zipCodePlaceholder"
                            />
                            <Row
                                name="oib"
                                component="input"
                                type="text"
                                labelTranslationKey="Clients.Edit.companyNumber"
                                placeholderTranslationKey="Clients.Edit.companyNumberPlaceholder"
                            />
                            <Row
                                name="email"
                                component="input"
                                type="email"
                                labelTranslationKey="Clients.Edit.email"
                                placeholderTranslationKey="Clients.Edit.emailPlaceholder"
                            />

                            <div className="info__row clickable" onClick={() => setShowAdditionalInformation(!showAdditionalInformation)}>
                                <h1 className="table__cell__highlighted-text">
                                    <span className="icon icon--clickable icon__info"></span>
                                    {t('Clients.Edit.additionalInformation')}
                                </h1>

                                <button type="button" className="button button--gray right">
                                    <span className="button button--gray right">
                                        <span className={`icon ${showAdditionalInformation ? 'icon__up' : 'icon__down-blue'}`}></span>
                                    </span>
                                </button>
                            </div>

                            {showAdditionalInformation && (
                                <>
                                    <Row
                                        name="country"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.country"
                                        placeholderTranslationKey="Clients.Edit.countryPlaceholder"
                                    />
                                    <Row
                                        name="phone"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.phone"
                                        placeholderTranslationKey="Clients.Edit.phone"
                                    />
                                    <Row
                                        name="cellPhone"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.cellphone"
                                        placeholderTranslationKey="Clients.Edit.cellphonePlaceholder"
                                    />
                                    <Row
                                        name="fax"
                                        component="input"
                                        type="text"
                                        labelTranslationKey="Clients.Edit.fax"
                                        placeholderTranslationKey="Clients.Edit.faxPlaceholder"
                                    />
                                    <Row
                                        name="clientRemark"
                                        component="textarea"
                                        rows={2}
                                        labelTranslationKey="Clients.Edit.clientRemark"
                                        placeholderTranslationKey="Clients.Edit.clientRemarkPlaceholder"
                                    />
                                    <Row
                                        name="printRemark"
                                        component="textarea"
                                        rows={2}
                                        labelTranslationKey="Clients.Edit.printRemark"
                                        placeholderTranslationKey="Clients.Edit.printRemarkPlaceholder"
                                    />
                                </>
                            )}
                        </div>

                        <div className="button-group bottom-controls">
                            <button className="button button--gray button--padded" type="submit">
                                <span className="icon icon__check-green"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                            </button>
                            <button className="button button--gray button--padded" type="button" onClick={props.closeForm}>
                                <span className="icon icon--small icon__cross-red"></span>
                                <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                            </button>
                        </div>

                        <br />
                    </div>
                </Form>
            </div>
        </Formik>
    )
}
