/**
 * Checks if the user is in trial period or not.
 * 
 * The check is a bit hacky, the convention (for now) is to check if:
 * - the user's package expiration date is in the future, and
 * - the numbers of users in package is set to "998".
 * 
 * The date is formatted, at least for now as "DD.MM.YYYY",
 * but we're checking, a few formats to be on the safe side.
 *
 * @param loginData the data returned from LoginAPI.login
 * @returns is the user in trial period or not.
 */
export function isTrialPeriod(loginData: any): boolean {
  // We'll do a check to ensure we got the object we need
  const hasRequiredAttrs = typeof loginData === 'object' && loginData !== null && "PackageExpirationDate" in loginData && "NumberOfUsersInPackage" in loginData
  if (!hasRequiredAttrs) {
    return false;
  } else {
    const expirationDate = parseDate(loginData["PackageExpirationDate"] as string)
    const isTrialUserNumber = loginData["NumberOfUsersInPackage"] === "998" || loginData["NumberOfUsersInPackage"] === 998
    const isPackageStillActive = expirationDate ? expirationDate >= new Date() : false;

    return isPackageStillActive && isTrialUserNumber

  }
}

function parseDate(dateString: string): Date | null {
  // just to be on the safe side
  if (typeof dateString !== "string") return null;

  const formats = [
    {
      // Example: 30.12.2024
      regex: /^\d{2}\.\d{2}\.\d{4}$/, parse: (s: string) => {
        const [day, month, year] = s.split('.').map(Number);
        return new Date(year, month - 1, day);
      }
    },
    {
      // Example: 30.12.2024.
      regex: /^\d{2}\.\d{2}\.\d{4}\.$/, parse: (s: string) => {
        const [day, month, year] = s.slice(0, -1).split('.').map(Number);
        return new Date(year, month - 1, day);
      }
    },
    {
      // Example: 12/30/2024
      regex: /^\d{2}\/\d{2}\/\d{4}$/, parse: (s: string) => {
        const [month, day, year] = s.split('/').map(Number);
        return new Date(year, month - 1, day);
      }
    },
    {
      // Example: 2024-30-12
      regex: /^\d{4}-\d{2}-\d{2}$/, parse: (s: string) => new Date(s)
    }
  ];

  for (const format of formats) {
    if (format.regex.test(dateString)) {
      return format.parse(dateString);
    }
  }

  return null;
}
