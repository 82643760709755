import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { useGridActions } from '../../../shared/grid/GridContextProvider';
import { ClientGlobalDiscountModel, ClientDiscountsApi } from '../../../services/ClientDiscountsApi';
import { GridFilterForm } from '../../../shared/grid/GridFilter';
import { Field } from 'formik';
import { NumberField } from '../../../shared/forms/NumberField';
import { useOfferionTranslation } from '../../../shared/store/hooks/useOfferionTranslation';
import { useOfferionModal } from '../../../shared/modal/ConfirmationModal';
import { toastSuccess } from '../../../shared/toastr';

interface FormModel {
    paymentDelayInDays: string,
    discountPercent: number,
    paymentDelayInDaysCustom: number
}

export const AgreedPricesSidebar = ({ updateFilter }: { updateFilter: (filter: any[], customFilter: string) => void }) => {
    
    const { t } = useOfferionTranslation();
    const params = useParams<{ clientId: string }>();
    const offerionModal = useOfferionModal();
    const gridActions = useGridActions();
    const [discounts, setDiscounts] = useState<ClientGlobalDiscountModel | null>();

    useEffect(() => {
        ClientDiscountsApi.getClientGlobalDiscounts(params.clientId).then(setDiscounts)
    }, [])

    if (!discounts)
        return null;

    const builtinOptions = ["0", "7", "15", "30", "45", "60", "75", "90"];

    const updateDiscountsConfiguration = (configuration: FormModel) => {
        const configurationData = {
            paymentDelayInDays: builtinOptions.includes(configuration.paymentDelayInDays)
                ? Number.parseInt(configuration.paymentDelayInDays)
                : configuration.paymentDelayInDaysCustom,
            discountPercent: configuration.discountPercent,
            clientId: params.clientId
        }

        ClientDiscountsApi.updateClientGlobalDiscounts(configurationData);
    }

    const deleteAllDiscountsForClient = () => {

        offerionModal.open('Clients.AgreedPrices.Side.Delete.pageTitle', 'Clients.AgreedPrices.Side.Delete.questionYesNo', async () => {
            await ClientDiscountsApi.deleteAllDiscountForClient(params.clientId);

            gridActions.refresh();

            toastSuccess('Clients.AgreedPrices.Side.Delete.Message.successDelete')
        })
    }

    const initialValues: FormModel = {
        paymentDelayInDays: builtinOptions.includes(discounts.paymentDelayInDays.toString()) ? discounts.paymentDelayInDays.toString() : "other",
        paymentDelayInDaysCustom: discounts.paymentDelayInDays,
        discountPercent: discounts.discountPercent
    }

    return (<div className="main__side-content">
        <GridFilterForm
            initialValues={initialValues}
            onSubmit={updateDiscountsConfiguration}
            titleKey="Clients.AgreedPrices.Side.conditionsTitle">

            {formikProps => {
                return <div className="search__group">
                    <label htmlFor="paymentDelay">{t('Clients.AgreedPrices.Side.paymentDelay')}</label>
                    <div className="select-container search__item">
                        <Field
                            as="select"
                            name="paymentDelayInDays"
                            id="paymentDelay"
                            className="select">
                            {builtinOptions.map(o => <option key={o} value={o}>{o != "0" ? o : ""}</option>)}
                            <option value="other">{t("Allaround.DropDown.Other")}</option>
                        </Field>
                    </div>
                    {
                        !builtinOptions.includes(formikProps.values.paymentDelayInDays) ?
                            <div className="search__group">
                                <label className="search__label less-than-wide-only">{t("Settings.Side.title")}</label>
                                <div className="search__content">
                                    <Field className="search__input search__item"
                                        type="number"
                                        id="paymentDelayAdditionalData"
                                        name="paymentDelayInDaysCustom"
                                        placeholder={t("Clients.AgreedPrices.Side.paymentDelay")}
                                        // onKeyPress="if(this.value.length===3) return false;"
                                        min="0" />
                                </div>
                            </div> : null
                    }
                    <div className="offers__search search-form search__group">
                        <label className="search__label" htmlFor="generalDiscount">{t('Clients.AgreedPrices.Side.generalDiscount')}</label>
                        <div className="search__content">
                            <Field className="search__input search__item"
                                id="generalDiscount"
                                component={NumberField}
                                name="discountPercent"
                                placeholder={t('Clients.AgreedPrices.Side.generalDiscount')}
                            />
                        </div>
                    </div>

                    <button className="button button--gray search__button mod-margin-top-8" type="submit">
                        <span className="icon icon__check-green"></span>
                        <span>{t('Allaround.Button.save')}</span>
                    </button>
                </div>
            }}

        </GridFilterForm >
        <div className="main__side-content-item catalogue__button-container">
            <button className="button button--white catalogue__button" onClick={deleteAllDiscountsForClient}>
                <span className="icon icon__invoice-delete"></span>
                <span className="catalogue__button-text button-text--right">{t('Clients.AgreedPrices.Side.deleteAllPrices')}</span>
            </button>
        </div>
    </div>
    )
}