import React, { useEffect, useState } from 'react'
import { Currency } from '../../../models';
import { TableParams } from '../../../grid/Types';
import { useGridActions } from '../../../grid/GridContextProvider';
import Modal from '../../../modal';
import { CurrencyAmount, CurrencyLabel } from '../../../ui/currency';
import { useOfferionTranslation } from '../../../store/hooks/useOfferionTranslation';

interface Props {
    aggregatedResultsPromiseFactory: (config: TableParams) => Promise<{ currency: Currency, sumOfItemsBasePrice: number, sumOfItemsFullPrice: number }[]>;
}

const AggregatedDetailsModal = (props: Props & { close: () => void }) => {
    const { t } = useOfferionTranslation();
    const [aggregatedResults, setAggregatedResults] = useState<{ currency: Currency, sumOfItemsBasePrice: number, sumOfItemsFullPrice: number }[] | undefined>();
    const ga = useGridActions();

    useEffect(() => {
        props.aggregatedResultsPromiseFactory(ga.config).then(result => {
            setAggregatedResults(result);
        })
    }, [])

    if (aggregatedResults == undefined)
        return null;

    return <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: "default" }}>
        <div className="popup">
            <h1 className="overlay__title">{t('Invoice.Table.Header.total')}</h1>
            <div className="popup__close icono-cross" style={{ cursor: "pointer" }} onClick={props.close}>{t('Allaround.Popup.close')}</div>
            <ul style={{ padding: "0 0 0 5px" }}>
                {aggregatedResults.map(l => {
                    return <>
                        <li style={{ maxWidth: "70%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>{t("Dashboard.amount")}:</span>
                                <CurrencyLabel amount={l.sumOfItemsBasePrice} currency={l.currency} />
                            </div>
                        </li>
                        <li style={{ borderBottom: "1px solid black", maxWidth: "70%" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>{t("Settings.PrintSettings.TableTotalName.taxDefaultText")}:</span>
                                <CurrencyLabel amount={l.sumOfItemsFullPrice - l.sumOfItemsBasePrice} currency={l.currency} />
                            </div>
                        </li>
                        <li style={{ maxWidth: "70%" }} className="mod-margin-bottom-16" >
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>{t("Invoice.Table.Totals.total")}:</span> 
                                <CurrencyLabel amount={l.sumOfItemsFullPrice} currency={l.currency} />
                            </div>
                        </li>
                    </>
                })}
            </ul>
        </div>
    </div >
}

export const AggregatedResultsButton = (props: Props) => {

    const { t } = useOfferionTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false)

    return <div className="button-container">
        <button className="button button--white table__button" onClick={() => setIsModalVisible(true)}>
            <span> &sum;</span>
        </button>
        <span className="button-text table__button-text mod-margin-left-4">{t('Invoice.Table.Header.total')}</span>
        <Modal isOpen={isModalVisible}>
            <AggregatedDetailsModal aggregatedResultsPromiseFactory={props.aggregatedResultsPromiseFactory} close={() => setIsModalVisible(false)} />
        </Modal>
    </div>
}