import { stringify } from "querystring";
import { AuthToken } from "../../shared/models"
import axios from 'axios'

export class LoginApi {
  public static login(email: string, password: string) {

    const instance = axios.create({
      baseURL: process.env.REACT_APP_SERVER_LOCATION
    })

    return instance.post<AuthToken>(
      "token",
      stringify({ userName: email, password, grant_type: "password" }),
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    ).then(r => r.data);
  }
}
