import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { GroupModel } from '../../shared/models';
import { useGridActions } from '../../shared/grid/GridContextProvider';
import { GroupApi } from '../../services/GroupApi';
import * as Yup from 'yup';
import { toastSuccess } from '../../shared/toastr';
import { Overlay } from '../../shared/elements/Overlay';
import { useHistory, useLocation } from 'react-router-dom';
import { ImageWithAuthorization } from '../../shared/elements/ImageWithAuthorization';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

export const GroupForm = (
    {
        initialValues,
        closeForm,
        groupId
    }:
        {
            initialValues: GroupModel | null,
            closeForm: () => void,
            groupId?: number | null
        }) => {


    const { t } = useOfferionTranslation();
    const gridActions = useGridActions();
    const history = useHistory();
    const onSubmit = (group: GroupModel) => {
        return GroupApi.upsert(group);
    }

    const validationSchema = Yup.object()
        .shape({
            name: Yup.string()
                .required(t("Allaround.Validation.required", { fieldName: t("PriceList.EditGroup.name") })),
        });

    return <Formik validationSchema={validationSchema} initialValues={initialValues || {
        id: 0,
        name: "",
        imagePath: "",
        groupId: groupId,
    }} onSubmit={onSubmit}>
        {formikProps => {

            return <Overlay>
                <Form>
                    <h1 className="overlay__title">{t(initialValues ? 'PriceList.EditGroup.pageTitleEdit' : 'PriceList.EditGroup.pageTitleNew')}</h1>
                    <button type="button" className="button overlay__close" onClick={closeForm}>
                        <span className="icono-cross"></span>
                    </button>

                    <div className="info__container">
                        <div className="info__container">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="name">{t('PriceList.EditGroup.name')}</label>
                                <div className="info__row-content">
                                    <div>
                                        <Field autoFocus as="textarea" id="name" name="name" rows={2} placeholder={t('PriceList.EditGroup.namePlaceholder')} />
                                        <OfferionErrorMessage name="name" />
                                    </div>
                                </div>
                            </div>

                            <div className="info__row">

                                {initialValues != null && formikProps.values.imagePath ? <>
                                    <label className="info__row-name" htmlFor="name">{t('PriceList.EditProduct.picture')}</label>
                                    <label className="button button--gray button--padded right" onClick={() => {
                                        GroupApi.setImage(initialValues.id, null, null).then(() => {
                                            formikProps.setFieldValue("imagePath", null);
                                        })
                                    }} >
                                        <span className="icon icon--small icon__upload-delete"></span>
                                        <span className="button-text">{t('Allaround.Picture.removePicture')}</span>
                                    </label>
                                    <ImageWithAuthorization className="mod-margin-top-8" src={initialValues.imagePath} /> </>
                                    :
                                    <>
                                        <label className="info__row-name" htmlFor="name">{t('PriceList.EditProduct.picture')}</label>
                                        <label className="button button--gray button--padded right" onClick={async () => {

                                            const errors = await formikProps.validateForm();

                                            if (Object.keys(errors).length)
                                                return;


                                            formikProps.submitForm().then(({ id }: any) => {

                                                history.push("/image-selection", {
                                                    groupId: id
                                                })
                                            })
                                        }} >
                                            <span className="icon icon--small icon__upload"></span>
                                            <span className="button-text">{t('Allaround.Picture.addPicture')}</span>
                                        </label>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="button-group bottom-controls">
                        <button className="button button--gray button--padded" type="button" onClick={async () => {

                            const errors = await formikProps.validateForm();

                            if (Object.keys(errors).length)
                                return;

                            formikProps.submitForm()
                                .then(() => {
                                    if (initialValues)
                                        toastSuccess('PriceList.EditGroup.Message.successUpdate');
                                    else
                                        toastSuccess('PriceList.EditGroup.Message.successCreate');
                                })
                                .then(gridActions.refresh)
                                .then(closeForm)
                        }}>
                            <span className="icon icon__check-green"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.save')}</span>
                        </button>
                        <button className="button button--gray button--padded" type="button" onClick={closeForm}>
                            <span className="icon icon--small icon__cross-red"></span>
                            <span className="button-text button-text--always-show">{t('Allaround.Button.cancel')}</span>
                        </button>
                    </div>

                    <br />
                </Form>
            </Overlay>
        }}
    </Formik>
}

export const AddGroupButton = ({ currentGroup }: { currentGroup: number | null }) => {
    const { t } = useOfferionTranslation();

    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    if (isOverlayVisible) {
        return (
            <div className="button-container-with-text float-left">
                <div className="button-container small-hide overlay__item overlay__item--bottom-left overlay__item--active  mr0">
                    <button className="button button--gray button--padded overlay__button overlay__button--active">
                        <span className="icon icon__group"></span>
                        <span className="icon icon__plus"></span>
                    </button>

                    <GroupForm initialValues={null} closeForm={() => setIsOverlayVisible(false)} groupId={currentGroup} />
                </div>
                <span className="button-text mod-margin-left-4">{t('PriceList.Header.addGroup')}</span>
            </div>
        );
    } else {
        return (
            <div className="button-container-with-text float-left">
                <div className="button-container mr0" onClick={() => setIsOverlayVisible(true)}>
                    <button className="button button--white button--padded overlay__button">
                        <span className="icon icon icon__group"></span>
                        <span className="icon icon__plus"></span>
                    </button>
                </div>
                <span className="button-text mod-margin-left-4">{t('PriceList.Header.addGroup')}</span>
            </div>
        );
    }
}
