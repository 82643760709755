import React, { useState, useEffect, useRef } from 'react'
import { Formik, Form } from 'formik'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDatePicker from 'react-datepicker'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import hr from 'date-fns/locale/hr'
import { useOfferionTranslation } from '../store/hooks/useOfferionTranslation'

dayjs.extend(utc);

Date.prototype.toJSON = function () {
    // you can use moment or anything else you prefer to format
    // the date here
    return dayjs(this).format("YYYY-MM-DDTHH:mm:ss")
}

const MobilePicker = ({
    currentValue,
    className,
    showTimeInput,
    onSave,
    placeholderKey,
}: {
    currentValue: Date | undefined
    className?: string
    showTimeInput?: boolean
    onSave: ({ newValue }: { newValue: Date | undefined }) => void
    placeholderKey?: string
}) => {
    const { t } = useOfferionTranslation()
    const fieldRef = useRef<HTMLInputElement>(null)

    const type = showTimeInput ? 'datetime-local' : 'date'

    const currentValueFormatted = currentValue
        ? showTimeInput
            ? dayjs(currentValue).format('YYYY-MM-DDTHH:mm')
            : dayjs(currentValue).format('YYYY-MM-DD')
        : ''

    return (
        <>
            <input
                autoFocus
                ref={fieldRef}
                type={type}
                onChange={e => {
                    const value = e.target.value

                    const dayjsValue = showTimeInput ? dayjs(value, 'YYYY-MM-DDTHH:mm') : dayjs(value, 'YYYY-MM-DD')

                    onSave({ newValue: dayjsValue.toDate() })
                }}
                className="offerion-mobile-date-picker"
                placeholder={t(placeholderKey || '')}
                // onBlur={() => fieldRef.current && (fieldRef.current.style.display = "none")}
                value={currentValueFormatted}
            />

            {/* <span className={className} onClick={() => {

            fieldRef.current && (fieldRef.current.style.display = "block");
            fieldRef.current && fieldRef.current.focus();
        }}> {currentValue ? dayjs(currentValue).format(`${showTimeInput ? "DD.MM.YYYY HH:mm" : "DD.MM.YYYY"}`) : t(placeholderKey || "")} </span> */}
        </>
    )
}

export const InlineDateEdit = ({
    currentValue,
    onSave,
    className,
    placeholderKey,
    showTimeInput,
}: {
    showTimeInput?: boolean | undefined
    placeholderKey?: string
    className?: string
    currentValue?: string | Date
    onSave: ({ newValue }: { newValue: Date | undefined }) => void
}) => {
    const [isEdit, setIsEdit] = useState(false)
    const fieldRef = useRef<ReactDatePicker>(null)

    useEffect(() => {
        if (isEdit && fieldRef.current) {
            fieldRef.current!.setFocus()
        }
    }, [isEdit])

    const { t } = useOfferionTranslation()

    const formValue = currentValue ? (typeof currentValue == 'string' ? new Date(currentValue) : currentValue) : undefined

    const shouldCloseOnSelect = !showTimeInput

    return false ? (
        <MobilePicker
            placeholderKey={placeholderKey}
            onSave={onSave}
            className={className}
            currentValue={formValue}
            showTimeInput={showTimeInput}
        />
    ) : (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{ newValue: formValue }}
                onSubmit={values => {
                    if (values.newValue != formValue && isEdit) onSave(values)

                    setIsEdit(false)
                }}>
                {props => {
                    return (
                        <Form style={{ display: isEdit ? 'block' : 'none' }}>
                            <DatePicker
                                autoFocus
                                onClickOutside={props.submitForm}
                                shouldCloseOnSelect={shouldCloseOnSelect}
                                ref={fieldRef}
                                selected={props.values.newValue}
                                locale={hr}
                                formatWeekDay={day => {
                                    return day.charAt(0).toUpperCase() + day.slice(1, 3)
                                }}
                                timeInputLabel={'Vrijeme'}
                                onChange={(e: any) => {

                                    props.setFieldValue('newValue', e)

                                    if (shouldCloseOnSelect) props.submitForm()
                                }}
                                popperPlacement="bottom-start"
                                popperModifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                }}
                                showTimeInput={showTimeInput}
                                dateFormat={`${showTimeInput ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy'}`}
                            />
                        </Form>
                    )
                }}
            </Formik>

            <span
                className={`${className} ${formValue ? 'has-value' : 'no-value'}`}
                style={{ display: isEdit ? 'none' : 'block', width: '100%', cursor: 'pointer' }}
                onClick={() => setIsEdit(true)}>
                {formValue != null
                    ? dayjs(formValue).format(`${showTimeInput ? 'DD.MM.YYYY HH:mm' : 'DD.MM.YYYY'}`)
                    : t(placeholderKey || '')}
            </span>
        </>
    )
}
