import React, { useRef } from 'react'
import { Field } from 'formik'

type CheckboxProps = {
    name: string
    label?: string
    children?: React.ReactNode
    // there's 2 icons for the checkbox, one for the regular version and one for the print version
    mobileIcon?: 'checkbox' | 'print'
    // should the text be collapsed on smaller screens
    hideLabelOnSmallerScreens?: boolean
}

export function Checkbox({
    name,
    label,
    children,
    mobileIcon = 'checkbox',
    hideLabelOnSmallerScreens = false,
}: CheckboxProps) {
    const checkboxRef = useRef<HTMLInputElement>(null)
    // depending on how this evolves, maybe the system for picking an icon should be more robust, for now this is enough
    const iconClasses = mobileIcon === 'checkbox' ? 'new-checkbox__button__icon' : 'new-checkbox__button__icon new-checkbox__button__icon--print'
    const buttonClasses = `new-checkbox__button ${hideLabelOnSmallerScreens ? 'new-checkbox__button--hide-label-on-mobile' : ''} ${label ? 'new-checkbox__button--has-label' : ''}`

    const handleClick = () => {
        // Programmatically click the checkbox
        if (checkboxRef.current) {
            checkboxRef.current.click()
        }
    }

    return (
        <div className="new-checkbox">
            <Field id={name} type="checkbox" name={name} innerRef={checkboxRef} />

            <button
                type="button"
                onClick={handleClick}
                className={buttonClasses}>
                <span className={iconClasses}></span>
                {label ? <span className="new-checkbox__button-text">{label}</span> : null}
            </button>
            <label htmlFor={name}>{children}</label>
        </div>
    )
}

