import { ApiClient } from '../shared/api/api-client'
import { ProductModel } from '../shared/models'

export class ProductApi {

    public static upsert(product: ProductModel) {
        return ApiClient.post<{ id: number }>(`api/products/upsert`, product)
    }

    public static getProduct(productId: number | number) {
        return ApiClient.get<ProductModel>(`api/products/getProduct/${productId}`)
    }

    public static delete(productId: number) {
        return ApiClient.delete(`api/products/delete/${productId}`);
    }

    public static patchStringField(productId: number, field: string, value: string) {
        return ApiClient.post('api/products/patchStringField', {
            id: productId,
            field,
            value
        })
    }

    public static patchNumberField(productId: number, field: string, value: number) {
        return ApiClient.post('api/products/patchNumberField', {
            id: productId,
            field,
            value
        })
    }

    public static setImage(productId: number, customImageId: number | null, predefinedImageId: number | null) {
        return ApiClient.post('api/products/setimage', {
            id: productId,
            customImageId,
            predefinedImageId
        })
    }
}