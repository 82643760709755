import { ApiClient } from '../shared/api/api-client'
import { UserModel } from '../shared/models'

export class UsersApi {

    public static get(userId: number | string) {
        return ApiClient.get<UserModel>(`api/users/getUser/${userId}`);
    }

    public static delete(userId: number | string) {
        return ApiClient.delete<boolean>(`api/users/DeleteUser/${userId}`);
    }

    public static upsertUser(user: UserModel & { newPassword: string, sendEmail: boolean }) {
        const { sendEmail, ...rest } = user;

        const data = {
            user: rest,
            sendEmail: sendEmail
        }

        return ApiClient.post<{ id: number, userUpserted: boolean, canCreateNextUser: boolean }>(`api/users/upsertUser`, data);
    }

    public static getCurrentUserSalePlaceInformation() {
        return ApiClient.get<{ companySalePlaceId: number, canChangeSalePlace: boolean }>(`api/users/getCurrentUserSalePlaceInformation`);
    }

    public static changeCurrentUserSalePlace(id: number) {
        return ApiClient.post(`api/users/ChangeCurrentUserSalePlace`, { id });
    }

    public static changeUserAdminStatus(userId: number, isAdministrator: boolean) {
        return ApiClient.post('api/users/ChangeAdminRoleStatus', {
            userId,
            isAdministrator
        })
    }

    public static isEmailUnique(email: string) {
        return ApiClient.post<boolean>('api/users/isEmailUnique', {

            email
        }, {}, { ignoreLoader: true })
    }

    public static changeUserEmail(userId: number, email: string) {
        return ApiClient.post('api/users/ChangeUserEmail', {
            userId,
            email
        })
    }
}
