import dayjs from 'dayjs';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';

interface Props {
    close: () => void;
    officialDate: Date | string;
    onSubmit: (shouldFiscalize: boolean) => Promise<void>;
}

export const ConfirmFiscalizationModal = ({ close, onSubmit, officialDate }: Props) => {

    const { t } = useOfferionTranslation();

    const dayJsOfficialDate = dayjs(officialDate).add(2, "day");
    const today = dayjs(new Date());
    const canFiscalize = dayJsOfficialDate.isAfter(today);

    return <div className="popup__overlay popup__overlay--show send-document-by-email">
        < div className="popup" >
            <h1 className="overlay__title">{t('Invoice.Print.Fiscalisation.pageTitle')}</h1>
            <div className="popup__close icono-cross" onClick={close}>{t('Allaround.Popup.close')}</div>
            <Formik initialValues={{ shouldNotFiscalize: !canFiscalize }}
                onSubmit={(val, ctx) => onSubmit(!val.shouldNotFiscalize).catch(() => {
                    if (!val.shouldNotFiscalize)
                        ctx.setFieldValue("shouldNotFiscalize", true);
                })}>
                {
                    formikProps => <Form>
                        <div className="address-placeholder">
                            <div className="info__row">
                                {t(canFiscalize ? "Invoice.Print.Fiscalisation.questionYesNo" : "Allaround.Validation.Fiscalization.tooLate")}
                            </div>
                            <div className="info__row">

                                <div style={{ float: "left" }}>
                                    <Field id="show-in-print1" type="checkbox" className="checkbox" name="shouldNotFiscalize" disabled={!canFiscalize} />
                                    <label htmlFor="show-in-print1"
                                        className="button button--gray button--padded checkbox-button button--print">
                                        <span className="checkbox-button__icon"></span>
                                    </label>
                                </div>
                                <label className="info__row-name" htmlFor="" style={{ float: "left" }}>
                                    {t("Invoice.Print.Fiscalisation.printDontFiscalize")}
                                </label>
                            </div>
                        </div>
                        <div className="popup__buttons mod-margin-top-8">
                            <button className="button button--gray popup__button" type="submit">
                                <span className="icon icon__check-green"></span>
                                {t('Allaround.Button.print')}
                            </button>
                            <button className="button button--gray popup__button" type="button" onClick={close}>
                                <span className="icon icon--small icon__cross-red"></span>
                                {t('Allaround.Button.cancel')}
                            </button>
                        </div>
                    </Form>
                }
            </Formik>
        </div >
    </div >

}