import React, { ReactNode } from 'react'
import { Currency } from '../../models';
import c from 'currency.js'
import { currencyAsText } from '../../utils/formatters';
import { useOfferionTranslation } from '../../store/hooks/useOfferionTranslation';

type CurrencyLabelProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    amount?: number | null
    currency: Currency | null;
    customFormatter?: (value: JSX.Element) => ReactNode;
}

const currencyToString = (currency: Currency | null) => {

    switch (currency) {
        case Currency.EUR:
            return '€'

        case Currency.HRK:
            return 'kn'

        case Currency.BAM:
            return 'KM'

        case Currency.GBP:
            return '£'

        case Currency.USD:
            return '$'

        case Currency.CHF:
            return 'Fr'

        case Currency.RSD:
            return 'din'

        default:
            return null;
    }
}

export const CurrencyLabel = ({ amount, currency, customFormatter, ...rest }: CurrencyLabelProps) => {

    const value = <><CurrencyAmount amount={amount} /> <CurrencyCode currency={currency} /></>
    const formattedValue = customFormatter ? customFormatter(value) : value;

    return (
        <span {...rest}>
            {formattedValue}
        </span>
    )
}

export const CurrencyAmount = ({ amount }: { amount?: number | null }) => {
    const fixedAmount = !amount || isNaN(amount) || amount == Infinity
        ? 0
        : amount;

    return (<>{currencyAsText(fixedAmount)} </>)
}

export const CurrencyName = ({ currency }: { currency: Currency }) => {
    const { t } = useOfferionTranslation();

    switch (currency) {
        case Currency.EUR:
            return <>{t("Allaround.Currency.EUR.title")}</>

        case Currency.HRK:
            return <>{t("Allaround.Currency.HRK.title")}</>

        case Currency.BAM:
            return <>{t("Allaround.Currency.BAM.title")}</>

        case Currency.GBP:
            return <>{t("Allaround.Currency.GBP.title")}</>

        case Currency.USD:
            return <>{t("Allaround.Currency.USD.title")}</>

        case Currency.CHF:
            return <>{t("Allaround.Currency.CHF.title")}</>

        case Currency.RSD:
            return <>{t("Allaround.Currency.RSD.title")}</>

        default:
            return <>''</>
    }

}

export const CurrencyCode = ({ currency }: { currency: Currency | null }) => {
    const code = currencyToString(currency);

    return <>{code}</>;
}

export const CurrencyOptions = () => {
    const { t } = useOfferionTranslation();

    return <>
        <option value={Currency.EUR}>{t("Allaround.Currency.EUR.title")} ({currencyToString(Currency.EUR)})</option>
        <option value={Currency.HRK}>{t("Allaround.Currency.HRK.title")} ({currencyToString(Currency.HRK)})</option>
        <option value={Currency.BAM}>{t("Allaround.Currency.BAM.title")} ({currencyToString(Currency.BAM)})</option>
        <option value={Currency.GBP}>{t("Allaround.Currency.GBP.title")} ({currencyToString(Currency.GBP)})</option>
        <option value={Currency.USD}>{t("Allaround.Currency.USD.title")} ({currencyToString(Currency.USD)})</option>
        <option value={Currency.CHF}>{t("Allaround.Currency.CHF.title")} ({currencyToString(Currency.CHF)})</option>
        <option value={Currency.RSD}>{t("Allaround.Currency.RSD.title")} ({currencyToString(Currency.RSD)})</option>
    </>
}
