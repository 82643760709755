import { ApiClient } from '../shared/api/api-client'

export interface PredefinedFolderModel {
    folders: { id: number, name: string }[]
    images: { id: number, name: string }[]
}

export interface CustomImageModel {
    resourceName: string;
    customImageId: number;
    resourceId: number;
}

export class PicturesApi {

    public static getContentForPredefinedFolder(folderId?: number | null) {
        return ApiClient.get<PredefinedFolderModel>(`api/predefinedimages/GetContentForFolder${folderId ? `?folderId=${folderId}` : ""}`);
    }

    public static getCustomImages() {
        return ApiClient.get<CustomImageModel[]>(`api/CustomImages/GetCustomImages`);
    }

    public static deleteCustomImage(imagesToDelete: number[]) {
        return ApiClient.post(`api/CustomImages/DeleteCustomImages`, imagesToDelete);
    }

    public static addCustomImage(resourceId: number) {
        return ApiClient.post(`api/CustomImages/AddCustomImageResource`, { resourceId: resourceId });
    }
}