import { ApiClient } from '../shared/api/api-client'
import { ListingApi } from './ListingApi';
import { GridData } from '../shared/grid/Types';

export interface UnitItemModel {
    id: number;
    name: string;
    existsInSettings : boolean;
}

export class UnitsApi {
    public static get() {
        return ListingApi.getList<UnitItemModel>("AvailableUnitsModel", 1, 100, []).then(r => r.data);
    }
}
