import React, { useState, useEffect, useCallback, useRef, ReactText } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'
import { ActionMenuItem, DocumentDetailsContainer, useOfferionDocument } from '../../shared/documents/details/DocumentDetailsContainer'
import {
    InvoiceDocumentModel,
    ConcreteTenderDocumentModel,
    TenderStatus,
    CompanyPrintSettingsModel,
    TemplateTenderDocumentModel,
    PaymentMethod,
    PriceCalculationMode,
    ReceiptStatus,
    TenderStatusHistoryEnum,
    VersionInformation,
} from '../../shared/models'
import { InlineInputEdit } from '../../shared/forms/InlineInputEdit'
import { FormikProps, Field, useFormikContext } from 'formik'
import { InlineDateEdit } from '../../shared/forms/InlineDateEdit'
import { ClientInput } from '../../shared/documents/details/elements/client-input/ClientInput'
import {
    ConcreteTenderApi,
    TenderConfigurationModel as ConcreteTenderConfigurationModel,
    TenderLogModel,
} from '../../services/ConcreteTenderApi'
import * as Yup from 'yup'
import { InvoiceApi } from '../../services/InvoiceApi'
import { OfferionTooltip } from '../../shared/tooltip'
import Modal from '../../shared/modal'
import { SendViaEmailModal } from '../../shared/documents/SendViaEmailModal'
import { CurrencyLabel } from '../../shared/ui/currency'
import forCharge from '../../shared/utils/forCharge'
import { ChangePricesAction } from '../../shared/documents/details/elements/ChangePricesAction'
import { toastSuccess, toastWarning } from '../../shared/toastr'
import { date, getKeyForTenderStatus } from '../../shared/utils/formatters'
import { faCog, faEnvelope, faFileMedical, faHistory, faPrint, faStream, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { ChangeStatusModal } from '../../shared/documents/ChangeStatusModal'
import { openDocument } from '../../shared/utils/openDocument'
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation'
import { useOfferionModal } from '../../shared/modal/ConfirmationModal'
import { QuickActionItem, QuickActionSubtitle, QuickActionTitle } from '../../shared/documents/QuickActions'

const TenderLogItem = ({ item }: { item: TenderLogModel }) => {
    const { t } = useOfferionTranslation()
    if (item.status == TenderStatusHistoryEnum.Draft)
        return (
            <>
                {t('Invoice.Log.documentCreated')} ({item.userFullName})
            </>
        )

    if (item.status == TenderStatusHistoryEnum.InvoiceCreated)
        return (
            <>
                {t('Invoice.Log.invoiceCreated')} ({item.userFullName})
            </>
        )

    if (item.showOnlyStatusChange)
        return (
            <>
                {t('Invoice.Log.StatusChanged', { status: t(getKeyForTenderStatus(item.status)) })} (
                {item.userFullName || t('Invoice.Log.System')})
            </>
        )

    if (item.status == TenderStatusHistoryEnum.Sent)
        return (
            <>
                {t('Invoice.Log.documentSent', { mailAddress: item.additionalInformation })} ({item.userFullName || t('Invoice.Log.System')}
                )
            </>
        )

    return (
        <>
            {t('Invoice.Log.StatusChanged', { status: t(getKeyForTenderStatus(item.status)) })} (
            {item.userFullName || t('Invoice.Log.System')})
        </>
    )
}

export const TenderLogModal = ({ close, tenderId }: { close: () => void; tenderId: ReactText | undefined }) => {
    const { t } = useOfferionTranslation()
    const [logs, setLogs] = useState<TenderLogModel[] | undefined>()

    useEffect(() => {
        if (!tenderId) return

        ConcreteTenderApi.getLogs(tenderId).then(result => {
            setLogs(result)
        })
    }, [])

    if (logs == undefined) return null

    return (
        <div className="popup__overlay popup__overlay--show send-document-by-email" style={{ cursor: 'default' }}>
            <div className="popup" style={{ maxWidth: '550px' }}>
                <h1 className="overlay__title">{t('Invoice.Log.pageTitle')}</h1>
                <div className="popup__close icono-cross" style={{ cursor: 'pointer' }} onClick={close}>
                    {t('Allaround.Popup.close')}
                </div>
                <ul style={{ padding: 0 }}>
                    {logs.map(l => {
                        return (
                            <li>
                                {date(l.createdAt)} <TenderLogItem item={l} />
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export const ConcreteTenderDetailsContainer = () => {
    const params = useParams<{ tenderId: string | undefined }>()
    const history = useHistory()
    const location = useLocation()
    const { t } = useOfferionTranslation()
    const [sendEmailModalVisible, setSendEmailModalVisible] = useState(false)
    const [isLogModalVisible, setIsLogModalVisible] = useState(false)
    const [changeStatusModalVisible, setChangeStatusModalVisible] = useState(false)
    const offerionModal = useOfferionModal()

    const [configuration, setConfiguration] = useState<ConcreteTenderConfigurationModel | undefined>()

    useEffect(() => {
        ConcreteTenderApi.getConfiguration(params.tenderId).then(config => {
            if (!config.canCreateTenders && !params.tenderId) {
                const message = t('Allaround.Message.packageLimitReached', {
                    offersOrInvoicesGenitiv: t('Allaround.Message.offersGenitiv'),
                })

                toastWarning(message, true)

                history.replace('/offers')
            }

            if (config.showVatAccountingWarning) {
                if (config.isAccountingVat) {
                    toastWarning('Invoice.Header.VATIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.VATIsNowOn.warningMessage')
                }
            }

            if (config.showPriceCalculationWarning) {
                if (config.enterFullPriceEnabled) {
                    toastWarning('Invoice.Header.TotalPriceIsNowOff.warningMessage')
                } else {
                    toastWarning('Invoice.Header.TotalPriceIsNowOn.warningMessage')
                }
            }

            setConfiguration(config)
        })
    }, [params.tenderId])

    const documentPromiseFactory = useCallback(() => {
        if (location.state) {
            return new Promise<ConcreteTenderDocumentModel>(resolve => {
                resolve(location.state as ConcreteTenderDocumentModel)
            })
        }

        if (params.tenderId) return ConcreteTenderApi.get(params.tenderId)
        else
            return ConcreteTenderApi.getConfiguration(params.tenderId).then(configuration => {
                return {
                    client: null,
                    details: {
                        cultureName: configuration.defaultTenderLanguageId,
                        id: 0,
                        number: configuration.nextTenderNumber,
                        documentCurrency: configuration.newTenderCurrency,
                        clientCurrency: configuration.newTenderClientCurrency,
                        documentCurrencyRate: configuration.documentCurrencyRate,
                        clientCurrencyRate: configuration.clientCurrencyRate,
                        paymentNoteForReceipt: configuration.newTenderReceiptNote,
                        paymentNoteForTender: configuration.newTenderNote,
                        priceCalculationMode: configuration.enterFullPriceEnabled
                            ? PriceCalculationMode.FullPrice
                            : PriceCalculationMode.BasePrice,
                        officialDate: new Date(),
                        status: TenderStatus.Draft,
                        versionInformation: VersionInformation.Latest,
                    },
                    items: [],
                }
            })
    }, [params.tenderId])

    if (!configuration) return null

    const upsertDocument = (document: ConcreteTenderDocumentModel) => {
        return ConcreteTenderApi.upsert(document).then(newDoc => {
            if (document.details.id == 0) {
                history.replace(`/offer/${newDoc.details.id}`)
            }

            return newDoc
        })
    }

    const createInvoice = async (document: ConcreteTenderDocumentModel) => {
        if (!configuration || !configuration.canCreateInvoices) {
            const message = t('Allaround.Message.packageLimitReached', {
                offersOrInvoicesGenitiv: t('Allaround.Message.invoicesGenitiv'),
            })

            toastWarning(message, true)
        } else {
            const nextNumber = await InvoiceApi.getNextInvoiceNumber()

            const invoice: InvoiceDocumentModel = {
                client: document.client
                    ? {
                          ...document.client,
                          hideOnListing: true,
                          sourceClientId: document.client.id,
                          id: 0,
                      }
                    : null,
                items: document.items.map(i => {
                    return { ...i, id: 0 }
                }),
                fiscalization: {
                    isFiscalized: false,
                    isInsideFiscalizationInterval: true,
                },
                details: {
                    ...document.details,
                    note: document.details.paymentNoteForReceipt,
                    number: nextNumber,
                    id: 0,
                    paymentMethod: PaymentMethod.Account,
                    status: ReceiptStatus.Draft,
                    officialDate: new Date(),
                    concreteTenderId: document.details.id,
                    paymentDateOffset: 15,
                },
            }

            InvoiceApi.upsert(invoice).then(result => {
                history.push(`/invoice/${result.details.id}`, result)
            })
        }
    }

    const deleteTender = (document: ConcreteTenderDocumentModel) => {
        offerionModal.open(
            'Invoice.DeleteOffer.pageTitle',
            'Invoice.DeleteOffer.questionYesNo',
            async () => {
                await ConcreteTenderApi.delete(document.details.id)

                history.replace('/offers')

                toastSuccess('Invoice.DeleteOffer.Message.successDelete')
            },
            { offerNumber: document.details.number }
        )
    }

    const createTemplate = (document: ConcreteTenderDocumentModel) => {
        const template: TemplateTenderDocumentModel = {
            client: document.client,
            items: document.items.map(i => {
                return { ...i, id: 0 }
            }),
            details: { ...document.details, id: 0, templateName: '' },
        }

        history.push('/template-offer/new-from-offer', template)
    }

    const getDocument = (document: ConcreteTenderDocumentModel) => {
        params.tenderId &&
            ConcreteTenderApi.getDocument(params.tenderId).then((data: any) => {
                openDocument(`${t('Invoice.Print.offer')} ${document.details.number}.pdf`, data)
            })
    }

    const emailOffer = (info: { toEmail: string; toEmailsCc: string; emailBody: string; includeCopyToSelf: boolean }) => {
        return ConcreteTenderApi.sendTender(params.tenderId, info.toEmail, info.toEmailsCc, info.emailBody, info.includeCopyToSelf).then(
            () => setSendEmailModalVisible(false)
        )
    }

    const pageTitle = t(params.tenderId ? 'Invoice.Header.pageTitleEditOffer' : 'Invoice.Header.pageTitleNewOffer')

    const ActionComponent = ({ document, openSettingsModal }: { document: ConcreteTenderDocumentModel; openSettingsModal: () => void }) => {
        const formik = useFormikContext<ConcreteTenderDocumentModel>()

        return (
            <>
                <ActionMenuItem
                    icon={faPrint}
                    onClick={() => getDocument(document)}
                    labelKey="Invoice.Header.Menu.print"
                    id="Invoice.Header.Menu.print"></ActionMenuItem>
                <ActionMenuItem
                    icon={faEnvelope}
                    onClick={() => setSendEmailModalVisible(true)}
                    labelKey="Invoice.Header.Menu.sendMail"
                    id="Invoice.Header.Menu.sendMail"></ActionMenuItem>

                <Modal isOpen={sendEmailModalVisible}>
                    <SendViaEmailModal
                        pageTitleKey="Invoice.Send.pageTitleOffer"
                        subTitleKey="Invoice.Send.offerAttached"
                        defaultMailText={configuration.defaultMailText}
                        clientEmail={document.client ? document.client.email : ''}
                        documentDetails={() => (
                            <span className="invoice-info">
                                <ul>
                                    <li>{document.details.number}</li>
                                    <li>{document.client ? document.client.name : null}</li>
                                    <li>
                                        <CurrencyLabel
                                            amount={forCharge(configuration.enterFullPriceEnabled, document.items)}
                                            currency={document.details.documentCurrency}
                                        />
                                    </li>
                                </ul>
                            </span>
                        )}
                        close={() => setSendEmailModalVisible(false)}
                        onSubmit={emailOffer}
                    />
                </Modal>
                <ActionMenuItem
                    icon={faCog}
                    onClick={openSettingsModal}
                    allowActionIfNotSaved={true}
                    allowActionWithoutAnyItems={true}
                    labelKey="Invoice.Header.Menu.offerSettings"
                    id="Invoice.Header.Menu.offerSettings"></ActionMenuItem>
                <ActionMenuItem
                    icon={faStream}
                    onClick={() => setChangeStatusModalVisible(true)}
                    labelKey="Invoice.Side.changeStatus"
                    id="Invoice.Side.changeStatus"></ActionMenuItem>
                <Modal isOpen={changeStatusModalVisible}>
                    <ChangeStatusModal
                        mapping="TenderStatus"
                        currentStatus={document.details.status}
                        onSubmit={async newStatus => {
                            formik.setValues({
                                ...document,
                                details: {
                                    ...document.details,
                                    status: newStatus,
                                },
                            })

                            await formik.submitForm()

                            setChangeStatusModalVisible(false)
                        }}
                        pageTitleKey="Invoice.ChangeStatus.pageTitle"
                        close={() => setChangeStatusModalVisible(false)}
                        options={[
                            { value: 0, translationKey: 'Allaround.Status.draft' },
                            { value: 1, translationKey: 'Allaround.Status.offerSent' },
                            { value: 2, translationKey: 'Allaround.Status.offerAccepted' },
                            { value: 3, translationKey: 'Allaround.Status.offerDeclined' },
                        ]}
                        showPaymentDate={() => false}
                    />
                </Modal>
                <ActionMenuItem
                    icon={faFileMedical}
                    onClick={() => createInvoice(document)}
                    labelKey="Invoice.Header.Menu.createInvoice"
                    id="Invoice.Header.Menu.createInvoice"></ActionMenuItem>
                <ActionMenuItem
                    icon={faFileMedical}
                    onClick={() => createTemplate(document)}
                    labelKey="Invoice.Header.Menu.makeTemplate"
                    id="Invoice.Header.Menu.makeTemplate"></ActionMenuItem>
                <ChangePricesAction enterFullPriceEnabled={configuration.enterFullPriceEnabled}></ChangePricesAction>
                <ActionMenuItem
                    icon={faHistory}
                    onClick={() => setIsLogModalVisible(true)}
                    allowActionWithoutAnyItems={true}
                    labelKey="Invoice.Header.Menu.log"
                    id="Invoice.Header.Menu.log"></ActionMenuItem>
                <Modal isOpen={isLogModalVisible}>
                    <TenderLogModal tenderId={params.tenderId} close={() => setIsLogModalVisible(false)} />
                </Modal>
                <ActionMenuItem
                    icon={faTrashAlt}
                    onClick={() => deleteTender(document)}
                    labelKey="Offers.Table.ItemMenu.delete"
                    id="Offers.Table.ItemMenu.delete"></ActionMenuItem>
            </>
        )
    }

    const QuickActionsComponent = ({ document }: { document: ConcreteTenderDocumentModel }) => {
        return (
            <>
                <QuickActionTitle id="Invoice.Side.actionsTitle" text={t('Invoice.Side.actionsTitle')} />
                <QuickActionSubtitle text={t('Invoice.Side.actionsDescription')} />
                <QuickActionItem
                    onClick={() => getDocument(document)}
                    icon={faPrint}
                    text={t('Invoice.Side.print')}
                    id="Invoice.Side.print"
                />
                <QuickActionItem
                    onClick={() => setSendEmailModalVisible(true)}
                    icon={faEnvelope}
                    text={t('Invoice.Side.sendMail')}
                    id="Invoice.Side.sendMail"
                />
                <QuickActionItem
                    icon={faFileMedical}
                    onClick={() => createInvoice(document)}
                    text={t('Invoice.Side.createInvoice')}
                    id="Invoice.Side.createInvoice"
                />
            </>
        )
    }

    const FooterComponent = ({
        document,
        formik,
    }: {
        document: ConcreteTenderDocumentModel
        formik: FormikProps<ConcreteTenderDocumentModel>
    }) => {
        return (
            <div className="notice__container notice__container--with-margin">
                <h2 className="notice__title">{t('Invoice.Table.Footer.remark')}</h2>
                <div className="notice__content remark-placeholder">
                    <InlineInputEdit
                        currentValue={document.details.paymentNoteForTender}
                        multiline
                        as="textarea"
                        preventSubmitOnEnter={true}
                        className="ng-inline-edit__text"
                        placeholderKey="Invoice.Table.Footer.remarkPlaceholder"
                        onSave={({ newValue }) => {
                            formik.setFieldValue(`details.paymentNoteForTender`, newValue)
                            formik.submitForm()
                        }}
                    />
                </div>
            </div>
        )
    }

    const HeaderComponent = ({
        document,
        formik,
        rateComponent,
    }: {
        document: ConcreteTenderDocumentModel
        formik: FormikProps<ConcreteTenderDocumentModel>
        rateComponent: JSX.Element
    }) => {
        return (
            <>
                <Field
                    shouldPreserveIdentity={true}
                    component={ClientInput}
                    name="client"
                    enterFullPriceEnabled={configuration.enterFullPriceEnabled}
                />

                <div className="info__row info__row--small">
                    <label className="info__row-name">{t('Invoice.Table.Date.title')}</label>

                    <div className="info__row-content">
                        <InlineDateEdit
                            showTimeInput
                            className="offerion-date"
                            currentValue={document.details.officialDate}
                            onSave={({ newValue }) => {
                                formik.setFieldValue('details.officialDate', newValue)
                                formik.submitForm()
                            }}
                        />
                    </div>
                </div>
                {rateComponent}
            </>
        )
    }

    const borderColorClassFactory = (doc: ConcreteTenderDocumentModel) => {
        switch (doc.details.status) {
            case TenderStatus.Draft:
                return 'blue'
            case TenderStatus.Sent:
                return 'yellow'
            case TenderStatus.Approved:
                return 'green'
            case TenderStatus.Declined:
                return 'red'
        }
    }

    const BeforeTableComponent = ({
        document,
        formik,
        printSettings,
        settingsComponent,
    }: {
        document: ConcreteTenderDocumentModel
        formik: FormikProps<ConcreteTenderDocumentModel>
        printSettings: CompanyPrintSettingsModel
        settingsComponent: JSX.Element
    }) => {
        return (
            <div className={`table__box border-left-${borderColorClassFactory(document)}`}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <h1 className="table__title table__title--inline">
                        <span className="table__title-separated">{printSettings.tenderTitle}</span>

                        <span className="table__title-separated">
                            <InlineInputEdit
                                currentValue={document.details.number}
                                placeholderKey="Invoice.Table.InvoiceNumber.offerNumberPlaceholder"
                                onSave={({ newValue }) => {
                                    formik.setFieldValue('details.number', newValue)
                                    formik.submitForm()
                                }}
                            />
                        </span>
                        <OfferionTooltip
                            placement="right"
                            titleKey="Invoice.Table.OfferNumber.infoTitle"
                            bodyKey="Invoice.Table.OfferNumber.infoText"
                        />
                    </h1>

                    {settingsComponent}
                </div>
            </div>
        )
    }

    const schema = Yup.object<ConcreteTenderDocumentModel>().shape({})

    return (
        <>
            <BreadcrumbsItem to={location.pathname}>{pageTitle}</BreadcrumbsItem>

            <DocumentDetailsContainer
                signatureSrc={
                    params.tenderId ? `api/company/getTenderSignatureResource/${params.tenderId}` : `api/company/getSignatureResource`
                }
                footerSrc={params.tenderId ? `api/company/getTenderFooterResource/${params.tenderId}` : `api/company/getFooterResource`}
                headerSrc={params.tenderId ? `api/company/getTenderHeaderResource/${params.tenderId}` : `api/company/getHeaderResource`}
                documentPromiseFactory={documentPromiseFactory}
                isFreeCompany={configuration.isFreeCompany}
                pageTitle={pageTitle}
                validationSchema={schema}
                showProductDimensionsInfo={configuration.showProductDimensionsInfo}
                editPurchasePrice={configuration.editPurchasePrice}
                canSubmit={() => true}
                canChangeVat={configuration.canChangeVat}
                defaultVat={configuration.defaultVat}
                isAccountingVat={configuration.isAccountingVat}
                onSubmit={upsertDocument}
                currencyOverlayKey={'Invoice.Settings.offerCurrency'}
                actionButtonsComponent={ActionComponent}
                quickActionsMenuComponent={QuickActionsComponent}
                headerComponent={HeaderComponent}
                beforeTableComponent={BeforeTableComponent}
                footerComponent={FooterComponent}
                borderColorClass={borderColorClassFactory}
                customRateFormTitleKey={'Invoice.Settings.pageTitleOffer'}
                successCreateKey="Invoice.SaveOffer.Message.successCreate"
                successUpdateKey="Invoice.SaveOffer.Message.successUpdate"
            />
        </>
    )
}
