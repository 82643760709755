import { useSelector } from "react-redux";
import { CompanyType, StoreState } from "../../models";

export const useRootStoreSelector = <TSelected = unknown>(
  selector: (state: StoreState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected => {
  const result = useSelector<StoreState, TSelected>(selector, equalityFn);

  return result;
};