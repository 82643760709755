import { ApiClient } from '../shared/api/api-client'
import { GridData } from '../shared/grid/Types'

export interface SalePlaceRowModel {
    id: number;
    name: string;
    address: string;
    isPrimarySalePlace: boolean;
}

export interface ClientGridRow {
    id: number
    name: string
    oib: string
    city: string
    country: string
    address: string
    email: string
    phone: string
    cellPhone: string
    fax: string
    clientRemark: string
    printRemark: string
    paymentDelayInDays: number;
    discountPercent: number;
}

export interface UserGridRow {

    id: number;
    firstName: string;
    lastName: string;
    email: string;
    title: string;
    oib: string;
    code: string;
    canCreateReceipts: boolean;
    isAdministrator: boolean;
    companySalePlaceId: number;

}

export interface CompanySalePlaceRow {
    id: number;
    name: string;
    address: string;
    isPrimarySalePlace: boolean;
}

export enum SortDirection {
    Desc = -1,
    Asc = 1
}

export class ListingApi {
    public static getList<TProjection>(projectionName: string, page: number, pageSize: number, filters?: any[] | null, customFilter?: string | null, orderByProperty?: string | null, sortDirection?: SortDirection | null) {
        return ApiClient.post<GridData<TProjection>>('api/listing/getlist', {
            projectionName,
            page,
            count: pageSize,
            filters: filters || [],
            customFilter,
            orderByProperty: orderByProperty || "Id",
            sortDirection: sortDirection || SortDirection.Desc
        })
    }
}
