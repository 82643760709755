/**
 * This sets the Userback replay tracking.
 * Only do it for PROD.
 */
export function enableUserbackReplay() {
  const isProd = process.env.REACT_APP_QA === 'false';

  if (!isProd) return false;

  // @ts-ignore
  window.Userback.startSessionReplay({
    block_rule: '.userback-block', // optional
    ignore_rule: '.userback-ignore', // optional
    log_level: ['log', 'warn', 'debug', 'error'],
  });
}

/**
 * This sets the Userback user info, so it's easy to track who's reporting tickets/errors on Userback.
 *
 * @param loginData the data returned from LoginAPI.login
 */
export function setUserbackUser(loginData: any) {
  // ensure we don't break the UI
  loginData = typeof loginData === "object" && loginData !== null ? loginData : {};

  const userInfo = extractUserInfo(loginData);

  // @ts-ignore
  window.Userback.identify(userInfo.email, {
    name: userInfo.name,
    email: userInfo.email,
    userId: userInfo.userId,
    role: userInfo.role,
    companyType: userInfo.companyType,
    uiLanguage: userInfo.uiLanguage
  });
}

function extractUserInfo(loginData: any) {
  const name =
    [loginData["FirstName"], loginData["LastName"]].filter(v => v)
      .join(" ")

  return {
    name: name,
    email: loginData["Email"],
    userId: loginData["UserId"],
    role: loginData["Role"],
    companyType: loginData["CompanyType"],
    uiLanguage: loginData["UiLanguage"]
  };
}