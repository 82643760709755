import { croatian } from "./croatian";
import { english } from "./english";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
  interpolation: { escapeValue: false },
  lng: "hr",
  fallbackLng: "hr",
  contextSeparator: ":",
  resources: {
    hr: {
      translation: croatian
    },
    en: {
      translation: english
    }
  },
  keySeparator: false
});

export default i18next;