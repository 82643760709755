import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup';
import { OfferionErrorMessage } from '../../shared/forms/validation/OfferionErrorMessage';
import { AccountApi, RegistrationData } from '../../services/AccountApi';
import { LoginApi } from '../login/LoginApi';
import { toastError, toastSuccess } from '../../shared/toastr';
import { UpdateAuthenticatedUser } from "../../shared/store/actions/userActions";
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../services/Spinner';
import { useOfferionTranslation } from '../../shared/store/hooks/useOfferionTranslation';
import { enableUserbackReplay, setUserbackUser } from '../../shared/utils/userback';

const questions = [
    {
        questionKey: 'Registration.securityQuestion1',
        answerKeys: [
            'Registration.securityQuestion1Answer1',
            'Registration.securityQuestion1Answer2',
        ]
    },
    {
        questionKey: 'Registration.securityQuestion2',
        answerKeys: [
            'Registration.securityQuestion2Answer1',
            'Registration.securityQuestion2Answer2'
        ]
    },
    {
        questionKey: 'Registration.securityQuestion3',
        answerKeys: [
            'Registration.securityQuestion3Answer1',
            'Registration.securityQuestion3Answer2'
        ]
    },
    {
        questionKey: 'Registration.securityQuestion4',
        answerKeys: [
            'Registration.securityQuestion4Answer1',
        ]
    },
    {
        questionKey: 'Registration.securityQuestion5',
        answerKeys: [
            'Registration.securityQuestion5Answer1',
            'Registration.securityQuestion5Answer2'
        ]
    },
]

export const RegistrationContainer = () => {

    const history = useHistory();
    const { t } = useOfferionTranslation();
    const dispatch = useDispatch();
    const [selectedQuestion] = useState(questions[Math.floor(Math.random() * 5)])

    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t("Registration.personaldataName") })),
        lastName: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.personaldataSurname') })),
        email: Yup.string()
            .email(t("Allaround.Validation.mail", { fieldName: t('Registration.personaldataEmail') }))
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.personaldataEmail') })),
        companyName: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.accountdataName') })),
        companyType: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.accountdataTypeSelector') })),
        password: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.accountdataPassword') }))
            .min(6, t("Allaround.Validation.minLength", { fieldName: t("Registration.accountdataPassword"), minLength: 6 })),
        questionAnswer: Yup.string()
            .required(t("Allaround.Validation.required", { fieldName: t('Registration.securityQuestion') }))
            .test('Check answer', t("Allaround.Validation.invalidAnswer"), function (val) {
                if (val == null || val == undefined)
                    return false;

                return selectedQuestion.answerKeys.map(x => t(x).toLocaleLowerCase()).includes(val.trim().toLocaleLowerCase())
            }),

    });

    const onSubmit = async (data: RegistrationData) => {
        try {
            Spinner.show()

            await AccountApi.register(data);

        } catch (e) {
            const isValidationError = e && e.response && e.response.data && e.response.data.modelState && e.response.data.modelState;

            if (isValidationError) {
                Object.values<string[]>(e.response.data.modelState).forEach((r: string[]) => toastError(r[0]))
            } else {
                toastError('Allaround.Message.unknownError');
            }

            return;
        }
        finally {
            Spinner.hide()
        }

        const result = await LoginApi.login(data.email, data.password);

        setUserbackUser(result);
        enableUserbackReplay();

        dispatch(UpdateAuthenticatedUser({ ...result, authenticated: true }));

        toastSuccess('Registration.showSuccessMessage');

        history.push("/dashboard");
    }

    return <main className="main registration">
        <div className="main__header">
            <div className="main-title">{t('Registration.title')}</div>
        </div>

        <div className="card">
            <h1 className="card__logo"><img src="/assets/images/logo_2x.png" alt="Izračunko logo" /></h1>
            <div className="separator separator--short"></div>
            <em>{t('Registration.promoText')}</em>
        </div>

        <main className="table__container registration__container">
            <Formik validationSchema={schema} initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                companyName: "",
                companyType: "",
                questionAnswer: ""
            }} onSubmit={onSubmit}>
                <Form noValidate={true}>
                    <h1 className="table__title">{t('Registration.personaldataTitle')}</h1>

                    <div className="table__outer">
                        <div className="info__container">
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="first-name">{t('Registration.personaldataName')}</label>
                                <div className="info__row-content">
                                    <Field id="first-name" type="text" placeholder={t('Registration.personaldataNamePlaceholder')} name="firstName" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="firstName" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="last-name">{t('Registration.personaldataSurname')}</label>
                                <div className="info__row-content">
                                    <Field id="last-name" type="text" placeholder={t('Registration.personaldataSurnamePlaceholder')} name="lastName" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="lastName" />
                                </div>
                            </div>


                            <div className="info__row">
                                <label className="info__row-name" htmlFor="email">{t('Registration.personaldataEmail')}</label>

                                <div className="info__row-content">
                                    <Field id="email" type="email" placeholder={t('Registration.personaldataEmailPlaceholder')} name="email" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="email" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <h1 className="table__title">{t('Registration.accountdataTitle')}</h1>

                    <div className="table__outer">
                        <div className="info__container">

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="company-name">{t('Registration.accountdataName')}</label>
                                <div className="info__row-content">
                                    <Field id="company-name" type="text" placeholder={t('Registration.accountdataNamePlaceholder')} name="companyName" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="companyName" />
                                </div>
                            </div>
                            <div className="info__row">
                                <label className="info__row-name" htmlFor="company-type">{t('Registration.accountdataTypeSelector')}</label>
                                <div className="info__row-content">
                                    <div className="info__select-container--inline select-container">
                                        <Field as="select" id="company-type" className="select" name="companyType">
                                            <option value="">{t('Registration.accountdataTypeSelectorPlaceholder')}</option>
                                            <option value={0}>{t("Allaround.CompanyType.basic")}</option>
                                            <option value={1}>{t("Allaround.CompanyType.lawyer")}</option>
                                            <option value={2}>{t("Allaround.CompanyType.windowMaker")}</option>
                                            {/* <option value={3}>{t("Allaround.CompanyType.renter")}</option> */}
                                            {/* <option value={4}>{t("Allaround.CompanyType.other")}</option> */}
                                        </Field>
                                    </div>
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="companyType" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="password">{t('Registration.accountdataPassword')}</label>

                                <div className="info__row-content">
                                    <Field id="password" type="password" placeholder={t('Registration.accountdataPasswordPlaceholder')} name="password" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="password" />
                                </div>
                            </div>

                            <div className="info__row">
                                <label className="info__row-name" htmlFor="questionAnswer">{t(selectedQuestion.questionKey)}</label>

                                <div className="info__row-content">
                                    <Field id="questionAnswer" type="text" placeholder={t('Registration.securityQuestionPlaceholder')} name="questionAnswer" />
                                    <OfferionErrorMessage showOnlyOnTouched={true} name="questionAnswer" />
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="registration__footer">
                        <div className="rapidssl">
                            <img src="assets/images/rapidssl.gif" alt="RapidSSL Security" />
                        </div>

                        <button className="button button--blue registration__button" type="submit">{t('Registration.registerButton')}</button>
                    </div>
                </Form>
            </Formik>

        </main>

        <ul className="card card-testimony__list">
            <li className="card-testimony__item">
                <img className="card-testimony__item__image" src="/assets/images/testimony1.png" alt="" />
                <span className="card-testimony__item__name">{t('Registration.testimonial1Name')}</span>
                <div className="card-testimony__item__quote" dangerouslySetInnerHTML={{ __html: t('Registration.testimonial1Text') }}></div>
            </li>
            <li className="card-testimony__item">
                <img className="card-testimony__item__image" src="/assets/images/testimony2.png" alt="" />
                <span className="card-testimony__item__name">{t('Registration.testimonial2Name')}</span>
                <div className="card-testimony__item__quote" dangerouslySetInnerHTML={{ __html: t('Registration.testimonial2Text') }}></div>
            </li>
            <li className="card-testimony__item">
                <img className="card-testimony__item__image" src="/assets/images/testimony3.png" alt="" />
                <span className="card-testimony__item__name">{t('Registration.testimonial3Name')}</span>
                <div className="card-testimony__item__quote" dangerouslySetInnerHTML={{ __html: t('Registration.testimonial3Text') }}></div>
            </li>
        </ul>

    </main>

}